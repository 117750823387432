import {TIME_TRACKING_LOGOUT, TIME_TRACKING_SUCCESS, TIME_TRACKING_ERROR, TIME_TRACKING_REQUEST} from "../actionTypes";

export const initialState = {
    isTimeTrackingInit: false,
    timeTrackingRequest: false,
    timeTrackingError: null,
    timeTrackingData: null
}

export const timeTrackingReducer = (initialState, action) => {
    switch (action.type) {
        case TIME_TRACKING_REQUEST:
            return {
                ...initialState, timeTrackingRequest: true, timeTrackingError: null
            }
        case TIME_TRACKING_ERROR:
            return {
                ...initialState, isTimeTrackingInit: true, timeTrackingRequest: false, timeTrackingError: action.timeTrackingError, timeTrackingData: null
            }
        case TIME_TRACKING_SUCCESS:
            return {
                ...initialState, isTimeTrackingInit: true, timeTrackingRequest: false, timeTrackingError: null, timeTrackingData: action.timeTrackingData
            }
        case TIME_TRACKING_LOGOUT:
            return {
                ...initialState, timeTrackingError: null, timeTrackingData: null
            }
        default:
            return initialState;
    }
}
