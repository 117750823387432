import React, {Fragment, useState} from "react";
import moment from "moment/moment";
import BuyerOrderObjectItemsList from "./BuyerOrderObjectItemsList";
import ModalBuyerOrderCommentEdit from "./ModalBuyerOrderCommentEdit";
import ModalBuyerOrderStateEdit from "./ModalBuyerOrderStateEdit";
import BuyerOrderObjectInfoView from "./BuyerOrderObjectInfoView";
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";
import BuyerOrderObjectWarehouseView from "./BuyerOrderObjectWarehouseView";
import BuyerOrderObjectHistoryView from "./BuyerOrderObjectHistoryView";
import ModalBuyerOrderExpertAssessmentEdit from "./ModalBuyerOrderExpertAssessmentEdit";
import {
  ButtonIcon,
  getAccountFio,
  Icon,
  isProfileRoleTypeTags,
  TextLink,
  TextSearchMarker,
  Tooltip,
  useProfile
} from "@vokymlak/kabinet-ui";
import {ROLE_ADMINISTRATOR, ROLE_DIRECTOR, ROLE_EMPLOYEE} from "../../../config/constants";
import {NavLink} from "react-router-dom";
import ModalBuyerOrderDateEdit from "./ModalBuyerOrderDateEdit";
import ModalBuyerOrderAllPrint from "./ModalBuyerOrderAllPrint";
import ModalBuyerOrderStateNewEdit from "./ModalBuyerOrderStateNewEdit";
import ModalBuyerOrderEdit from "./ModalBuyerOrderEdit";

function BuyerOrderObjectView(props) {
  const {
    object,
    isUnroll,
    changeUnroll,
    searchQuery,
    modalBuyerOrderCommentEdit,
    setModalBuyerOrderCommentEdit,
    modalBuyerOrderDateEdit,
    setModalBuyerOrderDateEdit,
    modalBuyerOrderStateEdit,
    setModalBuyerOrderStateEdit,
    modalBuyerOrderStateNewEdit,
    setModalBuyerOrderStateNewEdit,
    isUpdateList,
    setIsUpdateList,

    isSelect,
    selectOrders, setSelectOrders,
    isSelectOrder, changeSelectOrder,
  } = props;

  const {profile} = useProfile();

  const [tumbler, setTumbler] = useState("a");
  const [modalBuyerOrderExpertAssessmentEdit, setModalBuyerOrderExpertAssessmentEdit] = useState(null);
  const [modalBuyerOrderAllPrint, setModalBuyerOrderAllPrint] =
    useState(null);
  const [modalBuyerOrderEdit, setModalBuyerOrderEdit] =
    useState(null);

  function getTimeDiff(start, end) {
    const diff = moment.duration(moment(end).diff(moment(start)));
    const days = diff.days();
    const hours = diff.hours();
    const minutes = diff.minutes();
    return `${days} д. ${hours} ч. ${minutes} м.`;
  }

  const onContextMenuAllPrint = (event, data) => {
    event.preventDefault();
    setModalBuyerOrderAllPrint(data);
  };

  return (
    !!object && (
      <Fragment>
        <li
          className={isUnroll(object.uuid) ? "List__item active" : "List__item"}
          onClick={!!isSelect && isSelect ? () => changeSelectOrder(object.uuid) : () => changeUnroll(object.uuid)}

          style={{
            padding: "10px 12px",
            backgroundColor:
              !!isSelectOrder && isSelectOrder(selectOrders, object.uuid) ? "#0061ff25" :
                !object.is_final_state
                  ? !object.is_excepted_state
                    ? !!object.date_shipment && !!moment(
                    moment(
                      object.date_shipment
                    ).format("YYYY-MM-DD")
                  ).isSameOrBefore(moment().format("YYYY-MM-DD"))
                    ? "#ef9a9a95"
                    : moment(
                      object.date_shipment
                    ).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                      ? "#ffe082"
                      : false
                    : false
                  : "#a5d6a795",
            position: "relative",
          }}
        >
          <div className="Item__title">
            <div className="Element__title Element__title_flex">
              <div>
                <div>
                  <TextSearchMarker text={object.number} search={searchQuery}/>
                  &nbsp;
                  {!!object.date &&
                    "от " +
                    moment(object.date.substring(0, 19)).format(
                      "DD.MM.YYYY HH:mm:ss"
                    )}
                </div>
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 400,
                  }}
                >
                  {(!!object.organization &&
                    object.organization
                      .toUpperCase()
                      .replace('ООО', '')
                      .replace('ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ', '')
                      .replaceAll('\"', '')) || "-"}

                  {" | "}

                  {!!object.contractor && (
                    <TextSearchMarker
                      text={object.contractor}
                      search={searchQuery}
                    />
                  )}
                  {!!object.contractor && !!object.recipient_fio && (
                    <>
                      {" ("}
                      <TextSearchMarker
                        text={object.recipient_fio}
                        search={searchQuery}
                      />
                      {")"}
                    </>
                  )}
                  {!object.contractor && !!object.recipient_fio && (
                    <TextSearchMarker
                      text={object.recipient_fio}
                      search={searchQuery}
                    />
                  )}
                </div>

                {!!object.packer && (
                  <div
                    className="Title__text"
                    style={{
                      marginTop: 6,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        padding: "3px 9px 3px 9px",
                        backgroundColor: "#dfdfdf",
                        margin: "0 2px",
                        borderRadius: 24,
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <span
                        style={{
                          fontSize: 10,
                          position: "absolute",
                          left: 12,
                          top: -6,
                          opacity: 0.75,
                        }}
                      >
                        Фасовщик
                      </span>
                      <span>{getAccountFio(object.packer)}</span>
                    </div>
                  </div>
                )}
              </div>
              {!!object.amount && (
                <span
                  style={{
                    marginLeft: 12,
                  }}
                >
                  ({Number(object.amount) / 100}
                  <span
                    style={{
                      marginLeft: 2,
                      fontSize: 12,
                      fontWeight: 400,
                      opacity: 0.75,
                    }}
                  >
                    руб.
                  </span>
                  )
                </span>
              )}
              {!object.amount && (
                <span
                  style={{
                    marginLeft: 12,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  (
                  <span>
                    <Icon name={"custom-alert"} size={24} color={"#ff0500"}/>
                  </span>
                  )
                </span>
              )}
              {!!object.comment && (
                <div
                  style={{
                    margin: "0 12px",
                  }}
                >
                  <Icon name={"custom-comment"} size={24} color={"#202020"}/>
                </div>
              )}
              {!!object.packing_comment && (
                <div
                  style={{
                    margin: "0 12px",
                  }}
                >
                  <Icon name={"custom-to-comment"} size={24} color={"#202020"}/>
                </div>
              )}

              <div style={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                top: -8,
                left: 0,
                justifyContent: " flex-end",
                width: "100%",
                padding: "0 8px",
              }}>

                {!!object.date_dispatch && (
                  <div
                    style={{
                      fontSize: 11,
                      fontWeight: 700,
                      backgroundColor: "#2e7d32",
                      display: "inline-block",
                      color: "white",
                      padding: "2px 4px 2px",
                      borderRadius: 3,
                      margin: "0 2px",
                    }}
                  >
                    {"Доставлено: "}
                    {!!object.date_dispatch &&
                      moment(object.date_dispatch.substring(0, 19)).format(
                        "DD.MM.YYYY HH:mm"
                      )}
                  </div>
                )}

                {!object.is_excepted_state && !!object.history && (
                  <div
                    style={{
                      fontSize: 11,
                      fontWeight: 700,
                      backgroundColor: "#0066b0",
                      display: "inline-block",
                      color: "white",
                      padding: "2px 4px 2px",
                      borderRadius: 3,
                      margin: "0 2px",
                    }}
                  >

                    {!!object.history.at(-1) && !!object.date_dispatch &&
                      getTimeDiff(object.history.at(-1).created_at.substring(0, 19), object.date_dispatch.substring(0, 19))}

                    {!!object.history.at(-1) && !object.date_dispatch &&
                      getTimeDiff(object.history.at(-1).created_at.substring(0, 19), moment().format("YYYY-MM-DD HH:mm:ss"))}
                  </div>
                )}

                {!object.is_excepted_state && !!object.date_shipment && (
                  <div
                    style={{
                      fontSize: 11,
                      fontWeight: 700,
                      backgroundColor: "#202020",
                      display: "inline-block",
                      color: "white",
                      padding: "2px 4px 2px",
                      borderRadius: 3,
                      margin: "0 2px",
                    }}
                  >
                    {"Отгрузка: "}
                    {!!object.date_shipment &&
                      moment(object.date_shipment.substring(0, 19)).format(
                        "DD.MM.YYYY"
                      )}
                  </div>
                )}

                {!!object.is_deleted && (
                  <div
                    style={{
                      fontSize: 11,
                      fontWeight: 700,
                      backgroundColor: "#f44336",
                      display: "inline-block",
                      color: "white",
                      padding: "2px 4px 2px",
                      borderRadius: 3,
                      margin: "0 2px",
                    }}
                  >
                    Удалено в интеграции
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="Item__settings">
            {/*{!!object.buyer_order_state && object.buyer_order_state.name}*/}

            {!!object.expert_assessment && (

              <Tooltip text={'Оценка сложности'}>
                <div style={{
                  backgroundColor: "#202020",
                  borderRadius: 7,
                  padding: 1,
                  height: 26,
                  display: "inline-block",
                  marginRight: 6,
                }}>
                  <Icon name={
                    object.expert_assessment === 1
                      ? "custom-looks-1"
                      : object.expert_assessment === 2
                        ? "custom-looks-2"
                        : object.expert_assessment === 3
                          ? "custom-looks-3"
                          : object.expert_assessment === 4
                            ? "custom-looks-4"
                            : object.expert_assessment === 5
                              ? "custom-looks-5"
                              : ""
                  } color={
                    object.expert_assessment === 1
                      ? "#c0ca33"
                      : object.expert_assessment === 2
                        ? "#fdd835"
                        : object.expert_assessment === 3
                          ? "#ffb300"
                          : object.expert_assessment === 4
                            ? "#fb8c00"
                            : object.expert_assessment === 5
                              ? "#f4511e"
                              : ""
                  } size={24}/>
                </div>
              </Tooltip>
            )}

            {!!object.buyer_order_state && (
              <div
                style={{
                  fontSize: 13,
                  fontWeight: 700,
                  // backgroundColor: !!object.buyer_order_state.color ? object.buyer_order_state.color : "#bdbdbd",
                  // backgroundColor: "#202020",
                  backgroundColor: "white",
                  display: "inline-block",
                  color: "#202020",
                  padding: "4px 8px 2px",
                  borderRadius: 24,
                  margin: "0 2px",
                }}
              >
                {object.buyer_order_state.name}
              </div>
            )}
          </div>
        </li>

        {isUnroll(object.uuid) && (
          <li className={isUnroll(object.uuid) ? "List__content active" : "List__content"}>
            <div className="Content__header" style={{marginBottom: 12, height: "auto"}}>
              <div className="Header__title" style={{}}>
                {isProfileRoleTypeTags(profile, [
                    ROLE_ADMINISTRATOR,
                    ROLE_DIRECTOR,
                  ]) &&
                  (
                    <div className="Header__title"
                         style={{
                           marginRight: 24,
                         }}>
                      <NavLink to={"/order/" + object.uuid} target={'_blank'}>
                        <div className="Button__text">Подробнее</div>
                      </NavLink>
                    </div>
                  )}

                {isProfileRoleTypeTags(profile, [
                    ROLE_ADMINISTRATOR,
                    ROLE_DIRECTOR,
                  ]) &&
                  (
                    <div
                      className="Button__text"
                      style={{
                        marginRight: 24,
                      }}
                      onClick={() => setModalBuyerOrderExpertAssessmentEdit(object)}
                      onContextMenu={() => setModalBuyerOrderExpertAssessmentEdit(object)}
                    >
                      Оценка сложности
                    </div>
                  )}

                {isProfileRoleTypeTags(profile, [
                    ROLE_ADMINISTRATOR,
                    ROLE_DIRECTOR,
                  ]) &&
                  (
                    <div
                      className="Button__text"
                      style={{
                        marginRight: 24,
                      }}
                      onClick={() => setModalBuyerOrderDateEdit(object)}
                      onContextMenu={() => setModalBuyerOrderDateEdit(object)}
                    >
                      Дата отгрузки
                    </div>
                  )}
              </div>

              <div className="Header__settings">
                {!object.is_final_state && !object.is_excepted_state && (
                  <div
                    className="Button__text"
                    onClick={() => setModalBuyerOrderCommentEdit(object)}
                    onContextMenu={() => setModalBuyerOrderCommentEdit(object)}
                  >
                    Комментарий
                  </div>
                )}

                {isProfileRoleTypeTags(profile, [
                  ROLE_ADMINISTRATOR,
                  ROLE_DIRECTOR,
                ]) && (
                  <div
                    className="Button__text Button__text_left"
                    onClick={() => setModalBuyerOrderStateEdit(object)}
                    onContextMenu={() => setModalBuyerOrderStateEdit(object)}
                  >
                    Статус
                  </div>
                )}

                {!object.is_final_state && !object.is_excepted_state &&  isProfileRoleTypeTags(profile, [
                  ROLE_ADMINISTRATOR,
                  ROLE_DIRECTOR,
                  ROLE_EMPLOYEE,
                ]) && (
                  <div
                    className="Button__text Button__text_left"
                    onClick={() => setModalBuyerOrderStateNewEdit(object)}
                    onContextMenu={() => setModalBuyerOrderStateNewEdit(object)}
                  >
                    Перевести статус
                  </div>
                )}

                {isProfileRoleTypeTags(profile, [
                  ROLE_ADMINISTRATOR,
                  ROLE_DIRECTOR,
                ]) && (
                  <ButtonIcon
                    iconName={"custom-edit"}
                    iconColor={"#202020"}
                    size={36}
                    style={{marginLeft: 16, marginRight: 0}}
                    onClick={() => setModalBuyerOrderEdit(object)}
                  />
                )}
              </div>
            </div>

            {!!object.comment && (
              <div className="Content__header" style={{marginBottom: 12, height: "auto"}}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "12px",
                    border: "2px dashed #bdbdbd",
                    borderRadius: 5,
                  }}
                >
                  <div
                    style={{
                      marginRight: 12,
                    }}
                  >
                    <Icon name={"custom-comment"} size={24} color={"#202020"}/>
                  </div>
                  <div
                    style={{
                      fontSize: 19,
                    }}
                  >
                    <TextLink text={object.comment}/>
                  </div>
                </div>
              </div>
            )}

            {!!object.packing_comment && (
              <div className="Content__header" style={{marginBottom: 12, height: "auto"}}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "12px",
                    border: "2px solid #bdbdbd",
                    borderRadius: 5,
                  }}
                >
                  <div
                    style={{
                      marginRight: 12,
                    }}
                  >
                    <Icon
                      name={"custom-to-comment"}
                      size={24}
                      color={"#202020"}
                    />
                  </div>
                  <div
                    style={{
                      fontSize: 19,
                    }}
                  >
                    {object.packing_comment}
                  </div>
                </div>
              </div>
            )}

            <div className="Content__header" style={{marginBottom: 12}}>
              <div className="Header__title" style={{}}>
                <ul className={styleTumbler._}>
                  <li className={styleTumbler._link}>
                    <a
                      className={
                        styleTumbler._text +
                        ((!!tumbler &&
                            tumbler === "a" &&
                            " " + styleTumbler.active + " ") ||
                          "")
                      }
                      onClick={() => setTumbler("a")}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Позиции
                    </a>
                  </li>
                  <li className={styleTumbler._link}>
                    <a
                      className={
                        styleTumbler._text +
                        ((!!tumbler &&
                            tumbler === "b" &&
                            " " + styleTumbler.active + " ") ||
                          "")
                      }
                      onClick={() => setTumbler("b")}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Информация
                    </a>
                  </li>
                  <li className={styleTumbler._link}>
                    <a
                      className={
                        styleTumbler._text +
                        ((!!tumbler &&
                            tumbler === "c" &&
                            " " + styleTumbler.active + " ") ||
                          "")
                      }
                      onClick={() => setTumbler("c")}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Обеспечение
                    </a>
                  </li>
                  {/*{isProfileRoleTypeTags(profile, [*/}
                  {/*    ROLE_ADMINISTRATOR,*/}
                  {/*    ROLE_DIRECTOR,*/}
                  {/*  ]) &&*/}
                  {/*  (*/}
                  <li className={styleTumbler._link}>
                    <a
                      className={
                        styleTumbler._text +
                        ((!!tumbler &&
                            tumbler === "d" &&
                            " " + styleTumbler.active + " ") ||
                          "")
                      }
                      onClick={() => setTumbler("d")}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      История
                    </a>
                  </li>
                  {/*)}*/}
                </ul>
              </div>
              <div className="Header__settings">
                {!object.is_final_state && !object.is_excepted_state && (
                  <div
                    className={
                      "Action__button Action__button_blue"
                    }
                    style={{
                      height: 28,
                      padding: "3px 8px 4px",
                      cursor: "pointer"
                    }}
                    onClick={() =>
                      setModalBuyerOrderAllPrint(object)
                    }
                    onContextMenu={(event) =>
                      onContextMenuAllPrint(event, object)
                    }
                  >
                    Печать этикеток
                  </div>
                )}
              </div>
            </div>

            {!!tumbler && tumbler === "a" && (
              <BuyerOrderObjectItemsList
                object={object}
                isUpdateList={isUpdateList}
                setIsUpdateList={setIsUpdateList}
              />
            )}

            {!!tumbler && tumbler === "b" && (
              <BuyerOrderObjectInfoView object={object}/>
            )}

            {!!tumbler && tumbler === "c" && (
              <BuyerOrderObjectWarehouseView object={object}/>
            )}

            {!!tumbler && tumbler === "d" && (
              <BuyerOrderObjectHistoryView object={object}/>
            )}
          </li>
        )}

        {!!modalBuyerOrderStateEdit && (
          <ModalBuyerOrderStateEdit
            buyerOrder={modalBuyerOrderStateEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalBuyerOrderStateEdit(null)}
          />
        )}

        {!!modalBuyerOrderStateNewEdit && (
          <ModalBuyerOrderStateNewEdit
            buyerOrder={modalBuyerOrderStateNewEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalBuyerOrderStateNewEdit(null)}
          />
        )}


        {!!modalBuyerOrderDateEdit && (
          <ModalBuyerOrderDateEdit
            buyerOrder={modalBuyerOrderDateEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalBuyerOrderDateEdit(null)}
          />
        )}


        {!!modalBuyerOrderCommentEdit && (
          <ModalBuyerOrderCommentEdit
            buyerOrder={modalBuyerOrderCommentEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalBuyerOrderCommentEdit(null)}
          />
        )}

        {!!modalBuyerOrderExpertAssessmentEdit && (
          <ModalBuyerOrderExpertAssessmentEdit
            buyerOrder={modalBuyerOrderExpertAssessmentEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalBuyerOrderExpertAssessmentEdit(null)}
          />
        )}

        {!!modalBuyerOrderAllPrint && (
          <ModalBuyerOrderAllPrint
            object={modalBuyerOrderAllPrint}
            successfulRequest={() => setModalBuyerOrderAllPrint(null)}
            handleClose={() => setModalBuyerOrderAllPrint(null)}
          />
        )}

        {!!modalBuyerOrderEdit && (
          <ModalBuyerOrderEdit
            object={modalBuyerOrderEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalBuyerOrderEdit(null)}
          />
        )}

      </Fragment>
    )
  );
}

export default BuyerOrderObjectView;
