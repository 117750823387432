import React, {useState} from "react";
import {b64EncodeUnicode, ButtonIcon, ButtonIconExport, Loading} from "@vokymlak/kabinet-ui";
import moment from "moment";
import {formatAmount} from "../../../functions/formatAmount";
import ModalBuyerOrderShipmentEdit from "./ModalBuyerOrderShipmentEdit";
import {Select} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {NavLink} from "react-router-dom";
import ModalBuyerOrderDateEdit from "../../ScreenOrders/BuyerOrderObject/ModalBuyerOrderDateEdit";

function PlanShipmentsPage(props) {
  const {
    object,
    setObject,

    setIsUpdateObject,
    isUpdateObject,

    selectTransportCompany,
    listTransportCompanies,
    changeSelectTransportCompany,
  } = props;

  const getTransportCompaniesSelectOptions = (list) => {
    const options = [
      {
        value: '',
        title: 'Все',
      }
    ];

    list.map((item) => {
      const option = {
        value: item,
        title: item,
      };

      options.push(option);
    });

    return options;
  };

  const [modalBuyerOrderCollected, setModalBuyerOrderCollected] =
    useState(null);

  const [modalBuyerOrderDateEdit, setModalBuyerOrderDateEdit] =
    useState(null);

  const stringToColour = (str) => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  };

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
    <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 21,
            fontWeight: 700,
          }}
        >
          План отгрузки
          {!!object && (
            <ButtonIconExport
              tooltipText={"Выгрузить"}
              iconName={"custom-download"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 12 }}
              apiUrl={'/api/plan/shipments/export/xlsx?transport_company=' + selectTransportCompany}
              rootUrl={ROOT_URL}
              mimeType={
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              }
              fileName={
                ("план_отгрузки"+"_на_"+moment().format("DD_MM_YYYY")+".xlsx").toLowerCase()}
            />
          )}

          <span style={{ width: 220, marginLeft: 24, fontWeight: 400 }}>
            <Select
              isFixed={true}
              isDefaultOption={false}
              value={''}
              options={
                !!listTransportCompanies
                  ? getTransportCompaniesSelectOptions(listTransportCompanies)
                  : getTransportCompaniesSelectOptions([])
              }
              label="Транспортная"
              onChange={(event) => changeSelectTransportCompany(event.target.value)}
            />
          </span>
        </div>

        <div className="Title__settings">
          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 12 }}
            onClick={() => (
              setObject(null),
                setIsUpdateObject(!isUpdateObject)
            )}
          />
        </div>
      </div>

      {!!object && !!object.order_states && (
        <div className="Page__title" style={{height: "auto"}}>
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            {object.order_states.map(state => (
              <div key={state.uuid} style={{
                padding: "4px 6px",
                backgroundColor: !!state.color ? state.color+"25" : "#dbdbdb",
                display: "inline-block",
                marginRight: 6,
                borderRadius: 4,
                fontSize: 14,
                fontWeight: 400,
              }}>
                {state.name}
              </div>
            ))}
          </div>
        </div>
      )}

      <hr className={"Page__hr"} />


      {!object && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}



      {!!object && (
      <div className="Page__content">

          {!!object.orders && (
            <table className="Table" style={{marginBottom: 24}}>
              <thead className="Thead">
              <tr className="Table__tr Table__tr_padding Table__tr_border">
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title"></div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Дата</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Номер</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Состояние</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Покупатель</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Сумма</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Оплата</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Дата отгрузки</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">ТК</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Отправлено</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Примечание</div>
                </th>
              </tr>
              </thead>
              <tbody>
              {object.orders.map((item, index) => (
                <tr
                  className="Table__tr Table__tr_hover Table__tr_padding Table__tr_border"
                  key={"item_" + index}
                >
                  <td className="Table__td Table__td_border"
                      style={{
                        backgroundColor: (!!item.buyer_order_state && item.buyer_order_state.color) ? item.buyer_order_state.color+"33" : "#dfdfdf",
                      }}>
                    <div className="Td__text Td__text_center">
                      {index + 1}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border"
                      style={{
                        backgroundColor: (!!item.buyer_order_state && item.buyer_order_state.color) ? item.buyer_order_state.color+"33" : "#dfdfdf",
                      }}>
                    <div className="Td__text Td__text_center">
                      {(!!item.date &&
                        moment(item.date.substring(0, 19)).format(
                          "DD.MM.YYYY HH:mm"
                        )) || "-"}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border"
                      style={{
                        backgroundColor: (!!item.buyer_order_state && item.buyer_order_state.color) ? item.buyer_order_state.color+"33" : "#dfdfdf",
                      }}>
                    <div className="Td__text Td__text_center">
                      <div style={{
                        cursor: "pointer"
                      }}>
                        {(!!item.number && (
                        <NavLink to={"/order/" + item.uuid} target={'_blank'} key={'link_'+item.uuid}>
                          <div className="Button__text" style={{
                            fontSize: 15,
                            textDecoration: "underline"
                          }}>{item.number}</div>
                        </NavLink>
                        )) || "-"}
                      </div>
                    </div>
                  </td>
                  <td className="Table__td Table__td_border"
                      style={{
                        backgroundColor: (!!item.buyer_order_state && item.buyer_order_state.color) ? item.buyer_order_state.color+"33" : "#dfdfdf",
                      }}>
                    <div className="Td__text Td__text_center">
                      {(!!item.buyer_order_state && item.buyer_order_state.name) || "-"}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border"
                      style={{
                        backgroundColor: (!!item.buyer_order_state && item.buyer_order_state.color) ? item.buyer_order_state.color+"33" : "#dfdfdf",
                      }}>
                    <div className="Td__text Td__text_center">
                      <div
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {!!item.contractor && item.contractor}
                        {!!item.contractor && !!item.recipient_fio && (
                          <>
                            {" ("}
                            {item.recipient_fio}
                            {")"}
                          </>
                        )}
                        {!item.contractor && !!item.recipient_fio && item.recipient_fio}
                      </div>
                    </div>
                  </td>
                  <td className="Table__td Table__td_border"
                      style={{
                        backgroundColor: (!!item.buyer_order_state && item.buyer_order_state.color) ? item.buyer_order_state.color+"33" : "#dfdfdf",
                      }}>
                    <div className="Td__text Td__text_center">
                      {(!!item.amount && formatAmount(item.amount)) || "-"}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border"
                      style={{
                        backgroundColor: !!item.payment_status ? stringToColour(b64EncodeUnicode(item.payment_status).toUpperCase())+"75" : "#dfdfdf",
                      }}>
                    <div className="Td__text Td__text_center">
                      {(!!item.payment_status && item.payment_status.toUpperCase()) || "-"}
                    </div>
                  </td>

                  <td className="Table__td Table__td_border"
                      onClick={() => setModalBuyerOrderDateEdit(item)}
                  style={{
                    backgroundColor:
                      !!item.date_shipment && !!moment(
                        moment(
                          item.date_shipment
                        ).format("YYYY-MM-DD")
                      ).isSameOrBefore(moment().format("YYYY-MM-DD"))
                        ? "#ef9a9a"
                        : moment(
                          item.date_shipment
                        ).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                          ? "#ffe082"
                          : false,
                    cursor: "pointer"
                  }}>
                    <div className="Td__text Td__text_center">
                      {(!!item.date_shipment &&
                        moment(item.date_shipment.substring(0, 19)).format(
                          "DD.MM.YYYY"
                        )) || "-"}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border"
                      style={{
                        backgroundColor: !!item.transport_company ? stringToColour(b64EncodeUnicode((item.transport_company)))+"75" : "#dfdfdf",
                      }}>
                    <div className="Td__text Td__text_center">
                      {(!!item.transport_company && item.transport_company.toUpperCase()) || "-"}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border"
                      style={{
                        cursor: "pointer"
                      }}
                      onClick={() =>
                        setModalBuyerOrderCollected(item)
                      }>
                    <div className="Td__text Td__text_center"
                         >
                      {(!!item.state_shipment && item.state_shipment) || "-"}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border"
                      style={{
                        cursor: "pointer"
                      }}
                      onClick={() =>
                        setModalBuyerOrderCollected(item)
                      }>
                    <div className="Td__text Td__text_center"
                         >
                      {(!!item.note_shipment && item.note_shipment) || "-"}
                    </div>
                  </td>
                </tr>
              ))}
      </tbody>
        </table>
        )}
    </div>
      )}

      {!!modalBuyerOrderDateEdit && (
        <ModalBuyerOrderDateEdit
          buyerOrder={modalBuyerOrderDateEdit}
          successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
          handleClose={() => setModalBuyerOrderDateEdit(null)}
        />
      )}

      {!!modalBuyerOrderCollected && (
        <ModalBuyerOrderShipmentEdit
          buyerOrder={modalBuyerOrderCollected}
          successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
          handleClose={() => setModalBuyerOrderCollected(null)}
        />
      )}

    </div>
    </ScrollBlock>
  );
}

export default PlanShipmentsPage;
