import React, {Fragment, useState} from "react";
import {Icon, useList} from "@vokymlak/kabinet-ui";
import NomenclatureSpecifications
  from "../../ScreenNomenclatures/NomenclatureItem/NomenclatureSpecifications/NomenclatureSpecifications";
import ModalBuyerOrderCollected from "./ModalBuyerOrderCollected";
import {ROOT_URL} from "../../../config/url";
import ModalBuyerOrderPrint from "./ModalBuyerOrderPrint";

function BuyerOrderObjectItemsList(props) {
  const {object, isUpdateList, setIsUpdateList} = props;

  const {
    list: [list],
  } = useList({
    apiUrl: "/api/order/" + object.uuid + "/items",
    rootUrl: ROOT_URL
  });

  const [modalBuyerOrderCollected, setModalBuyerOrderCollected] =
    useState(null);
  const [modalBuyerOrderPrint, setModalBuyerOrderPrint] =
    useState(null);

  const [selectComposition, setSelectComposition] = useState(null);

  const isSelectComposition = (composition) => {
    return !!selectComposition && selectComposition.uuid === composition.uuid;
  };

  const changeSelectComposition = (composition) => {
    if (!!selectComposition && selectComposition.uuid === composition.uuid) {
      setSelectComposition(null);
    } else {
      setSelectComposition(composition);
    }
  };

  const onContextMenuCollected = (event, data) => {
    event.preventDefault();
    setModalBuyerOrderCollected(data);
  };

  const onContextMenuPrint = (event, data) => {
    event.preventDefault();
    setModalBuyerOrderPrint(data);
  };

  const isStickers = (collecting) => {
    let isStickers = false;
    if (!!collecting) {
      collecting.forEach((c) => {
        if (c.is_stickers) {
          isStickers = true;
        }
      });
    }
    return isStickers;
  };

  const isGlued = (collecting) => {
    let isGlued = false;
    if (!!collecting) {
      collecting.forEach((c) => {
        if (c.is_glued) {
          isGlued = true;
        }
      });
    }
    return isGlued;
  };

  const isVerified = (collecting) => {
    let isVerified = false;
    if (!!collecting) {
      collecting.forEach((c) => {
        if (c.is_verified) {
          isVerified = true;
        }
      });
    }
    return isVerified;
  };

  const getPackage = (item) => {
    if (!!item.nomenclature.name) {
      let name = item.nomenclature.name.toLowerCase().replace(/\s/g, "");

      if (name.indexOf("5к") >= 0) {
        return (<>5&nbsp;кг</>);
      } else if (name.indexOf("1к") >= 0) {
        return (<>1&nbsp;кг</>);
      } else if (name.indexOf("500г") >= 0) {
        return (<>500&nbsp;гр</>);
      } else if (name.indexOf("250г") >= 0) {
        return (<>250&nbsp;гр</>);
      } else if (name.indexOf("200г") >= 0) {
        return (<>200&nbsp;гр</>);
      } else if (name.indexOf("100г") >= 0) {
        return (<>100&nbsp;гр</>);
      } else if (name.indexOf("50г") >= 0) {
        return (<>50&nbsp;гр</>);
      } else if (name.indexOf("1шт") >= 0) {
        return (<>1&nbsp;шт</>);
      }
    }

    return null;
  }


  const isAction = (name) => {
    let isAction = true
    const arr = [
      "Доставка",
      "Доставка гидравлической тележки",
      "Доставка курьер",
      "Доставка Почта России",
      "Доставка ТК",
      "Запайщик пакетов ручной",
      "Заправка картриджа",
      "Пленка пузырчатая",
      "Пленка пузырчатая",
      "Пленка стрейч",
      "Пузырчатая пленка 1.2*10 (склад)",
      "разработка дизайна упаковки",
      "Разработка логотипа",
      "Разработка макета",
      "Разработка спецкупажа",
      "Услуга фасовки",
      "Услуга фасовки в коробку",
      "Услуга фасовки в полипропилен 100 грамм",
      "Услуга фасовки единичной позиции по фулфилменту",
      "Флакон стеклянный 10мл с крышкой",
      "Формирование розничных заказов",
      "Фотографии",
      "Фотосессия набора",
      "Фотоссесия (20 снимков)",
      "Фотосессия (20 снимков)",
      "Фотосессия",
      "Френч-пресс 600 мл",
      "Шпагат джутовый",
      "шпагат джутовый",
      "штрих-кодирование",
      "Экскурсия по складу",
      "Изменения размера или габаритов макета",
    ]
    arr.forEach(a => {
      if (name
        .replace(/\s/g, "")
        .toLowerCase()
        .indexOf(
          a
            .replace(/\s/g, "")
            .toLowerCase()
        ) >= 0) {
        isAction = false
      }
    })
    return isAction
  }


  return (
    !!list && (
      <Fragment>
        <table className="Table">
          <tbody>
          {list.map((item) => (
            <Fragment key={"composition_" + item.uuid}>
              <tr
                className={
                  isSelectComposition(item)
                    ? "Table__tr Table__tr_hover Table__tr_focus"
                    : "Table__tr Table__tr_hover"
                }
                style={{
                  borderStyle: "2px solid",
                  borderColor: "#bdbdbd",
                  borderBottomColor: isSelectComposition(item)
                    ? "#202020"
                    : "#bdbdbd",
                }}
              >
                <td className="Table__td">
                  <div className="Td__title Td__title_flex">
                    {item.index} # {item.nomenclature.name}
                    {!!item.total_amount && (
                      <span
                        style={{
                          marginLeft: 12,
                        }}
                      >
                            |&nbsp;&nbsp;
                        {(Number(item.total_amount) / 100).toFixed(2)}
                        <span
                          style={{
                            marginLeft: 2,
                            fontSize: 12,
                            fontWeight: 400,
                            opacity: 0.75,
                          }}
                        >
                              руб.
                            </span>
                          </span>
                    )}
                    {!item.total_amount && (
                      <span
                        style={{
                          marginLeft: 12,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                            |&nbsp;&nbsp;0
                            <span
                              style={{
                                marginLeft: 2,
                                fontSize: 12,
                                fontWeight: 400,
                                opacity: 0.75,
                              }}
                            >
                              руб.
                            </span>
                          </span>
                    )}
                    {item.nomenclature_specification_count > 0 && (
                      <div
                        className="Button__circle"
                        onClick={() => changeSelectComposition(item)}
                        onContextMenu={() => changeSelectComposition(item)}
                        style={{
                          padding:
                            !!selectComposition &&
                            item.id === selectComposition.id
                              ? "3px 4px 5px 4px"
                              : "5px 4px 3px 4px",
                        }}
                      >
                        {!!selectComposition &&
                          item.id === selectComposition.id && (
                            <Icon
                              name={"custom-expand-less"}
                              color={"#202020"}
                              size={24}
                            />
                          )}
                        {(!selectComposition ||
                          (!!selectComposition &&
                            item.id !== selectComposition.id)) && (
                          <Icon
                            name={"custom-expand-more"}
                            color={"#202020"}
                            size={24}
                          />
                        )}
                      </div>
                    )}

                    {!!item.specification_id && (
                      <div style={{
                        backgroundColor: "#202020",
                        height: 5,
                        width: 5,
                        borderRadius: 6,
                        margin: '0 4px'
                      }}></div>
                    )}

                    {!object.is_final_state && !object.is_excepted_state && !!item.nomenclature && !!item.nomenclature.stickers && (
                      <div
                        className={
                          "Action__button Action__button_blue"
                        }
                        style={{
                          marginLeft: 12,
                          height: 28,
                          padding: "3px 8px 4px",
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          setModalBuyerOrderPrint(item)
                        }
                        onContextMenu={(event) =>
                          onContextMenuPrint(event, item)
                        }
                      >
                        Печать
                      </div>
                    )}
                  </div>
                </td>
                <td
                  className="Table__td"
                  style={{
                    textAlign: "right",
                    fontSize: 24,
                    fontWeight: 700,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {isVerified(item.inspects) && (
                    <div
                      style={{
                        marginLeft: 8,
                        color: "white",
                        backgroundColor: "#0277bd",
                        fontSize: 13,
                        fontWeight: 700,
                        padding: "5px 8px 4px",
                        borderRadius: 24,
                      }}
                    >
                      Комплект
                    </div>
                  )}

                  {isStickers(item.preparations) && (
                    <div
                      style={{
                        marginLeft: 8,
                        color: "white",
                        backgroundColor: "#7b1fa2",
                        fontSize: 13,
                        fontWeight: 700,
                        padding: "5px 8px 4px",
                        borderRadius: 24,
                      }}
                    >
                      Наклейки
                    </div>
                  )}

                  {/*TODO удалить когда очищу БД*/}
                  {isStickers(item.collecting) && (
                    <div
                      style={{
                        marginLeft: 8,
                        color: "white",
                        backgroundColor: "#7b1fa2",
                        fontSize: 13,
                        fontWeight: 700,
                        padding: "5px 8px 4px",
                        borderRadius: 24,
                      }}
                    >
                      Наклейки
                    </div>
                  )}


                  {/* TODO Не нужен этот функционал */}
                  {isGlued(item.glued) && (
                    <div
                      style={{
                        marginLeft: 8,
                        color: "white",
                        backgroundColor: "#4b1fa2",
                        fontSize: 13,
                        fontWeight: 700,
                        padding: "5px 8px 4px",
                        borderRadius: 24,
                      }}
                    >
                      Наклеены
                    </div>
                  )}


                  {!!item.discount_percentage &&
                    Number(item.discount_percentage) / 100 === 100 && (
                      <div
                        style={{
                          marginLeft: 8,
                          color: "white",
                          backgroundColor: "#e7ad00",
                          fontSize: 13,
                          fontWeight: 700,
                          padding: "5px 8px 4px",
                          borderRadius: 24,
                        }}
                      >
                        Пробник
                      </div>
                    )}

                  {!(!!item.discount_percentage &&
                      Number(item.discount_percentage) / 100 === 100)
                    && !!item.specification_id && !!getPackage(item) && (
                      <div
                        style={{
                          marginLeft: 8,
                          color: "#202020",
                          backgroundColor: "#bdbdbd",
                          fontSize: 15,
                          fontWeight: 900,
                          padding: "3px 8px 4px",
                          borderRadius: 24,
                        }}
                      >
                        {getPackage(item)}
                      </div>
                    )}

                  {!!item.discount_percentage &&
                  Number(item.discount_percentage) / 100 === 100 ? (
                    <span
                      style={{
                        marginLeft: 12,
                        marginRight: 12,
                        fontSize: 18,
                        fontWeight: 400,
                      }}
                    >
                          ({(Number(item.discount_amount) / 100).toFixed(2)}
                      <span
                        style={{
                          marginLeft: 2,
                          fontSize: 12,
                          fontWeight: 400,
                          opacity: 0.75,
                        }}
                      >
                            руб.
                          </span>
                          )
                        </span>
                  ) : (
                    <span
                      style={{
                        marginLeft: 12,
                        marginRight: 12,
                        fontSize: 18,
                        fontWeight: 400,
                      }}
                    >
                          (
                      {((Number(item.price) / 100) * item.quantity).toFixed(
                        2
                      )}
                      <span
                        style={{
                          marginLeft: 2,
                          fontSize: 12,
                          fontWeight: 400,
                          opacity: 0.75,
                        }}
                      >
                            руб.
                          </span>
                          )
                        </span>
                  )}

                  {item.quantity}
                  {isAction(item.nomenclature.name) && (
                    <>
                      <div
                        className={
                          "Collected__count Collected__count" +
                          (item.collected_quantity === item.quantity
                            ? "_green"
                            : item.collected_quantity > item.quantity
                              ? "_red"
                              : "_blue")
                        }
                        style={{
                          marginLeft: 12,
                          borderRadius: 24
                        }}
                      >
                        {item.collected_quantity}
                      </div>

                      {!object.is_final_state && !object.is_excepted_state && (
                        <div
                          className={
                            "Button__collected Button__collected_black"
                          }
                          style={{
                            marginLeft: 12,
                            height: 28,
                            padding: "3px 8px 4px",
                            cursor: "pointer"
                          }}
                          onClick={() =>
                            setModalBuyerOrderCollected([object, item])
                          }
                          onContextMenu={(event) =>
                            onContextMenuCollected(event, [object, item])
                          }
                        >
                          Отметить
                        </div>
                      )}

                    </>
                  )}
                </td>
              </tr>
              {!!selectComposition && item.id === selectComposition.id && (
                <>
                  {item.nomenclature_specification_count > 0 && (
                    <tr
                      className={
                        isSelectComposition(item)
                          ? "Table__tr Table__tr_focus_light"
                          : "Table__tr"
                      }
                      style={{
                        borderStyle: "2px solid",
                        borderColor: isSelectComposition(item)
                          ? "#202020"
                          : "#bdbdbd",
                      }}
                    >
                      <td
                        className="Table__td"
                        colSpan={3}
                        style={{
                          padding: "0px",
                        }}
                      >
                        <NomenclatureSpecifications
                          nomenclature={item.nomenclature}
                          specification={!!item.specification ? item.specification : null}
                          quantity={item.quantity}
                          count={item.quantity}
                          isFirst={true}
                        />
                      </td>
                    </tr>
                  )}
                </>
              )}
            </Fragment>
          ))}
          </tbody>
        </table>

        {!!modalBuyerOrderCollected && (
          <ModalBuyerOrderCollected
            buyerOrder={modalBuyerOrderCollected[0]}
            buyerOrderItem={modalBuyerOrderCollected[1]}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalBuyerOrderCollected(null)}
          />
        )}

        {!!modalBuyerOrderPrint && (
          <ModalBuyerOrderPrint
            object={modalBuyerOrderPrint}
            successfulRequest={() => setModalBuyerOrderPrint(null)}
            handleClose={() => setModalBuyerOrderPrint(null)}
          />
        )}
      </Fragment>
    )
  );
}

export default BuyerOrderObjectItemsList;
