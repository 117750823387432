import React, {useRef} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {
  getErrorMessageByKey,
  isValidateNullText,
  jsonFormatText,
  Modal,
  Textarea,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";
import moment from "moment";
import {HandbookArticle} from "./HandbookArticle";

function ModalSiteNewArticle(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/site-new/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputCommentOnOrder = useRef(null);

  const {notification} = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const commentOnOrder = jsonFormatText(inputCommentOnOrder.current.value);

    if (isValidateNullText(commentOnOrder)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      answer: commentOnOrder,
    });

    axios
      .post("/api/site-feedback/" + props.object.uuid + "/edit/answer", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()} dialogStyle={{
        width: 900,
        height: "100%"
      }}
             contentStyle={{
               width: 900,
               height: "100%"
             }}>
        {/*<form className="Form" onSubmit={(event) => changeEdit(event)}>*/}
        {/*  <div*/}
        {/*    className="Form__title"*/}
        {/*    style={{fontSize: 19, fontWeight: 700}}*/}
        {/*  >*/}
        {/*    №{object.id}{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}*/}
        {/*    <div className="Text__signature">Обратная связь</div>*/}
        {/*  </div>*/}

        {/*  <div className="Form__field Form__field_last">*/}
        {/*    <Textarea reference={inputCommentOnOrder} label="Ответ"*/}
        {/*              value={!!object.answer && object.answer}/>*/}
        {/*  </div>*/}

        {/*  <div className="Form__actions Form__actions_center">*/}
        {/*    <button className="Action__button Action__button_all Action__button_blue">*/}
        {/*      Ответить*/}
        {/*    </button>*/}
        {/*  </div>*/}

        {/*  <div className="Form__actions Form__actions_center">*/}
        {/*    <div className="Action__link" onClick={() => props.handleClose()}>*/}
        {/*      Отмена*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</form>*/}


        <div className="Form">
          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Закрыть
            </div>
          </div>
        </div>

        <HandbookArticle handbookEntryUuid={props.object.uuid} />

      </Modal>
    )
  );
}

export default ModalSiteNewArticle;
