import React, {useState} from "react";
import ModalTransactionAdd from "./ModalTransactionAdd";
import ModalNomenclatureTransactionEdit from "./ModalNomenclatureTransactionEdit";
import {ButtonIcon, getAccountFio, Pagination, Search, Select, TextSearchMarker,} from "@vokymlak/kabinet-ui";
import moment from "moment";

function NomenclatureTransactionsPage(props) {
  const {
    object,

    list,
    setList,
    count,


    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    setIsUpdateList,
    isUpdateList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    modalAdd,
    setModalAdd,

    modalEdit,
    setModalEdit,
  } = props;

  return (
    !!object && (
      <>
        <div
          className="Page__title"
          style={{
            marginTop: 0,
            marginBottom: 6,
          }}
        >
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 21,
                fontWeight: 700,
              }}
            >

                Учёт
                {!!list && (
                  <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                    ({count})
                  </span>
                )}

                <span style={{ width: 140, marginLeft: 16 }}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>

          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{ marginLeft: 8 }}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 12 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 1000,
                      title: 1000,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{ marginLeft: 8 }}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setModalAdd(true)}
            />
          </div>
        </div>

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <React.Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid) ? "List__item active" : "List__item"
                    }
                    style={{
                      backgroundColor: !!item.nomenclature_transaction_type &&
                        (item.nomenclature_transaction_type.tag === "COMING" ? "#4caf5033"
                          : item.nomenclature_transaction_type.tag === "INTAKE" ? "#ffe08250"
                            : item.nomenclature_transaction_type.tag === "REVISION" ? "#0061ff25"
                              : item.nomenclature_transaction_type.tag === "WRITE_OFF" ? "#ff000025"
                                : false)
                    }}
                    onClick={() => changeUnroll(item.uuid)}
                  >
                    <div
                      className="Item__title"
                    >
                      <div
                        className="Element__title"
                        style={{
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 19,
                            fontWeight: 700,
                            width: "100%",
                          }}
                        >
                          {!!item.nomenclature_transaction_type &&
                            <TextSearchMarker text={item.nomenclature_transaction_type.name} search={searchQuery}/>}
                        </div>

                        {!!item.note && (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              marginTop: 2,
                            }}
                          >

                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                              {!!item.note &&
                                <TextSearchMarker text={item.note} search={searchQuery}/>}
                            </div>
                          </div>
                        )}

                        {!!item.date && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            marginTop: 2,
                          }}
                        >

                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                              {moment(item.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
                            </div>
                        </div>
                        )}

                        {!!item.executor && (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              marginTop: 2,
                            }}
                          >

                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                              {getAccountFio(item.executor)}
                            </div>
                          </div>
                        )}


                        <div
                          style={{
                            width: "100%",
                            opacity: 0.75,
                            marginTop: 4,
                            fontWeight: 700,
                            fontSize: 19,
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                        <span>
                  {(!!item.value && !!item.measurement_unit_type ? (item.value / item.measurement_unit_type.ratio) : !!item.value && item.value) || "0"}
                          {!!item.measurement_unit_type && <span style={{
                            marginLeft: 4,
                            fontWeight: 400,
                            fontSize: 13
                          }}>{item.measurement_unit_type.designation + '.'}</span>}
              </span>
                        {!!item.price && <span style={{marginLeft: 16}}>{new Intl.NumberFormat("ru-RU").format(
                          (item.price / 100).toFixed(2))}<span
                          style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>}
                        </div>


                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime">
                        {item.is_deleted && (
                          <div
                            style={{
                              color: "white",
                              backgroundColor: "#f44336",
                              fontSize: 13,
                              fontWeight: 700,
                              padding: "5px 8px 4px",
                              borderRadius: 24,
                              display: "inline-block",
                              marginLeft: 8,
                            }}
                          >
                            Удалена
                          </div>
                        )}
                        {item.is_basic && (
                          <div
                            style={{
                              color: "white",
                              backgroundColor: "#202020",
                              fontSize: 13,
                              fontWeight: 700,
                              padding: "5px 8px 4px",
                              borderRadius: 24,
                              display: "inline-block",
                              marginLeft: 8,
                            }}
                          >
                            Основная
                          </div>
                        )}
                        {!!item.code && (
                          <span style={{
                            marginLeft: 8
                          }}>
                        {item.code}
                          </span>
                        )}
                      </div>
                    </div>
                  </li>

                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      {!item.code && (
                        <div className="Content__header">
                          <div className="Header__title">
                          </div>
                          <div className="Header__settings">
                            {/*<ButtonIcon*/}
                            {/*  tooltipText={"Редактировать"}*/}
                            {/*  iconName={"custom-edit"}*/}
                            {/*  iconColor={"#202020"}*/}
                            {/*  size={36}*/}
                            {/*  style={{ marginLeft: 12 }}*/}
                            {/*  onClick={() => setModalEdit(item)}*/}
                            {/*/>*/}
                          </div>
                        </div>
                      )}
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalAdd && (
          <ModalTransactionAdd
            object={object}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalAdd(null)}
          />
        )}
        {!!modalEdit && (
          <ModalNomenclatureTransactionEdit
            transaction={modalEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalEdit(null)}
          />
        )}
      </>
    )
  );
}

export default NomenclatureTransactionsPage;
