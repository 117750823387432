import React, { useState } from "react";
import { ROOT_URL } from "../../../config/url";
import {ButtonIcon, ButtonIconExport, useObject, useUnroll} from "@vokymlak/kabinet-ui";
import styles from "./Orders.module.scss";
import {Loading} from "@vokymlak/kabinet-ui";
import {useProfile, isProfileRoleTypeTags} from "@vokymlak/kabinet-ui";
import {ROLE_ANALYST, ROLE_PACKER, ROLE_PICKER} from "../../../config/constants";
import moment from "moment/moment";
import {BuyerOrderObject} from "../../ScreenOrders/BuyerOrderObject";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";

function PlanProviding() {
  const {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject],
  } = useObject({apiUrl:"/api/plan/providing", rootUrl:ROOT_URL});

  const { isUnroll, changeUnroll } = useUnroll();


  const [tab, setTab] = useState('items');


  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
    <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 21,
            fontWeight: 700,
          }}
        >
          План обеспечения

          {!!object && (
            <ButtonIconExport
              tooltipText={"Выгрузить"}
              iconName={"custom-download"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 12 }}
              apiUrl={"/api/plan/providing/export/xlsx"}
              rootUrl={ROOT_URL}
              mimeType={
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              }
              fileName={
                ("план_обеспечения"+"_на_"+moment().format("DD_MM_YYYY")+".xlsx").toLowerCase()}
            />
          )}
        </div>

        <div className="Title__settings">
          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 12 }}
            onClick={() => (
              setObject(null),
                setIsUpdateObject(!isUpdateObject)
            )}
          />
        </div>
      </div>

      {!!object && !!object.order_states && (
        <div className="Page__title" style={{height: "auto"}}>
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            {object.order_states.map(state => (
              <div key={state.uuid} style={{
                padding: "4px 6px",
                backgroundColor: !!state.color ? state.color+"25" : "#dbdbdb",
                display: "inline-block",
                marginRight: 6,
                borderRadius: 4,
                fontSize: 14,
                fontWeight: 400
              }}>
                {state.name}
              </div>
            ))}
          </div>
        </div>
      )}


      {!!object && (
        <div className="Page__title" style={{height: "auto"}}>
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className={styles._block}>
              <div className={styles._block__content} style={{marginBottom: 4}}>
                <div className={styles._card + " " + styles._card_hover + " " + (tab === 'orders' ? styles._card_active : "")}
                     onClick={() => setTab('orders')}>
                  <div className={styles._card__number}>{!!object.orders ? object.orders.length : 0}</div>
                  <div className={styles._card__title}>Заказы</div>
                </div>
                <div className={styles._card + " " + styles._card_hover + " " + (tab === 'items' ? styles._card_active : "")}
                     onClick={() => setTab('items')}>
                  <div className={styles._card__number}>{!!object.nomenclatures ? object.nomenclatures.length : 0}</div>
                  <div className={styles._card__title}>Номенклатура</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <hr className={"Page__hr"} />


      {!object && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}

      {!!object && tab === 'items' && (
        <div className="Page__title" style={{height: "auto"}}>
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Номенклатура
            {!!object.nomenclatures && (
              <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({object.nomenclatures.length})
              </span>
            )}
          </div>
        </div>
      )}

      {tab === 'orders' && !!object && (
        <div className="Page__title" style={{height: "auto"}}>
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Заказы
            {!!object.orders && (
              <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({object.orders.length})
              </span>
            )}
          </div>
        </div>
      )}


      {!!object && (
        <div className="Page__content">

          {tab === 'items' && !!object.nomenclatures && (
            <table className="Table" style={{marginBottom: 24}}>
              <thead className="Thead">
              <tr className="Table__tr Table__tr_padding Table__tr_border">
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">№</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Номенклатура</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Артикль</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Номер</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Количество</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Ед. измерения</div>
                </th>
              </tr>
              </thead>
              <tbody>
              {object.nomenclatures.map((resource, index) => (
                <tr
                  className="Table__tr Table__tr_hover Table__tr_padding Table__tr_border"
                  key={"item_" + index}
                >
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      {resource.nomenclature.id}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text">
                      <div style={{
                        fontSize: 17,
                        fontWeight: 400,
                      }}>
                        {resource.nomenclature.name}
                        {resource.nomenclature.specifications &&
                          resource.nomenclature.specifications.map((specification) => (
                            <div
                              key={"marker_" + specification.uuid}
                              className="Marker Marker_left Marker_inline Marker_small"
                            />
                          ))}
                      </div>
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      {!!resource.nomenclature.article && resource.nomenclature.article || "-"}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      {resource.nomenclature.code}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_right">
                      <div style={{
                        fontSize: 17,
                        fontWeight: 700,
                      }}>
                        {(!!resource.nomenclature.warehouse_quantity && Number(resource.nomenclature.warehouse_quantity).toFixed(3)) || "-"}
                      </div>
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text ">
                      <div style={{
                        fontSize: 17,
                        fontWeight: 700,
                      }}>
                        {!!resource.nomenclature.measurement_type && (" " + resource.nomenclature.measurement_type.designation)}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
            )}



          {tab === 'orders' && !!object.orders && (

            <ul className="List">
              {object.orders.map((item) => (
                <BuyerOrderObject
                  key={"item_" + item.uuid}
                  object={item}
                  isUnroll={isUnroll}
                  changeUnroll={changeUnroll}
                  searchQuery={''}
                  isUpdateList={isUpdateObject}
                  setIsUpdateList={setIsUpdateObject}
                />
              ))}
            </ul>
          )}

        </div>
        )}

    </div>
    </ScrollBlock>
  );
}

export default PlanProviding;
