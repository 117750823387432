import React from "react";
import NomenclatureSpecifications from "../../ScreenNomenclatures/NomenclatureItem/NomenclatureSpecifications/NomenclatureSpecifications";
import {Loading, ButtonIconExport, ButtonIcon, Marker, TextSearchMarker} from "@vokymlak/kabinet-ui";
import styles from "../PlanProviding/Orders.module.scss";
import styleContent from "../../../styles/modules/Block.module.scss";
import moment from "moment";
import {ROOT_URL} from "../../../config/url";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {BuyerOrderObject} from "../../ScreenOrders/BuyerOrderObject";

function PlanKneadingPage(props) {
  const {
    object,
    setObject,

    isUnroll,
    changeUnroll,

    setIsUpdateObject,
    isUpdateObject,

    tab,
    setTab,
  } = props;

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
    <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 21,
            fontWeight: 700,
          }}
        >
          План замеса

          {!!object && (
            <ButtonIconExport
              tooltipText={"Выгрузить"}
              iconName={"custom-download"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 12 }}
              apiUrl={"/api/plan/kneading/export/xlsx"}
              rootUrl={ROOT_URL}
              mimeType={
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              }
              fileName={
                ("план_замеса"+"_на_"+moment().format("DD_MM_YYYY")+".xlsx").toLowerCase()}
            />
          )}
        </div>

        <div className="Title__settings">
          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 12 }}
            onClick={() => (
              setObject(null),
                setIsUpdateObject(!isUpdateObject)
            )}
          />
        </div>
      </div>

      {!!object && !!object.order_states && (
        <div className="Page__title" style={{height: "auto"}}>
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            {object.order_states.map(state => (
              <div key={state.uuid} style={{
                padding: "4px 6px",
                backgroundColor: !!state.color ? state.color+"25" : "#dbdbdb",
                display: "inline-block",
                marginRight: 6,
                borderRadius: 4,
                fontSize: 14,
                fontWeight: 400
              }}>
                {state.name}
              </div>
            ))}
          </div>
        </div>
      )}

      {!!object && (
        <div className="Page__title" style={{height: "auto"}}>
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className={styles._block}>
              <div className={styles._block__content} style={{marginBottom: 4}}>
                <div className={styles._card + " " + styles._card_hover + " " + (tab === 'orders' ? styles._card_active : "")}
                     onClick={() => setTab('orders')}>
                  <div className={styles._card__number}>{!!object.orders ? object.orders.length : 0}</div>
                  <div className={styles._card__title}>Заказы</div>
                </div>
                <div className={styles._card + " " + styles._card_hover + " " + (tab === 'items' ? styles._card_active : "")}
                     onClick={() => setTab('items')}>
                  <div className={styles._card__number}>{!!object.order_items ? object.order_items.length : 0}</div>
                  <div className={styles._card__title}>Позиции</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <hr className={"Page__hr"} />


      {!object && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}

      {!!object && tab === 'items' && (
        <div className="Page__title" style={{height: "auto"}}>
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Позиции
            {!!object.order_items && (
              <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({object.order_items.length})
              </span>
            )}
          </div>
        </div>
      )}

      {tab === 'orders' && !!object && (
        <div className="Page__title" style={{height: "auto"}}>
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Заказы
            {!!object.orders && (
              <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({object.orders.length})
              </span>
            )}
          </div>
        </div>
      )}


      {!!object && (
      <div className="Page__content">
            {tab === 'items' && !!object.order_items && (

              <ul className="List">
                {object.order_items.map((item, index) => (
              <React.Fragment key={"resource_" + item.uuid}>
                <li
                  className={
                    isUnroll(item.uuid) ? "List__item active" : "List__item"
                  }
                  onClick={() => changeUnroll(item.uuid)}
                >
                  <div className="Item__title">
                    <div className="Element__title">
                      {index + 1}.&nbsp;
                      {item.nomenclature.name}
                      {item.nomenclature_specification_count > 0 && (
                        <Marker color={"#9e9e9e"} size={8} style={{marginLeft: 12}} />
                      )}
                    </div>
                  </div>
                  <div className="Item__settings">
                    <div
                      style={{
                        textAlign: "right",
                        fontSize: 24,
                        fontWeight: 700,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      {item.count_quantity}
                      {!!item.nomenclature && !!item.nomenclature.quantity
                        && item.nomenclature.quantity > 0 && (
                          <div
                            className={"Collected__count Collected__count_blue"}
                            style={{
                              marginLeft: 8,
                              borderRadius: 24
                            }}
                          >
                            {item.nomenclature.quantity}
                          </div>
                        )}
                    </div>
                  </div>
                </li>

                {isUnroll(item.uuid) && (
                  <li className="List__content">
                    <div className={styleContent._block} style={{
                      marginBottom: 12
                    }}>
                      <div className={styleContent._block__title}>Заказы</div>
                      <div className={styleContent._block__content}>
                        {!!item.orders && item.orders.map(order => (
                          <span key={item.uuid + "_" + order.uuid}
                                style={{
                                  fontSize: 17,
                                  fontWeight: 700,
                                  marginRight: 12
                                }}>
                          {order.number}
                            &nbsp;
                            {!!order.date &&
                              "от " +
                              moment(order.date.substring(0, 19)).format(
                                "DD.MM.YYYY"
                              )}
                        </span>
                        ))}
                      </div>
                    </div>

                    <div className={styleContent._block}>
                      <div className={styleContent._block__title}>Спецификация</div>
                      <div className={styleContent._block__content}>
                        {item.nomenclature_specification_count > 0 && (
                          <NomenclatureSpecifications
                            nomenclature={item.nomenclature}
                            quantity={item.count_quantity}
                            count={item.count_quantity}
                            isFirst={true}
                          />
                        )}
                      </div>
                    </div>
                  </li>
                  )}
              </React.Fragment>
                  ))}
              </ul>
            )}

        {tab === 'orders' && !!object.orders && (

          <ul className="List">
            {object.orders.map((item) => (
              <BuyerOrderObject
                key={"item_" + item.uuid}
                object={item}
                isUnroll={isUnroll}
                changeUnroll={changeUnroll}
                searchQuery={''}
                isUpdateList={isUpdateObject}
                setIsUpdateList={setIsUpdateObject}
              />
            ))}
            </ul>
            )}

      </div>
        )}

    </div>
    </ScrollBlock>
  );
}

export default PlanKneadingPage;
