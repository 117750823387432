import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  useNotification,
  getErrorMessageByKey,
  jsonFormatText,
  isValidateNullText,
  Modal,
  Input,
  Select,
} from "@vokymlak/kabinet-ui";

function ModalAddEditorImage(props) {
  const inputFile = useRef(null);
  const [file, setFile] = useState(null);

  const { notification } = useNotification();

  const fileSend = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const changeAdd = (event) => {
    event.preventDefault();

    if (!file) {
      notification("Выберите изображение", "red");
      return;
    }

    let formData = new FormData();
    formData.append("file", file);

    axios
      .post(
        "/api/site-blog/" + props.object.uuid + "/article/image/add",
        formData,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then((response) => {
        let textImage;
        // Для разработки.
        // const rootUrl = ROOT_URL
        const rootUrl = ''
        if (response.data.width > 796) {
          textImage = `<img width="100%" src="${rootUrl}/api/site-blog/article/image/${response.data.uuid}" />`;
        } else {
          textImage = `<img src="${rootUrl}/api/site-blog/article/image/${response.data.uuid}" />`;
        }

        let selection = window.getSelection();
        // Устанавливаем выделение заново
        selection.removeAllRanges();
        selection.addRange(props.range);

        props.successfulRequest(textImage);
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });

  };

  const handleClose = () => {
    let selection = window.getSelection();
    // Устанавливаем выделение заново
    selection.removeAllRanges();
    selection.addRange(props.range);

    props.handleClose();
  };

  return (
    <Modal handleClose={() => handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">Изображение</div>

        <div className="Form__field Form__field_last">
          <Input
            reference={inputFile}
            type="file"
            label="Изображение *"
            isFixed={true}
            onChange={(event) => fileSend(event)}
          />
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => handleClose()}>
            Отмена
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ModalAddEditorImage;
