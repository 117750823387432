import React, { useRef, useState } from "react";
import axios from "axios";
import {
  getErrorMessageByKey,
  useNotification,
  Input,
  Modal, Loading,
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";

function ModalPriceSaleImport(props) {
  const [isRequest, setIsRequest] = useState(false);
  const inputContractNumber = useRef(null);
  const [file, setFile] = useState(null);

  const { notification } = useNotification();

  const fileSend = (event) => {
    const contractNumber = event.target.files[0];
    setFile(contractNumber);
  };

  const changeImport = () => {
    if (!file) {
      return;
    }

    setIsRequest(true)

    let formData = new FormData();
    formData.append("file", file);

    axios
      .post(
        "/api/price/import/sale",
        formData,
        {
          responseType: 'arraybuffer',
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/pdf",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "price_sale.pdf"
        ); //or any other extension
        document.body.appendChild(link);
        link.click();

        setIsRequest(false)

        props.successfulRequest();
        notification("Конвертирование завершено", "green");
        // props.handleClose();
      })
      .catch((error) => {
        setIsRequest(false)

        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <div className="Form">
        <div className="Form__title">
          Конвертировать
          <div className="Text__signature">Прайс с акция</div>
        </div>

        <div className="Form__field Form__field_last">
          <Input
            reference={inputContractNumber}
            type="file"
            label={"Excel"}
            isFixed={true}
            onChange={(event) => fileSend(event)}
          />
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue"
                  onClick={!isRequest ? () => changeImport() : () => null}>
            {!isRequest
              ? "Начать"
              : <Loading size="medium" color="blue" />}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalPriceSaleImport;
