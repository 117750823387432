import React, {useState} from "react";
import {Loading, isProfileRoleTypeTags, ButtonIcon} from "@vokymlak/kabinet-ui";
import {ROLE_ANALYST, ROLE_COURIER, ROLE_PACKER, ROLE_PICKER} from "../../../config/constants";
import moment from "moment";
import {formatAmount} from "../../../functions/formatAmount";
import ModalEditDispatch from "./ModalEditDispatch";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";

function PlanDispatchPage(props) {
  const {
    object,
    setObject,

    setIsUpdateObject,
    isUpdateObject,

    profile,
  } = props;

  const [modalBuyerOrderDispatch, setModalBuyerOrderDispatch] =
    useState(null);

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
    <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 21,
            fontWeight: 700,
          }}
        >
          План доставки
        </div>

        <div className="Title__settings">

          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 12 }}
            onClick={() => (
              setObject(null),
                setIsUpdateObject(!isUpdateObject)
            )}
          />
        </div>
      </div>

      {!!object && !!object.order_states && (
        <div className="Page__title" style={{height: "auto"}}>
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
          {object.order_states.map(state => (
            <div key={state.uuid} style={{
              padding: "4px 6px",
              backgroundColor: !!state.color ? state.color+"25" : "#dbdbdb",
              display: "inline-block",
              marginRight: 6,
              borderRadius: 4,
              fontSize: 14,
              fontWeight: 400,
            }}>
              {state.name}
            </div>
          ))}
          </div>
        </div>
      )}


      <hr className={"Page__hr"} />


      {!object && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}




      {!!object && (
      <div className="Page__content">


          {!!object.orders && (
            <table className="Table" style={{marginBottom: 24}}>
              <thead className="Thead">
              <tr className="Table__tr Table__tr_padding Table__tr_border">
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title"></div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Дата</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Номер</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Покупатель</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Сумма</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Оплата</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">ТК</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Доставлено</div>
                </th>
              </tr>
              </thead>
              <tbody>
              {object.orders.map((item, index) => (
                <tr
                  className="Table__tr Table__tr_hover Table__tr_padding Table__tr_border"
                  key={"item_" + index}
                >
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      {index + 1}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      {(!!item.date &&
                        moment(item.date.substring(0, 19)).format(
                          "DD.MM.YYYY HH:mm"
                        )) || "-"}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      <div style={{
                        fontSize: 15,
                      }}>
                        {(!!item.number && item.number) || "-"}
                      </div>
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      <div
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {!!item.contractor && item.contractor}
                        {!!item.contractor && !!item.recipient_fio && (
                          <>
                            {" ("}
                            {item.recipient_fio}
                            {")"}
                          </>
                        )}
                        {!item.contractor && !!item.recipient_fio && item.recipient_fio}
                      </div>
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      {(!!item.amount && formatAmount(item.amount)) || "-"}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      {(!!item.payment_status && item.payment_status.toUpperCase()) || "-"}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      {(!!item.transport_company && item.transport_company.toUpperCase()) || "-"}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center" style={{
                      cursor: "pointer"
                    }}
                         onClick={!item.date_dispatch
                           ? () =>
                           setModalBuyerOrderDispatch(item)
                           : () => null
                         }>
                      {(!!item.date_dispatch && moment(item.date_dispatch.substring(0, 19)).format("DD.MM.YYYY HH:mm")) || (
                        <div
                          className={
                            "Action__button Action__button_blue Action__button_maxi"
                          }
                        >
                          Отметить
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          )}

      </div>
        )}

      {!!modalBuyerOrderDispatch && (
        <ModalEditDispatch
          buyerOrder={modalBuyerOrderDispatch}
          successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
          handleClose={() => setModalBuyerOrderDispatch(null)}
        />
      )}
    </div>
    </ScrollBlock>
  );
}

export default PlanDispatchPage;
