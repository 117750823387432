import React, {useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {getErrorMessageByKey, Modal, useNotification, useObject,} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";

function ModalBuyerOrderEdit(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/buyer-order/" + props.object.id,
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const [isDelete, setIsDelete] = useState(false);

  const {notification} = useNotification();

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/buyer-order/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Заказ удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <>
        <Modal handleClose={() => props.handleClose()}>
          {!isDelete && (
            <div className="Form">
              <div
                className="Form__title"
                style={{fontSize: 19, fontWeight: 700}}
              >
                {object.number} от {moment(object.date).format("DD.MM.YYYY HH:mm")}
                <div className="Text__signature">Заказ</div>
              </div>

              <div className="Form__actions Form__actions_center">
                <div
                  className="Action__link Action__link_red"
                  onClick={() => setIsDelete(true)}
                >
                  Удалить
                </div>
              </div>
            </div>
          )}
          {isDelete && (
            <form className="Form" onSubmit={(event) => changeDelete(event)}>
              <div
                className="Form__title"
                style={{fontSize: 19, fontWeight: 700}}
              >
                {object.number} от {moment(object.date).format("DD.MM.YYYY HH:mm")}
                <div className="Text__signature">Удалить</div>
              </div>

              <div className="Form__actions Form__actions_center">
                <button className="Action__button Action__button_all Action__button_red">
                  Удалить
                </button>
              </div>

              <div className="Form__actions Form__actions_center">
                <div className="Action__link" onClick={() => setIsDelete(false)}>
                  Отмена
                </div>
              </div>
            </form>
          )}
        </Modal>
      </>
    )
  );
}

export default ModalBuyerOrderEdit;
