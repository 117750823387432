import React, {useState} from "react";
import {ButtonIcon, getAccountFio, Icon, Loading, Tooltip} from "@vokymlak/kabinet-ui";
import moment from "moment";
import {formatAmount} from "../../../functions/formatAmount";
import ModalBuyerOrderEmployeePackingEdit from "./ModalBuyerOrderEmployeePackingEdit";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import ModalBuyerOrderExpertAssessmentEdit
  from "../../ScreenOrders/BuyerOrderObject/ModalBuyerOrderExpertAssessmentEdit";

function PlanPackingPage(props) {
  const {
    object,
    setObject,

    setIsUpdateObject,
    isUpdateObject,
  } = props;

  const [modalBuyerOrderCollected, setModalBuyerOrderCollected] =
    useState(null);
  const [modalBuyerOrderExpertAssessmentEdit, setModalBuyerOrderExpertAssessmentEdit] = useState(null);

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
    <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 21,
            fontWeight: 700,
          }}
        >
          План фасовки
        </div>

        <div className="Title__settings">
          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 12 }}
            onClick={() => (
              setObject(null),
                setIsUpdateObject(!isUpdateObject)
            )}
          />
        </div>
      </div>

      {!!object && !!object.order_states && (
        <div className="Page__title" style={{height: "auto"}}>
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            {object.order_states.map(state => (
              <div key={state.uuid} style={{
                padding: "4px 6px",
                backgroundColor: !!state.color ? state.color+"25" : "#dbdbdb",
                display: "inline-block",
                marginRight: 6,
                borderRadius: 4,
                fontSize: 14,
                fontWeight: 400,
              }}>
                {state.name}
              </div>
            ))}
          </div>
        </div>
      )}


      <hr className={"Page__hr"} />


      {!object && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}




      {!!object && (
        <div className="Page__content">
          {!!object.orders && (
            <table className="Table" style={{marginBottom: 24}}>
              <thead className="Thead">
              <tr className="Table__tr Table__tr_padding Table__tr_border">
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title"></div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Дата</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Номер</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Состояние</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Сумма</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Дата отгрузки</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Сложность</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Фасовщик</div>
                </th>
              </tr>
              </thead>
              <tbody>
              {object.orders.map((item, index) => (
                <tr
                  className="Table__tr Table__tr_hover Table__tr_padding Table__tr_border"
                  key={"item_" + index}
                >
                  <td className="Table__td Table__td_border"
                      style={{
                        backgroundColor: (!!item.buyer_order_state && item.buyer_order_state.color) ? item.buyer_order_state.color+"33" : "#dfdfdf",
                      }}>
                    <div className="Td__text Td__text_center">
                      {index + 1}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border"
                      style={{
                        backgroundColor: (!!item.buyer_order_state && item.buyer_order_state.color) ? item.buyer_order_state.color+"33" : "#dfdfdf",
                      }}>
                    <div className="Td__text Td__text_center">
                      {(!!item.date &&
                        moment(item.date.substring(0, 19)).format(
                          "DD.MM.YYYY HH:mm"
                        )) || "-"}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border"
                      style={{
                        backgroundColor: (!!item.buyer_order_state && item.buyer_order_state.color) ? item.buyer_order_state.color+"33" : "#dfdfdf",
                      }}>
                    <div className="Td__text Td__text_center">
                      <div style={{
                        fontSize: 15,
                      }}>
                        {(!!item.number && item.number) || "-"}
                      </div>
                    </div>
                  </td>
                  <td className="Table__td Table__td_border"
                      style={{
                        backgroundColor: (!!item.buyer_order_state && item.buyer_order_state.color) ? item.buyer_order_state.color+"33" : "#dfdfdf",
                      }}>
                    <div className="Td__text Td__text_center">
                      {(!!item.buyer_order_state && item.buyer_order_state.name) || "-"}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border"
                      style={{
                        backgroundColor: (!!item.buyer_order_state && item.buyer_order_state.color) ? item.buyer_order_state.color+"33" : "#dfdfdf",
                      }}>
                    <div className="Td__text Td__text_center">
                      {(!!item.amount && formatAmount(item.amount)) || "-"}
                    </div>
                  </td>

                  <td className="Table__td Table__td_border"
                  style={{
                    backgroundColor:
                      !!item.date_shipment && !!moment(
                        moment(
                          item.date_shipment
                        ).format("YYYY-MM-DD")
                      ).isSameOrBefore(moment().format("YYYY-MM-DD"))
                        ? "#ef9a9a"
                        : moment(
                          item.date_shipment
                        ).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                          ? "#ffe082"
                          : false,
                  }}>
                    <div className="Td__text Td__text_center">
                      {(!!item.date_shipment &&
                        moment(item.date_shipment.substring(0, 19)).format(
                          "DD.MM.YYYY"
                        )) || "-"}
                    </div>
                  </td>

                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center"
                        style={{
                    cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                    }}
                         onClick={() => setModalBuyerOrderExpertAssessmentEdit(item)}>

                      {(!!item.expert_assessment && (
                          <Tooltip text={'Оценка сложности'} style={{margin: "0 2px"}}>
                            <div style={{
                              backgroundColor: "#202020",
                              borderRadius: 7,
                              padding: 1,
                              height: 26,
                              display: "inline-block"
                            }}>
                              <Icon name={
                                item.expert_assessment === 1
                                  ? "custom-looks-1"
                                  : item.expert_assessment === 2
                                    ? "custom-looks-2"
                                    : item.expert_assessment === 3
                                      ? "custom-looks-3"
                                      : item.expert_assessment === 4
                                        ? "custom-looks-4"
                                        : item.expert_assessment === 5
                                          ? "custom-looks-5"
                                          : ""
                              } color={
                                item.expert_assessment === 1
                                  ? "#c0ca33"
                                  : item.expert_assessment === 2
                                    ? "#fdd835"
                                    : item.expert_assessment === 3
                                      ? "#ffb300"
                                      : item.expert_assessment === 4
                                        ? "#fb8c00"
                                        : item.expert_assessment === 5
                                          ? "#f4511e"
                                          : ""
                              } size={24} />
                            </div>
                          </Tooltip>
                      )) || "-"}

                      {!!item.neural_expert_assessment && (
                        <Tooltip text={'Оценка нейросети'} style={{margin: "0 2px"}}>
                          <div style={{
                            backgroundColor: "#202020",
                            borderRadius: 4,
                            padding: 1,
                            height: 18,
                            display: "inline-block"
                          }}>
                            <Icon name={
                              Math.round(item.neural_expert_assessment) === 1
                                ? "custom-looks-1"
                                : Math.round(item.neural_expert_assessment) === 2
                                  ? "custom-looks-2"
                                  : Math.round(item.neural_expert_assessment) === 3
                                    ? "custom-looks-3"
                                    : Math.round(item.neural_expert_assessment) === 4
                                      ? "custom-looks-4"
                                      : Math.round(item.neural_expert_assessment) === 5
                                        ? "custom-looks-5"
                                        : ""
                            } color={
                              Math.round(item.neural_expert_assessment) === 1
                                ? "#c0ca33"
                                : Math.round(item.neural_expert_assessment) === 2
                                  ? "#fdd835"
                                  : Math.round(item.neural_expert_assessment) === 3
                                    ? "#ffb300"
                                    : Math.round(item.neural_expert_assessment) === 4
                                      ? "#fb8c00"
                                      : Math.round(item.neural_expert_assessment) === 5
                                        ? "#f4511e"
                                        : ""
                            } size={16} />
                          </div>
                        </Tooltip>
                      )}

                    </div>
                  </td>

                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center" style={{
                      cursor: "pointer"
                    }}
                         onClick={() =>
                           setModalBuyerOrderCollected(item)
                         }>
                      {(!!item.packer && getAccountFio(item.packer)) || "-"}
                    </div>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          )}
      </div>
      )}

      {!!modalBuyerOrderCollected && (
        <ModalBuyerOrderEmployeePackingEdit
          buyerOrder={modalBuyerOrderCollected}
          successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
          handleClose={() => setModalBuyerOrderCollected(null)}
        />
      )}

      {!!modalBuyerOrderExpertAssessmentEdit && (
        <ModalBuyerOrderExpertAssessmentEdit
          buyerOrder={modalBuyerOrderExpertAssessmentEdit}
          successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
          handleClose={() => setModalBuyerOrderExpertAssessmentEdit(null)}
        />
      )}
    </div>
    </ScrollBlock>
  );
}

export default PlanPackingPage;
