import React, {useEffect, useState} from "react";
import moment from "moment";
import {ROOT_URL} from "../../../../../config/url";
import {useList} from "@vokymlak/kabinet-ui";

function useTimeSheetTable({date, selectEmployee}) {
  const [toDay] = useState(moment);
  const [calendarDays, setCalendarDays] = useState(
    new Date(
      Number(moment(date).format("YYYY")),
      Number(moment(date).format("MM")),
      0
    ).getDate()
  );
  const [days, setDays] = useState(null);

  useEffect(() => {
    setCalendarDays(
      new Date(
        Number(moment(date).format("YYYY")),
        Number(moment(date).format("MM")),
        0
      ).getDate()
    );
  }, [date]);

  useEffect(() => {
    const days = [];
    for (let i = 0; i < calendarDays; i++) {
      let day = i + 1;

      const d = {
        day,
      };

      days.push(d);
    }

    setDays([...days]);
  }, [calendarDays]);

  const weekDayText = (weekDay) => {
    const weekDays = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

    return weekDays[Number(weekDay)];
  };

  useEffect(() => {
    return () => {
      setList(null);
      setDays(0);
    };
  }, []);

  const getUrlParams = () => {
    return {
      account: !!selectEmployee && selectEmployee > 0 ? selectEmployee : 0,
      date: date,
    }
  }

  const {
    list: [list, _count, setList],
    update: [isUpdateList, setIsUpdateList],
    setApiUrlParams,
  } = useList({
      apiUrl: "/api/time-sheet",
      rootUrl: ROOT_URL,
      urlParams: getUrlParams()
    }
  );

  useEffect(() => {
    setApiUrlParams(getUrlParams());
  }, [selectEmployee, date]);


  const getDayTimeSheet = (list, day) => {
    let dayTimeSheet = null;

    if (!!list) {
      list.map((item) => {
        if (
          Number(moment(item.start_time.substring(0, 10)).format("YYYY")) ===
          Number(moment(date).format("YYYY")) &&
          Number(moment(item.start_time.substring(0, 10)).format("MM")) ===
          Number(moment(date).format("MM")) &&
          Number(moment(item.start_time.substring(0, 10)).format("DD")) ===
          Number(day)
        ) {
          dayTimeSheet = item
        }
      });
    }

    return dayTimeSheet;
  };

  function getTimeBetweenDates(date1, date2) {
    const diff = moment(date2).diff(moment(date1));
    const duration = moment.duration(diff);
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }


  const formatDayTimeSheet = (timeSheet) => {
    return "" + moment(timeSheet.start_time.substring(0, 19)).format("HH:mm") +
      " - " + moment(timeSheet.end_time.substring(0, 19)).format("HH:mm") +
      " (" + getTimeBetweenDates(timeSheet.start_time.substring(0, 19), timeSheet.end_time.substring(0, 19)) + ")"
  }

  return {
    toDay,
    date,
    days,
    list,
    weekDayText,
    getDayTimeSheet,
    formatDayTimeSheet,
    isUpdateList,
    setIsUpdateList,
  };
}

export default useTimeSheetTable;
