import React, {useEffect, useState} from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import "./Kanban.scss";
import {KanbanMenu} from "./KanbanMenu/KanbanMenu";
import {TicketsTopics} from "./KanbanSpaces";
import {Tickets} from "./KanbanSpace";
import {useProfile} from "@vokymlak/kabinet-ui";
import KanbanSpaces from "./KanbanSpaces/KanbanSpaces";
import KanbanSpace from "./KanbanSpace/KanbanSpace";

export const KanbanRouting = () => {
  const { profile } = useProfile();

  return (
    <div className="Tickets">
      <div className="Block" >
        <div className="App App_vertical">
          <div className="Navigate" style={{
            width: 320,
          }}>
            <KanbanMenu />
          </div>

          <div className="Content" style={{
            borderLeft: "2px solid #20202015",
          }}>
            <Routes>
              <Route
                path="/space/:kanbanSpaceUuid"
                element={<KanbanSpace />}
              />

              <Route
                path="/author"
                element={<KanbanSpace authorId={profile.id} />}
              />

              <Route
                path="/executor"
                element={<KanbanSpace executorId={profile.id} />}
              />

              <Route path="/spaces" element={<KanbanSpaces />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};
