import React, { Fragment } from "react";
import { ROOT_URL } from "../../../config/url";
import {useObject} from "@vokymlak/kabinet-ui";

function BuyerOrderObjectWarehouseView(props) {
  const { object } = props;

  const {
    object: [warehouse],
  } = useObject({apiUrl:"/api/order/" +object.uuid + "/warehouse", rootUrl:ROOT_URL});

  return (
    !!warehouse && (
      <Fragment>
        <table
          className="Table"
          style={{
            marginBottom: 12,
          }}
        >
          <thead className="Thead">
            <tr className="Table__tr Table__tr_padding Table__tr_border">
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">№</div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Номенклатура</div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Артикль</div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Номер</div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Количество</div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Ед. измерения</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {!!warehouse.nomenclatures && warehouse.nomenclatures.map((resource, index) => (
              <tr
                className="Table__tr Table__tr_hover Table__tr_padding Table__tr_border"
                key={"item_" + index}
              >
                <td className="Table__td Table__td_border">
                  <div className="Td__text Td__text_center">
                    {resource.nomenclature.id}
                  </div>
                </td>
                <td className="Table__td Table__td_border">
                  <div className="Td__text">
                    <div
                      style={{
                        fontSize: 17,
                        fontWeight: 400,
                      }}
                    >
                      {resource.nomenclature.name}
                      {resource.nomenclature.specifications &&
                        resource.nomenclature.specifications.map(
                          (specification) => (
                            <div
                              key={"marker_" + specification.uuid}
                              className="Marker Marker_left Marker_inline Marker_small"
                            />
                          )
                        )}
                    </div>
                  </div>
                </td>
                <td className="Table__td Table__td_border">
                  <div className="Td__text Td__text_center">
                    {(!!resource.nomenclature.article &&
                      resource.nomenclature.article) ||
                      "-"}
                  </div>
                </td>
                <td className="Table__td Table__td_border">
                  <div className="Td__text Td__text_center">
                    {resource.nomenclature.code}
                  </div>
                </td>
                <td className="Table__td Table__td_border">
                  <div className="Td__text Td__text_right">
                    <div
                      style={{
                        fontSize: 17,
                        fontWeight: 700,
                      }}
                    >
                      {(!!resource.nomenclature.warehouse_quantity && Number(resource.nomenclature.warehouse_quantity).toFixed(3)) || "-"}
                    </div>
                  </div>
                </td>
                <td className="Table__td Table__td_border">
                  <div className="Td__text">
                    <div
                      style={{
                        fontSize: 17,
                        fontWeight: 700,
                      }}
                    >
                      {!!resource.nomenclature.measurement_type &&
                        " " +
                        resource.nomenclature.measurement_type.designation}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Fragment>
    )
  );
}

export default BuyerOrderObjectWarehouseView;
