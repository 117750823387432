import React from "react";
import { ROOT_URL } from "../../../config/url";
import {useProfile} from "@vokymlak/kabinet-ui";
import { useObject} from "@vokymlak/kabinet-ui";

function usePlanDispatch() {
  const {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject],
  } = useObject({
    apiUrl:"/api/plan/dispatch",
    rootUrl: ROOT_URL,
  });

  const {profile} = useProfile()

  return {
    object,
    setObject,

    setIsUpdateObject,
    isUpdateObject,


    profile,
  };
}

export default usePlanDispatch;
