import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {
  getErrorMessageByKey,
  Input,
  isValidateNullText,
  jsonFormatText,
  Modal,
  useNotification,
} from "@vokymlak/kabinet-ui";


function ModalPlanWarehouseRatio(props) {

  const inputRatio = useRef(null);

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeEdit = () => {
    const ratio = jsonFormatText(inputRatio.current.value);

    if (isValidateNullText(ratio)) {
      props.setSelectRatio(null)
    } else {
      props.setSelectRatio(numberConvert(ratio))
    }

    props.handleClose();
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <div className="Form">
        <div
          className="Form__title"
          style={{fontSize: 19, fontWeight: 700}}
        >
          План на сырьё
          <div className="Text__signature">Укажите параметры для выборки</div>
        </div>

        <div className="Form__field">
          <Input reference={inputRatio} type={'number'} label="Соотношение" value={!!props.ratio && (props.ratio / 100)}/>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue"
                  onClick={() => changeEdit()}>
            Рассчитать
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalPlanWarehouseRatio;
