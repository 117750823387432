import React, {Fragment, useState} from "react";
import styleContent from "../../../styles/modules/Block.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";
import ModalNomenclatureStickerAdd from "./ModalNomenclatureStickerAdd";
import ModalNomenclatureStickerEdit from "./ModalNomenclatureStickerEdit";

function NomenclatureStickersList(props) {
  const {object, isEdit, setIsUpdateList, isUpdateList} = props;


  const [modalFileAdd, setModalFileAdd] = useState(null);
  const [modalFileEdit, setModalFileEdit] = useState(null);

  function getColorFile(text) {
    if (text.toLowerCase().includes('docx') || text.toLowerCase().includes('doc')) {
      return "#3d6cc0";
    } else if (text.toLowerCase().includes('xlsx')) {
      return "#107c41";
    } else if (text.toLowerCase().includes('pdf')) {
      return "#b9090b";
    }
    return "#202020";
  }

  return (
    !!object.stickers && (
      <Fragment>
        <div className={styleContent._block}>
          <div className={styleContent._block__title}>Этикетки</div>
          <div className={styleContent._block__content}>
            {!!object.stickers && (
              <div className={styleContent._block}>
                <div className={styleContent._block__content}>
                  {object.stickers.map((file) => (
                    <div
                      className={styleCard._block + " " + styleCard._block_hover}
                      key={object.uuid + ":" + file.uuid}
                      // onContextMenu={(event) => (event.preventDefault(), setModalFileEdit(file))}
                      // onClick={(!!file.mime_type && (file.mime_type === "image/jpeg" || file.mime_type === "image/png"))
                      //   ? (event) => (event.preventDefault(), changeViewer(file.data, file.mime_type))
                      //   : (event) => (event.preventDefault(), setModalFileEdit(file))
                      // }
                      // onContextMenu={(event) => (event.preventDefault(), setModalFileEdit(file))}
                      onClick={(event) => (event.preventDefault(), setModalFileEdit(file))}
                      style={{
                        minWidth: "auto",
                        display: "flex",
                        alignItems: "center"
                      }}>
                      {!!file.expansion && (
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            textTransform: "uppercase",
                            fontSize: 10,
                            fontWeight: 900,
                            borderRadius: 3,
                            color: "white",
                            backgroundColor: getColorFile(file.expansion.slice(1)),
                            marginRight: 8,
                            padding: "2px 4px"
                          }}>
                                    {file.expansion.slice(1)}
                                  </span>
                      )}
                      <span className={styleCard._block__title} style={{
                        fontSize: 15
                      }}>
                                  {file.name}
                                </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>


        {!!modalFileAdd && (
          <ModalNomenclatureStickerAdd
            object={modalFileAdd}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalFileAdd(null)}
          />
        )}
        {!!modalFileEdit && (
          <ModalNomenclatureStickerEdit
            file={modalFileEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalFileEdit(null)}
          />
        )}
      </Fragment>
    )
  )
    ;
}

export default NomenclatureStickersList;
