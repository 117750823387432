import React, { useEffect, useState } from "react";
import { ROOT_URL } from "../../../../config/url";
import {useObject} from "@vokymlak/kabinet-ui";
import axios from "axios";
import {getErrorMessageByKey} from "@vokymlak/kabinet-ui";
import {useNotification} from "@vokymlak/kabinet-ui";

function EmployeeTimeTrackingView(props) {
  const {
    object: [object],
    update: [isUpdateList, setIsUpdateList],
  } = useObject({
      apiUrl:"/api/time-tracking/account/" + props.employee.id,
    rootUrl: ROOT_URL
  });

  const { notification } = useNotification();

  const isActivatedAccount = (accountId) => {
    axios
      .get("/api/time-tracking/account/" + accountId + "/edit", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  useEffect(() => {
    setIsUpdateList(!isUpdateList);
  }, [props]);

  return (
    !!object && (
      <div className="Button__swith">
        <div className="Swith__text">Временной учет</div>
        <div
          className={
            "Swith" +
            ((!!object.is_time_tracking &&
                " Swith_active ") ||
              "")
          }
          onClick={() => isActivatedAccount(props.employee.id)}
        ></div>
      </div>
    )
  );
}

export default EmployeeTimeTrackingView;
