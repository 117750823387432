import React, {Fragment, useState} from "react";
import {ROOT_URL} from "../../../../config/url";
import styleContent from "../../../../styles/modules/Block.module.scss";
import styleCard from "../../../../styles/modules/Card.module.scss";
import ModalSiteBlogImageEdit from "./ModalSiteBlogImageEdit";
import {useViewer} from "../../../../components/Viewer";
import ImageUploadB64 from "./ImageUpload64";

function SiteBlogImagesList(props) {
  const {object, setIsUpdateList, isUpdateList} = props;

  const {viewerChildren} = useViewer();

  const changeViewer = (imageView) => {
    viewerChildren(imageView);
  };

  const [modalNomenclatureImageEdit, setModalNomenclatureImageEdit] = useState(null);

  return (
    !!object.images && (
      <Fragment>
        <div className={styleContent._block}>
          <div className={styleContent._block__content}>
            {object.images.map((image) => (
              <Fragment
                key={"item" + ":" + image.uuid}>
                <div
                  className={styleCard._block + " " + styleCard._block_hover}
                  style={{
                    minWidth: 12 + 144,
                    height: 12 + 72,
                    padding: 6
                  }}
                  onContextMenu={(event) => (event.preventDefault(), setModalNomenclatureImageEdit(image))}
                  onClick={() =>
                    changeViewer(
                      <ImageUploadB64 url={ROOT_URL+"/api/site-blog/image/" + image.uuid + "/b64"}/>
                    )}>
                  <ImageUploadB64 url={ROOT_URL+"/api/site-blog/image/" + image.uuid + "/b64"} height={72} width={144}/>
                </div>
              </Fragment>
            ))}
          </div>
        </div>

        {!!modalNomenclatureImageEdit && (
          <ModalSiteBlogImageEdit
            image={modalNomenclatureImageEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalNomenclatureImageEdit(null)}
          />
        )}
      </Fragment>
    )
  );
}

export default SiteBlogImagesList;
