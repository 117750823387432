import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
  Input,
  jsonFormatText,
  isValidateNullText,
} from "@vokymlak/kabinet-ui";

function ModalEditKanbanSpace(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/kanban-space/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputTitle = useRef(null);

  const { notification } = useNotification();
  const [isDelete, setIsDelete] = useState(false);

  const changeEdit = (event) => {
    event.preventDefault();

    const title = jsonFormatText(inputTitle.current.value);

    if (isValidateNullText(title)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      title: title,
    });

    axios
      .put("/api/kanban-space/" + props.object.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/kanban-space/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Доска удалена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {object.title}
              <div className="Text__signature">
                Доска
              </div>
            </div>

            <div className="Form__field">
              <Input
                reference={inputTitle}
                type="text"
                label="Наименование *"
                value={object.title}
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
            </div>
          </form>
        )}
        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div className="Form__title">
              {object.title}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Удалить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalEditKanbanSpace;
