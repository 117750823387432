import React, { useState } from "react";
import TimeSheetTable from "./components/TimeSheetTable/TimeSheetTable";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {ButtonIcon, Select} from "@vokymlak/kabinet-ui";

export const TimeSheetPage = (props) => {
  const {
    list,

    monthText,
    selectMonth,
    selectYear,


    changeSelectMonthMinus,
    changeSelectMonthPlus,

    selectEmployee,
    getEmployeesSelectOptions,
    changeSelectEmployee,
  } = props;

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Табель
            <span style={{ width: 220, marginLeft: 16, fontWeight: 400 }}>
               <Select
                 isFixed={true}
                 isDefaultOption={false}
                 value={selectEmployee}
                 options={
                   !!list
                     ? getEmployeesSelectOptions(list)
                     : getEmployeesSelectOptions([])
                 }
                 label="Сотрудник"
                 onChange={(event) => (changeSelectEmployee(Number(event.target.value)))}
               />
            </span>
        </div>

        <div className="Title__settings">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
              marginLeft: 12,
            }}
          >
            {monthText(selectMonth)}
            <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                {selectYear}
              </span>
            <ButtonIcon
              tooltipText={"Назад"}
              iconName={"custom-circle-arrow-left"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 12 }}
              onClick={() => changeSelectMonthMinus()}
            />
            <ButtonIcon
              tooltipText={"В&nbsp;перёд"}
              iconName={"custom-circle-arrow-right"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 6 }}
              onClick={() => changeSelectMonthPlus()}
            />
          </div>
        </div>
      </div>

      <TimeSheetTable
        date={selectYear + "-" + selectMonth + "-01"}
        selectEmployee={selectEmployee}
      />
    </div>
    </ScrollBlock>
  );
}
