import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
  Input,
  jsonFormatText,
  isValidateNullText, Textarea,
} from "@vokymlak/kabinet-ui";

function convertToSEOAddress(productName) {
  // Преобразование к нижнему регистру
  productName = productName.toLowerCase();

  // Замена пробелов на дефисы
  productName = productName.replace(/\s+/g, '-');

  // Замена русских символов на английские эквиваленты
  const cyrillicToLatinMap = {
    "а": 'a', "б": 'b', "в": 'v', "г": 'g', "д": 'd', "е": 'e', "ё": 'yo', "ж": 'zh',
    "з": 'z', "и": 'i', "й": 'y', "к": 'k', "л": 'l', "м": 'm', "н": 'n', "о": 'o',
    "п": 'p', "р": 'r', "с": 's', "т": 't', "у": 'u', "ф": 'f', "х": 'h', "ц": 'c',
    "ч": 'ch', "ш": 'sh', "щ": 'sch', "ъ": '%', "ы": 'y', "ь": '%', "э": 'e', "ю": 'yu',
    "я": 'ya'
  };
  productName = productName.replace(/[а-яё]/g, function (char) {
    return cyrillicToLatinMap[char] || char;
  });

  // Удаление специальных символов и знаков препинания, сохраняя русские символы
  productName = productName.replace(/[^\dA-Za-zА-Яа-я-]/g, '');

  // Удаление двойных и последних дефисов
  productName = productName.replace(/[-]{2,}/g, '-').replace(/^-+|-+$/g, '');

  // Возвращаем результат
  return productName;
}

function ModalEditSiteBlogTopic(props) {
  const [isPublic, setIsPublic] = useState(false);

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/site-blog-topic/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
      setIsPublic(!!data && data.is_published);
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputTitle = useRef(null);
  const inputSeoUrl = useRef(null);
  const textareaDescription = useRef(null);

  const { notification } = useNotification();
  const [isDelete, setIsDelete] = useState(false);

  const changeEdit = (event) => {
    event.preventDefault();

    const title = jsonFormatText(inputTitle.current.value);
    const seoUrl = jsonFormatText(inputSeoUrl.current.value);
    const description = jsonFormatText(textareaDescription.current.value);

    if (isValidateNullText(title)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      title: title,
      is_published: isPublic,
      seo_url: seoUrl,
      description: description,
    });

    axios
      .put("/api/site-blog-topic/" + props.object.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/site-blog-topic/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Тема удалена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const generateSeoUrl = () => {
    const name = jsonFormatText(inputTitle.current.value);

    if (!isValidateNullText(name)) {
      const seoUrl = convertToSEOAddress(name)
      console.log(seoUrl)
      inputSeoUrl.current.value = seoUrl
    }
  }


  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {object.title}
              <div className="Text__signature">
                Тема
              </div>
            </div>

            <div className="Form__field">
              <Input
                reference={inputTitle}
                type="text"
                label="Наименование *"
                value={object.title}
              />
            </div>


            <div className="Form__field">
              <Input reference={inputSeoUrl}
                     isFixed={true}
                     label="SEO адрес"
                     value={object.seo_url}/>
              <div className="Action__link Action__link_blue" style={{
                marginTop: 4,
                fontSize: 12,
                padding: "0 4px"
              }} onClick={() => generateSeoUrl()}>
                Сгенерировать по названию
              </div>
            </div>

            <div className="Form__field">
              <Textarea
                reference={textareaDescription}
                label="Описание"
                value={object.description}
              />
            </div>

            <div className="Form__field Form__field_last">
              <div
                className="Button__swith"
                style={{
                  padding: "8px",
                  width: "100%",
                  border: isPublic
                    ? "2px solid #ffe082"
                    : "2px dashed #20202015",
                  backgroundColor: isPublic ? "#ffe082" : "#ffffff",
                  borderRadius: 6,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setIsPublic(!isPublic)}
              >
                <div
                  className="Swith__text"
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Опубликовать на сайт
                </div>
                <div
                  className={
                    "Swith Swith_gray" +
                    ((isPublic && " Swith_active Swith_active_gray") || "")
                  }
                ></div>
              </div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
            </div>
          </form>
        )}
        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div className="Form__title">
              {object.title}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Удалить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalEditSiteBlogTopic;
