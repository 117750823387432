import React from "react";
import {Modal} from "@vokymlak/kabinet-ui";

function ModalBuyerOrderStateNewEditConfirm(props) {

  return (
    props.buyerOrderState && (
      <Modal handleClose={() => props.handleClose()}>
        <div className="Form">
          <div className="Form__title">
            {props.buyerOrderState.name}
            <br/>
            <div className="Text__signature">
              Перевод в статус заказа
            </div>
          </div>

          <div className="Form__actions Form__actions_center">
            <button
              className="Action__button Action__button_all Action__button_blue"
              onClick={() => props.successfulRequest()}>
              Подтвердить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </div>
      </Modal>
    )
  );
}

export default ModalBuyerOrderStateNewEditConfirm;
