import React, { useContext, useEffect, useState } from "react";
import "./Keyboard.scss";
import { KeyboardContext, changeHashKeyboard } from "../../contexts/keyboard";
import ReactKeyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
// import layoutRussian from "simple-keyboard-layouts/build/layouts/russian";
import {Icon, newUuid} from "@vokymlak/kabinet-ui";

function Keyboard() {
  const { keyboardState, keyboardDispatch } = useContext(KeyboardContext);
  const [isView, setIsView] = useState(false);

  const [layoutName, setLayoutName] = useState("default");
  const [input, setInput] = useState("");
  const [keyboardInput, setKeyboardInput] = useState(null);

  const [keyboard, setKeyboard] = useState(null);

  useEffect(() => {
    if (!!keyboard) {
      keyboard.setInput(input);
    }
  }, [input]);

  useEffect(() => {
    if (!!keyboardState.inputKeyboard) {
      const inputKeyboard = keyboardState.inputKeyboard;
      if (
        !keyboardInput ||
        !inputKeyboard.current ||
        !inputKeyboard.current.id
      ) {
        setKeyboardInput(keyboardState.inputKeyboard);
        setInput("");
      } else if (
        !keyboardInput.current ||
        (!!keyboardInput &&
          !!inputKeyboard.current &&
          !!inputKeyboard.current.id &&
          inputKeyboard.current.id !== keyboardInput.current.id)
      ) {
        setKeyboardInput(keyboardState.inputKeyboard);
        setInput("");
      }
    } else {
      setKeyboardInput(null);
      setInput("");
    }
  }, [keyboardState.inputKeyboard]);

  const onChange = (input) => {
    setInput(input);
    // console.log("Input changed", input);
    if (keyboardState.inputKeyboard) {
      if (
        !!keyboardState.inputKeyboard &&
        !!keyboardState.inputKeyboard.current
      ) {
        keyboardState.inputKeyboard.current.value = input;
        keyboardDispatch(changeHashKeyboard(newUuid()));
      } else {
        keyboardDispatch(changeHashKeyboard(newUuid()));
      }
    }
  };

  const onKeyPress = (button) => {
    // console.log("Button pressed", button);
    if (button.includes("{") && button.includes("}")) {
      handleLayoutChange(button);
    }
  };

  function handleLayoutChange(button) {
    let currentLayout = layoutName;
    let layout;

    // switch (button) {
    //   case "{shift}":
    //   case "{shiftactivated}":
    //   case "{default}":
    //     layout = currentLayout === "default" ? "shift" : "default";
    //     break;

    //   case "{alt}":
    //   case "{altright}":
    //     layout = currentLayout === "alt" ? "default" : "alt";
    //     break;

    //   case "{smileys}":
    //     layout = currentLayout === "smileys" ? "default" : "smileys";
    //     break;

    //   default:
    //     break;
    // }

    switch (button) {
      case "{shiftEnglish}":
      case "{shiftEnglishActivated}":
      case "{default}":
        layout = currentLayout === "default" ? "shiftEnglish" : "default";
        break;

      case "{shiftRussian}":
      case "{shiftRussianActivated}":
      case "{russian}":
        layout = currentLayout === "russian" ? "shiftRussian" : "russian";
        break;

      case "{alt}":
        layout = currentLayout === "alt" ? "default" : "alt";
        break;

      default:
        break;
    }

    if (layout) {
      setLayoutName(layout);
    }
  }

  const onChangeInput = (event) => {
    onChange(event.target.value);
  };

  const layout = {
    //   default: [
    //     "q w e r t y u i o p {bksp}",
    //     "a s d f g h j k l {enter}",
    //     "{shift} z x c v b n m , . {shift}",
    //     "{alt} {smileys} {space} {altright} {downkeyboard}",
    //   ],
    //   shift: [
    //     "Q W E R T Y U I O P {bksp}",
    //     "A S D F G H J K L {enter}",
    //     "{shiftactivated} Z X C V B N M , . {shiftactivated}",
    //     "{alt} {smileys} {space} {altright} {downkeyboard}",
    //   ],
    //   alt: [
    //     "1 2 3 4 5 6 7 8 9 0 {bksp}",
    //     `@ # $ & * ( ) ' " {enter}`,
    //     "{shift} % - + = / ; : ! ? {shift}",
    //     "{default} {smileys} {space} {back} {downkeyboard}",
    //   ],
    //   smileys: [
    //     "😀 😊 😅 😂 🙂 😉 😍 😛 😠 😎 {bksp}",
    //     `😏 😬 😭 😓 😱 😪 😬 😴 😯 {enter}`,
    //     "😐 😇 🤣 😘 😚 😆 😡 😥 😓 🙄 {shift}",
    //     "{default} {smileys} {space} {altright} {downkeyboard}",
    //   ],

    default: [
      "q w e r t y u i o p {bksp}",
      "a s d f g h j k l {enter}",
      "{shiftEnglish} z x c v b n m , . {shiftEnglish}",
      "{alt} {space} {russian}",
    ],
    shiftEnglish: [
      "Q W E R T Y U I O P {bksp}",
      "A S D F G H J K L {enter}",
      "{shiftEnglishActivated} Z X C V B N M , . {shiftEnglishActivated}",
      "{alt} {space} {russian}",
    ],
    russian: [
      "й ц у к е н г ш щ з х ъ {bksp}",
      "ф ы в а п р о л д ж э ё {enter}",
      "{shiftRussian} я ч с м и т ь б ю , . {shiftRussian}",
      "{alt} {space} {default}",
    ],
    shiftRussian: [
      "Й Ц У К Е Н Г Ш Щ З Х Ъ {bksp}",
      "Ф Ы В А П Р О Л Д Ж Э Ё {enter}",
      "{shiftRussianActivated} Я Ч С М И Т Ь Б Ю , . {shiftRussianActivated}",
      "{alt} {space} {default}",
    ],
    alt: [
      "1 2 3 4 5 6 7 8 9 0 {bksp}",
      `@ # $ & * ( ) ' " {enter}`,
      "% - + = / ; : ! ? , .",
      "{default} {space} {russian}",
    ],
  };

  const display = {
    //   "{alt}": ".?123",
    //   "{smileys}": "\uD83D\uDE03",
    //   "{shift}": "⇧",
    //   "{shiftactivated}": "⇧",
    //   "{enter}": "return",
    //   "{bksp}": "⌫",
    //   "{altright}": ".?123",
    //   "{downkeyboard}": "🞃",
    //   "{space}": " ",
    //   "{default}": "ABC",
    //   "{back}": "⇦",

    "{default}": "English",
    "{shiftEnglish}": "⇧",
    "{shiftEnglishActivated}": "⇧",
    "{russian}": "Русский",
    "{shiftRussian}": "⇧",
    "{shiftRussianActivated}": "⇧",
    "{alt}": ".?123",
    "{enter}": "return",
    "{bksp}": "⌫",
    "{space}": " ",
  };

  return (
    <div className="Keyboard">
      <div
        style={{
          pointerEvents: "auto",
          cursor: "pointer",
          borderRadius: "5px 5px 0 0",
          backgroundColor: "#292929",
          padding: !isView ? "1px 12px 3px 12px" : "3px 12px 1px 12px",
          height: 28,
          width: 56,
          margin: "0 auto",
        }}
        onClick={() => setIsView(!isView)}
      >
        {!isView && (
          <Icon name={"custom-expand-less"} size={32} color={"white"} />
        )}
        {isView && (
          <Icon name={"custom-expand-more"} size={32} color={"white"} />
        )}
      </div>

      <div
        className="preview"
        style={{
          display: !isView ? "none" : "block",
        }}
      >
        <div className="simple-keyboard-preview">
          <input
            className="input"
            value={input}
            placeholder={"Нажмите на виртуальную клавиатуру, чтобы начать"}
            onChange={(event) => onChangeInput(event)}
          />
        </div>

        <ReactKeyboard
          keyboardRef={(r) => setKeyboard(r)}
          layoutName={layoutName}
          onChange={onChange}
          onKeyPress={onKeyPress}
          // {...layoutRussian}
          layout={layout}
          display={display}
          theme={"hg-theme-default hg-theme-ios"}
        />
      </div>
    </div>
  );
}

export default Keyboard;
