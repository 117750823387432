import React, {Fragment, useState} from "react";
import moment from "moment";
import {getAccountFio} from "@vokymlak/kabinet-ui";
import ModalAddTimeSheet from "./ModalAddTimeSheet";
import ModalEditTimeSheet from "./ModalEditTimeSheet";

function TimeSheetTableView(props) {
  const {
    toDay,
    date,
    days,
    list,
    weekDayText,
    getDayTimeSheet,
    formatDayTimeSheet,
    isUpdateList,
    setIsUpdateList,
  } = props;

  const [modalAddTimeSheet, setModalAddTimeSheet] = useState(null);
  const [modalEditTimeSheet, setModalEditTimeSheet] = useState(null);

  return (
    <>
      <div
        className="Page__content"
        style={{
          height: "calc(100% - 42px)",
        }}
      >
        <div
          className="List"
          style={{
            overflow: "auto",
            height: "100%",
          }}
        >
          <table className="Table">
            <thead className="Thead">
              <tr className="Table__tr Table__tr_padding Table__tr_border">
                <th className="Table__th Table__th_border Table__th_sticky"
                    style={{
                      backgroundColor: "#dfdfdf",
                    }}>
                  <div className="Th__title">ФИО /  Дата</div>
                </th>
                {!!days &&
                  days.map((day, index) => (
                    <th
                      key={'day_' + index}
                      className="Table__th Table__th_border"
                      style={{
                        backgroundColor:
                          (moment(
                              moment(date).format("YYYY") +
                              "-" +
                              moment(date).format("MM") +
                              "-" +
                              day.day,
                              "YYYY-MM-DD"
                            ).weekday() === 0 ||
                            moment(
                              moment(date).format("YYYY") +
                              "-" +
                              moment(date).format("MM") +
                              "-" +
                              day.day,
                              "YYYY-MM-DD"
                            ).weekday() === 6) ?
                          "#bdbdbd" : "#dfdfdf",
                      }}
                    >
                      <div className="Th__title">{moment(
                        moment(date).format("YYYY") +
                        "-" +
                        moment(date).format("MM") +
                        "-" +
                        day.day,
                        "YYYY-MM-DD"
                      ).format("DD.MM")}
                        {" ("} {weekDayText(
                          moment(
                            moment(date).format("YYYY") +
                            "-" +
                            moment(date).format("MM") +
                            "-" +
                            day.day,
                            "YYYY-MM-DD"
                          ).weekday()
                        )}
                        {")"}</div>
                    </th>
                  ))}

              </tr>
            </thead>
            <tbody>

            {!!list &&
              list.map(item => (
                <tr
                  className={
                    "Table__tr Table__tr_padding Table__tr_border Table__tr_hover "
                  }
                  key={"item_" + item.account.uuid}
                >
                  <td
                    className="Table__td Table__td_border Table__td_sticky"
                    style={{
                      backgroundColor: "#dfdfdf",
                    }}
                  >
                    <div className="Td__text Td__text_center">
                      {getAccountFio(item.account)}
                    </div>
                  </td>

                  {!!days &&
                    days.map((day, index) => (
                      <td
                        className="Table__td Table__td_border"
                        key={"day_" + item.account.uuid + "_" + index}

                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            (moment(
                                moment(date).format("YYYY") +
                                "-" +
                                moment(date).format("MM") +
                                "-" +
                                day.day,
                                "YYYY-MM-DD"
                              ).weekday() === 0 ||
                              moment(
                                moment(date).format("YYYY") +
                                "-" +
                                moment(date).format("MM") +
                                "-" +
                                day.day,
                                "YYYY-MM-DD"
                              ).weekday() === 6) ?
                            "#bdbdbd95" : "#dfdfdf95",
                        }}

                        onClick={!!getDayTimeSheet(item.time_sheet, day.day)
                          ? () => setModalEditTimeSheet({
                            accountTimeSheet: item,
                            date: moment(
                              moment(date).format("YYYY") +
                              "-" +
                              moment(date).format("MM") +
                              "-" +
                              day.day,
                              "YYYY-MM-DD"
                            ).format("YYYY-MM-DD")})
                          : () => setModalAddTimeSheet({
                            accountTimeSheet: item,
                            date: moment(
                              moment(date).format("YYYY") +
                              "-" +
                              moment(date).format("MM") +
                              "-" +
                              day.day,
                              "YYYY-MM-DD"
                            ).format("YYYY-MM-DD")})}
                      >
                        <div className="Td__text Td__text_center">
                          {(!!getDayTimeSheet(item.time_sheet, day.day) && formatDayTimeSheet(getDayTimeSheet(item.time_sheet, day.day))) || "-"}
                        </div>
                      </td>
                    ))}
                </tr>
              ))}


            </tbody>
          </table>
        </div>
      </div>

      {!!modalAddTimeSheet && (
        <ModalAddTimeSheet
          data={modalAddTimeSheet}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalAddTimeSheet(null)}
        />
      )}

      {!!modalEditTimeSheet && (
        <ModalEditTimeSheet
          data={modalEditTimeSheet}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalEditTimeSheet(null)}
        />
      )}
    </>
  );
}

export default TimeSheetTableView;
