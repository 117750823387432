import React, { useEffect, useState } from "react";
import { ROOT_URL } from "../../../../../config/url";
import moment from "moment/moment";
import {ROLE_ADMINISTRATOR, ROLE_DIRECTOR} from "../../../../../config/constants";
import {
  useList,
  useProfile,
  useUnroll,
  isProfileRoleTypeTags,
  getAccountFio, ButtonIcon, Loading
} from "@vokymlak/kabinet-ui";
import ModalPackingCancelled from "./ModalPackingCancelled";

function ListPackingKpi(props) {

  const { isUnroll, changeUnroll } = useUnroll();

  const {
    list: [list, _listCount, setList],
    update: [isUpdateList, setIsUpdateList],
    params: [_params, setParams]
  } = useList({
    apiUrl: "/api/kpi/packing/list",
    rootUrl: ROOT_URL,
    urlParams: {
      date: props.date,
      account: (!!props.account && props.account > 0 ? props.account : 0)
    }
  });

  useEffect(() => {
    setList(null)
    setParams({
      date: props.date,
      account: (!!props.account && props.account > 0 ? props.account : 0)
    })
  }, [props]);

  //--------------------------------------------------------------------------------------------------------------------

  const getPackage = (item) => {
    if (!!item.nomenclature.name) {
      let name = item.nomenclature.name.toLowerCase().replace(/\s/g, "");

      if (name.indexOf("5к") >= 0) {
        return <>5&nbsp;кг</>;
      } else if (name.indexOf("1к") >= 0) {
        return <>1&nbsp;кг</>;
      } else if (name.indexOf("500г") >= 0) {
        return <>500&nbsp;гр</>;
      } else if (name.indexOf("250г") >= 0) {
        return <>250&nbsp;гр</>;
      } else if (name.indexOf("200г") >= 0) {
        return <>200&nbsp;гр</>;
      } else if (name.indexOf("100г") >= 0) {
        return <>100&nbsp;гр</>;
      } else if (name.indexOf("50г") >= 0) {
        return <>50&nbsp;гр</>;
      } else if (name.indexOf("1шт") >= 0) {
        return <>1&nbsp;шт</>;
      }
    }

    return null;
  };

  const {profile} = useProfile()

  const [modalPackingCancelled, setModalPackingCancelled] = useState(null)

  return (
    <>
      {!list && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}
      {!!list && (
        <ul className="List">
          {list.map((item, index) => (
            <React.Fragment key={"item_" + item.id}>
            <li
              key={index}
              className={
                isUnroll(item.id) ? "List__item active" : "List__item"
              }
              style={{
                opacity: !!item.cancelled_at && .5,
            }}
              onClick={() => changeUnroll(item.id)}
            >
              <div className="Item__title" style={{
                textDecoration: !!item.cancelled_at && "line-through",
              }}>
                <div className="Element__title Element__title_flex">
                  <div>
                    <div>
                      {item.buyer_order_item.index} # {item.nomenclature.name}
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                      }}
                    >
                      {item.buyer_order.number}
                      &nbsp;
                      {!!item.buyer_order.date &&
                        "от " +
                          moment(item.buyer_order.date.substring(0, 19)).format(
                            "DD.MM.YYYY HH:mm:ss"
                          )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="Item__settings" style={{
                textDecoration: !!item.cancelled_at && "line-through",
              }}>
                {!!item.specification && (
                  <div
                    style={{
                      backgroundColor: "#202020",
                      height: 5,
                      width: 5,
                      borderRadius: 6,
                      margin: "0 4px",
                    }}
                  ></div>
                )}
                {!!item.buyer_order_item.discount_percentage &&
                  Number(item.buyer_order_item.discount_percentage) / 100 ===
                    100 && (
                    <div
                      style={{
                        marginLeft: 8,
                        color: "white",
                        backgroundColor: "#e7ad00",
                        fontSize: 13,
                        fontWeight: 700,
                        padding: "5px 8px 4px",
                        borderRadius: 24,
                      }}
                    >
                      Пробник
                    </div>
                  )}
                {!(
                  !!item.buyer_order_item.discount_percentage &&
                  Number(item.buyer_order_item.discount_percentage) / 100 ===
                    100
                ) &&
                  !!item.specification &&
                  !!getPackage(item) && (
                    <div
                      style={{
                        marginLeft: 8,
                        color: "#202020",
                        backgroundColor: "#bdbdbd",
                        fontSize: 15,
                        fontWeight: 900,
                        padding: "3px 8px 4px",
                        borderRadius: 24,
                      }}
                    >
                      {getPackage(item)}
                    </div>
                  )}
                <span
                  style={{
                    marginLeft: 12,
                    fontSize: 21,
                    fontWeight: 700,
                  }}
                >
                  {item.montage_quantity + item.warehouse_quantity}
                </span>
                {!!item.buyer_order_item.discount_percentage &&
                Number(item.buyer_order_item.discount_percentage) / 100 ===
                  100 ? (
                  <span
                    style={{
                      marginLeft: 12,
                      marginRight: 12,
                      fontSize: 18,
                      fontWeight: 400,
                    }}
                  >
                    (
                    {(
                      Number(item.buyer_order_item.discount_amount) / 100
                    ).toFixed(2)}
                    <span
                      style={{
                        marginLeft: 2,
                        fontSize: 12,
                        fontWeight: 400,
                        opacity: 0.75,
                      }}
                    >
                      руб.
                    </span>
                    )
                  </span>
                ) : (
                  <span
                    style={{
                      marginLeft: 12,
                      marginRight: 12,
                      fontSize: 18,
                      fontWeight: 400,
                    }}
                  >
                    (
                    {(
                      (Number(item.buyer_order_item.price) / 100) *
                      (item.montage_quantity + item.warehouse_quantity)
                    ).toFixed(2)}
                    <span
                      style={{
                        marginLeft: 2,
                        fontSize: 12,
                        fontWeight: 400,
                        opacity: 0.75,
                      }}
                    >
                      руб.
                    </span>
                    )
                  </span>
                )}
                {getAccountFio(item.account)}&nbsp;|&nbsp;
                {!!item.created_at &&
                  moment(item.created_at.substring(0, 19)).format(
                    "DD.MM.YYYY HH:mm:ss"
                  )}
              </div>
            </li>

              {isUnroll(item.id) && (
                <li className="List__content">
                  <div className="Content__header">
                    <div className="Header__title">
                      {!!item.cancelled_at && (
                        <>
                          <span>{!!item.nullifying && getAccountFio(item.nullifying)}</span>
                          <span style={{marginLeft: 8}}>{moment(item.cancelled_at).format("DD.MM.YYYY")}</span>
                        </>
                      )}
                    </div>
                    <div className="Header__settings">
                      {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR, ROLE_DIRECTOR]) && !item.cancelled_at && (
                        <ButtonIcon
                        tooltipText={"Аннулировать"}
                        iconName={"custom-delete"}
                        size={32}
                        style={{ marginLeft: 24 }}
                        onClick={() => setModalPackingCancelled(item)}
                         iconColor={''}/>
                      )}
                    </div>
                  </div>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      )}

      {!!modalPackingCancelled && (
        <ModalPackingCancelled
          item={modalPackingCancelled}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalPackingCancelled(null)}
        />
      )}
    </>
  );
}

export default ListPackingKpi;
