import React, { useState, useEffect } from "react";
import { ROOT_URL } from "../../../config/url";
import {useList, useObject} from "@vokymlak/kabinet-ui";

function usePlanShipments() {
  const [selectTransportCompany, setSelectTransportCompany] = useState('');

  const {
    list: [listTransportCompanies]
  } = useList({
    apiUrl: "/api/buyer-orders/transport-companies",
    rootUrl: ROOT_URL,
  });

  const {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject],
    setApiUrlParams,
  } = useObject({
    apiUrl:"/api/plan/shipments",
    rootUrl: ROOT_URL,
    urlParams: {
      "transport_company": selectTransportCompany
    }
  });

  const changeSelectTransportCompany = (transportCompany) => {
    setSelectTransportCompany(transportCompany.length > 0 ? transportCompany : '');
  };

  useEffect(() => {
    const params = {
      "transport_company": selectTransportCompany
    };
    setApiUrlParams(params);
  }, [selectTransportCompany]);


  return {
    object,
    setObject,

    setIsUpdateObject,
    isUpdateObject,

    selectTransportCompany,
    listTransportCompanies,
    changeSelectTransportCompany,
  };
}

export default usePlanShipments;
