import React, { createContext, useReducer, useState, useRef } from "react";
import Keyboard from "../../components/Keyboard/Keyboard";
import { initialState, keyboardReducer } from "./keyboardReducer";

export const KeyboardContext = createContext({
  keyboardState: initialState,
  keyboardDispatch: () => null,
});

export const KeyboardProvider = ({ children }) => {
  const [keyboardState, keyboardDispatch] = useReducer(
    keyboardReducer,
    initialState
  );

  // TODO доделать на аналитику
  return (
    <KeyboardContext.Provider value={{ keyboardState, keyboardDispatch }}>
      {children}
      {/*<Keyboard />*/}
    </KeyboardContext.Provider>
  );
};
