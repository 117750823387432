import React, { useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  isValidateNullText, Input,
} from "@vokymlak/kabinet-ui";

function ModalLeadStatisticAdd(props) {
  const inputStatisticDate = useRef(null);

  const inputSeoSiteFormCount = useRef(null);
  const inputSeoJivoCount = useRef(null);
  const inputAdSiteFormCount = useRef(null);
  const inputAdSpending = useRef(null);
  const inputAvitoCount = useRef(null);
  const inputVkCount = useRef(null);

  const { notification } = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0 && numberString !== "null"
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };


  const changeAdd = (event) => {
    event.preventDefault();

    const statisticDate = inputStatisticDate.current.value;
    const seoSiteFormCount = inputSeoSiteFormCount.current.value;
    const seoJivoCount = inputSeoJivoCount.current.value;
    const adSiteFormCount = inputAdSiteFormCount.current.value;
    const adSpending = inputAdSpending.current.value;
    const avitoCount = inputAvitoCount.current.value;
    const vkCount = inputVkCount.current.value;

    if (isValidateNullText(statisticDate)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      statistic_date: !!statisticDate && statisticDate.length > 0 ? statisticDate : null,
      seo_site_form_count: Number(seoSiteFormCount),
      seo_jivo_count: Number(seoJivoCount),
      ad_site_form_count: Number(adSiteFormCount),
      ad_spending: numberConvert(adSpending),
      avito_count: Number(avitoCount),
      vk_count: Number(vkCount),
    });

    axios
      .post("/api/lead-statistic/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные по лидам добавлены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Новые
          <div className="Text__signature">Данные по лидам</div>
        </div>

        <div className="Form__field">
          <Input
            reference={inputStatisticDate}
            type="date"
            isFixed={true}
            label="Дата"
          />
        </div>

        <div className="Form__field">
          <Input type={'number'} reference={inputSeoSiteFormCount} label="Лиды с формы (seo)" />
        </div>

        <div className="Form__field">
          <Input type={'number'} reference={inputAdSiteFormCount} label="Лиды с формы (реклама)" />
        </div>

        <div className="Form__field">
          <Input type={'number'} reference={inputSeoJivoCount} label="Лиды с Jivo" />
        </div>

        <div className="Form__field Form__field_last">
          <Input type={'number'} reference={inputAdSpending} label="Затраты на рекламу" />
        </div>

        <div className="Form__field Form__field_last">
          <Input type={'number'} reference={inputAvitoCount} label="Лиды с Avito" />
        </div>

        <div className="Form__field Form__field_last">
          <Input type={'number'} reference={inputVkCount} label="Лиды с vk" />
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalLeadStatisticAdd;
