import React, { useState } from "react";
import { ROOT_URL } from "../../../config/url";
import {useObject} from "@vokymlak/kabinet-ui";
import {useUnroll} from "@vokymlak/kabinet-ui";
import {useProfile} from "@vokymlak/kabinet-ui";

function usePlanKneading() {
  const {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject],
  } = useObject({apiUrl:"/api/plan/kneading", rootUrl:ROOT_URL});

  const { isUnroll, changeUnroll } = useUnroll();

  const {profile} = useProfile()

  const [tab, setTab] = useState('items');

  return {
    object,
    setObject,

    isUnroll,
    changeUnroll,

    setIsUpdateObject,
    isUpdateObject,

    profile,

    tab,
    setTab,
  };
}

export default usePlanKneading;
