import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  useObject,
  useList,
  Select,
  Modal,
} from "@vokymlak/kabinet-ui";

function ModalOrdersStateEdit(props) {
  const [buyerOrderStates, setBuyerOrderStates] = useState(null);

  const inputBuyerOrderStateId = useRef(null);

  const { notification } = useNotification();


  useEffect(() => {
    axios
      .get("/api/view-order-states", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then((response) => {
        setBuyerOrderStates(response.data ? response.data : null);
      }).catch((error) => {
      props.handleClose();
      notification(getErrorMessageByKey(error.response.data.error), "red");
    });

  }, []);


  const changeEdit = (event) => {
    event.preventDefault();

    const buyerOrderStateId = inputBuyerOrderStateId.current.value;

    if (!buyerOrderStateId || buyerOrderStateId.length === 0) {
      return;
    }

    const jsonData = JSON.stringify({
      buyer_orders: props.buyerOrders,
      buyer_order_state_id: buyerOrderStateId,
    });

    axios
      .put("/api/buyer-orders/edit/state/" + buyerOrderStateId, jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const buyerOrderStatesToSelectOptions = (states) => {
    const options = [];

    states.map((role) => {
      const option = {
        value: role.id,
        title: role.name,
      };

      options.push(option);
    });

    return options;
  };

  return (
      <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              Заказы
              <div className="Text__signature">Смена статуса</div>
            </div>

            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={inputBuyerOrderStateId}
                options={
                  buyerOrderStates &&
                  buyerOrderStatesToSelectOptions(buyerOrderStates)
                }
                label="Статус"
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>
          </form>
      </Modal>
  );
}

export default ModalOrdersStateEdit;
