import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { SettingsMenu } from "./SettingsMenu/SettingsMenu";
import {TimeWorkCategories} from "./TimeWorkCategories";
import FunnelOrderStates from "./FunnelOrderStates/FunnelOrderStates";
import {ImportOrderStates} from "./ImportOrderStates";
import OrderStateGroups from "./OrderStateGroups/OrderStateGroups";
import {BuyerOrderNeural} from "./BuyerOrderNeural";

export const SettingsRouting = () => {
  return (
    <div className="App App_vertical">
      <div className="Navigate" style={{ width: 240 }}>
        <SettingsMenu />
      </div>

      <div className="Content">
        <Routes>
          <Route
            path={"/time-work-categories"}
            element={<TimeWorkCategories />}
          />

          <Route
            path={"/import-order-states"}
            element={<ImportOrderStates />}
          />
          <Route
            path={"/order-state-groups"}
            element={<OrderStateGroups />}
          />

          <Route
          path={"/buyer-order-neural"}
          element={<BuyerOrderNeural />}
        />



          <Route
            path={"/funnel-order-states"}
            element={<FunnelOrderStates />}
          />
          <Route exact path="/" element={<Navigate to="./time-work-categories" replace />} />
        </Routes>
      </div>
    </div>
  );
};
