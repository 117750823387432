import { useEffect } from 'react';

const useHourlyPageReload = () => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Перезагружаем страницу
      window.location.reload();
    }, 3600000); // 3600000 миллисекунд = 1 час

    return () => clearInterval(intervalId);
  }, []);
};

export default useHourlyPageReload;
