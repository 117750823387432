import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../../config/url";
import {
  getAccountFio,
  getErrorMessageByKey,
  isValidateNullText,
  jsonFormatText,
  Modal,
  Select,
  Textarea,
  useList,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";

function ModalKanbanTaskAdd(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/kanban-space/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputTitle = useRef(null);
  const inputDescription = useRef(null);

  const inputTicketTypeTag = useRef(null);
  const inputTicketTopicUuid = useRef(null);
  const [isPriority, setIsPriority] = useState(false);
  const inputExecutorId = useRef(null);

  const {
    list: [states],
  } = useList({
    apiUrl: "/api/kanban-space/" + props.object.uuid + "/states",
    rootUrl: ROOT_URL,
  });
  const {
    list: [employees],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
  });

  const {notification} = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const title = jsonFormatText(inputTitle.current.value);
    const description = jsonFormatText(inputDescription.current.value);
    const ticketTopicUuid = inputTicketTopicUuid.current.value;
    const executorId = inputExecutorId.current.value;

    if (isValidateNullText(title)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    if (isValidateNullText(ticketTopicUuid) || ticketTopicUuid.length === 0 ||
      ticketTopicUuid === "null"
    ) {
      notification("Укажите статус", "red");
      return;
    }

    const jsonData = JSON.stringify({
      title: (!!title && title.trim()) || null,
      description: (!!description && description.trim()) || null,
      kanban_state:
        ticketTopicUuid.length > 0
          ? ticketTopicUuid === "null"
            ? null
            : {
              uuid: ticketTopicUuid,
            }
          : null,
      executor_id: executorId.length > 0 ? (executorId === "null" ? null : Number(executorId)) : null,
    });

    axios
      .post("/api/kanban-space/" + props.object.uuid + "/task/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Задача добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getSelectTopicOptions = (list) => {
    const options = [];

    list.map((type) => {
      const option = {
        value: type.uuid,
        title: type.title,
      };

      options.push(option);
    });

    return options;
  };

  const getSelectExecutorOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбран",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.id,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    // options.push({
    //   value: 1,
    //   title: "Администратор",
    // });

    return options;
  };

  return (
    !!object &&
    !!employees &&
    !!states && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Новая
            <div className="Text__signature">Задача</div>
          </div>

          <div className="Form__field">
            <Textarea reference={inputTitle} label="Заголовок *"/>
          </div>

          <div className="Form__field">
            <Textarea reference={inputDescription} label="Описание"/>
          </div>

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={inputTicketTopicUuid}
              options={
                states && [
                  ...getSelectTopicOptions(states),
                ]
              }
              label="Статус"
            />
          </div>

          <div className="Form__field Form__field_last">
            <Select
              isFixed={true}
              reference={inputExecutorId}
              options={
                employees && [
                  ...getSelectExecutorOptions(employees),
                ]
              }
              label="Исполнитель"
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalKanbanTaskAdd;
