import React, { useState } from "react";
import { ROOT_URL } from "../../../config/url";
import {useList, useUnroll} from "@vokymlak/kabinet-ui";
import {useAction} from "@vokymlak/kabinet-ui";
import axios from "axios";
import {getErrorMessageByKey} from "@vokymlak/kabinet-ui";
import {useNotification} from "@vokymlak/kabinet-ui";

function useFunnelOrderStates() {
  const {
    list: [list, _count, setList],
    update: [isUpdateList, setIsUpdateList],
  } = useList({apiUrl:"/api/funnels-order-states", rootUrl:ROOT_URL});

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const [modalObjectItemAdd, setModalObjectItemAdd] = useState(null);

  useAction("isUpdate", () => setIsUpdateList(!isUpdateList));

  const { notification } = useNotification();

  const deleteItem = (itemUuid) => {
    axios
      .delete("/api/funnel-order-state/" + itemUuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalObjectItemAdd,
    setModalObjectItemAdd,

    deleteItem,
  };
}

export default useFunnelOrderStates;
