import { KEYBOARD_OPEN, KEYBOARD_INPUT, KEYBOARD_HASH } from "../actionTypes";

export const initialState = {
  isKeyboard: false,
  inputKeyboard: null,
  hashKeyboard: "",
};

export const keyboardReducer = (initialState, action) => {
  switch (action.type) {
    case KEYBOARD_OPEN:
      return {
        ...initialState,
        isKeyboard: action.isKeyboard,
      };
    case KEYBOARD_INPUT:
      return {
        ...initialState,
        inputKeyboard: action.inputKeyboard,
      };
    case KEYBOARD_HASH:
      return {
        ...initialState,
        hashKeyboard: action.hashKeyboard,
      };
    default:
      return initialState;
  }
};
