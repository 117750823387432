import React, {Fragment, useState} from "react";
import {
  ButtonIcon,
  getAccountFio,
  Loading,
  Pagination,
  Search,
  Select,
  TextSearchMarker,
} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import moment from "moment/moment";
import ModalSiteFeedbackEdit from "./ModalSiteFeedbackEdit";
import ModalSiteSiteFeedbackAction from "./ModalSiteSiteFeedbackAction";
import ModalSiteFeedbackAnswer from "./ModalSiteFeedbackAnswer";

function SiteFeedbackPage(props) {
  const {
    list,
    count,
    setList,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    isPublishedFeedback,
  } = props;

  const [modalItemEdit, setModalItemEdit] = useState(null);
  const [modalOrderEditAction, setModalOrderEditAction] = useState(null);
  const [modalSiteFeedbackAnswer, setModalSiteFeedbackAnswer] = useState(null);

  function extractNumbers(str) {
    return str.replace(/\D/g, "");
  }

  function formatString(number) {
    if (number.length < 11) {
      return number;
    }

    if (number[0] === '8') {
      number = "7" + number.slice(1);
    }

    return "+" + number[0] + " (" + number.slice(1, 4) + ") " + number.slice(4, 7) + "-" + number.slice(7, 9) + "-" + number.slice(9);
  }

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Обратная связь
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
            )}
            <span style={{width: 220, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{marginLeft: 8}}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 500,
                      title: 500,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid) ? "List__item active" : "List__item"
                    }
                    onClick={() => changeUnroll(item.uuid)}
                    style={{
                      padding: "10px 12px",
                      position: "relative",
                      marginBottom: 12
                    }}
                  >
                    <div
                      className="Item__title"
                      style={{
                        width: "100%",
                      }}
                    >
                      <div
                        className="Element__title"
                        style={{
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 19,
                            fontWeight: 700,
                            width: "100%",
                          }}
                        >
                          №<TextSearchMarker
                          text={item.id}
                          search={searchQuery}
                        />{!!item.date && ' от ' + moment(item.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
                        </div>

                        <div
                          style={{
                            fontSize: 15,
                            fontWeight: 500,
                            width: "100%",
                            marginTop: 4
                          }}
                        >
                          {(!!item.user_name || !!item.user_phone || !!item.user_email) && (
                            <div
                              style={{
                                backgroundColor: "#20202015",
                                padding: "4px 8px 6px",
                                borderRadius: 5,
                                marginBottom: 4
                              }}
                            >
                              {!!item.user_name &&
                                <span style={{marginRight: 8}}><TextSearchMarker text={item.user_name}
                                                                                 search={searchQuery}/></span>}
                              {!!item.user_phone && <span style={{marginRight: 8}}><TextSearchMarker
                                text={formatString(extractNumbers(item.user_phone))} search={searchQuery}/></span>}
                              {!!item.user_email &&
                                <span style={{marginRight: 8}}><TextSearchMarker text={item.user_email}
                                                                                 search={searchQuery}/></span>}
                            </div>
                          )}

                          {!!item.type &&
                            (item.type === "GRATITUDE" || item.type === "COMPLAINT") && (
                              <div>
                                <div
                                  style={{
                                    fontSize: 11,
                                    fontWeight: 700,
                                    backgroundColor: item.type === "GRATITUDE" ? "#3e6546" : "#b03a3e",
                                    display: "inline-block",
                                    color: "white",
                                    padding: "2px 4px 2px",
                                    borderRadius: 3,
                                    marginBottom: 4
                                  }}
                                >
                                  {item.type === "GRATITUDE" ? "Благодарность" : "Жалоба"}
                                </div>
                              </div>
                            )}

                          {!!item.message && (
                            <div
                              style={{
                                backgroundColor: item.type === "GRATITUDE" ? "#3e654650" : item.type === "COMPLAINT" ? "#b03a3e50" : "#20202015",
                                padding: "6px 8px 6px",
                                borderRadius: 5,
                                marginBottom: 4
                              }}
                            >
                              <div style={{
                                fontSize: 13
                              }}>Сообщение
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  marginTop: 2,
                                }}
                              >
                                <TextSearchMarker text={item.message}
                                                  search={searchQuery}/>
                              </div>
                            </div>
                          )}

                          {!!item.answer && (
                            <div
                              style={{
                                backgroundColor: "#20202025",
                                padding: "6px 8px 6px",
                                borderRadius: 5,
                                marginBottom: 4
                              }}
                            >
                              <div style={{
                                fontSize: 13
                              }}>Ответ
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  marginTop: 2,
                                }}
                              >
                                <TextSearchMarker text={item.answer}
                                                  search={searchQuery}/>
                              </div>

                              <div style={{
                                marginTop: 8,
                                fontSize: 13
                              }}>Автор
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  marginTop: 2,
                                }}
                              >
                                <span>
                                <TextSearchMarker text={getAccountFio(item.answerable)}
                                                  search={searchQuery}/>
                                <span
                                  style={{marginLeft: 4}}>({moment(item.answer_date).format("DD.MM.YYYY HH:mm")})</span>
                                  </span>
                              </div>
                            </div>
                          )}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            top: -8,
                            left: 0,
                            justifyContent: " flex-end",
                            width: "100%",
                            padding: "0 8px",
                          }}
                        >
                          {item.is_published && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#0061ff",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Опубликовано
                            </div>
                          )}

                          {!!item.site_feedback_status_type && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: item.site_feedback_status_type.tag === 'AT_WORK' ? "#202020" :
                                  item.site_feedback_status_type.tag === 'NEW' ? "#2e7d32" : "#dddddd",
                                display: "inline-block",
                                color: item.site_feedback_status_type.tag === 'AT_WORK' ? "white" :
                                  item.site_feedback_status_type.tag === 'NEW' ? "white" : "#202020",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              {item.site_feedback_status_type.name}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime">
                      </div>
                    </div>
                  </li>

                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      <div className="Content__header">
                        <div className="Header__title">
                          <div
                            className="Button__text Button__text_right"
                            onClick={() => setModalOrderEditAction(item)}
                          >
                            Изменить действие
                          </div>

                          <div
                            className="Button__text Button__text_right"
                            onClick={() => setModalSiteFeedbackAnswer(item)}
                          >
                            Ответить
                          </div>
                        </div>
                        <div className="Header__settings">
                          <div className="Button__swith"
                               style={{marginLeft: 12}}>
                            <div className="Swith__text">На публикацию</div>
                            <div
                              className={
                                "Swith" +
                                ((!!item.is_published && " Swith_active ") ||
                                  "")
                              }
                              onClick={() => isPublishedFeedback(item)}
                            ></div>
                          </div>
                          <ButtonIcon
                            tooltipText={"Редактировать"}
                            iconName={"custom-edit"}
                            iconColor={"#202020"}
                            size={36}
                            style={{marginLeft: 12}}
                            onClick={() => setModalItemEdit(item)}
                          />
                        </div>
                      </div>
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalItemEdit && (
          <ModalSiteFeedbackEdit
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}

        {!!modalOrderEditAction && (
          <ModalSiteSiteFeedbackAction
            object={modalOrderEditAction}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalOrderEditAction(null)}
          />
        )}


        {modalSiteFeedbackAnswer && (
          <ModalSiteFeedbackAnswer
            object={modalSiteFeedbackAnswer}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalSiteFeedbackAnswer(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default SiteFeedbackPage;
