import React, { useState } from "react";
import moment from "moment";

export const useOrderMarks = () => {
  const [selectMonth, setSelectMonth] = useState(moment().format("MM"));
  const [selectYear, setSelectYear] = useState(moment().format("YYYY"));

  const changeSelectMonthMinus = () => {
    if (Number(selectMonth) > 1) {
      setSelectMonth(
        Number(selectMonth) - 1 < 10
          ? "0" + (Number(selectMonth) - 1)
          : String(Number(selectMonth) - 1)
      );
    } else {
      setSelectMonth("12");
      setSelectYear(Number(selectYear) - 1);
    }
  };

  const changeSelectMonthPlus = () => {
    if (Number(selectMonth) < 12) {
      setSelectMonth(
        Number(selectMonth) + 1 < 10
          ? "0" + (Number(selectMonth) + 1)
          : String(Number(selectMonth) + 1)
      );
    } else {
      setSelectMonth("01");
      setSelectYear(Number(selectYear) + 1);
    }
  };

  const monthText = (month) => {
    const months = [
      "январь",
      "февраль",
      "март",
      "апрель",
      "май",
      "июнь",
      "июль",
      "август",
      "сентябрь",
      "октябрь",
      "ноябрь",
      "декабрь",
    ];

    return (
      months[Number(month) - 1].charAt(0).toUpperCase() +
      months[Number(month) - 1].slice(1)
    );
  };

  return {
    monthText,
    selectMonth,
    selectYear,


    changeSelectMonthMinus,
    changeSelectMonthPlus,

  };
}

export default useOrderMarks;
