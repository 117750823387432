import React, {useEffect, useState} from "react";
import moment from "moment/moment";

function TimerWorkCategory(props) {
  const [dataTime, setDataTime] = useState(new Date());
  const [timer, setTimer] = useState(null);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (!!props.object) {
      setDataTime((!!props.object.start_time && props.object.start_time.substring(0, 19)) || null);
    } else {
      setDataTime(null);
    }
  }, [props.object]);


  useEffect(() => {
    return () => {
      if (!!timer) {
        clearInterval(timer);
      }
      setTimer(null);
      setSeconds(0);
    };
  }, []);

  useEffect(() => {
    if (!!dataTime) {
      const tick = (num) => setSeconds(seconds + num);

      setTimer(setInterval(() => tick(1), 1000));
    } else {
      if (!!timer) {
        clearInterval(timer);
        setTimer(null);
      }
      setSeconds(0);
    }

    return () => {
      if (!!timer) {
        clearInterval(timer);
      }
    };
  }, [seconds, dataTime]);

  const getTimeString = (dataTime) => {
    const dateA = moment(new Date());
    const dateB = moment(dataTime);

    const diff = dateA.diff(dateB, "seconds");

    return timeFormat(diff * 1000);
  };

  const timeFormat = (function () {
    function num(val) {
      val = Math.floor(val);
      return val < 10 ? "0" + val : val;
    }

    return function (ms /**number*/) {
      let sec = ms / 1000,
        hours = (sec / 3600) % 24,
        minutes = (sec / 60) % 60,
        seconds = sec % 60;
      if (sec / 60 > 60) {
        return num(hours) + ":" + num(minutes) + ":" + num(seconds);
      } else {
        return num(minutes) + ":" + num(seconds);
      }
    };
  })();

  return (
    !!props.object && ((!!dataTime && getTimeString(dataTime)) || "00:00")
  );
}

export default TimerWorkCategory;
