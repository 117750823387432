import React, {Fragment} from "react";
import {Icon} from "@vokymlak/kabinet-ui";
import moment from "moment";

function BuyerOrderObjectInfoView(props) {
  const {object} = props;

  const openLink = (url) => {
    // Открываем ссылку в новом окне браузера.
    // noopener,noreferrer,resizable,scrollbars,status (доп. параметры).
    const newWindow = window.open(url, "_blank", "noopener, noreferrer");
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  return (
    !!object && (
      <Fragment>
        <table
          className="Table"
          style={{
            marginBottom: 12,
          }}
        >
          <tbody>
          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Организация
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  {(!!object.organization &&
                    object.organization
                      .toUpperCase()
                      .replace('ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ', 'ООО')
                      .replaceAll('\"', '')) || "-"}
                </div>
              </div>
            </td>
          </tr>
          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Дата отгрузки
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {!!object.date_shipment &&
                    moment(object.date_shipment.substring(0, 19)).format(
                      "DD.MM.YYYY"
                    )}
                </div>
              </div>
            </td>
          </tr>
          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Сумма
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 21,
                    fontWeight: 700,
                  }}
                >
                  {!!object.amount && (
                    <span>
                        {Number(object.amount) / 100}
                      <span
                        style={{
                          marginLeft: 4,
                          fontSize: 14,
                          fontWeight: 400,
                          opacity: 0.75,
                        }}
                      >
                          руб.
                        </span>
                      </span>
                  )}
                  {!object.amount && (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                        <span>
                          <Icon
                            name={"custm-alert"}
                            size={24}
                            color={"#ff0500"}
                          />
                        </span>
                      </span>
                  )}
                </div>
              </div>
            </td>
          </tr>
          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Статус оплаты
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.payment_status && object.payment_status) || "-"}
                </div>
              </div>
            </td>
          </tr>
          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Тип оплаты
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.payment_type && object.payment_type) || "-"}
                </div>
              </div>
            </td>
          </tr>
          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Транспортная компания
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.transport_company && object.transport_company.toUpperCase()) ||
                    "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  ФИО получателя
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.recipient_fio && object.recipient_fio) || "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Телефон получателя
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.recipient_phone && object.recipient_phone) ||
                    "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Адрес
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.address && object.address) || "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Индекс
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.address_index && object.address_index) || "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Город
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.address_city && object.address_city) || "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Отправка до
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.sending_to && object.sending_to) || "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  ИНН получателя
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.recipient_inn && object.recipient_inn) || "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Палет борт
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.pallet_board && object.pallet_board) || "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Фасовка
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.packing && object.packing) || "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Фасовка 2
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.packing_two && object.packing_two) || "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Фасовка 3
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.packing_three && object.packing_three) || "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Наклейки
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.stickers && object.stickers) || "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Наклейки на упаковку
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.sticker_pack && object.sticker_pack) || "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Доставка за наш счет
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.delivery_our_expense &&
                      object.delivery_our_expense) ||
                    "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Причина отгрузки за наш счет
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.reason_our_expense &&
                      object.reason_our_expense) ||
                    "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Пробники
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.probes && object.probes) || "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Сумма наложенного платежа
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {!!object.amount_payment_made && (
                    <span>
                        {Number(object.amount_payment_made) / 100}
                      <span
                        style={{
                          marginLeft: 4,
                          fontSize: 14,
                          fontWeight: 400,
                          opacity: 0.75,
                        }}
                      >
                          руб.
                        </span>
                      </span>
                  )}
                  {!object.amount_payment_made && "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Сертификаты
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.certificates && object.certificates) || "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Ссылка на договор
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.link_to_contract && (
                      <span
                        onClick={() => openLink(object.link_to_contract)}
                        style={{
                          cursor: "pointer",
                          color: "#0064ff",
                          textDecoration: "underline",
                        }}
                      >
                        {object.link_to_contract}
                      </span>
                    )) ||
                    "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Ответственный за сборку
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.responsible_assembly &&
                      object.responsible_assembly) ||
                    "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Автор
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.author && object.author) || "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Идентификатор платежа
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.payment_id && object.payment_id) || "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Источник привлечения
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.source_attraction && object.source_attraction) ||
                    "-"}
                </div>
              </div>
            </td>
          </tr>

          <tr className="Table__tr Table__tr_padding Table__tr_border">
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 400,
                  }}
                >
                  Контрагент
                </div>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text ">
                <div
                  style={{
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {(!!object.contractor && object.contractor) || "-"}
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </Fragment>
    )
  );
}

export default BuyerOrderObjectInfoView;
