import React, { Fragment, useState } from "react";
import {
  Select,
  Pagination,
  Loading,
  ButtonIcon,
} from "@vokymlak/kabinet-ui";
import ModalLeadStatisticAdd from "./ModalLeadStatisticAdd";
import ModalLeadStatisticEdit from "./ModalLeadStatisticEdit";
import moment from "moment";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";

function LeadStatisticsPage(props) {
  const {
    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,
  } = props;

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(null);

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div className="Page__title">
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
              }}
            >
              <div>
                Данные по лидам
                {!!list && (
                  <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                    ({count})
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{ marginLeft: 8 }}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 1000,
                      title: 1000,
                    },
                  ]}
                  label="Лимит"
                  onChangeSelect={(limit) => setPageLimit(Number(limit))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{ marginLeft: 8 }}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalObjectAdd(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {!!list &&
                list.map((item) => (
                  <Fragment key={"item_" + item.uuid}>
                    <li
                      className={
                        isUnroll(item.uuid) ? "List__item active" : "List__item"
                      }
                      onClick={() => changeUnroll(item.uuid)}
                      style={{
                        padding: "10px 12px",
                        position: "relative",
                      }}
                    >
                      <div
                        className="Item__title"
                        style={{
                          width: "100%",
                        }}
                      >
                        <div
                          className="Element__title"
                          style={{
                            width: "100%",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 19,
                              fontWeight: 700,
                              width: "100%",
                            }}
                          >
                            {(!!item.statistic_date && (moment(item.statistic_date).format(
                                "DD.MM.YYYY"))) ||
                              "-"}
                          </div>

                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              opacity: 0.75,
                              marginTop: 4,
                            }}
                          >
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                              {!!item.updated_at
                                ? moment(item.updated_at).format(
                                    "DD.MM.YYYY HH:mm"
                                  )
                                : moment(item.created_at).format(
                                    "DD.MM.YYYY HH:mm"
                                  )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="Item__settings">
                        <div className="Element__datetime">
                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 19,
                              fontWeight: 700,
                            }}>{((!!item.seo_site_form_count && item.seo_site_form_count) || 0) +
                              ((!!item.ad_site_form_count && item.ad_site_form_count) || 0) +
                              ((!!item.seo_jivo_count && item.seo_jivo_count) || 0)}</div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>Всего</div>
                          </div>

                          <div style={{
                            height: 32,
                            width: 2,
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                          </div>

                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 19,
                              fontWeight: 700,
                            }}>
                              {(!!item.ad_spending &&
                                new Intl.NumberFormat("ru").
                                format((Number(item.ad_spending / ((!!item.ad_site_form_count && item.ad_site_form_count) || 0)) / 100)
                                  .toFixed(2))) || 0}
                            </div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>Стоимость&nbsp;одного</div>
                          </div>
                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 19,
                              fontWeight: 700,
                            }}>
                              {(!!item.ad_spending &&
                                new Intl.NumberFormat("ru").
                                format((Number(item.ad_spending / (
                                  ((!!item.seo_site_form_count && item.seo_site_form_count) || 0) +
                                  ((!!item.ad_site_form_count && item.ad_site_form_count) || 0) +
                                  ((!!item.seo_jivo_count && item.seo_jivo_count) || 0)
                                ) / 100))
                                  .toFixed(2))) || 0}
                            </div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>Стоимость&nbsp;общая</div>
                          </div>

                          <div style={{
                            height: 32,
                            width: 2,
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                          </div>

                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 19,
                              fontWeight: 700,
                            }}>{(!!item.seo_site_form_count && item.seo_site_form_count) || 0}</div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>Форма&nbsp;(seo)</div>
                          </div>

                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 19,
                              fontWeight: 700,
                            }}>{(!!item.ad_site_form_count && item.ad_site_form_count) || 0}</div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>Форма&nbsp;(рек)</div>
                          </div>

                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 19,
                              fontWeight: 700,
                            }}>{(!!item.seo_jivo_count && item.seo_jivo_count) || 0}</div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>Jivo</div>
                          </div>

                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 19,
                              fontWeight: 700,
                            }}>{(!!item.avito_count && item.avito_count) || 0}</div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>Avito</div>
                          </div>

                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 19,
                              fontWeight: 700,
                            }}>{(!!item.vk_count && item.vk_count) || 0}</div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>vk</div>
                          </div>

                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 19,
                              fontWeight: 700,
                            }}>
                              {(!!item.ad_spending &&
                                new Intl.NumberFormat("ru").format((Number(item.ad_spending) / 100).toFixed(2))) || 0}
                            </div>
                            <div style={{
                              fontSize: 10,
                              fontWeight: 400,
                              opacity: .75,
                            }}>Затраты&nbsp;(руб)</div>
                          </div>
                        </div>
                      </div>
                    </li>

                    {isUnroll(item.uuid) && (
                      <li className="List__content">
                        <div className="Content__header">
                          <div className="Header__title">
                          </div>
                          <div className="Header__settings">
                              <ButtonIcon
                                tooltipText={"Редактировать"}
                                iconName={"custom-edit"}
                                iconColor={"#202020"}
                                size={36}
                                style={{ marginLeft: 12 }}
                                onClick={() => setModalItemEdit(item)}
                              />
                          </div>
                        </div>
                      </li>
                    )}
                  </Fragment>
                ))}
            </ul>
          </div>
        )}

        {!!modalItemEdit && (
          <ModalLeadStatisticEdit
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}
        {!!isModalObjectAdd && (
          <ModalLeadStatisticAdd
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default LeadStatisticsPage;
