import React, { Fragment } from "react";
import {ButtonIcon} from "@vokymlak/kabinet-ui";
import {Loading} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import ModalBuyerOrderNeuralTrain from "./ModalBuyerOrderNeuralTrain";

function BuyerOrderNeuralPage(props) {
  const {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,
  } = props;

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
    <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 21,
            fontWeight: 700,
          }}
        >
          Оценка сложности
          {!!list && (
            <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({list.length})
              </span>
          )}
        </div>

        <div className="Title__settings">
          {!!list && (
            <ButtonIcon
              tooltipText={
                list.length === unrolls.length ? "Свернуть" : "Развернуть"
              }
              iconName={
                list.length === unrolls.length
                  ? "custom-unfold-less"
                  : "custom-unfold-more"
              }
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => changeAllUnroll(list, "uuid")}
            />
          )}

          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 8 }}
            onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
          />

          <ButtonIcon
            tooltipText={"Обучить нейросеть"}
            iconName={"custom-cloud-upload"}
            iconColor={"#202020"}
            size={26}
            style={{ marginLeft: 12 }}
            onClick={() => setIsModalObjectAdd(true)}
          />
        </div>
      </div>


      {!list && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}


      {!!list && (
        <div className="Page__content">
          <ul className="List">
                  {!!list &&
                    list.map((item, index) => (
                            <Fragment key={"item_" + item.uuid}>
                              <li
                                className={
                                  isUnroll(item.uuid)
                                    ? "List__item active"
                                    : "List__item"
                                }
                                onClick={() => changeUnroll(item.uuid)}
                              >
                                <div className="Item__title">
                                  <div className="Element__title" style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}>
                                    {item.created_at}
                                  </div>
                                </div>
                                <div className="Item__settings">
                                  <div className="Element__datetime">
                                  </div>
                                </div>
                              </li>

                              {isUnroll(item.uuid) && (
                                <li className="List__content">
                                  <div className="Content__header">
                                    <div className="Header__title"></div>
                                    <div className="Header__settings">
                                      {/*<ButtonIcon*/}
                                      {/*  tooltipText={"Редактировать"}*/}
                                      {/*  iconName={"custom-edit"}*/}
                                      {/*  iconColor={"#202020"}*/}
                                      {/*  size={36}*/}
                                      {/*  style={{ marginLeft: 12 }}*/}
                                      {/*  onClick={() => setModalItemEdit(item)}*/}
                                      {/*/>*/}
                                    </div>
                                  </div>
                                </li>
                              )}
                            </Fragment>
                    ))}
        </ul>
      </div>
      )}

      {!!isModalObjectAdd && (
        <ModalBuyerOrderNeuralTrain
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setIsModalObjectAdd(false)}
        />
      )}
    </div>
    </ScrollBlock>
  );
}

export default BuyerOrderNeuralPage;
