import React, { Fragment, useEffect, useState } from "react";
import ModalEditSiteBlogTopic from "./ModalEditSiteBlogTopic";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ModalAddSiteBlogTopic from "./ModalAddSiteBlogTopic";
import {
  ButtonIcon,
  Loading,
} from "@vokymlak/kabinet-ui";
import { ScrollBlock } from "../../../../components/ScrollBlock/ScrollBlock";
import {useNavigate} from "react-router-dom";

export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

export const SiteBlogTopicsPage = (props) => {
  const {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,

    onDragEnd,
  } = props;

  const navigate = useNavigate();

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="TicketsPage" style={{
        backgroundColor: "#f1f1f1"
      }}>
        <div className="Page__title">
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
              }}
            >
              Блог статей (темы)
              <span
                style={{
                  fontSize: 13,
                  opacity: 0.5,
                  fontWeight: 400
                }}
              >
                          Добавьте тему и настройте отображение
                        </span>
            </div>

            <ButtonIcon
              style={{
                marginLeft: 16
              }}
              iconName={"custom-settings"}
              iconColor={"#20202075"}
              size={24}
              onClick={() => navigate("/site-blog/articles")}
            />
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{ marginLeft: 8 }}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalObjectAdd(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              <DragDropContext onDragEnd={onDragEnd}>
                <StrictModeDroppable droppableId={"droppable"}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {!!list &&
                        list.map((item, index) => (
                          <Draggable
                            draggableId={String(item.uuid)}
                            index={index}
                            key={"draggable_" + item.uuid}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <Fragment key={"item_" + item.uuid}>
                                  <li
                                    className={
                                      isUnroll(item.uuid)
                                        ? "List__item active"
                                        : "List__item"
                                    }
                                    style={{
                                      position: "relative",
                                      marginBottom: 12
                                    }}
                                    onClick={() => changeUnroll(item.uuid)}
                                  >
                                    <div
                                      className="Item__title"
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        className="Element__title"
                                        style={{
                                          flexDirection: "column",
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontSize: 19,
                                            fontWeight: 700,
                                            width: "100%",
                                          }}
                                        >
                                          {item.title}
                                        </div>

                                        {item.description && (
                                        <div
                                          style={{
                                            marginTop: 4,
                                            marginBottom: 4,
                                            fontSize: 16,
                                            fontWeight: 400,
                                            width: "100%",
                                          }}
                                        >
                                          {item.description}
                                        </div>
                                        )}

                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            position: "absolute",
                                            top: -8,
                                            left: 0,
                                            justifyContent: " flex-end",
                                            width: "100%",
                                            padding: "0 8px",
                                          }}
                                        >

                                          {!!item.seo_url && (
                                            <div
                                              style={{
                                                fontSize: 11,
                                                fontWeight: 700,
                                                backgroundColor: "#dbdbdb",
                                                display: "inline-block",
                                                color: "#202020",
                                                padding: "2px 4px 2px",
                                                borderRadius: 3,
                                                margin: "0 2px",
                                              }}
                                            >
                                              {(!!item.seo_url && item.seo_url) || "-"}
                                            </div>
                                          )}

                                          {!!item.is_published && (
                                            <div
                                              style={{
                                                fontSize: 11,
                                                fontWeight: 700,
                                                backgroundColor: "#0064ff",
                                                display: "inline-block",
                                                color: "white",
                                                padding: "2px 4px 2px",
                                                borderRadius: 3,
                                                margin: "0 2px",
                                              }}
                                            >
                                              На публикацию
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="Item__settings">
                                      <div className="Element__datetime"></div>
                                    </div>
                                  </li>

                                  {isUnroll(item.uuid) && (
                                    <li className="List__content">
                                    <div className="Content__header">
                                        <div className="Header__title">
                                        </div>
                                        <div className="Header__settings">
                                          <ButtonIcon
                                            tooltipText={"Редактировать"}
                                            iconName={"custom-edit"}
                                            iconColor={"#202020"}
                                            size={36}
                                            style={{marginLeft: 12}}
                                            onClick={() => setModalItemEdit(item)}
                                          />
                                        </div>
                                      </div>
                                    </li>
                                  )}
                                </Fragment>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              </DragDropContext>
            </ul>
          </div>
        )}

        {!!modalItemEdit && (
          <ModalEditSiteBlogTopic
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}
        {!!isModalObjectAdd && (
          <ModalAddSiteBlogTopic
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}
      </div>
    </ScrollBlock>
  );
};
