import React from "react";
import { ROOT_URL } from "../../../config/url";
import {useObject} from "@vokymlak/kabinet-ui";

function usePlanPacking() {
  const {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject],
  } = useObject({
    apiUrl:"/api/plan/packing",
    rootUrl: ROOT_URL,
  });

  return {
    object,
    setObject,


    setIsUpdateObject,
    isUpdateObject,
  };
}

export default usePlanPacking;
