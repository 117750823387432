import React, {useEffect, useState} from "react";
import {getErrorMessageByKey, Input, Modal, useList, useNotification} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import moment from "moment/moment";
import axios from "axios";

function ModalBuyerOrderAllPrint(props) {
  const {
    object
  } = props
  const [stickers, setStickers] = useState(null);

  const {
    list: [list, _, setList],
  } = useList({
    apiUrl: "/api/order/" + object.uuid + "/items",
    rootUrl: ROOT_URL
  });

  const {notification} = useNotification();

  const changePrint = () => {

    const jsonData = JSON.stringify({
      stickers: stickers || [],
    });

    axios
      .post("/api/buyer-order/" + object.uuid + "/print/stickers", jsonData, {
        responseType: "arraybuffer",
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Заказ отправлен на печать этикеток", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const plusOneMontageQuantity = (item) => {
    stickers.map(i => {
      if (i.uuid === item.uuid) {
        if (i.count >= Number(item.quantity)) {
          i.count = Number(item.quantity)
        } else {
          i.count = (Number(i.count) || 0) + 1
        }
      }

      return i
    });

    setStickers([...stickers]);
  };

  const minusOneMontageQuantity = (item) => {
    stickers.map(i => {
      if (i.uuid === item.uuid) {
        if (i.count <= 0) {
          i.count = 0
        } else {
          i.count = (Number(i.count) || 0) - 1
        }
      }

      return i
    });

    setStickers([...stickers]);
  };

  const numberMontageQuantity = (item) => {
    stickers.map(i => {
      if (i.uuid === item.uuid) {
        i.count = (Number(item.quantity) || 0)
      }

      return i
    });

    setStickers([...stickers]);
  };

  useEffect(() => {
    if (!!list) {
      list.map(i => {
        i.count = i.quantity

        return i
      })

      setStickers([...list])
    }
  }, [list]);

  return (
    !!stickers && (
      <Modal handleClose={() => props.handleClose()}>
        <div className="Form">
          <div
            className="Form__title"
            style={{
              fontSize: 15,
            }}
          >
            {object.number} от {moment(object.date).format("DD.MM.YYYY HH:mm")}
            <div className="Text__signature">Этикетки</div>
          </div>

          {stickers.map((item, index) => (
            <div key={item.uuid} style={{
              border: "2px solid #dbdbdb",
              borderRadius: 7,
              padding: 4,
              marginBottom: 4,
              display: (!(!!item.nomenclature && !!item.nomenclature.stickers && item.nomenclature.stickers.length > 0) && "none")
            }}>
              <div
                className="Form__title"
                style={{
                  fontSize: 15,
                }}
              >
                <span>{index + 1}. {item.nomenclature.name}</span>
                <div
                  className={
                    "Collected__count Collected__count" + "_blue"
                  }
                  style={{
                    display: "inline-block",
                    marginLeft: 12,
                    borderRadius: 24,
                    height: 24,
                    padding: "1px 4px 2px"
                  }}
                >
                  {item.quantity}
                </div>
              </div>
              <div className="Form__field">
                <div className="Field__label">
                  Количество
                </div>
                <div className="Field__text">
                  {(!!item.count && item.count) || 0}
                </div>
              </div>

              <div
                className="Form__field"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    margin: "0 12px 0 0",
                  }}
                  className="Action__button Action__button_maxi Action__button_blue"
                  onClick={() => minusOneMontageQuantity(item)}
                >
                  - 1
                </div>
                <div
                  style={{
                    margin: "0 0 0 12px",
                  }}
                  className="Action__button Action__button_maxi Action__button_blue"
                  onClick={() => plusOneMontageQuantity(item)}
                >
                  + 1
                </div>
                <div
                  style={{
                    margin: "0 12px",
                  }}
                  className="Action__button Action__button_maxi Action__button_blue"
                  onClick={() => numberMontageQuantity(item, item.quantity)}
                >
                  {item.quantity}
                </div>
              </div>
            </div>
          ))}

          <div className="Form__actions Form__actions_center" onClick={() => changePrint()}>
            <button className="Action__button Action__button_all Action__button_gray">
              Печать
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </div>
      </Modal>
    )
  );
}

export default ModalBuyerOrderAllPrint;
