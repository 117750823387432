import React from "react";
import {
  Modal,
} from "@vokymlak/kabinet-ui";

function ModalLogout(props) {
  const changeLogout = (event) => {
    event.preventDefault();
    props.successfulRequest();
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeLogout(event)}>
        <div className="Form__title">
          Вы точно хотите выйти?
          <div className="Text__signature">Выход</div>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_gray">
            Выйти
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Отмена
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ModalLogout;
