import React, { useState } from "react";

function useBuyerOrderObject({
  object,
  isUnroll,
  changeUnroll,
  searchQuery,
  isUpdateList,
  setIsUpdateList,
}) {
  const [modalBuyerOrderStateEdit, setModalBuyerOrderStateEdit] =
    useState(null);
  const [modalBuyerOrderStateNewEdit, setModalBuyerOrderStateNewEdit] =
    useState(null);
  const [modalBuyerOrderDateEdit, setModalBuyerOrderDateEdit] =
    useState(null);
  const [modalBuyerOrderCommentEdit, setModalBuyerOrderCommentEdit] =
    useState(null);

  return {
    object,
    isUnroll,
    changeUnroll,
    searchQuery,
    modalBuyerOrderCommentEdit,
    setModalBuyerOrderCommentEdit,
    modalBuyerOrderDateEdit,
    setModalBuyerOrderDateEdit,
    modalBuyerOrderStateEdit,
    setModalBuyerOrderStateEdit,
    modalBuyerOrderStateNewEdit,
    setModalBuyerOrderStateNewEdit,
    isUpdateList,
    setIsUpdateList,
  };
}

export default useBuyerOrderObject;
