import React from 'react'
import axios from 'axios'
import { ROOT_URL } from '../../../../../config/url'
import {Modal} from "@vokymlak/kabinet-ui";

function ModalPackingCancelled(props) {

  const cancelledItem = (event) => {
    event.preventDefault()
    axios
      .get('/api/buyer-order-collecting/' + props.item.id + '/cancelled', {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('tokenSession'),
        },
      })
      .then(() => {
        props.successfulRequest()
        props.handleClose()
      })
  }

  return (
      <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => cancelledItem(event)}>
            <div className="Form__title">
              Аннулировать запись
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Продолжить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link"
                onClick={() => props.handleClose()}
              >
                Отмена
              </div>
            </div>
          </form>
      </Modal>
  )
}

export default ModalPackingCancelled
