import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { ROOT_URL } from '../../../../../config/url'
import {getAccountFio, getErrorMessageByKey, useNotification, Input, Modal} from "@vokymlak/kabinet-ui";
import moment from "moment";

function ModalAddTimeSheet(props) {
  const inputStartTime = useRef(null);
  const inputEndTime = useRef(null);
  const [timeSheet, setTimeSheet] = useState(null);

  const [isNextDay, setIsNextDay] = useState(false);

  const { notification } = useNotification();

  const submitAdd = (event) => {
    event.preventDefault();

    const startTime = inputStartTime.current.value;
    const endTime = inputEndTime.current.value;

    if (
      !startTime ||
      startTime.length === 0 ||
      !endTime ||
      endTime.length === 0
  ) {
      notification("Укажите время", "red");
      return;
    }

    const startDateTime = moment(props.data.date).format("YYYY-MM-DD") + " " + startTime + ":00"
    const endDateTime = (isNextDay
      ? moment(props.data.date).add(1, 'days').format("YYYY-MM-DD")
      : moment(props.data.date).format("YYYY-MM-DD"))  + " " + endTime + ":00"

    if (moment(startDateTime).isAfter(moment(endDateTime))) {
      notification("Не правильно задано время", "red");
      return;
    }

    const jsonData = JSON.stringify({
      start_time: startDateTime.length > 0 ? startDateTime : null,
      end_time: endDateTime.length > 0 ? endDateTime : null,
    });

    axios
      .post("/api/time-sheet/account/"+ props.data.accountTimeSheet.account.id +"/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Запись добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  }

  function getTimeBetweenDates(date1, date2) {
    const diff = moment(date2).diff(moment(date1));
    const duration = moment.duration(diff);
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  const getTimeSheet = () => {
    if (!inputStartTime.current || !inputEndTime.current) {
      return setTimeSheet(null);
    }

    const startTime = inputStartTime.current.value;
    const endTime = inputEndTime.current.value;

    if (
      !startTime ||
      startTime.length === 0 ||
      !endTime ||
      endTime.length === 0
    ) {
      return setTimeSheet(null);
    }

    const startDateTime = moment(props.data.date).format("YYYY-MM-DD") + " " + startTime + ":00"
    const endDateTime = (isNextDay
      ? moment(props.data.date).add(1, 'days').format("YYYY-MM-DD")
      : moment(props.data.date).format("YYYY-MM-DD"))  + " " + endTime + ":00"

    setTimeSheet(getTimeBetweenDates(startDateTime, endDateTime))
  }

  useEffect(() => {
    getTimeSheet()
  }, [isNextDay])

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => submitAdd(event)}>
        <div className="Form__title">
          {getAccountFio(props.data.accountTimeSheet.account)} ({moment(props.data.date).format("DD.MM.YYYY")})
          <div className="Text__signature">Новая запись</div>
        </div>

        <div className="Form__field">
          <Input
            reference={inputStartTime}
            type="time"
            label="Начало"
            isFixed={true}
            onChange={() => getTimeSheet()}
          />
        </div>

        <div className="Form__field">
          <div
            className="Button__swith"
            style={{
              padding: "8px",
              width: "100%",
              border: isNextDay
                ? "2px solid #eee"
                : "2px dashed #20202015",
              backgroundColor: isNextDay ? "#eee" : "#ffffff",
              borderRadius: 6,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setIsNextDay(!isNextDay)}
          >
            <div
              className="Swith__text"
              style={{
                fontSize: 17,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              Следующий день
            </div>
            <div
              className={
                "Swith Swith_gray" +
                ((isNextDay && " Swith_active Swith_active_gray") || "")
              }
            ></div>
          </div>
        </div>

        <div className="Form__field ">
          <Input
            reference={inputEndTime}
            type="time"
            label="Конец"
            isFixed={true}
            onChange={() => getTimeSheet()}
          />
        </div>

        <div className="Form__field Form__field_last">
          Общее время {(!!timeSheet && timeSheet) || "-" }
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div
            className="Action__link"
            onClick={() => props.handleClose()}
          >
            Отмена
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default ModalAddTimeSheet
