import React, { useState, useEffect, useRef } from "react";
import {Modal} from "@vokymlak/kabinet-ui";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {Textarea} from "@vokymlak/kabinet-ui";
import {getErrorMessageByKey} from "@vokymlak/kabinet-ui";
import {useNotification} from "@vokymlak/kabinet-ui";

function ModalBuyerOrderCommentEdit(props) {
  const [buyerOrder, setBuyerOrder] = useState(props.buyerOrder);

  const inputBuyerOrderComment = useRef(null);

  const { notification } = useNotification();

  useEffect(() => {
    if (props.buyerOrder) {
      setBuyerOrder(props.buyerOrder);
    }
  }, [props.buyerOrder]);

  useEffect(() => {
    return () => {
      setBuyerOrder(null);
    };
  }, []);

  const addAccountRole = (event) => {
    event.preventDefault();
    const buyerOrderStateId = inputBuyerOrderComment.current.value;

    const jsonData = JSON.stringify({
      packing_comment: buyerOrderStateId,
    });

    axios
      .post("/api/buyer-order/" + buyerOrder.id + "/comment", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    buyerOrder && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => addAccountRole(event)}>
          <div className="Form__title">
            Комментарий заказа
            <br />
            <div className="Text__signature">
              {buyerOrder.number} от {buyerOrder.date}
            </div>
          </div>

          <div className="Form__field Form__field_last">
            <Textarea
              reference={inputBuyerOrderComment}
              label="Комментарий"
              value={buyerOrder.packing_comment}
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Применить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalBuyerOrderCommentEdit;
