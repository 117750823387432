import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import moment from "moment";
import {
  getErrorMessageByKey,
  Textarea,
  useList,
  Select,
  useNotification,
  Modal,
  Input,
  useObject,
  jsonFormatText,
  isValidateNullText, getAccountFio,
} from "@vokymlak/kabinet-ui";
function ModalTicketAdminEdit(props) {
  const [isPriority, setIsPriority] = useState(false);

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/ticket/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
      setIsPriority(!!data && data.is_priority);
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const {
    list: [employees],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
  });

  const {
    list: [types],
  } = useList({ apiUrl: "/api/ticket-types", rootUrl: ROOT_URL });

  const {
    list: [statuses],
  } = useList({ apiUrl: "/api/ticket-statuses", rootUrl: ROOT_URL });

  const {
    list: [topics],
  } = useList({ apiUrl: "/api/ticket-topics", rootUrl: ROOT_URL });

  const inputTitle = useRef(null);
  const inputDescription = useRef(null);
  const inputPercentageCompletion = useRef(null);

  const inputTicketTypeTag = useRef(null);
  const inputTicketStatusTag = useRef(null);
  const inputDateEnd = useRef(null);
  const inputTicketTopicUuid = useRef(null);
  const inputTimeEstimate = useRef(null);
  const inputExecutorId = useRef(null);

  const [isDelete, setIsDelete] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const [isReArchive, setIsReArchive] = useState(false);

  const { notification } = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeEdit = (event) => {
    event.preventDefault();

    const title = jsonFormatText(inputTitle.current.value);
    const description = jsonFormatText(inputDescription.current.value);
    const ticketTypeTag = inputTicketTypeTag.current.value;
    const ticketTopicUuid = inputTicketTopicUuid.current.value;

    const percentageCompletion = inputPercentageCompletion.current.value;
    const ticketStatusTag = inputTicketStatusTag.current.value;
    const dateEnd = inputDateEnd.current.value;
    const timeEstimate = inputTimeEstimate.current.value;

    const executorId = inputExecutorId.current.value;

    if (isValidateNullText(title)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      title: (!!title && title.trim() )|| null,
      description: (!!description && description.trim()) || null,
      ticket_type: {
        tag: ticketTypeTag,
      },
      ticket_status:
        ticketStatusTag.length > 0
          ? ticketStatusTag === "null"
            ? null
            : {
                tag: ticketStatusTag,
              }
          : null,
      is_priority: isPriority,
      percentage_completion: Number(percentageCompletion),
      date_end: !!dateEnd && dateEnd.length > 0 ? dateEnd : null,
      time_estimate: !!timeEstimate && timeEstimate.length > 0 ? numberConvert(timeEstimate) : null,
      ticket_topic:
        ticketTopicUuid.length > 0
          ? ticketTopicUuid === "null"
            ? null
            : {
              uuid: ticketTopicUuid,
            }
          : null,
      executor_id: executorId.length > 0 ? (executorId === "null" ? null : Number(executorId)) : null,
    });

    axios
      .put("/api/ticket/" + props.object.uuid + "/admin/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeReArchive = (event) => {
    event.preventDefault();

    axios
      .get("/api/ticket/" + props.object.uuid + "/re-archive", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Тикет восстановлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/ticket/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Тикет удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeArchive = (event) => {
    event.preventDefault();

    axios
      .get("/api/ticket/" + props.object.uuid + "/archive", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Тикет в архиве", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getSelectOptions = (list) => {
    const options = [];

    list.map((type) => {
      const option = {
        value: type.tag,
        title: type.name,
      };

      options.push(option);
    });

    return options;
  };

  const getSelectTopicOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((type) => {
      const option = {
        value: type.uuid,
        title: type.title,
      };

      options.push(option);
    });

    return options;
  };

  const getSelectExecutorOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.id,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    options.push({
      value: 1,
      title: "Администратор",
    });

    return options;
  };

  return (
    !!object &&
    !!types &&
    !!statuses &&
    !!topics &&
    !!employees && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && !isArchive && !isReArchive && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div
              className="Form__title"
              style={{fontSize: 19, fontWeight: 700}}
            >
              {object.title}
              <div className="Text__signature">Тикет</div>
            </div>

            <div className="Form__field">
              <Select
                isFixed={true}
                reference={inputTicketTypeTag}
                options={types && getSelectOptions(types)}
                value={!!object.ticket_type ? object.ticket_type.tag : null}
                label="Тип тикета"
              />
            </div>

            <div className="Form__field">
              <Textarea
                reference={inputTitle}
                label="Заголовок *"
                value={object.title}
              />
            </div>

            <div className="Form__field ">
              <Textarea
                reference={inputDescription}
                label="Описание"
                value={object.description}
              />
            </div>

            <div className="Form__field">
              <Select
                isFixed={true}
                reference={inputTicketStatusTag}
                options={
                  statuses && [
                    {
                      value: "null",
                      title: "Не выбрано",
                    },
                    ...getSelectOptions(statuses),
                  ]
                }
                value={!!object.ticket_status ? object.ticket_status.tag : null}
                label="Статус тикета"
              />
            </div>

            <div className="Form__field ">
              <Input
                reference={inputPercentageCompletion}
                type={"number"}
                label="Выполнено"
                value={object.percentage_completion}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputTimeEstimate}
                type="number"
                label="Оценка времени (в часах)"
                value={
                  !!object.time_estimate
                    ? object.time_estimate / 100
                    : null
                }
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputDateEnd}
                type="date"
                isFixed={true}
                label="Дата окончания"
                value={
                  !!object.date_end
                    ? moment(object.date_end).format("YYYY-MM-DD")
                    : null
                }
              />
            </div>

            <div className="Form__field">
              <div
                className="Button__swith"
                style={{
                  padding: "8px",
                  width: "100%",
                  border: isPriority
                    ? "2px solid #ffe082"
                    : "2px dashed #20202015",
                  backgroundColor: isPriority ? "#ffe082" : "#ffffff",
                  borderRadius: 6,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setIsPriority(!isPriority)}
              >
                <div
                  className="Swith__text"
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Приоритетная
                </div>
                <div
                  className={
                    "Swith Swith_gray" +
                    ((isPriority && " Swith_active Swith_active_gray") || "")
                  }
                ></div>
              </div>
            </div>

            <div className="Form__field">
              <Select
                isFixed={true}
                reference={inputExecutorId}
                options={employees && getSelectExecutorOptions(employees)}
                value={!!object.executor_id ? object.executor_id : null}
                label="Исполнитель"
              />
            </div>

            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={inputTicketTopicUuid}
                options={topics && getSelectTopicOptions(topics)}
                value={!!object.ticket_topic ? object.ticket_topic.uuid : null}
                label="Тема"
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
              {!object.archived_at && (
                <div
                  className="Action__link Action__link_gray"
                  onClick={() => setIsArchive(true)}
                >
                  В архив
                </div>
              )}
              {!!object.archived_at && (
                <div
                  className="Action__link Action__link_blue"
                  onClick={() => setIsReArchive(true)}
                >
                  Из архива
                </div>
              )}
              <div className="Action__link" onClick={() => props.handleClose()}>
                Отмена
              </div>
            </div>
          </form>
        )}

        {isArchive && (
          <form className="Form" onSubmit={(event) => changeArchive(event)}>
            <div
              className="Form__title"
              style={{fontSize: 19, fontWeight: 700}}
            >
              {object.title}
              <div className="Text__signature">В архив</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_gray">
                Переместить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsArchive(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}

        {isReArchive && (
          <form className="Form" onSubmit={(event) => changeReArchive(event)}>
            <div className="Form__title">
              {object.title}
              <div className="Text__signature">Из архива</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Восстановить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link"
                onClick={() => setIsReArchive(false)}
              >
                Отмена
              </div>
            </div>
          </form>
        )}

        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              {object.title}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Продолжить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalTicketAdminEdit;
