import React, { useEffect } from "react";
import { ROOT_URL } from "../../../../../config/url";
import {getAccountFio, Loading, useList, useUnroll} from "@vokymlak/kabinet-ui";
import moment from "moment";
import {formatTime} from "../../../../../functions/formatTime";

function ListKpiTimeTracking(props) {
  const { isUnroll, changeUnroll } = useUnroll();

  const {
    list: [list, _listCount, setList],
    update: [isUpdateList, setIsUpdateList],
    params: [_params, setParams]
  } = useList({
    apiUrl: "/api/my/tracking-time/list",
    rootUrl: ROOT_URL,
    urlParams: {
      date: props.date,
    }
  });

  useEffect(() => {
    setList(null)
    setParams({
      date: props.date,
    })
  }, [props]);

  //--------------------------------------------------------------------------------------------------------------------


  return (
    <>
      {!list && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}

      {!!list && (
        <ul className="List">
          {list.map((item, index) => (
            <React.Fragment key={"item_" + index}>
              <li
                className={
                  isUnroll(item.account.id) ? "List__item active" : "List__item"
                }
                onClick={() => changeUnroll(item.account.id)}
              >
              <div className="Item__title">
                <div className="Element__title Element__title_flex">
                  <div>
                    <div>
                      {getAccountFio(item.account)}
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                      }}
                    >
                    </div>
                  </div>
                </div>
              </div>
              <div className="Item__settings">
                {(!!item.days && !!item.days[0] && !!item.days[0].work_time &&
                  formatTime(item.days[0].work_time)) || "-"}
              </div>
            </li>

              {isUnroll(item.account.id) && (
                <li className="List__content">

                  <table
                    className="Table"
                    style={{
                      marginBottom: 12,
                    }}
                  >
                    <thead className="Thead">
                    <tr className="Table__tr Table__tr_padding Table__tr_border">
                      <th
                        className="Table__th Table__th_border"
                        style={{
                          backgroundColor: "#dfdfdf",
                        }}
                      >
                        <div className="Th__title">Назначение</div>
                      </th>
                      <th
                        className="Table__th Table__th_border"
                        style={{
                          backgroundColor: "#dfdfdf",
                        }}
                      >
                        <div className="Th__title">Начало</div>
                      </th>
                      <th
                        className="Table__th Table__th_border"
                        style={{
                          backgroundColor: "#dfdfdf",
                        }}
                      >
                        <div className="Th__title">Завершение</div>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {!!item.days && !!item.days[0] && !!item.days[0].time_tracking_records &&
                      item.days[0].time_tracking_records.map((resource, index) => (
                      <tr
                        className="Table__tr Table__tr_hover Table__tr_padding Table__tr_border"
                        key={"item_" + index}
                      >
                        <td className="Table__td Table__td_border">
                          <div className="Td__text ">
                            {resource.time_work_category.name}
                          </div>
                        </td>
                        <td className="Table__td Table__td_border">
                          <div className="Td__text ">
                            {(!!resource.start_time && moment(resource.start_time.substring(0, 19)).format(
                              "HH:mm:ss"
                            )) || "-"}
                          </div>
                        </td>
                        <td className="Table__td Table__td_border">
                          <div className="Td__text ">
                            {(!!resource.end_time && moment(resource.end_time.substring(0, 19)).format(
                              "HH:mm:ss"
                            )) || "-"}
                          </div>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      )}

    </>
  );
}

export default ListKpiTimeTracking;
