import React, { useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {useNotification, Select, useList, Modal, getErrorMessageByKey} from "@vokymlak/kabinet-ui";

function ModalFunnelOrderStateAdd(props) {
  const {
    list: [list],
  } = useList({apiUrl:"/api/view-order-states", rootUrl:ROOT_URL});

  const selectOrderStateUuid = useRef(null);

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const orderStateUuid = selectOrderStateUuid.current.value;

    if (
      !orderStateUuid ||
      orderStateUuid.length === 0 ||
      (orderStateUuid.length > 0 && orderStateUuid === "null")
    ) {
      return;
    }

    axios
      .get(
        "/api/funnel-order-state/funnel/" +
          props.object.tag +
          "/order-state/" +
          orderStateUuid +
          "/add",
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();

        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.uuid,
        title: item.name,
      };

      options.push(option);
    });

    return options;
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          {props.object.name}
          <div className="Text__signature">Добавить</div>
        </div>

        <div className="Form__field Form__field_last">
          <Select
            isFixed={true}
            reference={selectOrderStateUuid}
            options={
              !!list
                ? getSelectOptions(list)
                : getSelectOptions([])
            }
            label="Статус"
          />
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Отмена
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ModalFunnelOrderStateAdd;
