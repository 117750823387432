import React, { Fragment, useState } from "react";
import {
  Select,
  Pagination,
  Loading,
  ButtonIcon,
} from "@vokymlak/kabinet-ui";
import moment from "moment";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import ModalPriceImport from "./ModalPriceImport";
import ModalPriceSaleImport from "./ModalPriceSaleImport";

function PricesPage(props) {
  const {
  } = props;

  const [isModalPriceSaleImport, setIsModalPriceSaleImport] = useState(false);
  const [isModalPriceImport, setIsModalPriceImport] = useState(false);

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div className="Page__title">
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
              }}
            >
              <div>
                Прайсы
              </div>
            </div>

            <ButtonIcon
              tooltipText={"Конвертировать&nbsp;с&nbsp;акциями"}
              iconName={"custom-cloud-upload"}
              iconColor={"#202020"}
              size={26}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalPriceSaleImport(true)}
            />

            <ButtonIcon
              tooltipText={"Конвертировать&nbsp;без&nbsp;акций"}
              iconName={"custom-cloud-upload"}
              iconColor={"#202020"}
              size={26}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalPriceImport(true)}
            />
          </div>

          <div className="Title__settings">
          </div>
        </div>

        {!!isModalPriceSaleImport && (
          <ModalPriceSaleImport
            successfulRequest={() => null}
            handleClose={() => setIsModalPriceSaleImport(false)}
          />
        )}

        {!!isModalPriceImport && (
          <ModalPriceImport
            successfulRequest={() => null}
            handleClose={() => setIsModalPriceImport(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default PricesPage;
