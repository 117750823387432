import { useEffect, useState } from "react";
import { ROOT_URL } from "../../../config/url";
import moment from "moment";
import axios from "axios";
import {useUnroll} from "@vokymlak/kabinet-ui";
import {useAction} from "@vokymlak/kabinet-ui";

function useImportOrders() {
  const [isUpdateList, setIsUpdateList] = useState(false);
  const [list, setList] = useState(null);
  const [isUpdateListCalendar, setIsUpdateListCalendar] = useState(false);
  const [listCalendar, setListCalendar] = useState(null);
  const [selectMonth, setSelectMonth] = useState(moment().format("MM"));
  const [selectYear, setSelectYear] = useState(moment().format("YYYY"));
  const [selectDate, setSelectDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDay] = useState(moment);
  const [calendarDays, setCalendarDays] = useState(
    new Date(Number(selectYear), Number(selectMonth), 0).getDate()
  );
  const [days, setDays] = useState(null);

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalBuyerOrderStateEdit, setModalBuyerOrderStateEdit] = useState(null);
  const [modalBuyerOrderCommentEdit, setModalBuyerOrderCommentEdit] = useState(null);

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  useAction("isUpdate", () => {
    setIsUpdateList(!isUpdateList);
    setIsUpdateListCalendar(!isUpdateListCalendar);
  });

  useEffect(() => {
    setCalendarDays(
      new Date(Number(selectYear), Number(selectMonth), 0).getDate()
    );
  }, [selectYear, selectMonth]);

  useEffect(() => {
    const days = [];
    for (let i = 0; i < calendarDays; i++) {
      let day = i + 1;

      const d = {
        day,
      };

      days.push(d);
    }

    setDays([...days]);
  }, [calendarDays]);

  useEffect(() => {
    axios
      .get(
        "/api/orders/calendar/month?"  +
        "date=" +
        selectYear +"-"+ selectMonth + "-01",
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then((response) => {
        setListCalendar(!!response.data ? [...response.data] : null);
      });
  }, [
    selectYear,
    selectMonth,
    isUpdateListCalendar,
  ]);

  useEffect(() => {
    axios
      .get(
        "/api/orders/calendar/day?" +
          "date=" +
          selectDate,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then((response) => {
        setList(!!response.data ? [...response.data] : null);
      });
  }, [
    selectDate,
    isUpdateList,
  ]);

  const changeSelectMonthMinus = () => {
    if (Number(selectMonth) > 1) {
      setSelectMonth((Number(selectMonth) - 1) < 10 ? "0"+(Number(selectMonth) - 1) : String((Number(selectMonth) - 1)));
    } else {
      setSelectMonth("12");
      setSelectYear(Number(selectYear) - 1);
    }
    setListCalendar(null);
  };

  const changeSelectMonthPlus = () => {
    if (Number(selectMonth) < 12) {
      setSelectMonth((Number(selectMonth) + 1) < 10 ? "0"+(Number(selectMonth) + 1) : String((Number(selectMonth) + 1)));
    } else {
      setSelectMonth("01");
      setSelectYear(Number(selectYear) + 1);
    }
    setListCalendar(null);
  };

  const changeSelectDay = (day) => {
    setSelectDate(
      moment(selectYear + "-" + selectMonth + "-" + day, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      )
    );
  };

  const weekDayText = (weekDay) => {
    const weekDays = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

    return weekDays[Number(weekDay)];
  };

  const monthText = (month) => {
    const months = [
      "январь",
      "февраль",
      "март",
      "апрель",
      "май",
      "июнь",
      "июль",
      "август",
      "сентябрь",
      "октябрь",
      "ноябрь",
      "декабрь",
    ];

    return (
      months[Number(month) - 1].charAt(0).toUpperCase() +
      months[Number(month) - 1].slice(1)
    );
  };

  const isDayMarker = (listCalendar, selectYear, selectMonth, day) => {
    let isDayMarker = false;

    if (!!listCalendar) {
      listCalendar.map((point) => {
        if (
          Number(moment(point.date.substring(0, 19)).format("YYYY")) ===
            Number(selectYear) &&
          Number(moment(point.date.substring(0, 19)).format("MM")) ===
            Number(selectMonth) &&
          Number(moment(point.date.substring(0, 19)).format("DD")) === Number(day)
        ) {
          isDayMarker = true;
        }
      });
    }

    return isDayMarker;
  };

  const getDayCount = (listCalendar, selectYear, selectMonth, day) => {
    let dayCount = 0;

    if (!!listCalendar) {
      listCalendar.map((point) => {
        if (
          Number(moment(point.date.substring(0, 19)).format("YYYY")) ===
          Number(selectYear) &&
          Number(moment(point.date.substring(0, 19)).format("MM")) ===
          Number(selectMonth) &&
          Number(moment(point.date.substring(0, 19)).format("DD")) === Number(day)
        ) {
          dayCount = point.count_orders
        }
      });
    }

    return String(dayCount) === "0" ? "" : String(dayCount) ;
  };

  const getDayAmount = (listCalendar, selectYear, selectMonth, day) => {
    let dayCount = 0;

    if (!!listCalendar) {
      listCalendar.map((point) => {
        if (
          Number(moment(point.date.substring(0, 19)).format("YYYY")) ===
          Number(selectYear) &&
          Number(moment(point.date.substring(0, 19)).format("MM")) ===
          Number(selectMonth) &&
          Number(moment(point.date.substring(0, 19)).format("DD")) === Number(day)
        ) {
          dayCount = point.count_amounts
        }
      });
    }

    return String(dayCount) === "0" ? "" : String(dayCount) ;
  };


  const getAllCount = (listCalendar) => {
    let allCount = 0;

    if (!!listCalendar) {
      listCalendar.map((point) => {
        allCount += point.count_orders
      });
    }

    return allCount;
  };


  const getOptionDays = () => {
    const optionDays = [];
    if (!!days) {
      days.forEach(d => {
        optionDays.push(d.day)
      })
    }
    return optionDays;
  }

  const getOptionOrders = () => {
    const optionDays = [];
    if (!!days && !!listCalendar) {
      days.forEach(d => {
        optionDays.push(Number(getDayCount(listCalendar, selectYear, selectMonth, d.day)))
      })
    }
    return optionDays;
  }

  const getOptionAmounts = () => {
    const optionDays = [];
    if (!!days && !!listCalendar) {
      days.forEach(d => {
        optionDays.push(Number(getDayAmount(listCalendar, selectYear, selectMonth, d.day)) / 100)
      })
    }

    return optionDays;
  }

  const option = {
    grid: {
      left: '8px',
      right: '8px',
      bottom: '8px',
      top: '8px',
      containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },
    xAxis: [
      {
        type: 'category',
        data: getOptionDays(),
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        // name: 'Заказы',
        min: 0,
        max: 100,
        interval: 25,
        axisLabel: {
          formatter: '{value}'
        }
      },
      {
        type: 'value',
        // name: 'Сумма',
        min: 0,
        max: 2000000,
        interval: 500000,
        axisLabel: {
          formatter: '{value}'
        }
      }
    ],
    series: [
      {
        name: 'Заказы',
        type: 'bar',
        data: getOptionOrders()
      },
      {
        yAxisIndex: 1,
        name: 'Сумма',
        type: 'bar',
        data: getOptionAmounts()
      },
    ],
    aria: {
      enabled: true,
      decal: {
        show: true
      }
    }
  };

  return {
    list,
    setList,
    isUpdateList,
    setIsUpdateList,

    listCalendar,
    setListCalendar,
    isUpdateListCalendar,
    setIsUpdateListCalendar,

    changeSelectMonthMinus,
    changeSelectMonthPlus,
    changeSelectDay,
    monthText,
    weekDayText,
    selectMonth,
    selectYear,
    days,
    selectDate,
    toDay,

    isDayMarker,
    getDayCount,

    isModalObjectAdd,
    setIsModalObjectAdd,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    getAllCount,

    option,

    setModalBuyerOrderStateEdit,
    modalBuyerOrderStateEdit,

    setModalBuyerOrderCommentEdit,
    modalBuyerOrderCommentEdit,
  };
}

export default useImportOrders;
