import { ROOT_URL } from "../../../config/url";
import {useAction, useObject} from "@vokymlak/kabinet-ui";
import useMinuteInterval from "../../../hooks/useMinuteInterval";
import useHourlyPageReload from "../../../hooks/useHourlyPageReload";

function useDashboardPacking() {
  const {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject],
  } = useObject({
    apiUrl:"/api/dashboard/packing",
    rootUrl: ROOT_URL,
  });

  useAction(["dashboard"], () => setIsUpdateObject(!isUpdateObject));

  useMinuteInterval(() => setIsUpdateObject(!isUpdateObject));

  useHourlyPageReload();

  return {
    object,
    setObject,

    setIsUpdateObject,
    isUpdateObject,
  };
}

export default useDashboardPacking;
