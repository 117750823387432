import React from "react";
import { ROOT_URL } from "../../../config/url";
import moment from "moment";
import {useParams} from "react-router-dom";
import {BuyerOrderObject} from "../BuyerOrderObject";
import {ButtonIcon, ButtonIconExport, Loading, useObject, useUnroll} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";

export const Order = () => {
  let { orderUuid } = useParams();

  const {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject],
  } = useObject({apiUrl:"/api/order/"+orderUuid + "/warehouse", rootUrl:ROOT_URL});

  const { isUnroll, changeUnroll } = useUnroll();

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
    <div className="Page">
      <div className="Page__title">
        <div className="Title__text">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              fontSize: 19,
              fontWeight: 700,
            }}
          >
            <div>
              Подробнее
            </div>
            <span
              style={{
                fontSize: 12,
                fontWeight: 400,
              }}
            >
                {!!object && !!object.order  && !!object.order.number  && (
                  <span>
                    {object.order.number} от {!!object.order.date && moment(object.order.date.substring(0, 19)).format("DD.MM.YYYY HH:mm:ss")}
                  </span>
                )}
              </span>
          </div>

          {!!object && (
            <ButtonIconExport
              tooltipText={"Выгрузить"}
              iconName={"custom-download"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 12 }}
              apiUrl={"/api/order/"+orderUuid+"/warehouse/export/xlsx"}
              rootUrl={ROOT_URL}
              mimeType={
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              }
              fileName={
              (object.order.number+"_от_"+moment(object.order.date).format("DD_MM_YYYY")+"_план_обеспечения.xlsx").toLowerCase()}
            />
            )}
        </div>

        <div className="Title__settings">
          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 8 }}
            onClick={() => (setObject(null), setIsUpdateObject(!isUpdateObject))}
          />
        </div>
      </div>

      {!object && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}

      {!!object && (
        <div className="Page__content">
          <ul className="List">
                <BuyerOrderObject
                  key={"item_" + object.order.uuid}
                  object={object.order}
                  isUnroll={isUnroll}
                  changeUnroll={changeUnroll}
                  isUpdateList={isUpdateObject}
                  setIsUpdateList={setIsUpdateObject}
                />
              </ul>
        </div>
        )}
    </div>
    </ScrollBlock>
  );
}
