import React from "react";
import {BrowserRouter} from "react-router-dom";
import "@vokymlak/kabinet-ui/dist/esm/index.css";
import {Screen} from "./screens/Screen";
import {Kabinet} from "@vokymlak/kabinet-ui";
import {ROOT_URL, WS_URL} from "./config/url";
import {KeyboardProvider} from "./contexts/keyboard";
import {ViewerProvider} from "./components/Viewer";

function App() {
  return (
    <Kabinet rootUrl={ROOT_URL} wsUrl={WS_URL}>
      <KeyboardProvider>
        <ViewerProvider>
          <BrowserRouter>
            <Screen/>
          </BrowserRouter>
        </ViewerProvider>
      </KeyboardProvider>
    </Kabinet>
  );
}

export default App;
