import React, { useState, useEffect, useRef } from "react";
import {getAccountFio, Modal, Select, useList} from "@vokymlak/kabinet-ui";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {Textarea} from "@vokymlak/kabinet-ui";
import {getErrorMessageByKey} from "@vokymlak/kabinet-ui";
import {useNotification} from "@vokymlak/kabinet-ui";
import {Input} from "@vokymlak/kabinet-ui";

function ModalBuyerOrderEmployeePackingEdit(props) {
  const {
    list: [list],
  } = useList({apiUrl:"/api/employees/list", rootUrl:ROOT_URL});


  const selectWhomId = useRef(null);

  const [buyerOrder, setBuyerOrder] = useState(props.buyerOrder);

  const { notification } = useNotification();

  const [selectEmployee, setSelectEmployee] = useState(0);
  const changeSelectEmployee = (accountId) => {
    setSelectEmployee(Number(accountId));
  };

  const getSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.id,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    return options;
  };

  useEffect(() => {
    if (props.buyerOrder) {
      setBuyerOrder(props.buyerOrder);
    }
  }, [props.buyerOrder]);

  useEffect(() => {
    return () => {
      setBuyerOrder(null);
    };
  }, []);

  const addAccountRole = (event) => {
    event.preventDefault();

    const whomId = selectWhomId.current.value;


    const jsonData = JSON.stringify({
      packer_id:
        whomId.length > 0 ? (whomId === "null" ? null : Number(whomId)) : null,

    });

    axios
      .post("/api/buyer-order/" + buyerOrder.id + "/packer", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    buyerOrder && list && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => addAccountRole(event)}>
          <div className="Form__title">
            Отгрузка заказа
            <br />
            <div className="Text__signature">
              {buyerOrder.number} от {buyerOrder.date}
            </div>
          </div>

          <div className="Form__field ">
            <Select
              isFixed={true}
              reference={selectWhomId}
              options={!!list ? getSelectOptions(list) : getSelectOptions([])}
              label="Фасовщик"
              value={
                !!buyerOrder.packer_id ? buyerOrder.packer_id
                  : "null"
              }
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Применить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalBuyerOrderEmployeePackingEdit;
