import React, {useEffect, useRef, useState} from "react";
import {getErrorMessageByKey, Input, Modal, useNotification} from "@vokymlak/kabinet-ui";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";

function ModalBuyerOrderPrint(props) {
  const {
    object
  } = props
  const [sticker, setSticker] = useState(null);

  const inputMontageCount = useRef(null);
  const [montageQuantity, setMontageQuantity] = useState(object.quantity);

  useEffect(() => {
    if (!!object && !!object.nomenclature && !!object.nomenclature.stickers) {
      setSticker(object.nomenclature.stickers[0]);
    } else {
      setSticker(null);
    }
  }, [object])

  const {notification} = useNotification();

  const changePrint = () => {
    if (montageQuantity <= 0) {
      notification("Укажите верное количество для печати", "red");
      return;
    }

    if (montageQuantity > 10) {
      notification("Не больше 10 этикеток за раз", "red");
      return;
    }

    axios
      .get("/api/nomenclature-sticker/" + sticker.uuid + "/print?count=" + montageQuantity, {
        responseType: "arraybuffer",
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Файл отправлен на печать", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const plusOneMontageQuantity = () => {
    const montageCount = inputMontageCount.current.value;
    setMontageQuantity((Number(montageCount) || 0) + 1);
    inputMontageCount.current.value =
      Math.round(((Number(montageCount) || 0) + 1) * 100000) / 100000;
  };

  const minusOneMontageQuantity = () => {
    const montageCount = inputMontageCount.current.value;
    if (montageCount <= 0) {
      setMontageQuantity(0);
      inputMontageCount.current.value = 0;
      return;
    }
    setMontageQuantity((Number(montageCount) || 0) - 1);
    inputMontageCount.current.value =
      Math.round(((Number(montageCount) || 0) - 1) * 100000) / 100000;
  };

  const numberMontageQuantity = (number) => {
    setMontageQuantity(Number(number) || 0);
    inputMontageCount.current.value =
      Math.round((Number(number) || 0) * 100000) / 100000;
  };

  return (
    !!sticker && (
      <Modal handleClose={() => props.handleClose()}>
        <div className="Form">
          <div
            className="Form__title"
            style={{
              fontSize: 15,
            }}
          >
            {sticker.name + sticker.expansion}
            <div className="Text__signature">Этикетка</div>
          </div>

          <div className="Form__field">
            <Input
              reference={inputMontageCount}
              type="number"
              label="Количество"
              isFixed={true}
              value={montageQuantity}
            />
          </div>

          <div
            className="Form__field"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                margin: "0 12px 0 0",
              }}
              className="Action__button Action__button_maxi Action__button_blue"
              onClick={() => minusOneMontageQuantity()}
            >
              - 1
            </div>
            <div
              style={{
                margin: "0 12px",
              }}
              className="Action__button Action__button_maxi Action__button_blue"
              onClick={() => numberMontageQuantity(1)}
            >
              1
            </div>
            <div
              style={{
                margin: "0 0 0 12px",
              }}
              className="Action__button Action__button_maxi Action__button_blue"
              onClick={() => plusOneMontageQuantity()}
            >
              + 1
            </div>
          </div>

          <div className="Form__actions Form__actions_center" onClick={() => changePrint()}>
            <button className="Action__button Action__button_all Action__button_gray">
              Печать
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </div>
      </Modal>
    )
  );
}

export default ModalBuyerOrderPrint;
