import React, {useState} from "react";
import {Loading, Modal} from "@vokymlak/kabinet-ui";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {getErrorMessageByKey} from "@vokymlak/kabinet-ui";
import {useNotification} from "@vokymlak/kabinet-ui";

function ModalOrderStatesImport(props) {

  const [isRequestDay, setIsRequestDay] = useState(false);
  const { notification } = useNotification();

  const changeAdd = () => {

    setIsRequestDay(true)

    axios
      .get("/api/order-states/import", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsRequestDay(false);
        props.successfulRequest();
        props.handleClose();
      })
      .catch((error) => {
        setIsRequestDay(false);
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <div className="Form">
        <div className="Form__title">Импорт</div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue"
                  onClick={!isRequestDay ? () => changeAdd() : () => null}>

            {!isRequestDay
              ? "Продолжить"
              : <Loading size="medium" color="blue" />}
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Отмена
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalOrderStatesImport;
