import React from "react";
import {ROLE_EMPLOYEE} from "../../../config/constants";
import {BuyerOrderObject} from "../BuyerOrderObject";
import moment from "moment";
import {ButtonIcon, isProfileRoleTypeTags, Loading, Pagination, Search, Select} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";

export const BuyerOrdersPage = (props) => {
  const {
    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    profile,

    listYears,
    changeSelectYear,
  } = props;

  const getYearsSelectOptions = (list) => {
    const options = [];

    list.map((item) => {
      const option = {
        value: item,
        title: item,
      };

      options.push(option);
    });

    return options;
  };

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page"
           onContextMenu={
             isProfileRoleTypeTags(profile, [ROLE_EMPLOYEE]) ?
               ((event) => event.preventDefault()) : () => null
           }>
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Все заказы
            {!!list && (
              <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({count})
              </span>
            )}
            <span style={{ width: 140, marginLeft: 16 }}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
            <span style={{ width: 80, marginLeft: 16, fontSize: 17, fontWeight: 400 }}>
            <Select
              isFixed={true}
              isDefaultOption={false}
              value={moment().format('YYYY')}
              options={
                !!listYears
                  ? getYearsSelectOptions(listYears)
                  : getYearsSelectOptions([])
              }
              label="Год"
              onChange={(event) => changeSelectYear(event.target.value)}
            />
          </span>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{ marginLeft: 8 }}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 25,
                      title: 25,
                    },
                    {
                      value: 100,
                      title: 100,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{ marginLeft: 8 }}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}
          </div>
      </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
          {list.map((item) => (
              <BuyerOrderObject
                key={"item_" + item.uuid}
                object={item}
                isUnroll={isUnroll}
                changeUnroll={changeUnroll}
                searchQuery={searchQuery}
                isUpdateList={isUpdateList}
                setIsUpdateList={setIsUpdateList}
              />
          ))}
            </ul>
          </div>
        )}
      </div>
    </ScrollBlock>
  );
}
