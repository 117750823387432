import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {Icon, isProfileOpportunityTypeTags, isProfileRoleTypeTags, Tooltip, useProfile} from "@vokymlak/kabinet-ui";
import {
  OPPORTUNITY_DASHBOARD,
  OPPORTUNITY_EMPLOYEES,
  OPPORTUNITY_HANDBOOK,
  OPPORTUNITY_KANBAN,
  OPPORTUNITY_LEAD,
  OPPORTUNITY_MY,
  OPPORTUNITY_NOMENCLATURES,
  OPPORTUNITY_NOMENCLATURES_ALL,
  OPPORTUNITY_ORDER,
  OPPORTUNITY_ORDER_ALL,
  OPPORTUNITY_ORDER_STATUS,
  OPPORTUNITY_PLAN,
  OPPORTUNITY_PLAN_DISPATCH,
  OPPORTUNITY_PRICES,
  OPPORTUNITY_PRODUCT_WAREHOUSE,
  OPPORTUNITY_SITE,
  OPPORTUNITY_SUMMARY,
  OPPORTUNITY_TICKETS,
  OPPORTUNITY_TIME_SHEET,
  OPPORTUNITY_TIME_TRACKING,
  ROLE_ADMINISTRATOR,
  ROLE_ANALYST,
  ROLE_DIRECTOR,
} from "../../config/constants";

function MenuRouting(props) {
  const {profile} = useProfile();

  const [isFullScreen, setIsFullScreen] = useState(false);
  useEffect(() => {
    updateFullScreen();
  }, []);

  const onChange = () => {
    if (!!props.onChange) {
      props.onChange();
    }
  };

  const onChangeFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }

    setTimeout(() => updateFullScreen(), 500);
  };

  const updateFullScreen = () => {
    if (!document.fullscreenElement) {
      setIsFullScreen(false);
    } else if (document.exitFullscreen) {
      setIsFullScreen(true);
    }
  };

  return (
    <>
      {(isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_ORDER_ALL,
      ])) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/buyer-orders"}
              onClick={() => onChange()}
            >
              Все заказы
            </NavLink>
          </li>
        </ul>
      )}

      {(isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_ORDER_STATUS,
      ])) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/orders/view"}
              onClick={() => onChange()}
            >
              Заказы по статусам
            </NavLink>
          </li>
        </ul>
      )}

      {(isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_PRODUCT_WAREHOUSE,
      ])) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/product-warehouse"}
              onClick={() => onChange()}
            >
              Склад продукции
            </NavLink>
          </li>
        </ul>
      )}

      {(isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_NOMENCLATURES_ALL,
      ])) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/all-nomenclatures"}
              onClick={() => onChange()}
            >
              Вся номенклатура
            </NavLink>
          </li>
        </ul>
      )}

      {(isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_MY,
      ])) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__header">
            <div className="Header__text">Персональные</div>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/my/summary-table"}
              onClick={() => onChange()}
            >
              Сводная таблица
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/my/order-marks"}
              onClick={() => onChange()}
            >
              Отметки по заказам
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/my/time-tracking"}
              onClick={() => onChange()}
            >
              Учет времени
            </NavLink>
          </li>
        </ul>
      )}

      {(isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) || isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_ORDER,
      ])) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__header">
            <div className="Header__text">Заказы</div>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/buyer-orders"}
              onClick={() => onChange()}
            >
              Все заказы
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/orders/view"}
              onClick={() => onChange()}
            >
              По статусам
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/import-orders"}
              onClick={() => onChange()}
            >
              Заказы
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_PLAN_DISPATCH,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/plan-dispatch"}
              onClick={() => onChange()}
            >
              План доставки
            </NavLink>
          </li>
        </ul>
      )}

      {(isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) || isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_PLAN,
      ])) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__header">
            <div className="Header__text">Планы</div>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/plan-kneading"}
              onClick={() => onChange()}
            >
              План замеса
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/plan-packing"}
              onClick={() => onChange()}
            >
              План фасовки
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/plan-providing"}
              onClick={() => onChange()}
            >
              План обеспечения
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/plan-shipments"}
              onClick={() => onChange()}
            >
              План отгрузки
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/plan-dispatch"}
              onClick={() => onChange()}
            >
              План доставки
            </NavLink>
          </li>
          {/*<li className="List__link">*/}
          {/*  <NavLink*/}
          {/*    className={"Link__text"}*/}
          {/*    to={"/plan-warehouse"}*/}
          {/*    onClick={() => onChange()}*/}
          {/*  >*/}
          {/*    План на сырьё*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/plan-warehouse-new"}
              onClick={() => onChange()}
            >
              План на сырьё
            </NavLink>
          </li>
        </ul>
      )}

      {(isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) || isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_NOMENCLATURES,
      ])) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__header">
            <div className="Header__text">Номенклатура</div>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/product-warehouse"}
              onClick={() => onChange()}
            >
              Склад продукции
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/all-nomenclatures"}
              onClick={() => onChange()}
            >
              Вся номенклатура
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/nomenclatures"}
              onClick={() => onChange()}
            >
              Номенклатура
            </NavLink>
          </li>
        </ul>
      )}

      {(isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) || isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_DASHBOARD,
      ])) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__header">
            <div className="Header__text">Этапы</div>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/dashboard-registration"}
              onClick={() => onChange()}
            >
              Оформление
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/dashboard-sticker"}
              onClick={() => onChange()}
            >
              Наклейки
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/dashboard-packing"}
              onClick={() => onChange()}
            >
              Фасовка
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/dashboard-assembly"}
              onClick={() => onChange()}
            >
              Комплектовка
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/dashboard-pickup"}
              onClick={() => onChange()}
            >
              Самовывоз
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/dashboard-logistics"}
              onClick={() => onChange()}
            >
              Логистика
            </NavLink>
          </li>
          {/*<li className="List__link">*/}
          {/*  <NavLink*/}
          {/*    className={"Link__text"}*/}
          {/*    to={"/dashboard-general"}*/}
          {/*    onClick={() => onChange()}*/}
          {/*  >*/}
          {/*    Общий*/}
          {/*  </NavLink>*/}
          {/*</li>*/}
        </ul>
      )}

      {(isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) || isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_SUMMARY,
      ])) && (
        <ul className="Menu__list Menu__list_box">
        <li className="List__header">
            <div className="Header__text">Показатели</div>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/summary-table"}
              onClick={() => onChange()}
            >
              Сводная таблица
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/order-marks"}
              onClick={() => onChange()}
            >
              Отметки по заказам
            </NavLink>
          </li>
        </ul>
      )}

      {(isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) || isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_LEAD,
      ])) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__header">
            <div className="Header__text">Лиды</div>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/lead-charts"}
              onClick={() => onChange()}
            >
              Сводная по лидам
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/lead-statistics"}
              onClick={() => onChange()}
            >
              Данные по лидам
            </NavLink>
          </li>
        </ul>
      )}

      {(isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) || isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_SITE,
      ])) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__header">
            <div className="Header__text">Сайт</div>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/site-callbacks"}
              onClick={() => onChange()}
            >
              Заявки с сайта
            </NavLink>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/site-opt-orders"}
              onClick={() => onChange()}
            >
              Заказы по опту
            </NavLink>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/site-shop-orders"}
              onClick={() => onChange()}
            >
              Заказы по рознице
            </NavLink>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/site-feedback"}
              onClick={() => onChange()}
            >
              Обратная связь
            </NavLink>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/site-subscribe"}
              onClick={() => onChange()}
            >
              Подписки на новости
            </NavLink>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/site-news"}
              onClick={() => onChange()}
            >
              Новости компании
            </NavLink>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/site-blog"}
              onClick={() => onChange()}
            >
              Блог статей
            </NavLink>
          </li>
        </ul>
      )}


      {(isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) || isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_PRICES,
      ])) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/prices"}
              onClick={() => onChange()}
            >
              Прайсы
            </NavLink>
          </li>
        </ul>
      )}

      {(isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) || isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_TIME_SHEET,
        OPPORTUNITY_TIME_TRACKING,
      ])) && (
        <ul className="Menu__list Menu__list_box">
          {(isProfileRoleTypeTags(profile, [
            ROLE_ADMINISTRATOR,
            ROLE_DIRECTOR,
          ]) || isProfileOpportunityTypeTags(profile, [
            OPPORTUNITY_TIME_SHEET,
          ])) && (
            <li className="List__link">
              <NavLink
                className={"Link__text"}
                to={"/time-sheet"}
                onClick={() => onChange()}
              >
                Табель
              </NavLink>
            </li>
          )}
          {(isProfileRoleTypeTags(profile, [
            ROLE_ADMINISTRATOR,
            ROLE_DIRECTOR,
          ]) || isProfileOpportunityTypeTags(profile, [
            OPPORTUNITY_TIME_TRACKING,
          ])) && (
            <li className="List__link">
              <NavLink
                className={"Link__text"}
                to={"/time-tracking"}
                onClick={() => onChange()}
              >
                Учет времени
              </NavLink>
            </li>
          )}
        </ul>
      )}


      {(isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) || isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_KANBAN,
      ])) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/kanban"}
              onClick={() => onChange()}
            >
              Канбан
            </NavLink>
          </li>
        </ul>
      )}

      {(isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) || isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_EMPLOYEES,
      ])) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/employees"}
              onClick={() => onChange()}
            >
              Сотрудники
            </NavLink>
          </li>
          {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR]) && (
            <li className="List__link">
              <NavLink className={"Link__text"} to={"/accounts"} onClick={() => onChange()}>
                Аккаунты
              </NavLink>
            </li>
          )}
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR, ROLE_DIRECTOR]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/settings"}
              onClick={() => onChange()}
            >
              Настройки
            </NavLink>
          </li>
        </ul>
      )}

      {(isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR]) || isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_TICKETS,
      ])) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/tickets"}
              onClick={() => onChange()}
            >
              Тикеты
            </NavLink>
          </li>
        </ul>
      )}

      {(isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR]) || isProfileOpportunityTypeTags(profile, [
        OPPORTUNITY_HANDBOOK,
      ])) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/handbook"}
              onClick={() => onChange()}
            >
              Справочник
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [ROLE_ANALYST]) && (
        <ul className="Menu__list">
          <li className="List__link" onClick={() => onChangeFullScreen()}>
            <Tooltip
              text={isFullScreen ? "В окне" : "На весь экран"}
              style={{cursor: "pointer"}}
            >
              {isFullScreen && (
                <Icon name={"custom-fullscreen-exit"} size={48} color={"#bbb"}/>
              )}
              {!isFullScreen && (
                <Icon name={"custom-fullscreen"} size={48} color={"#bbb"}/>
              )}
            </Tooltip>
          </li>
        </ul>
      )}
    </>
  );
}

export default MenuRouting;
