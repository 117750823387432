import React, { useEffect, useState } from "react";
import { ROOT_URL } from "../../../../../config/url";
import moment from "moment";
import {useList} from "@vokymlak/kabinet-ui";

function useKpiTimeTracking({ date, selectEmployee }) {
  const [selectDate, setSelectDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDay] = useState(moment);
  const [calendarDays, setCalendarDays] = useState(
    new Date(
      Number(moment(date).format("YYYY")),
      Number(moment(date).format("MM")),
      0
    ).getDate()
  );
  const [days, setDays] = useState(null);

  useEffect(() => {
    setCalendarDays(
      new Date(
        Number(moment(date).format("YYYY")),
        Number(moment(date).format("MM")),
        0
      ).getDate()
    );
  }, [date]);

  useEffect(() => {
    const days = [];
    for (let i = 0; i < calendarDays; i++) {
      let day = i + 1;

      const d = {
        day,
      };

      days.push(d);
    }

    setDays([...days]);
  }, [calendarDays]);

  const {
    list: [list, _count, setList],
    setApiUrlParams,
  } = useList({
      apiUrl: "/api/tracking-time/chart",
      rootUrl: ROOT_URL,
      urlParams:
        {
          account: !!selectEmployee && selectEmployee > 0 ? selectEmployee : 0,
          date: date,
        }
    }
  );

  useEffect(() => {
    setList(null)
    const params = {
      account: !!selectEmployee && selectEmployee > 0 ? selectEmployee : 0,
      date: date,
    };
    setApiUrlParams(params);
  }, [selectEmployee, date]);

  const weekDayText = (weekDay) => {
    const weekDays = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

    return weekDays[Number(weekDay)];
  };

  const changeSelectDay = (day) => {
    setSelectDate(
      moment(moment(date).format("YYYY-MM") + "-" + day, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      )
    );
  };

  //--------------------------------------------------------------------------------------------------------------------

  const isDayMarker = (listCalendar, selectYear, selectMonth, day) => {
    let isDayMarker = false;

    if (!!listCalendar) {
      listCalendar.map((point) => {
        if (
          Number(moment(point.date.substring(0, 19)).format("YYYY")) ===
            Number(selectYear) &&
          Number(moment(point.date.substring(0, 19)).format("MM")) ===
            Number(selectMonth) &&
          Number(moment(point.date.substring(0, 19)).format("DD")) ===
            Number(day)
        ) {
          isDayMarker = true;
        }
      });
    }

    return isDayMarker;
  };

  return {
    selectEmployee,
    date,

    changeSelectDay,
    selectDate,
    toDay,
    weekDayText,
    days,

    list,
    isDayMarker,
  };
}

export default useKpiTimeTracking;
