import React, {useState} from "react";
import {ButtonIcon, getAccountFio, Icon, Loading, Tooltip} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import styleContent from "../../../styles/modules/Block.module.scss";
import moment from "moment/moment";
import styleCard from "../../../styles/modules/Card.module.scss";
import {formatAmount} from "../../../functions/formatAmount";

function DashboardLogisticsPage(props) {
  const {
    object,
    setObject,

    setIsUpdateObject,
    isUpdateObject,
  } = props;

  function getOrderStates(stage) {
    let color = null;
    if (!!stage && !!stage.order_states && stage.order_states.length > 0) {
      color = stage.order_states[0].color
    }
    return color
  }

  function getOrderState(item) {
    let color = null;
    if (!!item && !!item.buyer_order_state) {
      color = item.buyer_order_state.color
    }
    return color
  }


  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Экран логистики

            {!!object && (
              <div style={{
                marginLeft: 24,
                fontSize: 32,
                marginBottom: 0,
                opacity: .75
              }}>{moment(object.data).format("DD.MM.YYYY")}</div>
            )}
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 12}}
              onClick={() => (
                setObject(null),
                  setIsUpdateObject(!isUpdateObject)
              )}
            />
          </div>
        </div>

        {!object && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        <div className="Page__content" style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}>
          <div className="Page__content" style={{
            width: "100%",
            padding: "0 12px 0 0"
          }}>

            {!!object && !!object.stage_logistics_new && (
              <div
                className={styleContent._block + " " + styleContent._block_bottom}
              >
                <div className={styleContent._block__content}
                     style={{
                       display: "block"
                     }}>
                  <div className={styleCard._block} style={{
                    padding: "6px 8px",
                    minWidth: "none",
                    marginRight: 0
                  }}>
                    <div
                      className={styleCard._block__number}
                      style={{
                        fontSize: 24,
                        marginBottom: 2
                      }}
                    >
                      {((!!object.stage_logistics_new.count && object.stage_logistics_new.count) || 0)}
                    </div>
                    <div
                      className={styleCard._block__title}
                      style={{
                        fontSize: 16
                      }}
                    >
                      На очереди
                    </div>
                  </div>

                  {!!object && !!object.stage_logistics_new && !!object.stage_logistics_new.order_states && (
                    <div className={styleContent._block__content}
                         style={{
                           flexDirection: "column",
                           flexWrap: "nowrap",
                           justifyContent: "flex-start",
                         }}>
                      {object.stage_logistics_new.order_states.map(state => (
                        <div key={state.uuid} style={{
                          padding: "6px 8px",
                          backgroundColor: !!state.color ? state.color + "25" : "#dbdbdb",
                          display: "inline-block",
                          borderRadius: 4,
                          fontSize: 19,
                          fontWeight: 700
                        }}>
                          {state.name}
                        </div>
                      ))}
                    </div>
                  )}

                </div>
              </div>
            )}

            {!!object && !!object.stage_logistics_new && !!object.stage_logistics_new.orders && (
              <table className="Table" style={{marginBottom: 24}}>
                <thead className="Thead">
                <tr className="Table__tr Table__tr_padding Table__tr_border">
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor:
                        !!getOrderStates(object.stage_logistics_new)
                          ? getOrderStates(object.stage_logistics_new) + "50"
                          : "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Дата</div>
                  </th>
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor:
                        !!getOrderStates(object.stage_logistics_new)
                          ? getOrderStates(object.stage_logistics_new) + "50"
                          : "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Номер</div>
                  </th>
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor:
                        !!getOrderStates(object.stage_logistics_new)
                          ? getOrderStates(object.stage_logistics_new) + "50"
                          : "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Состояние</div>
                  </th>
                </tr>
                </thead>
                <tbody>
                {object.stage_logistics_new.orders.map((item, index) => (
                  <tr
                    className="Table__tr Table__tr_hover Table__tr_padding Table__tr_border"
                    key={"item_" + index}
                  >
                    <td className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            !!getOrderState(item)
                              ? getOrderState(item) + "33"
                              : "#dfdfdf",
                        }}>
                      <div className="Td__text Td__text_center">
                        {(!!item.date_stage_logistics_new &&
                          moment(item.date_stage_logistics_new.substring(0, 19)).format(
                            "DD.MM.YYYY HH:mm"
                          )) || "-"}
                      </div>
                    </td>
                    <td className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            !!getOrderState(item)
                              ? getOrderState(item) + "33"
                              : "#dfdfdf",
                        }}>
                      <div className="Td__text Td__text_center">
                        <div style={{
                          fontSize: 21,
                        }}>
                          {(!!item.number && item.number) || "-"}
                        </div>
                      </div>
                    </td>
                    <td className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            !!getOrderState(item)
                              ? getOrderState(item) + "33"
                              : "#dfdfdf",
                        }}>
                      <div className="Td__text Td__text_center">
                        {(!!item.buyer_order_state && item.buyer_order_state.name) || "-"}
                      </div>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            )}
          </div>

          <div className="Page__content" style={{
            width: "100%",
            padding: "0 12px"
          }}>
            {!!object && !!object.stage_logistics_at_work && (
              <div
                className={styleContent._block + " " + styleContent._block_bottom}
              >
                <div className={styleContent._block__content}
                     style={{
                       display: "block"
                     }}>
                  <div className={styleCard._block} style={{
                    padding: "6px 8px",
                    minWidth: "none",
                    marginRight: 0
                  }}>
                    <div
                      className={styleCard._block__number}
                      style={{
                        fontSize: 24,
                        marginBottom: 2
                      }}
                    >
                      {((!!object.stage_logistics_at_work.count && object.stage_logistics_at_work.count) || 0)}
                    </div>
                    <div
                      className={styleCard._block__title}
                      style={{
                        fontSize: 16
                      }}
                    >
                      В работе
                    </div>
                  </div>

                  {!!object && !!object.stage_logistics_at_work && !!object.stage_logistics_at_work.order_states && (
                    <div className={styleContent._block__content}
                         style={{
                           flexDirection: "column",
                           flexWrap: "nowrap",
                           justifyContent: "flex-start",
                         }}>
                      {object.stage_logistics_at_work.order_states.map(state => (
                        <div key={state.uuid} style={{
                          padding: "6px 8px",
                          backgroundColor: !!state.color ? state.color + "25" : "#dbdbdb",
                          display: "inline-block",
                          borderRadius: 4,
                          fontSize: 19,
                          fontWeight: 700
                        }}>
                          {state.name}
                        </div>
                      ))}
                    </div>
                  )}

                </div>
              </div>
            )}

            {!!object && !!object.stage_logistics_at_work && !!object.stage_logistics_at_work.orders && (
              <table className="Table" style={{marginBottom: 24}}>
                <thead className="Thead">
                <tr className="Table__tr Table__tr_padding Table__tr_border">
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor:
                        !!getOrderStates(object.stage_logistics_at_work)
                          ? getOrderStates(object.stage_logistics_at_work) + "50"
                          : "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Дата</div>
                  </th>
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor:
                        !!getOrderStates(object.stage_logistics_at_work)
                          ? getOrderStates(object.stage_logistics_at_work) + "50"
                          : "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Номер</div>
                  </th>
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor:
                        !!getOrderStates(object.stage_logistics_at_work)
                          ? getOrderStates(object.stage_logistics_at_work) + "50"
                          : "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Состояние</div>
                  </th>
                </tr>
                </thead>
                <tbody>
                {object.stage_logistics_at_work.orders.map((item, index) => (
                  <tr
                    className="Table__tr Table__tr_hover Table__tr_padding Table__tr_border"
                    key={"item_" + index}
                  >
                    <td className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            !!getOrderState(item)
                              ? getOrderState(item) + "33"
                              : "#dfdfdf",
                        }}>
                      <div className="Td__text Td__text_center">
                        {(!!item.date_stage_logistics &&
                          moment(item.date_stage_logistics.substring(0, 19)).format(
                            "DD.MM.YYYY HH:mm"
                          )) || "-"}
                      </div>
                    </td>
                    <td className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            !!getOrderState(item)
                              ? getOrderState(item) + "33"
                              : "#dfdfdf",
                        }}>
                      <div className="Td__text Td__text_center">
                        <div style={{
                          fontSize: 21,
                        }}>
                          {(!!item.number && item.number) || "-"}
                        </div>
                      </div>
                    </td>
                    <td className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            !!getOrderState(item)
                              ? getOrderState(item) + "33"
                              : "#dfdfdf",
                        }}>
                      <div className="Td__text Td__text_center">
                        {(!!item.buyer_order_state && item.buyer_order_state.name) || "-"}
                      </div>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            )}
          </div>

          <div className="Page__content" style={{
            width: "100%",
            padding: "0 0 0 12px"
          }}>
            {!!object && !!object.stage_logistics_completed && (
              <div
                className={styleContent._block + " " + styleContent._block_bottom}
              >
                <div className={styleContent._block__content}
                     style={{
                       display: "block"
                     }}>
                  <div className={styleCard._block} style={{
                    padding: "6px 8px",
                    minWidth: "none",
                    marginRight: 0
                  }}>
                    <div
                      className={styleCard._block__number}
                      style={{
                        fontSize: 24,
                        marginBottom: 2
                      }}
                    >
                      {((!!object.stage_logistics_completed.count && object.stage_logistics_completed.count) || 0)}
                    </div>
                    <div
                      className={styleCard._block__title}
                      style={{
                        fontSize: 16
                      }}
                    >
                      Завершены
                    </div>
                  </div>

                  {!!object && !!object.stage_logistics_completed && !!object.stage_logistics_completed.order_states && (
                    <div className={styleContent._block__content}
                         style={{
                           flexDirection: "column",
                           flexWrap: "nowrap",
                           justifyContent: "flex-start",
                         }}>
                      {object.stage_logistics_completed.order_states.map(state => (
                        <div key={state.uuid} style={{
                          padding: "6px 8px",
                          backgroundColor: !!state.color ? state.color + "25" : "#dbdbdb",
                          display: "inline-block",
                          borderRadius: 4,
                          fontSize: 19,
                          fontWeight: 700
                        }}>
                          {state.name}
                        </div>
                      ))}
                    </div>
                  )}

                </div>
              </div>
            )}

            {!!object && !!object.stage_logistics_completed && !!object.stage_logistics_completed.orders && (
              <table className="Table" style={{marginBottom: 24}}>
                <thead className="Thead">
                <tr className="Table__tr Table__tr_padding Table__tr_border">
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor:
                        !!getOrderStates(object.stage_logistics_completed)
                          ? getOrderStates(object.stage_logistics_completed) + "50"
                          : "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Дата</div>
                  </th>
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor:
                        !!getOrderStates(object.stage_logistics_completed)
                          ? getOrderStates(object.stage_logistics_completed) + "50"
                          : "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Номер</div>
                  </th>
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor:
                        !!getOrderStates(object.stage_logistics_completed)
                          ? getOrderStates(object.stage_logistics_completed) + "50"
                          : "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Состояние</div>
                  </th>
                </tr>
                </thead>
                <tbody>
                {object.stage_logistics_completed.orders.map((item, index) => (
                  <tr
                    className="Table__tr Table__tr_hover Table__tr_padding Table__tr_border"
                    key={"item_" + index}
                  >
                    <td className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            !!getOrderState(item)
                              ? getOrderState(item) + "33"
                              : "#dfdfdf",
                        }}>
                      <div className="Td__text Td__text_center">
                        {(!!item.date_stage_logistics_completed &&
                          moment(item.date_stage_logistics_completed.substring(0, 19)).format(
                            "DD.MM.YYYY HH:mm"
                          )) || "-"}
                      </div>
                    </td>
                    <td className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            !!getOrderState(item)
                              ? getOrderState(item) + "33"
                              : "#dfdfdf",
                        }}>
                      <div className="Td__text Td__text_center">
                        <div style={{
                          fontSize: 21,
                        }}>
                          {(!!item.number && item.number) || "-"}
                        </div>
                      </div>
                    </td>
                    <td className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            !!getOrderState(item)
                              ? getOrderState(item) + "33"
                              : "#dfdfdf",
                        }}>
                      <div className="Td__text Td__text_center">
                        {(!!item.buyer_order_state && item.buyer_order_state.name) || "-"}
                      </div>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            )}
          </div>
        </div>

      </div>
    </ScrollBlock>
  );
}

export default DashboardLogisticsPage;
