import React, {useState} from "react";
import styles from "./ViewOrders.module.scss";
import { NavLink } from "react-router-dom";
import {ROLE_ANALYST, ROLE_EMPLOYEE} from "../../../config/constants";
import {ButtonIcon, Loading} from "@vokymlak/kabinet-ui";
import {formatAmount} from "../../../functions/formatAmount";
import {Icon, isProfileRoleTypeTags} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import ModalOrderHistoryExport from "./ModalOrderHistoryExport";

function ViewOrdersPage(props) {
  const {
    object,
    setObject,
    setIsUpdateObject,
    isUpdateObject,
    profile,

    listOrderStateGroups,
    isViewOrderStateGroup,
    isViewOrderStateNotGroups,
  } = props;

  const getCountGroup = (obj, arr) => {
    let count = 0
    arr.map(state => {
      if (isViewOrderStateGroup(obj, state)) {
        count += state.count
      }
    })

    return count
  }

  const getAmountGroup = (obj, arr) => {
    let amount = 0
      arr.map(state => {
        if (isViewOrderStateGroup(obj, state)) {
          amount += state.amount
        }
      })

    return amount
  }

  const [amounts, setAmounts] = useState(null)

  const addOrDeleteAmount = (uuid) => {
    let repeat = false

    if (!!amounts && amounts.length > 0) {
      amounts.map((object, index) => {
        if (String(object) === String(uuid)) {
          repeat = true
          amounts.splice(index, 1)
        }
      })
    }

    if (!repeat) {
      amounts.push(uuid)
    }

    setAmounts([...amounts])
  }

  const isAmount = (uuid) => {
    let repeat = false

    if (!!amounts && amounts.length > 0) {
      amounts.map((object) => {
        if (String(object) === String(uuid)) {
          repeat = true
        }
      })
    }

    return repeat
  }

  const getAmount = (arr) => {
    let amount = 0

    if (!!object && object.order_states) {
      object.order_states.map((state) => {
        let isAmount = false
        arr.map(a => {
          if (String(a) === String(state.uuid)) {
            isAmount = true
          }
        })
        if (isAmount) {
          amount += state.amount
        }
      })
    }

    return amount
  }

  const getCount = (arr) => {
    let amount = 0

    if (!!object && object.order_states) {
      object.order_states.map((state) => {
        let isAmount = false
        arr.map(a => {
          if (String(a) === String(state.uuid)) {
            isAmount = true
          }
        })
        if (isAmount) {
          amount += state.count
        }
      })
    }

    return amount
  }

  const [modalOrderHistoryExport, setIsModalOrderHistoryExport] = useState(false)

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
    <div className="Page"
         onContextMenu={
           isProfileRoleTypeTags(profile, [ROLE_EMPLOYEE]) ?
             ((event) => event.preventDefault()) : () => null
         }>
      <div className="Page__title">
        <div className="Title__text"
             style={{
               display: "flex",
               alignItems: "center",
               fontSize: 21,
               fontWeight: 700,
             }}>
          По статусам
          {!!object && (
            <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({object.count})
              </span>
          )}

          <ButtonIcon
            tooltipText={"Калькуляция"}
            iconName={"custom-calculate"}
            iconColor={
              !!amounts ? '#0064ff' : '#202020'
            }
            size={24}
            style={{ marginLeft: 24 }}
            onClick={!amounts ? () => setAmounts([]) : () => setAmounts(null)}
          />

          {!!amounts && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "#0064ff",
                fontSize: 17,
                fontWeight: 700,
                marginLeft: 12
              }}>
              {getCount(amounts)} | {formatAmount(getAmount(amounts))}
            </div>
          )}
        </div>

        <div className="Title__settings">
          <ButtonIcon
            tooltipText={"Выгрузка&nbsp;истории заказов"}
            iconName={"custom-download"}
            iconColor={"#202020"}
            size={26}
            style={{ marginLeft: 12 }}
            onClick={() => setIsModalOrderHistoryExport(true)}
          />

          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 12 }}
            onClick={() => (setObject(null), setIsUpdateObject(!isUpdateObject))}
          />
        </div>
      </div>

      {!object && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}

      {!!object && (
      <div className="Page__content">

          {!!listOrderStateGroups &&
            listOrderStateGroups.map((group, index) => (
              <div className={styles._block} key={'group_'+index}>
                <div className={styles._block__title}>{group.name} (
                  {getCountGroup(group, object.order_states)
                  } | {
                    formatAmount(getAmountGroup(group, object.order_states))
                })</div>
                <div className={styles._block__content}>
                  {object.order_states.map((state, index) => (
                      isViewOrderStateGroup(group, state) &&
                      <NavLink
                        to={"/orders/state/" + state.uuid}
                        key={"state_" + index}
                        onClick={!!amounts && (e => (e.preventDefault(), addOrDeleteAmount(state.uuid)))}
                      >
                        <div className={styles._card} style={{
                          backgroundColor: !!state.color ? state.color+"25" : null,
                          border: isAmount(state.uuid) && "2px solid #0064ff"
                        }}>
                          <div className={styles._card__number}>{state.count}</div>
                          <div className={styles._card__title}>{state.name}</div>
                          <div className={styles._card__amount}>
                            {formatAmount(state.amount)}
                          </div>
                        </div>
                      </NavLink>
                    ))}
                </div>
              </div>
            ))
          }

          <div className={styles._block}>
            <div className={styles._block__content}>
            {!!object && !!object.order_states && object.order_states.map((state, index) => (
                  isViewOrderStateNotGroups(listOrderStateGroups, state) &&
                  <NavLink
                    to={"/orders/state/" + state.uuid}
                    key={"state_" + index}
                    onClick={!!amounts && (e => (e.preventDefault(), addOrDeleteAmount(state.uuid)))}
                  >
                    <div className={styles._card} style={{
                      backgroundColor: !!state.color ? state.color+"25" : null,
                      border: isAmount(state.uuid) && "2px solid #0064ff"
                    }}>
                      <div className={styles._card__number}>{state.count}</div>
                      <div className={styles._card__title}>{state.name}</div>
                      <div className={styles._card__amount}>
                        {formatAmount(state.amount)}
                      </div>
                    </div>
                  </NavLink>
                ))}
            </div>
          </div>

        </div>
      )}

      {modalOrderHistoryExport && (
        <ModalOrderHistoryExport
          handleClose={() => setIsModalOrderHistoryExport(false)}
        />
      )}

    </div>
    </ScrollBlock>
  );
}

export default ViewOrdersPage;
