import React, {useState} from "react";
import {ButtonIcon, Loading} from "@vokymlak/kabinet-ui";
import moment from "moment/moment"
import ModalOrdersImport from "./ModalOrdersImport";
import ReactECharts from "echarts-for-react";
import ModalBuyerOrderStateEdit from "../BuyerOrderObject/ModalBuyerOrderStateEdit";
import ModalBuyerOrderCommentEdit from "../BuyerOrderObject/ModalBuyerOrderCommentEdit";
import {BuyerOrderObject} from "../BuyerOrderObject";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import ModalImportOrdersScheduler from "./ModalImportOrdersScheduler";

function ImportOrdersPage(props) {
  const {
    list,
    setList,
    isUpdateList,
    setIsUpdateList,

    listCalendar,
    setListCalendar,
    isUpdateListCalendar,
    setIsUpdateListCalendar,

    changeSelectMonthMinus,
    changeSelectMonthPlus,
    changeSelectDay,
    monthText,
    weekDayText,
    selectMonth,
    selectYear,
    days,
    selectDate,
    toDay,

    isDayMarker,
    getDayCount,

    isModalObjectAdd,
    setIsModalObjectAdd,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    getAllCount,

    option,

    setModalBuyerOrderStateEdit,
    modalBuyerOrderStateEdit,

    setModalBuyerOrderCommentEdit,
    modalBuyerOrderCommentEdit,
  } = props;

  const getAllAmount = (list) => {
    return list.map(c => c.count_amounts).reduce((a, b) => a + b, 0)
  }

  const getAmount = (list) => {
    return list.map(c => c.amount).reduce((a, b) => a + b, 0)
  }

  const [isModalImportOrdersScheduler, setIsModalImportOrdersScheduler] = useState(false);

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 21,
            fontWeight: 700,
          }}
        >
          Заказы
          {!!listCalendar && (
            <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({getAllCount(listCalendar)})
              </span>
          )}
          {!!listCalendar && (
            <span style={{
              marginLeft: 12,
              fontSize: 21,
            }}>
                          {(Number(getAllAmount(listCalendar)
                          ) / 100).toFixed(2)}
              <span
                style={{
                  marginLeft: 2,
                  fontSize: 12,
                  fontWeight: 400,
                  opacity: 0.75,
                }}
              >
                            руб.
                          </span>

                        </span>
          )}
        </div>

        <div className="Title__settings">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
              marginLeft: 12,
            }}
          >
            {monthText(selectMonth)}
            <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                {selectYear}
              </span>
            <ButtonIcon
              tooltipText={"Назад"}
              iconName={"custom-circle-arrow-left"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 12 }}
              onClick={() => changeSelectMonthMinus()}
            />
            <ButtonIcon
              tooltipText={"В&nbsp;перёд"}
              iconName={"custom-circle-arrow-right"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 6 }}
              onClick={() => changeSelectMonthPlus()}
            />
          </div>
          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 12 }}
            onClick={() => (
              setList(null),
                setListCalendar(null),
                setIsUpdateList(!isUpdateList),
                setIsUpdateListCalendar(!isUpdateListCalendar)
            )}
          />

          <ButtonIcon
            tooltipText={"Планировщик заказов покупателя"}
            iconName={"custom-priority"}
            iconColor={"#202020"}
            size={24}
            style={{ marginLeft: 12 }}
            onClick={() => setIsModalImportOrdersScheduler(true)}
          />


          <ButtonIcon
            tooltipText={"Импорт"}
            iconName={"custom-cloud-upload"}
            iconColor={"#202020"}
            size={26}
            style={{ marginLeft: 12 }}
            onClick={() => setIsModalObjectAdd(true)}
          />
        </div>
      </div>

      <div
        className="Page__title"
        style={{
          marginBottom: 72,
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {!!days &&
          days.map((day, index) => (
            <div
              key={index}
              onClick={() => changeSelectDay(day.day)}
              style={{
                backgroundColor: "white",
                borderRadius: 7,
                padding: "3px 7px",
                minWidth: 56,
                margin: 2,
                cursor: "pointer",
                border:
                  Number(moment(selectDate, "YYYY-MM-DD").format("DD")) ===
                    Number(day.day) &&
                  Number(moment(selectDate, "YYYY-MM-DD").format("MM")) ===
                    Number(selectMonth) &&
                  Number(moment(selectDate, "YYYY-MM-DD").format("YYYY")) ===
                    Number(selectYear)
                    ? Number(toDay.format("DD")) === Number(day.day) &&
                      Number(toDay.format("MM")) === Number(selectMonth) &&
                      Number(toDay.format("YYYY")) === Number(selectYear)
                      ? "3px solid #f44336"
                      : "3px solid #202020"
                    : "3px solid white",
                position: "relative",
                opacity:
                  moment(
                    selectYear + "-" + selectMonth + "-" + day.day,
                    "YYYY-MM-DD"
                  ).weekday() === 0 ||
                  moment(
                    selectYear + "-" + selectMonth + "-" + day.day,
                    "YYYY-MM-DD"
                  ).weekday() === 6
                    ? 0.5
                    : 1,
              }}
            >
              <div
                style={{
                  color:
                    Number(toDay.format("DD")) === Number(day.day) &&
                    Number(toDay.format("MM")) === Number(selectMonth) &&
                    Number(toDay.format("YYYY")) === Number(selectYear)
                      ? "#f44336"
                      : "#202020",
                  fontSize: 11,
                  textTransform: "capitalize",
                }}
              >
                {weekDayText(
                  moment(
                    selectYear + "-" + selectMonth + "-" + day.day,
                    "YYYY-MM-DD"
                  ).weekday()
                )}
              </div>
              <div
                style={{
                  color:
                    Number(toDay.format("DD")) === Number(day.day) &&
                    Number(toDay.format("MM")) === Number(selectMonth) &&
                    Number(toDay.format("YYYY")) === Number(selectYear)
                      ? "#f44336"
                      : "#202020",
                  fontSize: 17,
                  fontWeight: 700,
                }}
              >
                {day.day}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 3,
                  right: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontSize: 8,
                    marginRight: 3,
                  }}
                >
                  {getDayCount(listCalendar, selectYear, selectMonth, day.day)}
                </div>
                {isDayMarker(
                  listCalendar,
                  selectYear,
                  selectMonth,
                  day.day
                ) && (
                  <div
                    className={"Marker Marker_small"}
                    style={{
                      backgroundColor: "#0064ff",
                    }}
                  ></div>
                )}
              </div>
            </div>
          ))}
      </div>

      <ReactECharts
        option={option}
        style={{ height: "192px", width: "100%" }}
      />

      <hr className={"Page__hr"} />

      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            fontSize: 21,
            fontWeight: 900,
          }}
        >
          {moment(selectDate, "YYYY-MM-DD").format("DD.MM.YYYY")}

          {!!list && (
            <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({list.filter(item => !item.is_excepted_state).length} / {list.filter(item => item.is_excepted_state).length})
              </span>
          )}
          {!!list && (
            <span style={{
              marginLeft: 12,
              fontSize: 21,
            }}>
                          {(Number(getAmount(list.filter(item => !item.is_excepted_state))
                          ) / 100).toFixed(2)}
              <span
                style={{
                  marginLeft: 2,
                  fontSize: 12,
                  fontWeight: 400,
                  opacity: 0.75,
                }}
              >
                            руб.
                          </span>

                        </span>
          )}
        </div>

        <div className="Title__settings">
          {!!list && (
            <ButtonIcon
              tooltipText={
                list.length === unrolls.length ? "Свернуть" : "Развернуть"
              }
              iconName={
                list.length === unrolls.length
                  ? "custom-unfold-less"
                  : "custom-unfold-more"
              }
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8, marginRight: 8 }}
              onClick={() => changeAllUnroll(list, "uuid")}
            />
          )}
        </div>
      </div>

      {!!list && (
        <div className="Page__content">
          <ul className="List">
            {!!list &&
              list.map((item) => (
                <BuyerOrderObject
                  key={"item_" + item.uuid}
                  object={item}
                  isUnroll={isUnroll}
                  changeUnroll={changeUnroll}
                  searchQuery={''}
                  isUpdateList={isUpdateList}
                  setIsUpdateList={setIsUpdateList}
                />
              ))}
          </ul>
        </div>
      )}

      {isDayMarker(listCalendar, selectYear, selectMonth, selectDate.substr(-2))
        ? !list && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
          )
        : !list && (
            <div className="Page__content">
              <ul className="List">
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "100px 0",
                    fontSize: 48,
                    opacity: 0.1,
                  }}
                >
                  Заказов нет
                </div>
              </ul>
            </div>
          )}

      {!!isModalObjectAdd && (
        <ModalOrdersImport
          month={selectYear + "-" + selectMonth + "-01"}
          day={selectDate}
          successfulRequest={() => (
            setIsUpdateList(!isUpdateList),
            setIsUpdateListCalendar(!isUpdateListCalendar)
          )}
          handleClose={() => setIsModalObjectAdd(false)}
        />
      )}

      {!!modalBuyerOrderStateEdit && (
        <ModalBuyerOrderStateEdit
          buyerOrder={modalBuyerOrderStateEdit}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalBuyerOrderStateEdit(null)}
        />
      )}

      {!!modalBuyerOrderCommentEdit && (
        <ModalBuyerOrderCommentEdit
          buyerOrder={modalBuyerOrderCommentEdit}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalBuyerOrderCommentEdit(null)}
        />
      )}

        {isModalImportOrdersScheduler && (
          <ModalImportOrdersScheduler
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalImportOrdersScheduler(false)}
          />
        )}
    </div>
    </ScrollBlock>
  );
}

export default ImportOrdersPage;
