import React, {Fragment} from "react";
import {ModalAddEmployee} from "./ModalAddEmployee";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {Avatar} from "../../../components/Avatar/Avatar";
import {
  ButtonIcon,
  getAccountFio,
  isProfileRoleTypeTags,
  Loading,
  Marker,
  Pagination,
  Search,
  Select,
  TextSearchMarker,
} from "@vokymlak/kabinet-ui";
import moment from "moment";
import {ModalEditEmployee} from "./ModalEditEmployee";
import {ROLE_ADMINISTRATOR, ROLE_DIRECTOR, ROLE_EMPLOYEE} from "../../../config/constants";
import {ROOT_URL} from "../../../config/url";
import {EmployeeScheduledAccessView} from "./customs/EmployeeScheduledAccessView";
import EmployeeTimeTrackingView from "./customs/EmployeeTimeTrackingView";
import {ModalAddAccountRoleType} from "../Accounts/ModalAddAccountRoleType";
import {ModalAddAccountOpportunityType} from "./ModalAddAccountOpportunityType";

export const EmployeesPage = (props) => {
  const {
    profile,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    isActivatedAccount,

    modalEditAccount,
    setModalEditAccount,
    isModalAddAccount,
    setIsModalAddAccount,

    selectRoleType,
    changeSelectRoleType,

    deleteAccountOpportunityType,

    modalAddAccountOpportunityType,
    setModalAddAccountOpportunityType
  } = props;

  function isMD5Hash(str) {
    // Проверяем, соответствует ли строка формату MD5 хэша.
    const md5Regex = /^[a-f0-9]{32}$/i;
    return md5Regex.test(str);
  }

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Сотрудники
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
            )}
            <span style={{width: 220, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>

            <span style={{width: 240, marginLeft: 16, fontWeight: 400}}>
              <Select
                isFixed={true}
                options={[
                  {
                    value: "null",
                    title: "Все",
                  },
                  {
                    value: ROLE_EMPLOYEE,
                    title: "Сотрудник",
                  },
                  {
                    value: ROLE_DIRECTOR,
                    title: "Руководитель",
                  },
                ]}
                label="Роль"
                value={selectRoleType}
                onChange={(event) => changeSelectRoleType(event.target.value)}
              />
            </span>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{marginLeft: 8}}
                onClick={() => changeAllUnroll(list, "created_at")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 1000,
                      title: 1000,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            {isProfileRoleTypeTags(profile, [
              ROLE_ADMINISTRATOR,
              ROLE_DIRECTOR,
            ]) && (
              <ButtonIcon
                tooltipText={"Добавить"}
                iconName={"custom-add"}
                iconColor={"#0064ff"}
                size={36}
                style={{marginLeft: 12}}
                onClick={() => setIsModalAddAccount(true)}
              />
            )}
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.account.uuid}>
                  <li
                    className={
                      isUnroll(item.created_at)
                        ? "List__item active"
                        : "List__item"
                    }
                    onClick={() => changeUnroll(item.created_at)}
                    style={{
                      paddingLeft: 8,
                      position: "relative",
                      marginBottom: 12
                    }}
                  >
                    <div className="Item__title">
                      <div className="Element__title">
                        <div style={{
                          height: 36
                        }}>
                          {!!item.account.avatar && isMD5Hash(item.account.avatar) && (
                            <img height={36} width={36} style={{
                              borderRadius: 36,
                            }} src={ROOT_URL + "/api/account/avatar/" + item.account.avatar}/>
                          )}
                          {!item.account.avatar && (
                            <Avatar size={36} value={getAccountFio(item.account)}/>
                          )}
                        </div>

                        <div style={{marginLeft: 8}}>
                          <div>
                            <TextSearchMarker
                              text={getAccountFio(item.account)}
                              search={searchQuery}
                            />
                            <span
                              style={{
                                fontSize: 11,
                                opacity: 0.75,
                                marginLeft: 4
                              }}
                            >
                              {!!item.account.position &&
                                "(" + item.account.position + ")"}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 12,
                              opacity: 0.5,
                            }}
                          >
                            {!!item.account.login && (
                              <div
                                style={{
                                  fontSize: 11,
                                  fontWeight: 700,
                                  backgroundColor: "#202020",
                                  display: "inline-block",
                                  color: "white",
                                  padding: "2px 6px",
                                  borderRadius: 24,
                                  marginRight: 4,
                                }}
                              >
                                {item.account.login}
                              </div>
                            )}

                            {!!item.account.email && (
                              <div
                                style={{
                                  fontSize: 11,
                                  fontWeight: 700,
                                  backgroundColor: "#202020",
                                  display: "inline-block",
                                  color: "white",
                                  padding: "2px 6px",
                                  borderRadius: 24,
                                  marginRight: 4,
                                }}
                              >
                                {item.account.email}
                              </div>
                            )}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            top: -8,
                            left: 0,
                            justifyContent: " flex-end",
                            width: "100%",
                            padding: "0 8px",
                          }}
                        >
                          {item.account.is_activated && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#4cae50",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Доступ открыт
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">
                      <div
                        style={{
                          display: "inline-block",
                          backgroundColor: "#202020",
                          padding: "4px 8px",
                          fontSize: 12,
                          borderRadius: 24,
                          color: "#e1e1e1",
                          marginRight: 12,
                          fontWeight: 700,
                        }}
                      >
                        {item.role_type.name}
                      </div>
                      <div className="Element__datetime">
                        {!!item.account.last_action_in &&
                          moment(
                            item.account.last_action_in.substring(0, 19)
                          ).format("HH:mm DD.MM.YYYY")}
                        <Marker
                          size={10}
                          style={{
                            marginLeft: 8,
                            marginRight: 4,
                          }}
                          color={
                            !!item.account.last_action_in
                              ? moment()
                                .subtract(5, "minutes")
                                .isBefore(
                                  moment(
                                    item.account.last_action_in.substring(
                                      0,
                                      19
                                    )
                                  )
                                )
                                ? "#4caf50"
                                : "#9e9e9e"
                              : "#9e9e9e"
                          }
                        />
                      </div>
                    </div>
                  </li>

                  {isUnroll(item.created_at) && (
                    <li className="List__content">
                      {isProfileRoleTypeTags(profile, [
                        ROLE_ADMINISTRATOR,
                        ROLE_DIRECTOR,
                      ]) && (
                        <div className="Content__header">
                          <div className="Header__title">
                            <div
                              className="Button__text Button__text_right"
                              onClick={() => setModalAddAccountOpportunityType(item.account)}
                            >
                              Добавить доступ
                            </div>
                            <EmployeeScheduledAccessView employee={item.account}/>
                            <EmployeeTimeTrackingView employee={item.account}/>
                          </div>
                          <div className="Header__settings">
                            <div className="Button__swith">
                              <div className="Swith__text">Доступ к работе</div>
                              <div
                                className={
                                  "Swith" +
                                  ((!!item.account.is_activated &&
                                      " Swith_active ") ||
                                    "")
                                }
                                onClick={() =>
                                  isActivatedAccount(item.account.uuid)
                                }
                              ></div>
                            </div>
                            {isProfileRoleTypeTags(profile, [
                              ROLE_ADMINISTRATOR,
                              ROLE_DIRECTOR,
                            ]) && (
                              <ButtonIcon
                                tooltipText={"Редактировать"}
                                iconName={"custom-edit"}
                                iconColor={"#202020"}
                                size={36}
                                style={{marginLeft: 12}}
                                onClick={() => setModalEditAccount(item.account)}
                              />
                            )}
                          </div>
                        </div>
                      )}

                      {isProfileRoleTypeTags(profile, [
                        ROLE_ADMINISTRATOR,
                        ROLE_DIRECTOR,
                      ]) && (
                        <table className="Table">
                          <tbody>
                          {item.account.account_opportunities &&
                            item.account.account_opportunities.map((opportunity, index) => (
                              <tr
                                className="Table__tr"
                                key={
                                  item.uuid + ":" + opportunity.uuid + ":" + index
                                }
                              >
                                <td className="Table__td">
                                  <div className="Td__title">
                                    {opportunity.opportunity_type.name}
                                  </div>
                                </td>
                                <td className="Table__td Table__td_child">
                                  {profile.uuid !== item.uuid && (
                                    <div
                                      style={{
                                        height: 32,
                                        display: "flex",
                                        marginLeft: 12,
                                      }}
                                    >
                                      <ButtonIcon
                                        tooltipText={"Удалить"}
                                        iconName={"custom-delete"}
                                        iconColor={"#0064ff"}
                                        size={32}
                                        onClick={() =>
                                          deleteAccountOpportunityType(opportunity.uuid)
                                        }
                                      />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalEditAccount && (
          <ModalEditEmployee
            account={modalEditAccount}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalEditAccount(null)}
          />
        )}

        {!!isModalAddAccount && (
          <ModalAddEmployee
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalAddAccount(false)}
          />
        )}

        {!!modalAddAccountOpportunityType && (
          <ModalAddAccountOpportunityType
            account={modalAddAccountOpportunityType}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalAddAccountOpportunityType(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
};
