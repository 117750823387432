import React, {Fragment, useState} from "react";
import {
  Pagination,
  TextSearchMarker,
  Loading,
  Search,
  Select,
  ButtonIcon,
} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import moment from "moment/moment";
import ModalSiteOptOrderAdd from "./ModalSiteOptOrderAdd";
import ModalSiteOptOrderEdit from "./ModalSiteOptOrderEdit";
import {ModalAddSiteOptOrderItem} from "./ModalAddSiteOptOrderItem";
import ModalSiteOptOrderItemEdit from "./ModalSiteOptOrderItemEdit";
import ModalSiteOptOrderEditAction from "./ModalSiteOptOrderEditAction";
import ModalSiteOrderToSentMail from "./ModalSiteOrderToSentMail";
import ModalSendSiteOrderScheduler from "./ModalSendSiteOrderScheduler";

function SiteOptOrdersPage(props) {
  const {
    list,
    count,
    setList,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    // isPublishedNomenclature,
  } = props;


  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(null);
  const [modalOrderItemAdd, setModalOrderItemAdd] = useState(null);
  const [modalOrderItemEdit, setModalOrderItemEdit] = useState(null);
  const [modalOrderEditAction, setModalOrderEditAction] = useState(null);
  const [modalOrderEditPayment, setModalOrderEditPayment] = useState(null);
  const [modalOrderEditDelivery, setModalOrderEditDelivery] = useState(null);
  const [modalSiteOrderSentMail, setModalSiteOrderSentMail] = useState(null);
  const [isModalSendSiteOrderScheduler, setIsModalSendSiteOrderScheduler] = useState(false);

  function extractNumbers(str) {
    return str.replace(/\D/g, "");
  }

  function formatString(number) {
    if (number.length < 11) {
      return number;
    }

    if (number[0] === '8') {
      number = "7" + number.slice(1);
    }

    return "+" + number[0] + " (" + number.slice(1, 4) + ") " + number.slice(4, 7) + "-" + number.slice(7, 9) + "-" + number.slice(9);
  }

  function getAddressOrder(item) {
    const address = []

    if (!!item.city) {
      address.push(item.city)
    }

    if (!!item.address) {
      address.push(item.address)
    }

    if (!!item.street) {
      address.push(item.street)
    }
    if (!!item.house) {
      address.push('дом ' + item.house)
    }
    if (!!item.apartment) {
      address.push('квартира/офис ' + item.apartment)
    }
    if (!!item.entrance) {
      address.push('подъезд ' + item.entrance)
    }
    if (!!item.floor) {
      address.push('этаж ' + item.floor)
    }


    return address.length > 0 ? address.join(', ') : null
  }

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Заказы по опту
            {!!list && (
              <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({count})
              </span>
            )}
            <span style={{ width: 220, marginLeft: 16 }}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{ marginLeft: 8 }}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 10,
                      title: 10,
                    },
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 500,
                      title: 500,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{ marginLeft: 8 }}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Планировщик отправки заказов"}
              iconName={"custom-priority"}
              iconColor={"#202020"}
              size={24}
              style={{marginLeft: 12}}
              onClick={() => setIsModalSendSiteOrderScheduler(true)}
            />

            {/*<ButtonIcon*/}
            {/*  tooltipText={"Добавить"}*/}
            {/*  iconName={"custom-add"}*/}
            {/*  iconColor={"#0064ff"}*/}
            {/*  size={36}*/}
            {/*  style={{ marginLeft: 12 }}*/}
            {/*  onClick={() => setIsModalObjectAdd(true)}*/}
            {/*/>*/}
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid) ? "List__item active" : "List__item"
                    }
                    onClick={() => changeUnroll(item.uuid)}
                    style={{
                      padding: "10px 12px",
                      backgroundColor: item.is_publish && "#ffe082",
                      position: "relative",
                    }}
                  >
                    <div
                      className="Item__title"
                      style={{
                        width: "100%",
                      }}
                    >
                      <div
                        className="Element__title"
                        style={{
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 19,
                            fontWeight: 700,
                            width: "100%",
                          }}
                        >
                          №<TextSearchMarker
                          text={item.id}
                          search={searchQuery}
                        />{!!item.date && ' от ' + moment(item.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
                        </div>

                        <div
                          style={{
                            fontSize: 15,
                            fontWeight: 500,
                            width: "100%",
                            marginTop: 4
                          }}
                        >
                          <div style={{
                            backgroundColor: "#20202015",
                            padding: "4px 8px 6px",
                            borderRadius: 5,
                            marginBottom: 4
                          }}>
                            {!!item.buyer_type && (
                              <span style={{marginRight: 8}}>
                                {item.buyer_type === 'PERSON' && 'Физ.лицо'}
                                {item.buyer_type === 'ENTREPRENEUR' && 'Инд. предприниматель'}
                                {item.buyer_type === 'COMPANY' && 'Компания'}
                              </span>
                            )}
                            {!!item.inn && (
                              <span style={{marginRight: 8}}>
                                <TextSearchMarker
                                  text={item.inn}
                                  search={searchQuery}/>
                              </span>
                            )}
                          </div>

                          {(!!item.buyer_name || !!item.buyer_phone || !!item.buyer_email) && (
                            <div
                              style={{
                                backgroundColor: "#20202015",
                                padding: "4px 8px 6px",
                                borderRadius: 5,
                                marginBottom: 4
                              }}
                            >
                              {!!item.buyer_name &&
                                <span style={{marginRight: 8}}><TextSearchMarker text={item.buyer_name}
                                                                                 search={searchQuery}/></span>}
                              {!!item.buyer_phone && <span style={{marginRight: 8}}><TextSearchMarker
                                text={formatString(extractNumbers(item.buyer_phone))} search={searchQuery}/></span>}
                              {!!item.buyer_email &&
                                <span style={{marginRight: 8}}><TextSearchMarker text={item.buyer_email}
                                                                                 search={searchQuery}/></span>}
                            </div>
                          )}
                          {(!!item.first_name || !!item.last_name || !!item.patronymic_name || !!item.phone || !!item.email) && (
                            <div
                              style={{
                                backgroundColor: "#20202015",
                                padding: "4px 8px 6px",
                                borderRadius: 5,
                                marginBottom: 4
                              }}
                            >
                              {!!item.first_name &&
                                <span style={{marginRight: 8}}><TextSearchMarker text={item.first_name}
                                                                                 search={searchQuery}/></span>}
                              {!!item.last_name &&
                                <span style={{marginRight: 8}}><TextSearchMarker text={item.last_name}
                                                                                 search={searchQuery}/></span>}
                              {!!item.patronymic_name &&
                                <span style={{marginRight: 8}}><TextSearchMarker text={item.patronymic_name}
                                                                                 search={searchQuery}/></span>}

                              {!!item.phone && <span style={{marginRight: 8}}><TextSearchMarker
                                text={formatString(extractNumbers(item.phone))} search={searchQuery}/></span>}

                              {!!item.email && <span style={{marginRight: 8}}><TextSearchMarker text={item.email}
                                                                                                search={searchQuery}/></span>}
                            </div>
                          )}
                          {(item.is_pickup || !!item.pickup_address) && (
                            <div
                              style={{
                                backgroundColor: "#0061ff33",
                                padding: "6px 8px 6px",
                                borderRadius: 5,
                                marginBottom: 4
                              }}
                            >
                              <div style={{
                                fontSize: 13
                              }}>Самовывоз
                              </div>
                              {!!item.pickup_address &&
                                <div style={{marginRight: 8}}><TextSearchMarker text={item.pickup_address}
                                                                                search={searchQuery}/></div>}
                            </div>
                          )}
                          {!!getAddressOrder(item) && (
                            <div style={{
                              backgroundColor: "#0061ff33",
                              padding: "6px 8px 6px",
                              borderRadius: 5,
                              marginBottom: 4
                            }}
                            >
                              <div style={{
                                fontSize: 13
                              }}>Доставка
                              </div>
                              <div style={{
                                marginRight: 2,
                                fontSize: 15,
                              }}>
                                <TextSearchMarker
                                  text={getAddressOrder(item)}
                                  search={searchQuery}
                                />
                              </div>
                            </div>
                          )}

                          {!!item.note && (
                            <div
                              style={{
                                backgroundColor: "#20202015",
                                padding: "6px 8px 6px",
                                borderRadius: 5,
                                marginBottom: 4
                              }}
                            >
                              <div style={{
                                fontSize: 13
                              }}>Комментарий к заказу
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  marginTop: 2,
                                }}
                              >
                                <TextSearchMarker text={item.note}
                                                  search={searchQuery}/>
                              </div>
                            </div>
                          )}

                          <div style={{
                            backgroundColor:
                              item.payment_type !== 'CASH' && item.payment_type !== 'CARD' &&
                              item.payment_type !== 'ONLINE' ? 'rgba(175,76,99,0.2)' :
                            "#4CAF5033",
                            padding: "6px 8px 6px",
                            borderRadius: 5,
                            marginBottom: 4
                          }}
                          >
                            <div style={{
                              fontSize: 13
                            }}>Оптала
                            </div>
                            <div style={{
                              marginRight: 2,
                              fontSize: 15,
                            }}>
                              {item.payment_type === 'CASH' && 'Наличными'}
                              {item.payment_type === 'CARD' && 'Картой'}
                              {item.payment_type === 'ONLINE' && 'Онлайн'}
                              {item.payment_type !== 'CASH' && item.payment_type !== 'CARD' &&
                                item.payment_type !== 'ONLINE' && 'Не указана'}
                            </div>
                          </div>

                          {(item.is_paid || !!item.payment_token) && (
                            <div style={{
                              backgroundColor: "#ffc10733",
                              padding: "6px 8px 6px",
                              borderRadius: 5,
                              marginBottom: 4
                            }}>
                              <div style={{
                                fontSize: 13
                              }}>Платёж
                              </div>
                              {item.payment_token &&
                                <div style={{marginRight: 8}}>
                                  <TextSearchMarker text={item.payment_token}
                                                    search={searchQuery}/></div>}
                            </div>
                          )}

                        </div>

                        {!!item.final_amount && (
                          <div
                            style={{
                              width: "100%",
                              marginTop: 4,
                              fontWeight: 700,
                              fontSize: 24
                            }}
                          >
                          <span style={{marginRight: 16}}>
                            {!!item.final_amount && new Intl.NumberFormat("ru-RU").format(Math.round(Number(
                              (item.final_amount / 100).toFixed(2))))}

                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>
                            {!!item.final_amount && "(" + new Intl.NumberFormat("ru-RU").format(Number(
                              (item.final_amount / 100).toFixed(2))) + ")"}
                            </span>

                            <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                        </div>
                          )}

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            top: -8,
                            left: 0,
                            justifyContent: " flex-end",
                            width: "100%",
                            padding: "0 8px",
                          }}
                        >

                          {!!item.yclid && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#db9a00",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Рекламная
                            </div>
                          )}

                          {!!item.date_sent_mail && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: item.is_sending_mail_error ? "#cf2f00" : "#0061ff",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              {item.is_sending_mail_error ? "Ошибка отправки" : "Отправлено"}:
                              {' '}
                              {moment(item.date_sent_mail.substring(0, 19)).format("YYYY.MM.DD HH:mm:ss")}
                            </div>
                          )}



                          {!!item.site_opt_order_status_type && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: item.site_opt_order_status_type.tag === 'AT_WORK' ? "#202020" :
                                  item.site_opt_order_status_type.tag === 'NEW' ? "#2e7d32" : "#dddddd",
                                display: "inline-block",
                                color: item.site_opt_order_status_type.tag === 'AT_WORK' ? "white" :
                                  item.site_opt_order_status_type.tag === 'NEW' ? "white" : "#202020",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              {item.site_opt_order_status_type.name}
                            </div>
                          )}

                          {item.is_paid && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#ffe082",
                                display: "inline-block",
                                color: "#202020",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Оплачен!
                            </div>
                          )}

                          {/*{!!item.date_delivery && (*/}
                          {/*  <div*/}
                          {/*    style={{*/}
                          {/*      fontSize: 11,*/}
                          {/*      fontWeight: 700,*/}
                          {/*      backgroundColor: "#0064ff",*/}
                          {/*      display: "inline-block",*/}
                          {/*      color: "white",*/}
                          {/*      padding: "2px 4px 2px",*/}
                          {/*      borderRadius: 3,*/}
                          {/*      margin: "0 2px",*/}
                          {/*    }}*/}
                          {/*  >*/}
                          {/*    Вручён: {moment(item.date_delivery.substring(0, 19)).format("DD.MM.YYYY HH:mm")}*/}
                          {/*  </div>*/}
                          {/*)}*/}


                          {item.is_pickup && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#9c27b0",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Самовывоз
                            </div>
                          )}

                          {!item.is_pickup && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#202020",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Доставка
                            </div>
                          )}


                          {/*{!!item.by_time && (*/}
                          {/*  <div*/}
                          {/*    style={{*/}
                          {/*      fontSize: 11,*/}
                          {/*      fontWeight: 700,*/}
                          {/*      backgroundColor: "#f58ea6",*/}
                          {/*      display: "inline-block",*/}
                          {/*      color: "#202020",*/}
                          {/*      padding: "2px 4px 2px",*/}
                          {/*      borderRadius: 3,*/}
                          {/*      margin: "0 2px",*/}
                          {/*    }}*/}
                          {/*  >*/}
                          {/*    Ко времени: {moment(item.by_time.substring(0, 19)).format("DD.MM.YYYY HH:mm")}*/}
                          {/*  </div>*/}
                          {/*)}*/}

                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime">
                      </div>
                    </div>
                  </li>

                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      <div className="Content__header">
                        <div className="Header__title">
                          {item.payment_type !== 'ONLINE' && (
                            <div
                              className="Button__text Button__text_right"
                              onClick={() => setModalOrderItemAdd(item)}
                            >
                              Добавить позицию
                            </div>
                          )}
                          <div
                            className="Button__text Button__text_right"
                            onClick={() => setModalOrderEditAction(item)}
                          >
                            Изменить действие
                          </div>
                          {/*<div*/}
                          {/*  className="Button__text Button__text_right"*/}
                          {/*  onClick={() => setModalOrderEditPayment(item)}*/}
                          {/*>*/}
                          {/*  Отметка об оплате*/}
                          {/*</div>*/}
                          {/*<div*/}
                          {/*  className="Button__text Button__text_right"*/}
                          {/*  onClick={() => setModalOrderEditDelivery(item)}*/}
                          {/*>*/}
                          {/*  Отметка о вручении*/}
                          {/*</div>*/}
                        </div>
                        <div className="Header__settings">
                          <div
                            className="Button__text"
                            onClick={() => setModalSiteOrderSentMail(item)}
                          >
                            Отправить на почту
                          </div>
                          {item.is_paid && (
                            <ButtonIcon
                              tooltipText={"Редактировать"}
                              iconName={"custom-edit"}
                              iconColor={"#202020"}
                              size={36}
                              style={{marginLeft: 12}}
                              onClick={() => setModalItemEdit(item)}
                            />
                          )}
                        </div>
                      </div>

                      <div>
                        <table className="Table">
                          <tbody>
                          {!!item.items &&
                            item.items.map((object, index) => (
                              <tr
                                className="Table__tr"
                                key={
                                  item.uuid + ":" + object.uuid + ":" + index
                                }
                                style={{
                                  backgroundColor: (!object.final_amount || !object.price_opt) && "#ff000033",
                                  borderColor: (!object.final_amount || !object.price_opt) && "#ef9a9a"
                                }}
                              >
                                <td className="Table__td">
                                  <div className="Td__title">
                                    {!!object.nomenclature && object.nomenclature.name}
                                    <span style={{
                                      marginLeft: 16,
                                      fontWeight: 700,
                                      fontSize: 24
                                    }}>
                                      {!!object.nomenclature && !!object.nomenclature.value && !!object.nomenclature.measurement_unit_type &&
                                        new Intl.NumberFormat("ru-RU").format(Number((!!object.nomenclature.value && !!object.nomenclature.measurement_unit_type ?
                                          (object.nomenclature.value / object.nomenclature.measurement_unit_type.ratio) : !!object.nomenclature.value && object.nomenclature.value).toFixed(3)))}
                                      <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>
                                      {!!object.nomenclature && !!object.nomenclature.measurement_unit_type && object.nomenclature.measurement_unit_type.designation + '.'}</span>
                                    </span>
                                    <span style={{
                                      marginLeft: 16,
                                      fontWeight: 700,
                                      fontSize: 24,
                                      opacity: !!object.price_sale_opt && 0.5,
                                      textDecoration: !!object.price_sale_opt && "line-through",
                                    }}>{!!object.price_opt && new Intl.NumberFormat("ru-RU").format(Number(
                                      (object.price_opt / 100).toFixed(2)))}<span
                                      style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                                    {!!object.price_sale_opt && (
                                      <span style={{
                                        marginLeft: 16,
                                        fontWeight: 700,
                                        fontSize: 24
                                      }}>{(!!object.price_sale_opt && new Intl.NumberFormat("ru-RU").format(Number(
                                        (object.price_sale_opt / 100).toFixed(2)))) || "-"}
                                        <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                                    )}
                                  </div>
                                </td>
                                <td className="Table__td">
                                  <div className="Td__title">
                                    {!!object.quantity && (
                                      <div className={'Button__collected'}>
                                        <div
                                          className={'Collected__count Collected__count_blue'}>{object.quantity / 1000000}</div>
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td className="Table__td">
                                  <div className="Td__title">
                                    <span style={{
                                      marginRight: 16,
                                      fontWeight: 700,
                                      fontSize: 24
                                    }}>
                                      {!!object.quantity && !!object.nomenclature && !!object.nomenclature.value &&
                                        new Intl.NumberFormat("ru-RU").format(Number((Number(object.quantity / 1000000).toFixed(6) * (!!object.nomenclature.value && !!object.nomenclature.measurement_unit_type ?
                                          (object.nomenclature.value / object.nomenclature.measurement_unit_type.ratio) : !!object.nomenclature.value && object.nomenclature.value)).toFixed(3)))}
                                      <span style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>
                                      {!!object.nomenclature && !!object.nomenclature.measurement_unit_type && object.nomenclature.measurement_unit_type.designation + '.'}</span>
                                    </span>
                                  </div>
                                </td>
                                <td className="Table__td">
                                  <div className="Td__title">
                                    <span style={{
                                      marginRight: 16,
                                      fontWeight: 700,
                                      fontSize: 24
                                    }}>{!!object.final_amount && new Intl.NumberFormat("ru-RU").format(Number(
                                      (object.final_amount / 100).toFixed(2)))}<span
                                      style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>
                                  </div>
                                </td>
                                <td className="Table__td Table__td_child">
                                  <div
                                    style={{
                                      height: 36,
                                      display: "flex",
                                      marginLeft: 12,
                                    }}
                                  >
                                    {item.payment_type !== 'ONLINE' && (
                                      <ButtonIcon
                                        tooltipText={"Редактировать"}
                                        iconName={"custom-edit"}
                                        iconColor={"#202020"}
                                        size={36}
                                        onClick={() =>
                                          setModalOrderItemEdit(object)
                                        }
                                      />
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalItemEdit && (
          <ModalSiteOptOrderEdit
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}
        {!!isModalObjectAdd && (
          <ModalSiteOptOrderAdd
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}

        {!!modalOrderItemAdd && (
          <ModalAddSiteOptOrderItem
            object={modalOrderItemAdd}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalOrderItemAdd(null)}
          />
        )}
        {!!modalOrderItemEdit && (
          <ModalSiteOptOrderItemEdit
            object={modalOrderItemEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalOrderItemEdit(null)}
          />
        )}

        {!!modalOrderEditAction && (
          <ModalSiteOptOrderEditAction
            object={modalOrderEditAction}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalOrderEditAction(null)}
          />
        )}

        {isModalSendSiteOrderScheduler && (
          <ModalSendSiteOrderScheduler
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalSendSiteOrderScheduler(false)}
          />
        )}

        {modalSiteOrderSentMail && (
          <ModalSiteOrderToSentMail
            object={modalSiteOrderSentMail}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalSiteOrderSentMail(null)}
          />
        )}

        {/*{!!modalOrderEditDelivery && (*/}
        {/*  <ModalSiteOptOrderEditDelivery*/}
        {/*    object={modalOrderEditDelivery}*/}
        {/*    successfulRequest={() => setIsUpdateList(!isUpdateList)}*/}
        {/*    handleClose={() => setModalOrderEditDelivery(null)}*/}
        {/*  />*/}
        {/*)}*/}

        {/*{!!modalOrderEditPayment && (*/}
        {/*  <ModalSiteOptOrderEditPayment*/}
        {/*    object={modalOrderEditPayment}*/}
        {/*    successfulRequest={() => setIsUpdateList(!isUpdateList)}*/}
        {/*    handleClose={() => setModalOrderEditPayment(null)}*/}
        {/*  />*/}
        {/*)}*/}
    </div>
    </ScrollBlock>
  );
}

export default SiteOptOrdersPage;
