import React, {useEffect, useRef, useState} from "react";
import {getErrorMessageByKey, Modal, useNotification, useObject} from "@vokymlak/kabinet-ui";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import moment from "moment";
import ModalBuyerOrderStateNewEditConfirm from "./ModalBuyerOrderStateNewEditConfirm";

function ModalBuyerOrderStateNewEdit(props) {
  const [buyerOrder, setBuyerOrder] = useState(props.buyerOrder);

  const inputBuyerOrderStateId = useRef(null);

  const {notification} = useNotification();

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/buyer-order/" + props.buyerOrder.id + "/states",
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  useEffect(() => {
    if (props.buyerOrder) {
      setBuyerOrder(props.buyerOrder);
    }

  }, [props.buyerOrder]);

  useEffect(() => {
    return () => {
      setBuyerOrder(null);
    };
  }, []);

  const changeBuyerOrderState = (buyerOrderStateId) => {
    if (!buyerOrderStateId || buyerOrderStateId.length === 0) {
      return;
    }

    axios
      .get(
        "/api/buyer-order/" + props.buyerOrder.id + "/state/" + buyerOrderStateId,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();
        props.handleClose();
      }).catch((error) => {
      notification(getErrorMessageByKey(error.response.data.error), "red");
    });
  };

  const [modalBuyerOrderStateConfirm, setModalBuyerOrderStateConfirm] = useState(null);

  return (
    object &&
    buyerOrder && (
      <>
        <Modal handleClose={() => props.handleClose()}>
          <div className="Form">
            <div className="Form__title">
              Перевести статус заказа
              <br/>
              <div className="Text__signature">
                {buyerOrder.number} от {!!buyerOrder.date && moment(buyerOrder.date).format("DD.MM.YYYY HH:mm")}
              </div>
            </div>

            {!!object.next && (
              <div className="Form__field">
                <div className="Field__label">На очереди</div>
                {object.next.sort((a, b) => a.name.localeCompare(b.name)).map(item => (
                  <button
                    className="Action__button Action__button_all Action__button_blue"
                    onClick={() => setModalBuyerOrderStateConfirm(item)}
                    style={{
                      backgroundColor: (!!item.color && item.color + "25") || "#dbdbdb50",
                      color: "#202020",
                      border: "none",
                      marginBottom: 12
                    }}>
                    {item.name}
                  </button>
                ))}
              </div>
            )}

            {!!object.others && (
              <div className="Form__field Form__field_last">
                <div className="Field__label">Промежуточные</div>
                {object.others.sort((a, b) => a.name.localeCompare(b.name)).map(item => (
                  <button
                    key={item.uuid}
                    className="Action__button Action__button_all Action__button_blue"
                    onClick={() => setModalBuyerOrderStateConfirm(item)}
                    style={{
                      backgroundColor: (!!item.color && item.color + "25") || "#dbdbdb50",
                      color: "#202020",
                      border: "none",
                      marginBottom: 12
                    }}>
                    {item.name}
                  </button>
                ))}
              </div>
            )}

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => props.handleClose()}>
                Отмена
              </div>
            </div>
          </div>
        </Modal>

        {modalBuyerOrderStateConfirm && (
          <ModalBuyerOrderStateNewEditConfirm
            buyerOrderState={modalBuyerOrderStateConfirm}
            successfulRequest={() => changeBuyerOrderState(String(modalBuyerOrderStateConfirm.id))}
            handleClose={() => setModalBuyerOrderStateConfirm(null)}
          />
        )}
      </>
    )
  );
}

export default ModalBuyerOrderStateNewEdit;
