import React, {Fragment, useState} from "react";
import {
  ButtonIcon,
  Loading,
  Pagination,
  Search,
  Select,
} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {Nomenclature} from "../../ScreenNomenclatures/NomenclatureItem/Nomenclature";
import ModalNomenclatureAdd from "../../ScreenNomenclatures/NomenclatureItem/ModalNomenclatureAdd";
import {ModalAddProductWarehouse} from "./ModalAddProductWarehouse";

export const ProductWarehousePage = (props) => {
  const {
    isEdit,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,

    setIsUpdateList,
    isUpdateList,

    selectNomenclatures,
    setSelectNomenclatures,
  } = props;

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Склад продукции
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
            )}
            <span style={{width: 140, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>

            {!!selectNomenclatures && selectNomenclatures.length > 0 && (
              <div
                style={{
                  backgroundColor: "#0061ff",
                  padding: "2px 3px 4px 8px",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 24,
                  marginLeft: 12,
                }}
              >
              <span
                style={{
                  color: "white",
                  fontSize: 14,
                  pointerEvents: "auto",
                  fontWeight: 700,
                  cursor: "default",
                }}
              >
                {selectNomenclatures.length}
              </span>
                <ButtonIcon
                  style={{
                    marginLeft: 4,
                    width: 18,
                    height: 18
                  }}
                  tooltipText={"Отменить"}
                  iconName={"custom-circle-close"}
                  iconColor={"white"}
                  size={18}
                  onClick={() => setSelectNomenclatures([])}
                />
              </div>
            )}
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{marginLeft: 8}}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 1000,
                      title: 1000,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{marginLeft: 12}}
              onClick={() => setIsModalObjectAdd(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.uuid}>
                  <Nomenclature {...props} item={item} isEdit={isEdit} isTransaction={true} />
                </Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!isModalObjectAdd && (
          <ModalAddProductWarehouse
            successfulRequest={() =>
              setIsUpdateList(!isUpdateList)
            }
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}
