import React from "react";
import { ROOT_URL } from "../../../config/url";
import {
  useAction, useObject,
} from "@vokymlak/kabinet-ui";

function useLeadCharts() {
  const {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject, changeUpdate],
  } = useObject({
    apiUrl: "/api/lead-charts",
    rootUrl: ROOT_URL,
  });

  const {
    object: [objectMonth, setObjectMonth],
    update: [isUpdateObjectMonth, setIsUpdateObjectMonth, changeUpdateMonth],
  } = useObject({
    apiUrl: "/api/lead-charts/month",
    rootUrl: ROOT_URL,
  });

  useAction("lead-charts", () => (changeUpdate(), changeUpdateMonth()));

  return {
    object,
    setObject,

    setIsUpdateObject,
    isUpdateObject,

    objectMonth,
    setObjectMonth,

    setIsUpdateObjectMonth,
    isUpdateObjectMonth,
  };
}

export default useLeadCharts;
