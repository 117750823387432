import React, { useState } from "react";
import { ROOT_URL } from "../../../../config/url";
import axios from "axios";
import { useAction, useList, useUnroll } from "@vokymlak/kabinet-ui";

export const useSiteBlogTopics = () => {
  const {
    list: [list, _count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
  } = useList({ apiUrl: "/api/site-blog-topics", rootUrl: ROOT_URL });

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(null);

  useAction("site-blog", () => updateList());

  const onDragEnd = (result) => {
    if (result.destination) {
      const oldIndex = result.source.index;
      const newIndex = result.destination.index;
      const draggableId = result.draggableId;

      let object = null;

      if (!!list) {
        list.map((item) => {
          if (draggableId === item.uuid) {
            object = {...item};
          }
        });

        setList([...dndArray(list, oldIndex, newIndex)]);

        axios
          .get("/api/site-blog-topic/" + object.uuid + "/index/" + newIndex, {
            withCredentials: false,
            baseURL: ROOT_URL,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("tokenSession"),
            },
          })
          .then(() => {
            setIsUpdateList(!isUpdateList);
          })
          .catch(() => {
            setIsUpdateList(!isUpdateList);
          });
      }
    }
  };

  const dndArray = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  return {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,

    onDragEnd,
  };
};
