import React, {useRef, useState} from "react";
import {isValidateNullText, jsonFormatText, Modal} from "@vokymlak/kabinet-ui";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {getErrorMessageByKey, useNotification, Input, useObject} from "@vokymlak/kabinet-ui";

function ModalEditTimeWorkCategory(props) {
  const [isUnblockWork, setIsUnblockWork] = useState(false);
  const [isConsidered, setIsConsidered] = useState(false);

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/time-work-category/" +props.object.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
      setIsUnblockWork(!!data && data.is_unblock_work);
      setIsConsidered(!!data && data.is_considered);
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputName = useRef(null);
  const inputColor = useRef(null);
  const inputMinOperatingTime = useRef(null);

  const { notification } = useNotification();
  const [isDelete, setIsDelete] = useState(false);

  const changeEdit = (event) => {
    event.preventDefault();

    const name = jsonFormatText(inputName.current.value);
    const color = inputColor.current.value;
    const minOperatingTime = Number(
      jsonFormatText(inputMinOperatingTime.current.value).length > 0
        ? jsonFormatText(inputMinOperatingTime.current.value)
        : '0'
    );

    if (
      isValidateNullText(name)
    ) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      name: name,
      color: color.length > 0 ? color !== "#000000" ? color : null : null,
      is_unblock_work: isUnblockWork,
      min_operating_time: minOperatingTime,
      is_considered: isConsidered,
    });

    axios
      .put(
        "/api/time-work-category/" + props.object.uuid + "/edit",
        jsonData,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/time-work-category/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Аккаунт удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {object.name}
              <div className="Text__signature">Категория работ</div>
            </div>

            <div className="Form__field">
              <Input
                reference={inputName}
                type="text"
                label="Наименование *"
                value={object.name}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputColor}
                type="color"
                label="Цвет"
                isFixed={true}
                value={object.color}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputMinOperatingTime}
                type="number"
                isFixed={true}
                label="Минимальное время работы (секунды)"
                value={object.min_operating_time}
              />
            </div>

            <div className="Form__field">
              <div
                className="Button__swith"
                style={{
                  padding: "8px",
                  width: "100%",
                  border: isUnblockWork
                    ? "2px solid #ffe082"
                    : "2px dashed #20202015",
                  backgroundColor: isUnblockWork ? "#ffe082" : "#ffffff",
                  borderRadius: 6,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setIsUnblockWork(!isUnblockWork)}
              >
                <div
                  className="Swith__text"
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Разблокировать работу
                </div>
                <div
                  className={
                    "Swith Swith_gray" +
                    ((isUnblockWork && " Swith_active Swith_active_gray") || "")
                  }
                ></div>
              </div>
            </div>

            <div className="Form__field Form__field_last">
              <div
                className="Button__swith"
                style={{
                  padding: "8px",
                  width: "100%",
                  border: isConsidered
                    ? "2px solid #ffe082"
                    : "2px dashed #20202015",
                  backgroundColor: isConsidered ? "#ffe082" : "#ffffff",
                  borderRadius: 6,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setIsConsidered(!isConsidered)}
              >
                <div
                  className="Swith__text"
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Учитывать время
                </div>
                <div
                  className={
                    "Swith Swith_gray" +
                    ((isConsidered && " Swith_active Swith_active_gray") || "")
                  }
                ></div>
              </div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
            </div>
          </form>
        )}

        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div className="Form__title">
              {object.name}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Удалить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalEditTimeWorkCategory;
