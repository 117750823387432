import React, {useEffect, useRef, useState} from "react";
import {getErrorMessageByKey, Input, Modal, useNotification} from "@vokymlak/kabinet-ui";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import moment from "moment";
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";

function ModalBuyerOrderCollected(props) {
  const [buyerOrder, setBuyerOrder] = useState(props.buyerOrder);
  const [buyerOrderItem, setBuyerOrderItem] = useState(props.buyerOrderItem);
  const [buyerOrderItemCollecting, setBuyerOrderItemCollecting] =
    useState(null);

  const inputMontageCount = useRef(null);
  const inputWarehouseCount = useRef(null);

  const [montageQuantity, setMontageQuantity] = useState(0);
  const [warehouseQuantity, setWarehouseQuantity] = useState(0);
  const [isStickers, setIsStickers] = useState(false);
  const [isGlued, setIsGlued] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const {notification} = useNotification();

  useEffect(() => {
    return () => {
      setBuyerOrderItemCollecting(null);
      setBuyerOrderItem(null);
      setBuyerOrder(null);
      setMontageQuantity(0);
      setWarehouseQuantity(0);
    };
  }, []);

  useEffect(() => {
    setMontageQuantity(
      (!!buyerOrderItemCollecting &&
        buyerOrderItemCollecting.montage_quantity) ||
      0
    );
    setWarehouseQuantity(
      (!!buyerOrderItemCollecting &&
        buyerOrderItemCollecting.warehouse_quantity) ||
      0
    );
  }, [buyerOrderItemCollecting]);

  useEffect(() => {
    if (!!buyerOrder && !!buyerOrderItem) {
      axios
        .get(
          "/api/buyer-order/" +
          buyerOrder.id +
          "/item/" +
          buyerOrderItem.id +
          "/collecting",
          {
            withCredentials: false,
            baseURL: ROOT_URL,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("tokenSession"),
            },
          }
        )
        .then((response) => {
          setBuyerOrderItemCollecting(response.data ? response.data : null);
        })
        .catch((error) => {
          props.handleClose();

          notification(getErrorMessageByKey(error.response.data.error), "red");
        });
    }
  }, [buyerOrder, buyerOrderItem]);

  useEffect(() => {
    if (!!buyerOrder && !!buyerOrderItem) {
      axios
        .get(
          "/api/buyer-order/" +
          buyerOrder.id +
          "/item/" +
          buyerOrderItem.id +
          "/preparation",
          {
            withCredentials: false,
            baseURL: ROOT_URL,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("tokenSession"),
            },
          }
        )
        .then((response) => {
          setIsStickers(!!response.data ? response.data.is_stickers : false);
        })
        .catch((error) => {
          props.handleClose();

          notification(getErrorMessageByKey(error.response.data.error), "red");
        });
    }
  }, [buyerOrder, buyerOrderItem]);

  useEffect(() => {
    if (!!buyerOrder && !!buyerOrderItem) {
      axios
        .get(
          "/api/buyer-order/" +
          buyerOrder.id +
          "/item/" +
          buyerOrderItem.id +
          "/glued",
          {
            withCredentials: false,
            baseURL: ROOT_URL,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("tokenSession"),
            },
          }
        )
        .then((response) => {
          setIsGlued(!!response.data ? response.data.is_glued : false);
        })
        .catch((error) => {
          props.handleClose();

          notification(getErrorMessageByKey(error.response.data.error), "red");
        });
    }
  }, [buyerOrder, buyerOrderItem]);

  useEffect(() => {
    if (!!buyerOrder && !!buyerOrderItem) {
      axios
        .get(
          "/api/buyer-order/" +
          buyerOrder.id +
          "/item/" +
          buyerOrderItem.id +
          "/inspect",
          {
            withCredentials: false,
            baseURL: ROOT_URL,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("tokenSession"),
            },
          }
        )
        .then((response) => {
          setIsVerified(!!response.data ? response.data.is_verified : false);
        })
        .catch((error) => {
          props.handleClose();

          notification(getErrorMessageByKey(error.response.data.error), "red");
        });
    }
  }, [buyerOrder, buyerOrderItem]);

  useEffect(() => {
    setBuyerOrderItem(props.buyerOrderItem);
  }, [props.buyerOrderItem]);

  useEffect(() => {
    setBuyerOrder(props.buyerOrder);
  }, [props.buyerOrder]);

  const addCollected = () => {

    const montageQuantity = inputMontageCount.current.value;
    const warehouseQuantity = inputWarehouseCount.current.value;

    const jsonData = {
      montage_quantity: Number(montageQuantity) || 0,
      warehouse_quantity: Number(warehouseQuantity) || 0,
    };

    if (jsonData.montage_quantity < 0 || jsonData.warehouse_quantity < 0) {
      notification("Не допускаются отрицательные значения", "red");
      return;
    }

    axios
      .post(
        "/api/buyer-order/" +
        buyerOrder.id +
        "/item/" +
        buyerOrderItem.id +
        "/collecting",
        JSON.stringify(jsonData),
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();

        props.handleClose();
      })
      .catch((err) => {
        notification(getErrorMessageByKey(err.response.data.error), "red");
      });
  };

  const addPreparation = () => {

    const jsonData = {
      is_stickers: isStickers,
    };

    axios
      .post(
        "/api/buyer-order/" +
        buyerOrder.id +
        "/item/" +
        buyerOrderItem.id +
        "/preparation",
        JSON.stringify(jsonData),
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();

        props.handleClose();
      })
      .catch((err) => {
        notification(getErrorMessageByKey(err.response.data.error), "red");
      });
  };

  const addGlued = () => {

    const jsonData = {
      is_glued: isGlued,
    };

    axios
      .post(
        "/api/buyer-order/" +
        buyerOrder.id +
        "/item/" +
        buyerOrderItem.id +
        "/glued",
        JSON.stringify(jsonData),
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();

        props.handleClose();
      })
      .catch((err) => {
        notification(getErrorMessageByKey(err.response.data.error), "red");
      });
  };

  const addInspect = () => {

    const jsonData = {
      is_verified: isVerified,
    };

    axios
      .post(
        "/api/buyer-order/" +
        buyerOrder.id +
        "/item/" +
        buyerOrderItem.id +
        "/inspect",
        JSON.stringify(jsonData),
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();

        props.handleClose();
      })
      .catch((err) => {
        notification(getErrorMessageByKey(err.response.data.error), "red");
      });
  };


  const plusOneMontageQuantity = () => {
    const montageCount = inputMontageCount.current.value;
    setMontageQuantity((Number(montageCount) || 0) + 1);
    inputMontageCount.current.value =
      Math.round(((Number(montageCount) || 0) + 1) * 100000) / 100000;
  };

  const minusOneMontageQuantity = () => {
    const montageCount = inputMontageCount.current.value;
    setMontageQuantity((Number(montageCount) || 0) - 1);
    inputMontageCount.current.value =
      Math.round(((Number(montageCount) || 0) - 1) * 100000) / 100000;
  };

  const numberMontageQuantity = (number) => {
    setMontageQuantity(Number(number) || 0);
    inputMontageCount.current.value =
      Math.round((Number(number) || 0) * 100000) / 100000;
  };

  const plusOneWarehouseQuantity = () => {
    const warehouseCount = inputWarehouseCount.current.value;
    setWarehouseQuantity((Number(warehouseCount) || 0) + 1);
    inputWarehouseCount.current.value =
      Math.round(((Number(warehouseCount) || 0) + 1) * 100000) / 100000;
  };

  const minusOneWarehouseQuantity = () => {
    const warehouseCount = inputWarehouseCount.current.value;
    setWarehouseQuantity((Number(warehouseCount) || 0) - 1);
    inputWarehouseCount.current.value =
      Math.round(((Number(warehouseCount) || 0) - 1) * 100000) / 100000;
  };

  const numberWarehouseQuantity = (number) => {
    setWarehouseQuantity(Number(number) || 0);
    inputWarehouseCount.current.value =
      Math.round((Number(number) || 0) * 100000) / 100000;
  };

  const accountFio = (account) => {
    let stringFio = String(
      (!!account.last_name ? account.last_name : "") +
      " " +
      (!!account.first_name ? account.first_name : "") +
      " " +
      (!!account.patronymic ? account.patronymic : "")
    );

    return stringFio.replace(/\s+/g, " ").trim();
  };

  const [tumbler, setTumbler] = useState("a");

  return (
    buyerOrderItemCollecting &&
    buyerOrder &&
    buyerOrderItem && (
      <Modal handleClose={() => props.handleClose()}>
        <div className="Form">
          <div className="Form__title">
            Позиция
            <br/>
            <div className="Text__signature">
              {buyerOrder.number} от {buyerOrder.date}
            </div>
            <div className="Text__signature">
              {buyerOrderItem.index} # {buyerOrderItem.nomenclature.name}
            </div>
          </div>

          <div className="Form__field">
            {!!props.buyerOrderItem &&
              !!props.buyerOrderItem.preparations &&
              props.buyerOrderItem.preparations.map(
                (collect, index) =>
                  ((!!collect.is_stickers && collect.is_stickers)) && (
                    <div
                      key={index}
                      style={{
                        backgroundColor: "#e9e9e9",
                        fontSize: 12,
                        padding: "4px 6px",
                        borderRadius: 4,
                        marginBottom: 3,
                      }}
                    >
                      <span style={{
                        fontSize: 13,
                        fontWeight: 700
                      }}>
                      {accountFio(collect.account)}
                        </span>
                      {!!collect.is_stickers && collect.is_stickers && (
                        <div>Наклейки собраны{" "}
                          ({moment(collect.created_at.substring(0, 19)).format("DD.MM.YYYY")})</div>
                      )}
                    </div>
                  )
              )}
            {!!props.buyerOrderItem &&
              !!props.buyerOrderItem.glued &&
              props.buyerOrderItem.glued.map(
                (collect, index) =>
                  ((!!collect.is_glued && collect.is_glued)) && (
                    <div
                      key={index}
                      style={{
                        backgroundColor: "#e9e9e9",
                        fontSize: 12,
                        padding: "4px 6px",
                        borderRadius: 4,
                        marginBottom: 3,
                      }}
                    >
                      <span style={{
                        fontSize: 13,
                        fontWeight: 700
                      }}>
                      {accountFio(collect.account)}
                        </span>
                      {!!collect.is_glued && collect.is_glued && (
                        <div>Наклейки наклеены{" "}
                          ({moment(collect.created_at.substring(0, 19)).format("DD.MM.YYYY")})</div>
                      )}
                    </div>
                  )
              )}
            {!!props.buyerOrderItem &&
              !!props.buyerOrderItem.collecting &&
              props.buyerOrderItem.collecting.map(
                (collect, index) =>
                  ((!!collect.montage_quantity &&
                      collect.montage_quantity > 0) ||
                    (!!collect.warehouse_quantity &&
                      collect.warehouse_quantity > 0) ||
                    (!!collect.is_stickers && collect.is_stickers)) && (
                    <div
                      key={index}
                      style={{
                        backgroundColor: "#e9e9e9",
                        fontSize: 12,
                        padding: "4px 6px",
                        borderRadius: 4,
                        marginBottom: 3,
                      }}
                    >
                      <span style={{
                        fontSize: 13,
                        fontWeight: 700
                      }}>
                      {accountFio(collect.account)}
                        </span>
                      {(!!collect.montage_quantity ||
                        !!collect.warehouse_quantity) && (
                        <div>
                          Собрано {collect.montage_quantity || "0"} | Со склада{" "}
                          {collect.warehouse_quantity || "0"}{" "}
                          ({moment(collect.created_at.substring(0, 19)).format("DD.MM.YYYY")})
                        </div>
                      )}
                      {/*TODO Убрать когда очищу базуданных*/}
                      {!!collect.is_stickers && collect.is_stickers && (
                        <div>Наклейки собраны{" "}
                          ({moment(collect.created_at.substring(0, 19)).format("DD.MM.YYYY")})</div>
                      )}
                    </div>
                  )
              )}
            {!!props.buyerOrderItem &&
              !!props.buyerOrderItem.inspects &&
              props.buyerOrderItem.inspects.map(
                (collect, index) =>
                  ((!!collect.is_verified && collect.is_verified)) && (
                    <div
                      key={index}
                      style={{
                        backgroundColor: "#e9e9e9",
                        fontSize: 12,
                        padding: "4px 6px",
                        borderRadius: 4,
                        marginBottom: 3,
                      }}
                    >
                      <span style={{
                        fontSize: 13,
                        fontWeight: 700
                      }}>
                      {accountFio(collect.account)}
                        </span>
                      {!!collect.is_verified && collect.is_verified && (
                        <div>Укомплектовано{" "}
                          ({moment(collect.created_at.substring(0, 19)).format("DD.MM.YYYY")})</div>
                      )}
                    </div>
                  )
              )}
          </div>

          <div className="Form__field">
            <ul className={styleTumbler._} style={{
              justifyContent: "space-between",
              backgroundColor: "#f5f5f5"
            }}>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((!!tumbler &&
                        tumbler === "a" &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setTumbler("a")}
                  style={{
                    cursor: "pointer",
                    fontSize: 12
                  }}
                >
                  Наклейки
                </a>
              </li>
              {/* TODO Не нужен этот функционал */}
              {/*<li className={styleTumbler._link}>*/}
              {/*  <a*/}
              {/*    className={*/}
              {/*      styleTumbler._text +*/}
              {/*      ((!!tumbler &&*/}
              {/*          tumbler === "d" &&*/}
              {/*          " " + styleTumbler.active + " ") ||*/}
              {/*        "")*/}
              {/*    }*/}
              {/*    onClick={() => setTumbler("d")}*/}
              {/*    style={{*/}
              {/*      cursor: "pointer",*/}
              {/*      fontSize: 12*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    Наклеены*/}
              {/*  </a>*/}
              {/*</li>*/}
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((!!tumbler &&
                        tumbler === "b" &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setTumbler("b")}
                  style={{
                    cursor: "pointer",
                    fontSize: 12
                  }}
                >
                  Фасовка
                </a>
              </li>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((!!tumbler &&
                        tumbler === "c" &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setTumbler("c")}
                  style={{
                    cursor: "pointer",
                    fontSize: 12
                  }}
                >
                  Комплектован
                </a>
              </li>
            </ul>
          </div>

          {!!tumbler &&
            tumbler === "a" && (
              <>
                <div className="Form__field Form__field_last">
                  <div
                    className="Button__swith"
                    style={{
                      padding: "8px",
                      width: "100%",
                      border: isStickers
                        ? "2px solid #e1bee7"
                        : "2px dashed #20202015",
                      backgroundColor: isStickers ? "#e1bee7" : "#ffffff",
                      borderRadius: 6,
                      marginTop: 20,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsStickers(!isStickers)}
                  >
                    <div
                      className="Swith__text"
                      style={{
                        fontSize: 17,
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Наклейки собраны
                    </div>
                    <div
                      className={
                        "Swith Swith_gray" +
                        ((isStickers && " Swith_active Swith_active_gray") || "")
                      }
                    ></div>
                  </div>
                </div>

                <div className="Form__actions Form__actions_center">
                  <button className="Action__button Action__button_all Action__button_blue"
                          onClick={() => addPreparation()}>
                    Сохранить
                  </button>
                </div>

              </>
            )}


          {!!tumbler &&
            tumbler === "b" && (
              <>
                <div className="Form__field">
                  <Input
                    reference={inputMontageCount}
                    type="number"
                    label="Собрано"
                    isFixed={true}
                    value={Math.round(montageQuantity * 100000) / 100000}
                  />
                </div>

                <div
                  className="Form__field"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      margin: "0 12px 0 0",
                    }}
                    className="Action__button Action__button_maxi Action__button_blue"
                    onClick={() => minusOneMontageQuantity()}
                  >
                    - 1
                  </div>
                  <div
                    style={{
                      margin: "0 12px",
                    }}
                    className="Action__button Action__button_maxi Action__button_blue"
                    onClick={() => numberMontageQuantity(0)}
                  >
                    0.00
                  </div>
                  <div
                    style={{
                      margin: "0 12px",
                    }}
                    className="Action__button Action__button_maxi Action__button_blue"
                    onClick={() => numberMontageQuantity(buyerOrderItem.quantity)}
                  >
                    {buyerOrderItem.quantity}
                  </div>
                  <div
                    style={{
                      margin: "0 0 0 12px",
                    }}
                    className="Action__button Action__button_maxi Action__button_blue"
                    onClick={() => plusOneMontageQuantity()}
                  >
                    + 1
                  </div>
                </div>

                <div className="Form__field">
                  <Input
                    reference={inputWarehouseCount}
                    type="number"
                    label="Со склада"
                    isFixed={true}
                    value={Math.round(warehouseQuantity * 100000) / 100000}
                  />
                  {!!buyerOrderItemCollecting && !!buyerOrderItemCollecting.nomenclature && !!buyerOrderItemCollecting.nomenclature.quantity
                    && buyerOrderItemCollecting.nomenclature.quantity > 0 && (
                      <div className={"Field__text"} style={{
                        marginTop: 4,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}>
                        Доступно на складе:
                        <div
                            className={"Collected__count Collected__count_blue"}
                            style={{
                              marginLeft: 12,
                              borderRadius: 24
                            }}
                          >
                            {buyerOrderItemCollecting.nomenclature.quantity}
                          </div>
                      </div>
                    )}
                </div>

                <div
                  className="Form__field Form__field_last"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      margin: "0 12px 0 0",
                    }}
                    className="Action__button Action__button_maxi Action__button_blue"
                    onClick={() => minusOneWarehouseQuantity()}
                  >
                    - 1
                  </div>
                  <div
                    style={{
                      margin: "0 12px",
                    }}
                    className="Action__button Action__button_maxi Action__button_blue"
                    onClick={() => numberWarehouseQuantity(0)}
                  >
                    0.00
                  </div>
                  <div
                    style={{
                      margin: "0 12px",
                    }}
                    className="Action__button Action__button_maxi Action__button_blue"
                    onClick={() => numberWarehouseQuantity(buyerOrderItem.quantity)}
                  >
                    {buyerOrderItem.quantity}
                  </div>
                  <div
                    style={{
                      margin: "0 0 0 12px",
                    }}
                    className="Action__button Action__button_maxi Action__button_blue"
                    onClick={() => plusOneWarehouseQuantity()}
                  >
                    + 1
                  </div>
                </div>

                <div className="Form__actions Form__actions_center">
                  <button className="Action__button Action__button_all Action__button_blue"
                          onClick={() => addCollected()}>
                    Сохранить
                  </button>
                </div>

              </>
            )}

          {!!tumbler &&
            tumbler === "c" && (
              <>
                <div className="Form__field Form__field_last">
                  <div
                    className="Button__swith"
                    style={{
                      padding: "8px",
                      width: "100%",
                      border: isVerified
                        ? "2px solid #b3e5fc"
                        : "2px dashed #20202015",
                      backgroundColor: isVerified ? "#b3e5fc" : "#ffffff",
                      borderRadius: 6,
                      marginTop: 20,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsVerified(!isVerified)}
                  >
                    <div
                      className="Swith__text"
                      style={{
                        fontSize: 17,
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Укомплектовано
                    </div>
                    <div
                      className={
                        "Swith Swith_gray" +
                        ((isVerified && " Swith_active Swith_active_gray") || "")
                      }
                    ></div>
                  </div>
                </div>

                <div className="Form__actions Form__actions_center">
                  <button className="Action__button Action__button_all Action__button_blue"
                          onClick={() => addInspect()}>
                    Сохранить
                  </button>
                </div>

              </>
            )}

          {!!tumbler &&
            tumbler === "d" && (
              <>
                <div className="Form__field Form__field_last">
                  <div
                    className="Button__swith"
                    style={{
                      padding: "8px",
                      width: "100%",
                      border: isGlued
                        ? "2px solid #e5def2"
                        : "2px dashed #20202015",
                      backgroundColor: isGlued ? "#e5def2" : "#ffffff",
                      borderRadius: 6,
                      marginTop: 20,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsGlued(!isGlued)}
                  >
                    <div
                      className="Swith__text"
                      style={{
                        fontSize: 17,
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Наклейки наклеены
                    </div>
                    <div
                      className={
                        "Swith Swith_gray" +
                        ((isGlued && " Swith_active Swith_active_gray") || "")
                      }
                    ></div>
                  </div>
                </div>

                <div className="Form__actions Form__actions_center">
                  <button className="Action__button Action__button_all Action__button_blue"
                          onClick={() => addGlued()}>
                    Сохранить
                  </button>
                </div>

              </>
            )}

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </div>
      </Modal>
    )
  );
}

export default ModalBuyerOrderCollected;
