import React, { Fragment } from "react";
import moment from "moment";
import {formatTime} from "../../../../../functions/formatTime";
import {NavLink} from "react-router-dom";
import {getAccountFio, Loading} from "@vokymlak/kabinet-ui";

function KpiPackingSummaryTableView(props) {
  const {
    date,
    days,
    list,
    weekDayText,
    getDayCount,
    getAccountAllDayCount,
  } = props;

  const getAverage = (amount, time) => {
    return new Intl.NumberFormat("ru-RU").format(
      Number(
        (amount / (time / 3600)) / 100
      ).toFixed(2))
  }

  const stringToColour = (str) => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("ff" + value.toString(16)).substr(-2);
    }
    return colour;
  };

  return (
    <>
      {!list && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}
      {!!list && (
      <div
        className="Page__content"
        style={{
          height: "calc(100% - 42px)",
        }}
      >
        <div
          className="List"
          style={{
            overflow: "auto",
            height: "100%",
          }}
        >
          <table className="Table">
            <thead className="Thead">
              <tr className="Table__tr Table__tr_padding Table__tr_border">
                <th className="Table__th Table__th_border Table__th_sticky"
                    style={{
                      backgroundColor: "#dfdfdf",
                    }}>
                  <div className="Th__title"></div>
                </th>

                {!!list &&
                  list.map((item, index) => (
                    <th
                      key={index}
                      className="Table__th Table__th_border"
                      style={{
                        backgroundColor:
                          stringToColour(getAccountFio(item.account)) + "99",
                        zIndex: 9999,
                      }}
                      colSpan={14}
                    >
                      <div className="Th__title">
                        {getAccountFio(item.account)}
                      </div>
                    </th>
                  ))}
              </tr>
              <tr className="Table__tr Table__tr_padding Table__tr_border ">
                <th className="Table__th Table__th_border Table__th_sticky"
                    style={{
                      backgroundColor: "#dfdfdf",
                    }}>
                  <div className="Th__title">Дата / ФИО</div>
                </th>

                {!!list &&
                  list.map((item, index) => (
                    <Fragment key={index}>
                      <th
                        className="Table__th Table__th_border"
                        style={{
                          backgroundColor: stringToColour(
                            getAccountFio(item.account)
                          ),
                          zIndex: 1,
                        }}
                      >
                        <div className="Th__title">Номера заказов</div>
                      </th>
                      <th
                        className="Table__th Table__th_border"
                        style={{
                          backgroundColor: stringToColour(
                            getAccountFio(item.account)
                          ),
                          zIndex: 1,
                        }}
                      >
                        <div className="Th__title">Кол-во заказов</div>
                      </th>
                      <th
                        className="Table__th Table__th_border"
                        style={{
                          backgroundColor: stringToColour(
                            getAccountFio(item.account)
                          ),
                          zIndex: 1,
                        }}
                      >
                        <div className="Th__title">Сумма</div>
                      </th>

                      <th
                        className="Table__th Table__th_border"
                        style={{
                          backgroundColor: stringToColour(
                            getAccountFio(item.account)
                          ),
                          zIndex: 1,
                        }}
                      >
                        <div className="Th__title">Пробники</div>
                      </th>

                      <th
                        className="Table__th Table__th_border"
                        style={{
                          backgroundColor: stringToColour(
                            getAccountFio(item.account)
                          ),
                          zIndex: 1,
                        }}
                      >
                        <div className="Th__title">50 гр</div>
                      </th>
                      <th
                        className="Table__th Table__th_border"
                        style={{
                          backgroundColor: stringToColour(
                            getAccountFio(item.account)
                          ),
                          zIndex: 1,
                        }}
                      >
                        <div className="Th__title">100 гр</div>
                      </th>
                      <th
                        className="Table__th Table__th_border"
                        style={{
                          backgroundColor: stringToColour(
                            getAccountFio(item.account)
                          ),
                          zIndex: 1,
                        }}
                      >
                        <div className="Th__title">200 гр</div>
                      </th>
                      <th
                        className="Table__th Table__th_border"
                        style={{
                          backgroundColor: stringToColour(
                            getAccountFio(item.account)
                          ),
                          zIndex: 1,
                        }}
                      >
                        <div className="Th__title">250 гр</div>
                      </th>
                      <th
                        className="Table__th Table__th_border"
                        style={{
                          backgroundColor: stringToColour(
                            getAccountFio(item.account)
                          ),
                          zIndex: 1,
                        }}
                      >
                        <div className="Th__title">500 гр</div>
                      </th>
                      <th
                        className="Table__th Table__th_border"
                        style={{
                          backgroundColor: stringToColour(
                            getAccountFio(item.account)
                          ),
                          zIndex: 1,
                        }}
                      >
                        <div className="Th__title">1 кг</div>
                      </th>
                      <th
                        className="Table__th Table__th_border"
                        style={{
                          backgroundColor: stringToColour(
                            getAccountFio(item.account)
                          ),
                          zIndex: 1,
                        }}
                      >
                        <div className="Th__title">5 кг</div>
                      </th>
                      <th
                        className="Table__th Table__th_border"
                        style={{
                          backgroundColor: stringToColour(
                            getAccountFio(item.account)
                          ),
                          zIndex: 1,
                        }}
                      >
                        <div className="Th__title">1 шт</div>
                      </th>
                      <th
                        className="Table__th Table__th_border"
                        style={{
                          backgroundColor: stringToColour(
                            getAccountFio(item.account)
                          ),
                          zIndex: 1,
                        }}
                      >
                        <div className="Th__title">Время</div>
                      </th>
                      <th
                        className="Table__th Table__th_border"
                        style={{
                          backgroundColor: stringToColour(
                            getAccountFio(item.account)
                          ),
                          zIndex: 1,
                        }}
                      >
                        <div className="Th__title">В час</div>
                      </th>
                    </Fragment>
                  ))}
              </tr>
            </thead>
            <tbody>
              {!!list &&
                !!days &&
                days.map((day, index) => (
                  <tr
                    className={
                      "Table__tr Table__tr_padding Table__tr_border Table__tr_hover "
                    }
                    key={"item_" + index}
                    style={{
                      backgroundColor:
                        (moment(
                          moment(date).format("YYYY") +
                            "-" +
                            moment(date).format("MM") +
                            "-" +
                            day.day,
                          "YYYY-MM-DD"
                        ).weekday() === 0 ||
                          moment(
                            moment(date).format("YYYY") +
                              "-" +
                              moment(date).format("MM") +
                              "-" +
                              day.day,
                            "YYYY-MM-DD"
                          ).weekday() === 6) &&
                        "#bdbdbd",
                    }}
                  >
                    <td className="Table__td Table__td_border Table__td_sticky"
                        style={{
                          backgroundColor: "#dfdfdf",
                        }}>
                      <div className="Td__text Td__text_center">
                        {day.day}.{moment(date).format("MM")} (
                        {weekDayText(
                          moment(
                            moment(date).format("YYYY") +
                              "-" +
                              moment(date).format("MM") +
                              "-" +
                              day.day,
                            "YYYY-MM-DD"
                          ).weekday()
                        )}
                        )
                      </div>
                    </td>


                    {!!list &&
                      list.map((item, index) => (
                        <Fragment key={index}>
                          <td
                            className="Table__td Table__td_border"
                            style={{
                              backgroundColor:
                                stringToColour(getAccountFio(item.account)) + "25",
                            }}
                          >
                            <div className="Td__text Td__text_center">
                              {(!!getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).buyerOrderNumbers && getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).buyerOrderNumbers.map(bon => (
                                <NavLink to={"/order/" + bon.uuid} target={'_blank'} key={'link_'+bon.uuid}>
                                  <div className="Button__text" style={{
                                  fontSize: 12
                                  }}>{bon.number}</div>
                                </NavLink>
                              ))) || "-"}
                            </div>
                          </td>
                          <td
                            className="Table__td Table__td_border"
                            style={{
                              backgroundColor:
                                stringToColour(getAccountFio(item.account)) + "25",
                            }}
                          >
                            <div className="Td__text Td__text_center">
                              {getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).buyerOrders || "-"}
                            </div>
                          </td>
                          <td
                            className="Table__td Table__td_border"
                            style={{
                              backgroundColor:
                                stringToColour(getAccountFio(item.account)) + "25",
                            }}
                          >
                            <div className="Td__text Td__text_center">
                              {(!!getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).amount &&
                                new Intl.NumberFormat("ru-RU").format(
                                  Number(
                                    getDayCount(
                                      item.days,
                                      moment(props.date).format("YYYY"),
                                      moment(props.date).format("MM"),
                                      day.day
                                    ).amount / 100
                                  ).toFixed(2)
                                )) ||
                                "-"}
                            </div>
                          </td>

                          <td
                            className="Table__td Table__td_border"
                            style={{
                              backgroundColor:
                                stringToColour(getAccountFio(item.account)) + "25",
                            }}
                          >
                            <div className="Td__text Td__text_center">
                              {getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).countProbes || "-"}
                            </div>
                          </td>

                          <td
                            className="Table__td Table__td_border"
                            style={{
                              backgroundColor:
                                stringToColour(getAccountFio(item.account)) + "25",
                            }}
                          >
                            <div className="Td__text Td__text_center">
                              {getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).count50s || "-"}
                            </div>
                          </td>
                          <td
                            className="Table__td Table__td_border"
                            style={{
                              backgroundColor:
                                stringToColour(getAccountFio(item.account)) + "25",
                            }}
                          >
                            <div className="Td__text Td__text_center">
                              {getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).count100s || "-"}
                            </div>
                          </td>
                          <td
                            className="Table__td Table__td_border"
                            style={{
                              backgroundColor:
                                stringToColour(getAccountFio(item.account)) + "25",
                            }}
                          >
                            <div className="Td__text Td__text_center">
                              {getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).count200s || "-"}
                            </div>
                          </td>
                          <td
                            className="Table__td Table__td_border"
                            style={{
                              backgroundColor:
                                stringToColour(getAccountFio(item.account)) + "25",
                            }}
                          >
                            <div className="Td__text Td__text_center">
                              {getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).count250s || "-"}
                            </div>
                          </td>
                          <td
                            className="Table__td Table__td_border"
                            style={{
                              backgroundColor:
                                stringToColour(getAccountFio(item.account)) + "25",
                            }}
                          >
                            <div className="Td__text Td__text_center">
                              {getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).count500s || "-"}
                            </div>
                          </td>
                          <td
                            className="Table__td Table__td_border"
                            style={{
                              backgroundColor:
                                stringToColour(getAccountFio(item.account)) + "25",
                            }}
                          >
                            <div className="Td__text Td__text_center">
                              {getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).count1000s || "-"}
                            </div>
                          </td>
                          <td
                            className="Table__td Table__td_border"
                            style={{
                              backgroundColor:
                                stringToColour(getAccountFio(item.account)) + "25",
                            }}
                          >
                            <div className="Td__text Td__text_center">
                              {getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).count5000s || "-"}
                            </div>
                          </td>
                          <td
                            className="Table__td Table__td_border"
                            style={{
                              backgroundColor:
                                stringToColour(getAccountFio(item.account)) + "25",
                            }}
                          >
                            <div className="Td__text Td__text_center">
                              {getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).count1s || "-"}
                            </div>
                          </td>
                          <td
                            className="Table__td Table__td_border"
                            style={{
                              backgroundColor:
                                stringToColour(getAccountFio(item.account)) + "25",
                            }}
                          >
                            <div className="Td__text Td__text_center">
                              {(getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).time  && formatTime(getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).time)) || "-"}
                            </div>
                          </td>
                          <td
                            className="Table__td Table__td_border"
                            style={{
                              backgroundColor:
                                stringToColour(getAccountFio(item.account)) + "25",
                            }}
                          >
                            <div className="Td__text Td__text_center">
                              {(!!getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).time && !!getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).amount && getAverage(
                                getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).amount,
                                getDayCount(
                                item.days,
                                moment(props.date).format("YYYY"),
                                moment(props.date).format("MM"),
                                day.day
                              ).time)) || "-"}
                            </div>
                          </td>
                        </Fragment>
                      ))}
                  </tr>
                ))}

              <tr
                className={
                  "Table__tr Table__tr_padding Table__tr_border Table__tr_hover "
                }
              >
                <td className="Table__td Table__td_border Table__td_sticky"

                    style={{
                      backgroundColor: "#dfdfdf",
                    }}>
                  <div className="Td__text Td__text_center">Итого</div>
                </td>

                {!!list &&
                  list.map((item, index) => (
                    <Fragment key={index}>
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            stringToColour(getAccountFio(item.account)) + "25",
                        }}
                      >
                        <div className="Td__text Td__text_center">
                          -
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            stringToColour(getAccountFio(item.account)) + "25",
                        }}
                      >
                        <div className="Td__text Td__text_center">
                          {getAccountAllDayCount(item.days).buyerOrders || "-"}
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            stringToColour(getAccountFio(item.account)) + "25",
                        }}
                      >
                        <div className="Td__text Td__text_center">
                          {(!!getAccountAllDayCount(item.days).amount &&
                            new Intl.NumberFormat("ru-RU").format(
                              Number(
                                getAccountAllDayCount(item.days).amount / 100
                              ).toFixed(2)
                            )) ||
                            "-"}
                        </div>
                      </td>

                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            stringToColour(getAccountFio(item.account)) + "25",
                        }}
                      >
                        <div className="Td__text Td__text_center">
                          {getAccountAllDayCount(item.days).countProbes || "-"}
                        </div>
                      </td>

                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            stringToColour(getAccountFio(item.account)) + "25",
                        }}
                      >
                        <div className="Td__text Td__text_center">
                          {getAccountAllDayCount(item.days).count50s || "-"}
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            stringToColour(getAccountFio(item.account)) + "25",
                        }}
                      >
                        <div className="Td__text Td__text_center">
                          {getAccountAllDayCount(item.days).count100s || "-"}
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            stringToColour(getAccountFio(item.account)) + "25",
                        }}
                      >
                        <div className="Td__text Td__text_center">
                          {getAccountAllDayCount(item.days).count200s || "-"}
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            stringToColour(getAccountFio(item.account)) + "25",
                        }}
                      >
                        <div className="Td__text Td__text_center">
                          {getAccountAllDayCount(item.days).count250s || "-"}
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            stringToColour(getAccountFio(item.account)) + "25",
                        }}
                      >
                        <div className="Td__text Td__text_center">
                          {getAccountAllDayCount(item.days).count500s || "-"}
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            stringToColour(getAccountFio(item.account)) + "25",
                        }}
                      >
                        <div className="Td__text Td__text_center">
                          {getAccountAllDayCount(item.days).count1000s || "-"}
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            stringToColour(getAccountFio(item.account)) + "25",
                        }}
                      >
                        <div className="Td__text Td__text_center">
                          {getAccountAllDayCount(item.days).count5000s || "-"}
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            stringToColour(getAccountFio(item.account)) + "25",
                        }}
                      >
                        <div className="Td__text Td__text_center">
                          {getAccountAllDayCount(item.days).count1s || "-"}
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            stringToColour(getAccountFio(item.account)) + "25",
                        }}
                      >
                        <div className="Td__text Td__text_center">
                          {(getAccountAllDayCount(item.days).time && formatTime(getAccountAllDayCount(item.days).time)) || "-"}
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor:
                            stringToColour(getAccountFio(item.account)) + "25",
                        }}
                      >
                        <div className="Td__text Td__text_center">
                          {(!!getAccountAllDayCount(item.days).time && !!getAccountAllDayCount(item.days).amount && getAverage(
                            getAccountAllDayCount(item.days).amount,
                            getAccountAllDayCount(item.days).time)) || "-"}
                        </div>
                      </td>
                    </Fragment>
                  ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
        )}
    </>
  );
}

export default KpiPackingSummaryTableView;
