import React, { useState } from "react";
import { ROOT_URL } from "../../../config/url";
import {useList, useUnroll} from "@vokymlak/kabinet-ui";
import {useAction} from "@vokymlak/kabinet-ui";
import axios from "axios";

function useBuyerOrderNeural() {
  const {
    list: [list, _count, setList],
    update: [isUpdateList, setIsUpdateList],
  } = useList({apiUrl:"/api/buyer-order-neural", rootUrl:ROOT_URL});

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(null);

  return {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,
  };
}

export default useBuyerOrderNeural;
