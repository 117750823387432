import React, { useState, useEffect, useRef } from "react";
import {Modal} from "@vokymlak/kabinet-ui";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {getErrorMessageByKey} from "@vokymlak/kabinet-ui";
import {useNotification} from "@vokymlak/kabinet-ui";
import {Input} from "@vokymlak/kabinet-ui";
import moment from "moment";

function ModalEditDispatch(props) {
  const [buyerOrder, setBuyerOrder] = useState(props.buyerOrder);

  const inputDateDispatch = useRef(null);

  const { notification } = useNotification();

  useEffect(() => {
    if (props.buyerOrder) {
      setBuyerOrder(props.buyerOrder);
    }
  }, [props.buyerOrder]);

  useEffect(() => {
    return () => {
      setBuyerOrder(null);
    };
  }, []);

  const addAccountRole = (event) => {
    event.preventDefault();
    const dateDispatch = inputDateDispatch.current.value;

    if (
      !dateDispatch ||
      dateDispatch.length === 0
     ) {
      notification("Укажите дату и время", "red");
      return;
    }

    const jsonData = JSON.stringify({
      date_dispatch: (dateDispatch+":00").replaceAll("T", " "),
    });

    axios
      .post("/api/buyer-order/" + buyerOrder.id + "/dispatch", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    buyerOrder && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => addAccountRole(event)}>
          <div className="Form__title">
            {buyerOrder.number} от {moment(buyerOrder.date).format("DD.MM.YYYY HH:mm")}

            <br />
            <div className="Text__signature">
              Доставка заказа
            </div>
          </div>

          <div className="Form__field ">
            <Input
              reference={inputDateDispatch}
              label="Доставлено"
              isFixed={true}
              type={"datetime-local"}
              value={(!!buyerOrder.date_dispatch && buyerOrder.date_dispatch) || moment().format("YYYY-MM-DD HH:mm")}
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Доставлен
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalEditDispatch;
