import React, {createContext, useReducer, useEffect, useContext, useState} from 'react';
import { initialState, timeTrackingReducer } from './timeTrackingReducer';
import {timeTrackingInit} from './timeTrackingAction';
import ModalSelectTimeWorkCategory from "./ModalSelectTimeWorkCategory";
import ModalTimeWorkCategory from "./ModalTimeWorkCategory";
import {AuthContext, profileInit, useAction, useProfile} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../config/url";

export const TimeTrackingContext = createContext(initialState);

export const TimeTrackingProvider = ({ children }) => {
  const [isModalTimeWorkCategory, setIsModalTimeWorkCategory] = useState(false);

    const { authState } = useContext(AuthContext)
    const [timeTrackingState, timeTrackingDispatch] = useReducer(timeTrackingReducer, initialState);
    const {profile} = useProfile();

    useEffect(() => {
        if (!!authState.authToken) {
            timeTrackingInit(timeTrackingDispatch, null).then();
        }
    }, [authState.authToken]);

  const { setAction } = useAction(!!profile ? profile.uuid : null, () => {
    timeTrackingInit(timeTrackingDispatch, null).then();
  });

  useEffect(() => {
    setAction(!!profile ? profile.uuid : null);
  }, [profile]);


  useEffect(() => {
    if (!!timeTrackingState.timeTrackingData
      && !!timeTrackingState.timeTrackingData.time_tracking_record
      && !!timeTrackingState.timeTrackingData.time_tracking_record.time_work_category
      && !timeTrackingState.timeTrackingData.time_tracking_record.time_work_category.is_unblock_work
    ) {
      setIsModalTimeWorkCategory(true);
    }
  }, [timeTrackingState]);

  // const [isModalObjectSelect, setIsModalObjectSelect] = useState(false);

    return (
        <TimeTrackingContext.Provider value={{ timeTrackingState, timeTrackingDispatch }}>
            {timeTrackingState.isTimeTrackingInit && (<>
                {/*{(!!timeTrackingState.timeTrackingData*/}
                {/*    && timeTrackingState.timeTrackingData.is_time_tracking*/}
                {/*    && !timeTrackingState.timeTrackingData.time_tracking_record*/}
                {/*) &&*/}
                {/*  <div style={{*/}
                {/*    display: "flex",*/}
                {/*    flexDirection: "row",*/}
                {/*    flexWrap: "nowrap",*/}
                {/*    alignItems: "center",*/}
                {/*    justifyContent: "center",*/}
                {/*    paddingTop: 12*/}
                {/*  }}>*/}
                {/*    <button className="Action__button Action__button_blue Action__button_mini"*/}
                {/*            onClick={() => setIsModalObjectSelect(true)}*/}
                {/*    >*/}
                {/*      Временной учет*/}
                {/*    </button>*/}
                {/*</div>}*/}

              {!!timeTrackingState.timeTrackingData && children}

              {/*{!!isModalObjectSelect && (*/}
              {/*  <ModalSelectTimeWorkCategory*/}
              {/*    successfulRequest={() => timeTrackingInit(timeTrackingDispatch, null).then()}*/}
              {/*    handleClose={() => setIsModalObjectSelect(false)}*/}
              {/*  />*/}
              {/*)}*/}
              {isModalTimeWorkCategory && (
                <ModalTimeWorkCategory
                  profile={profile}
                  object={timeTrackingState.timeTrackingData.time_tracking_record}
                  successfulRequest={() => timeTrackingInit(timeTrackingDispatch, null).then()}
                  handleClose={() => setIsModalTimeWorkCategory(false)}
                />
              )}
            </>) }
        </TimeTrackingContext.Provider>
    );
};
