import React, { useState, useEffect, useRef } from "react";
import {Modal} from "@vokymlak/kabinet-ui";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {Textarea} from "@vokymlak/kabinet-ui";
import {getErrorMessageByKey} from "@vokymlak/kabinet-ui";
import {useNotification} from "@vokymlak/kabinet-ui";
import {Input} from "@vokymlak/kabinet-ui";
import moment from "moment";

function ModalBuyerOrderShipmentEdit(props) {
  const [buyerOrder, setBuyerOrder] = useState(props.buyerOrder);

  const inputBuyerOrderState = useRef(null);
  const inputBuyerOrderNote = useRef(null);

  const { notification } = useNotification();

  useEffect(() => {
    if (props.buyerOrder) {
      setBuyerOrder(props.buyerOrder);
    }
  }, [props.buyerOrder]);

  useEffect(() => {
    return () => {
      setBuyerOrder(null);
    };
  }, []);

  const addAccountRole = (event) => {
    event.preventDefault();
    const buyerOrderState = inputBuyerOrderState.current.value;
    const buyerOrderNote = inputBuyerOrderNote.current.value;

    const jsonData = JSON.stringify({
      state_shipment: buyerOrderState,
      note_shipment: buyerOrderNote,
    });

    axios
      .post("/api/buyer-order/" + buyerOrder.id + "/shipment", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    buyerOrder && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => addAccountRole(event)}>
          <div className="Form__title">
            {buyerOrder.number} от {moment(buyerOrder.date).format("DD.MM.YYYY HH:mm")}
            <div className="Text__signature">
              Отгрузка заказа
            </div>
          </div>

          <div className="Form__field ">
            <Input
              reference={inputBuyerOrderState}
              label="Отправлено"
              value={buyerOrder.state_shipment}
            />
          </div>

          <div className="Form__field Form__field_last">
            <Textarea
              reference={inputBuyerOrderNote}
              label="Примечание"
              value={buyerOrder.note_shipment}
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Применить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalBuyerOrderShipmentEdit;
