import React, { useEffect, useState } from "react";
import { ROOT_URL } from "../../../../../config/url";
import { useAction, useObject } from "@vokymlak/kabinet-ui";
import { useParams } from "react-router-dom";

export const useHandbookArticle = (props) => {
  const [isEdit, setIsEdit] = useState(false);

  const {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject, updateObject],
  } = useObject({
    apiUrl: "/api/site-blog/" + props.handbookEntryUuid + "/article",
    rootUrl: ROOT_URL,
  });

  return {
    object,
    setObject,

    setIsUpdateObject,
    isUpdateObject,

    isEdit,
    setIsEdit,
  };
};
