import React from "react";
import {ButtonIcon, ButtonIconExport, Loading, Pagination} from "@vokymlak/kabinet-ui";
import {Search} from "@vokymlak/kabinet-ui";
import {Select} from "@vokymlak/kabinet-ui";
import {ROLE_ANALYST, ROLE_EMPLOYEE} from "../../../config/constants";
import {BuyerOrderObject} from "../BuyerOrderObject";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import moment from "moment/moment";
import {useNotification, isProfileRoleTypeTags} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import ModalNomenclaturesIsPublish from "../../ScreenNomenclatures/NomenclatureItem/ModalNomenclaturesIsPublish";
import ModalOrdersStateEdit from "../BuyerOrderObject/ModalOrdersStateEdit";

function StateOrdersPage(props) {
  const {
    object,

    list,
    count,
    setList,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,
    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,
    inputSearchText,
    onChangeSearchText,
    searchQuery,
    setIsUpdateList,
    isUpdateList,

    profile,

    isSelect,
    selectOrders, setSelectOrders,
    isSelectOrder, changeSelectOrder,

    isModalOrdersStateEdit,
    setIsModalOrdersStateEdit,
  } = props;

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
    <div className="Page"
         onContextMenu={
           isProfileRoleTypeTags(profile, [ROLE_EMPLOYEE]) ?
           ((event) => event.preventDefault()) : () => null
         }>
      <div className="Page__title">
        <div className="Title__text" style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              alignItems: "flex-start",
              // marginRight: 24,
            }}
          >
            <div
              style={{
                fontSize: 21,
                fontWeight: 700,
              }}
            >
              Заказы
              {!!list && (
                <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({count})
              </span>
              )}
            </div>
            <div
              style={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              {!!object && object.name}
            </div>
          </div>

          <span style={{ width: 140, marginLeft: 16 }}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>

          {!!object && (
            <ButtonIconExport
            tooltipText={"Выгрузить"}
            iconName={"custom-download"}
            iconColor={"#202020"}
            size={24}
            style={{ marginLeft: 12 }}
            apiUrl={"/api/orders/state/"+object.uuid+"/export/xlsx"}
            rootUrl={ROOT_URL}
            mimeType={
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }
            fileName={
              ("заказы_по_"+
                object.name.replaceAll(".", " ").replaceAll("  ", " ").replaceAll(" ", "_")+
                "_на_"+moment().format("DD_MM_YYYY")+".xlsx").toLowerCase()}
          />
            )}

          {!!selectOrders && selectOrders.length > 0 && (
            <div
              style={{
                backgroundColor: "#0061ff",
                padding: "2px 3px 4px 8px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 24,
                marginLeft: 12,
              }}
            >
              <span
                style={{
                  color: "white",
                  fontSize: 14,
                  pointerEvents: "auto",
                  fontWeight: 700,
                  cursor: "default",
                }}
              >
                {selectOrders.length}
              </span>
              <ButtonIcon
                style={{
                  marginLeft: 4,
                  width: 18,
                  height: 18
                }}
                tooltipText={"Отменить"}
                iconName={"custom-circle-close"}
                iconColor={"white"}
                size={18}
                onClick={() => setSelectOrders([])}
              />
            </div>
          )}
        </div>

        <div className="Title__settings">
          {!!list && (
            <ButtonIcon
              tooltipText={
                list.length === unrolls.length ? "Свернуть" : "Развернуть"
              }
              iconName={
                list.length === unrolls.length
                  ? "custom-unfold-less"
                  : "custom-unfold-more"
              }
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => changeAllUnroll(list, "uuid")}
            />
          )}

          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 8 }}
            onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
          />

          {!!count && !!pageLimit && (
            <div
              style={{
                marginLeft: 12,
                width: 64,
              }}
            >
              <Select
                isFixed={true}
                isDefaultOption={false}
                value={pageLimit}
                options={[
                  {
                    value: 100,
                    title: 100,
                  },
                  {
                    value: 1000,
                    title: 1000,
                  },
                ]}
                label="Лимит"
                onChange={(event) => setPageLimit(Number(event.target.value))}
              />
            </div>
          )}

          {!!count && count > pageLimit && (
            <div style={{ marginLeft: 8 }}>
              <Pagination
                totalRecords={count}
                pageLimit={pageLimit}
                currentPage={pageNumber}
                onPageChanged={(data) => changePage(data)}
              />
            </div>
          )}
        </div>
      </div>

      {!list && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}

      {!!list && (
        <div className="Page__content">
          <ul className="List">
            {list.map((item) => (
              <BuyerOrderObject
                {...props}
                key={"item_" + item.uuid}
                object={item}
                isUnroll={isUnroll}
                changeUnroll={changeUnroll}
                searchQuery={searchQuery}
                isUpdateList={isUpdateList}
                setIsUpdateList={setIsUpdateList}
              />
            ))}
          </ul>
        </div>
      )}

      {!!selectOrders && selectOrders.length > 0 && (
        <div
          style={{
            position: "fixed",
            bottom: 24,
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignItems: "center",
            justifyContent: "center",
            right: 48,
            zIndex: 9,
            pointerEvents: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#202020",
                padding: "8px 12px",
                color: "white",
                marginLeft: 3,
                marginRight: 3,
                cursor: "pointer",
                borderRadius: 24,
                fontSize: 15,
                fontWeight: 700,
                pointerEvents: "auto"
              }}
              onClick={() => setIsModalOrdersStateEdit(true)}
            >
              Сменить статус
            </div>
          </div>
        </div>
      )}


      {!!isModalOrdersStateEdit && (
        <ModalOrdersStateEdit
          buyerOrders={selectOrders}
          successfulRequest={() => (setSelectOrders([]), setIsUpdateList(!isUpdateList))}
          handleClose={() => setIsModalOrdersStateEdit(false)}
        />
      )}
    </div>
    </ScrollBlock>
  );
}

export default StateOrdersPage;
