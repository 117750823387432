import React, {useEffect, useRef, useState} from "react";
import {getErrorMessageByKey, Modal, Select, useNotification} from "@vokymlak/kabinet-ui";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import moment from "moment/moment";

function ModalBuyerOrderStateEdit(props) {
  const [buyerOrder, setBuyerOrder] = useState(props.buyerOrder);
  const [buyerOrderStates, setBuyerOrderStates] = useState(null);

  const inputBuyerOrderStateId = useRef(null);

  const {notification} = useNotification();

  useEffect(() => {
    axios
      .get("/api/view-order-states", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then((response) => {
        setBuyerOrderStates(response.data ? response.data : null);
      }).catch((error) => {
      props.handleClose();
      notification(getErrorMessageByKey(error.response.data.error), "red");
    });

  }, []);

  useEffect(() => {
    if (props.buyerOrder) {
      setBuyerOrder(props.buyerOrder);
    }

  }, [props.buyerOrder]);

  useEffect(() => {
    return () => {
      setBuyerOrderStates(null);
      setBuyerOrder(null);
    };
  }, []);

  const changeBuyerOrderState = (event) => {
    event.preventDefault();
    const buyerOrderStateId = inputBuyerOrderStateId.current.value;

    if (!buyerOrderStateId || buyerOrderStateId.length === 0) {
      return;
    }

    axios
      .get(
        "/api/buyer-order/" + buyerOrder.id + "/state/" + buyerOrderStateId,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();
        props.handleClose();
      }).catch((error) => {
      notification(getErrorMessageByKey(error.response.data.error), "red");
    });
  };

  const buyerOrderStatesToSelectOptions = (states) => {
    const options = [];

    states.map((role) => {
      const option = {
        value: role.id,
        title: role.name,
      };

      options.push(option);
    });

    return options;
  };

  return (
    buyerOrderStates &&
    buyerOrder && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeBuyerOrderState(event)}>
          <div className="Form__title">
            Статус заказа
            <br/>
            <div className="Text__signature">
              {buyerOrder.number} от {!!buyerOrder.date && moment(buyerOrder.date).format("DD.MM.YYYY HH:mm")}
            </div>
          </div>

          <div className="Form__field Form__field_last">
            <Select
              isFixed={true}
              reference={inputBuyerOrderStateId}
              value={
                (!!buyerOrder.buyer_order_state &&
                  buyerOrder.buyer_order_state.id) ||
                null
              }
              options={
                buyerOrderStates &&
                buyerOrderStatesToSelectOptions(buyerOrderStates)
              }
              label="Статус"
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Применить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalBuyerOrderStateEdit;
