import React, { useEffect, useState, useContext } from "react";
import "./MenuHorizontal.scss";
import { useNavigate } from "react-router-dom";
import {
  getAccountFio,
  AuthContext,
  authLogout,
  ProfileContext, useProfile, useAction, profileInit, ButtonIcon, isProfileRoleTypeTags, Icon
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import {Avatar} from "../../../components/Avatar/Avatar";
import ModalProfile from "../../Profile/ModalProfile";
import ModalLogout from "../ModalLogout";
import MenuRouting from "../MenuRouting";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import ModalSelectTimeWorkCategory from "../../../contexts/time-tracking/ModalSelectTimeWorkCategory";
import {TimeTrackingContext, timeTrackingInit} from "../../../contexts/time-tracking";
import ModalTimeWorkCategory from "../../../contexts/time-tracking/ModalTimeWorkCategory";
import TimerWorkCategory from "../../../contexts/time-tracking/TimerWorkCategory";
import {ROLE_CLIENT} from "../../../config/constants";

const getPhoneNumber = (value) => {
  if (!value) {
    value = '';
  }
  return value.replace(/\D/g, '').length > 11
    ? value.replace(/\D/g, '').substring(0, 11)
    : value.replace(/\D/g, '');
};

// Функция для форматирования номера телефона
const formatPhoneNumber = (value) => {
  if (value.length === 0 || value.length === 1) {
    return '+7 (';
  } else if (value.length === 2) {
    return '+7 (' + value.substring(1, 2);
  } else if (value.length === 3) {
    return '+7 (' + value.substring(1, 3);
  } else if (value.length === 4) {
    return '+7 (' + value.substring(1, 4);
  } else if (value.length === 5) {
    return '+7 (' + value.substring(1, 4) + ') ' + value.substring(4, 5);
  } else if (value.length === 6) {
    return '+7 (' + value.substring(1, 4) + ') ' + value.substring(4, 6);
  } else if (value.length === 7) {
    return '+7 (' + value.substring(1, 4) + ') ' + value.substring(4, 7);
  } else if (value.length === 8) {
    return (
      '+7 (' +
      value.substring(1, 4) +
      ') ' +
      value.substring(4, 7) +
      '-' +
      value.substring(7, 8)
    );
  } else if (value.length === 9) {
    return (
      '+7 (' +
      value.substring(1, 4) +
      ') ' +
      value.substring(4, 7) +
      '-' +
      value.substring(7, 9)
    );
  } else if (value.length === 10) {
    return (
      '+7 (' +
      value.substring(1, 4) +
      ') ' +
      value.substring(4, 7) +
      '-' +
      value.substring(7, 9) +
      '-' +
      value.substring(9, 10)
    );
  } else if (value.length === 11 || value.length > 11) {
    return (
      '+7 (' +
      value.substring(1, 4) +
      ') ' +
      value.substring(4, 7) +
      '-' +
      value.substring(7, 9) +
      '-' +
      value.substring(9, 11)
    );
  } else {
    return ''
  }
};

function MenuHorizontal(props) {
  const {
    setIsHiddenMenu,
    isHiddenMenu
  } = props

  const { timeTrackingState, timeTrackingDispatch } = useContext(TimeTrackingContext);
  const [isModalTimeWorkCategory, setIsModalTimeWorkCategory] = useState(false);
  const [isModalObjectSelect, setIsModalObjectSelect] = useState(false);

  const { authDispatch } = useContext(AuthContext);
  const { profileDispatch } = useContext(ProfileContext);

  const [isModalProfile, setIsModalProfile] = useState(false);
  const [isModalLogout, setIsModalLogout] = useState(false);

  const [isNavigate, setIsNavigate] = useState(false);

  const { profile } = useProfile();

  const { setAction } = useAction(!!profile ? profile.uuid : null, () => {
    void profileInit(profileDispatch, ROOT_URL, null).then();
  });

  useEffect(() => {
    setAction(!!profile ? profile.uuid : null);
  }, [profile]);

  const navigate = useNavigate();

  const handleLogout = () => {
    authLogout(authDispatch, () => {
      navigate("/");
    });
  };

  function isMD5Hash(str) {
    // Проверяем, соответствует ли строка формату MD5 хэша.
    const md5Regex = /^[a-f0-9]{32}$/i;
    return md5Regex.test(str);
  }


  return (
    <div className="MenuHorizontal">
      <ul className="Menu__list">
        <li className="List__link">
          <ButtonIcon
            onClick={() => setIsNavigate(!isNavigate)}
            iconName={isNavigate ? "custom-close" : "custom-menu"}
            iconColor={"#20202090"}
            size={30}
          />
          <ButtonIcon
            iconName={"custom-circle-arrow-left"}
            iconColor={"#20202075"}
            size={24}
            style={{marginLeft: 12, transform: "rotate(90deg)"}}
            onClick={() => setIsHiddenMenu(!isHiddenMenu)}
          />
        </li>
      </ul>

      {(!!timeTrackingState.timeTrackingData
        && timeTrackingState.timeTrackingData.is_time_tracking
      ) && (
        <ul className="Menu__list">
          <li className="List__link">
            <button className="Action__button Action__button_blue Action__button_mini"
                    onClick={(!!timeTrackingState.timeTrackingData
                      && !!timeTrackingState.timeTrackingData.time_tracking_record
                    ) ? () => setIsModalTimeWorkCategory(true) : () => setIsModalObjectSelect(true)}
            >


              {(!!timeTrackingState.timeTrackingData
                && !!timeTrackingState.timeTrackingData.time_tracking_record
              ) ? (<>{timeTrackingState.timeTrackingData.time_tracking_record.time_work_category.name} <TimerWorkCategory object={timeTrackingState.timeTrackingData.time_tracking_record} /></>) : "Временной учет"}
            </button>
          </li>
        </ul>
      )}

      <ul className="Menu__list">
        {profile && (
          <li
            className="List__link List__link_header"
            onClick={() => setIsModalProfile(true)}
          >
            <div className="Link__text">
              <div className="Text__signature">
                {profile.login && (
                  <div
                    style={{
                      fontSize: 12,
                      opacity: 0.5,
                    }}
                  >
                    {profile.login}
                  </div>
                )}
                {profile.email && (
                  <div
                    style={{
                      fontSize: 12,
                      opacity: 0.5,
                    }}
                  >
                    {profile.email}
                  </div>
                )}
                {profile.phone && (
                  <div
                    style={{
                      fontSize: 12,
                      opacity: 0.5,
                    }}
                  >
                    {formatPhoneNumber(getPhoneNumber(profile.phone))}
                  </div>
                )}
              </div>
              <div
                className="Text__title"
              >
                {getAccountFio(profile)}
              </div>
            </div>
            {!isProfileRoleTypeTags(profile, [
              ROLE_CLIENT,
            ]) && (
              <div className="Link__text">
                <div
                  style={{
                    borderRadius: 5,
                    backgroundColor: "#fff",
                    boxShadow: "0 8px 12px rgba(0, 0, 0, 0.03)",
                    height: 36,
                    width: 36,
                    marginLeft: 8
                  }}
                >
                  {!!profile.avatar && isMD5Hash(profile.avatar) && (
                    <img height={36} width={36} style={{
                      borderRadius: 7,
                    }}  src={ROOT_URL + "/api/account/avatar/" + profile.avatar}/>
                  )}
                  {!!profile.avatar && !isMD5Hash(profile.avatar) && (
                    <img height={36} width={36} style={{
                      borderRadius: 7,
                    }}  src={"data:image/jpeg;base64, "+ profile.avatar}/>
                  )}
                  {!profile.avatar && (
                    <Avatar size={36} value={getAccountFio(profile)} />
                  )}
                </div>
              </div>
            )}

            {isProfileRoleTypeTags(profile, [
              ROLE_CLIENT,
            ]) && (
              <div className="Link__text">
                <div
                  style={{
                    marginLeft: 8
                  }}
                >
                  <div style={{
                    borderRadius: 36,
                    // backgroundColor: "#f3f3f3",
                    height: 36,
                    width: 36,
                  }}>
                    <Icon size={36} name={'custom-account-circle'} color={"#20202095"}/>
                  </div>
                </div>
              </div>
            )}
          </li>
        )}
        <li className="List__hr"></li>
        <li className="List__link">
          <ButtonIcon
            onClick={() => setIsModalLogout(true)}
            iconName={"custom-exit"}
            iconColor={"#20202090"}
            size={36}
          />
        </li>
      </ul>

      {!!isModalProfile && (
        <ModalProfile handleClose={() => setIsModalProfile(false)} />
      )}

      {!!isModalLogout && (
        <ModalLogout
          successfulRequest={() => (setIsModalLogout(false), handleLogout())}
          handleClose={() => setIsModalLogout(false)}
        />
      )}

      {isNavigate && (
        <div  style={{
          position: "fixed",
          top: 58,
          bottom: 0,
          left: 0,
          right: 0,
          borderTop: "1px solid #20202025",
          backgroundColor: "#f1f1f1",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
          alignItems: "stretch",
          height: "100%",
          overflow: "hidden",
        }}>
          <ScrollBlock
            style={{
              width: "100%",
              height: "calc(100% - 58px)",
            }}
          >
            <div className="Menu__navigate">
              <div>
                <MenuRouting onChange={() => setIsNavigate(false)} />
              </div>
            </div>
          </ScrollBlock>
        </div>
      )}

      {!!isModalObjectSelect && (
        <ModalSelectTimeWorkCategory
          succesfullRequest={() => timeTrackingInit(timeTrackingDispatch, null).then()}
          handleClose={() => setIsModalObjectSelect(false)}
        />
      )}
      {isModalTimeWorkCategory && (
        <ModalTimeWorkCategory
          profile={profile}
          object={timeTrackingState.timeTrackingData.time_tracking_record}
          succesfullRequest={() => timeTrackingInit(timeTrackingDispatch, null).then()}
          handleClose={() => setIsModalTimeWorkCategory(false)}
        />
      )}

    </div>
  );
}
export default MenuHorizontal;
