import React, {Fragment} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import "./KanbanMenu.scss";
import {ScrollBlock} from "../../../../components/ScrollBlock/ScrollBlock";
import {ROOT_URL} from "../../../../config/url";
import {ButtonIcon, useAction, useObject} from "@vokymlak/kabinet-ui";

export const KanbanMenu = () => {
  const navigate = useNavigate();

  const {
    object: [object],
    update: [_isUpdateObject, _setIsUpdateObject, updateObject],
  } = useObject({apiUrl: "/api/kanban-spaces/view", rootUrl: ROOT_URL});

  useAction(["kanban"], () => updateObject());

  const getTopicCount = (object, uuid) => {
    let count = null;
    if (!!object && !!object.kanban_spaces) {
      object.kanban_spaces.forEach(t => {
        if (t.uuid === uuid) {
          count = t.count_kanban_tasks;
        }
      })
    }
    return count;
  }

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="TicketsMenu">
        <div className="Menu__header">
          <div className="Header__text">
            Канбан
          </div>

          <ButtonIcon
            iconName={"custom-settings"}
            iconColor={"#20202033"}
            size={24}
            onClick={() => navigate("/kanban/spaces")}
          />
        </div>

        {/*<ul className="Menu__list" style={{paddingBottom: 0}}>*/}
        {/*  <li className="List__link">*/}
        {/*    <NavLink*/}
        {/*      className="Link__text"*/}
        {/*      to={"/tickets/author"}*/}
        {/*    >*/}
        {/*      Мои задачи*/}
        {/*      {!!object && !!object.count_author_tickets && (*/}
        {/*        <span style={{*/}
        {/*          marginLeft: 4,*/}
        {/*          opacity: .75,*/}
        {/*          fontSize: 12*/}
        {/*        }}>*/}
        {/*            ({object.count_author_tickets})*/}
        {/*          </span>*/}
        {/*      )}*/}

        {/*      {!!object && !object.count_author_tickets && (*/}
        {/*        <span style={{*/}
        {/*          marginLeft: 4,*/}
        {/*          opacity: .75,*/}
        {/*          fontSize: 12*/}
        {/*        }}>*/}
        {/*            (0)*/}
        {/*          </span>*/}
        {/*      )}*/}
        {/*    </NavLink>*/}
        {/*  </li>*/}
        {/*  <li className="List__link">*/}
        {/*    <NavLink*/}
        {/*      className="Link__text"*/}
        {/*      to={"/tickets/executor"}*/}
        {/*    >*/}
        {/*      Я исполнитель*/}
        {/*      {!!object && !!object.count_executor_tickets && (*/}
        {/*        <span style={{*/}
        {/*          marginLeft: 4,*/}
        {/*          opacity: .75,*/}
        {/*          fontSize: 12*/}
        {/*        }}>*/}
        {/*            ({object.count_executor_tickets})*/}
        {/*          </span>*/}
        {/*      )}*/}

        {/*      {!!object && !object.count_executor_tickets && (*/}
        {/*        <span style={{*/}
        {/*          marginLeft: 4,*/}
        {/*          opacity: .75,*/}
        {/*          fontSize: 12*/}
        {/*        }}>*/}
        {/*            (0)*/}
        {/*          </span>*/}
        {/*      )}*/}
        {/*    </NavLink>*/}
        {/*  </li>*/}
        {/*</ul>*/}

        {/*<ul className="Menu__list">*/}
        {/*  <li className="List__link">*/}
        {/*    <NavLink*/}
        {/*      className="Link__catalog"*/}
        {/*      to={"/tickets/tracker"}*/}
        {/*    >*/}
        {/*      Трекер*/}
        {/*    </NavLink>*/}
        {/*  </li>*/}
        {/*</ul>*/}

        {!!object && (
          <ul className="Menu__list" style={{paddingBottom: 0}}>
            <div
              className={"List__title"}
              style={{opacity: .75, cursor: 'default', fontSize: 16}}
            >
              {/*По доскам*/}

            </div>
            {!!object.kanban_spaces && object.kanban_spaces.map((item) => (
              <Fragment key={item.uuid}>
                <li className="List__link">
                  <NavLink
                    className="Link__text"
                    to={"/kanban/space/" + item.uuid}
                  >
                    {item.title}
                    {!!getTopicCount(object, item.uuid) && (
                      <span style={{
                        marginLeft: 4,
                        opacity: .75,
                        fontSize: 12
                      }}>
                    ({getTopicCount(object, item.uuid)})
                  </span>
                    )}

                    {!getTopicCount(object, item.uuid) && (
                      <span style={{
                        marginLeft: 4,
                        opacity: .75,
                        fontSize: 12
                      }}>
                    (0)
                  </span>
                    )}
                  </NavLink>
                </li>
              </Fragment>
            ))}
          </ul>
        )}

      </div>
    </ScrollBlock>
  );
};
