import React, {useEffect, useState} from "react";
import MenuVertical from "./Menu/MenuVertical/MenuVertical";
import "../App.scss";
import {
  ButtonIcon,
  useAuth,
  useLocalStorage,
  useProfile,
  useWindowSize,
  WebsocketProvider,
} from "@vokymlak/kabinet-ui";
import {WS_URL} from "../config/url";
import MenuHorizontal from "./Menu/MenuHorizontal/MenuHorizontal";
import {Routing} from "./Routing";
import {TimeTrackingProvider} from "../contexts/time-tracking";
import {Auth} from "./Auth/Auth";

export const Screen = () => {
  const {profile} = useProfile();
  const {authToken} = useAuth();
  const {windowSize} = useWindowSize();

  const [kabinetSetting, setKabinetSetting] = useLocalStorage("kabinet", {
    isHiddenMenu: false,
  });

  const [isHiddenMenu, setIsHiddenMenu] = useState(kabinetSetting.isHiddenMenu || false);

  useEffect(() => {
    setKabinetSetting({
      isHiddenMenu,
    });
  }, [
    isHiddenMenu,
  ]);

  return (
    !!windowSize &&
    <div className={"App " + ((windowSize.width < 1024 ||
      (windowSize.width < windowSize.height)) ? 'App_horizontal' : 'App_vertical')}>
      {!!authToken && !!profile && (
        <WebsocketProvider wsUrl={WS_URL}>
          <TimeTrackingProvider>
            {!isHiddenMenu && (
              <div className="Navigate">
                {(windowSize.width < 1024 ||
                  (windowSize.width < windowSize.height))
                  ? <MenuHorizontal setIsHiddenMenu={setIsHiddenMenu} isHiddenMenu={isHiddenMenu}/>
                  : <MenuVertical setIsHiddenMenu={setIsHiddenMenu} isHiddenMenu={isHiddenMenu}/>}
              </div>
            )}
            <div className="Content">
              <Routing/>
            </div>
          </TimeTrackingProvider>
        </WebsocketProvider>
      )}

      {isHiddenMenu && (<>
        {(windowSize.width < 1024 ||
          (windowSize.width < windowSize.height))
          ? <div
            style={{
              position: "absolute",
              top: -10,
              left: 0
            }}
          >
            <ButtonIcon
              iconName={"custom-circle-arrow-left"}
              iconColor={"#20202075"}
              size={24}
              style={{transform: "rotate(-90deg)", left: 24}}
              onClick={() => setIsHiddenMenu(!isHiddenMenu)}
            />
          </div>
          : <div
            style={{
              position: "absolute",
              top: 20,
              left: -34
            }}
          >
            <ButtonIcon
              iconName={"custom-circle-arrow-right"}
              iconColor={"#20202075"}
              size={24}
              style={{left: 24}}
              onClick={() => setIsHiddenMenu(!isHiddenMenu)}
            />
          </div>}
      </>)}

      {!authToken && (
        <Auth/>
      )}
    </div>
  );
};
