import React from "react";
import {NavLink} from "react-router-dom";
import "./SettingsMenu.scss";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";

export const SettingsMenu = () => {
  return (
    <ScrollBlock
      style={{width: '100%', height: '100%'}}>
      <div className="SettingsMenu">
        <ul className="Menu__list"></ul>

        <ul className="Menu__list Menu__list_box">
          <li className="List__header">
            <div className="Header__text">Заказы</div>
          </li>
            <li className="List__link" style={{paddingTop: 12}}>
              <NavLink
                className={"Link__text"}
                to={"/settings/import-order-states"}
              >
                Статусы заказов
              </NavLink>
            </li>
            <li className="List__link">
              <NavLink
                className={"Link__text"}
                to={"/settings/order-state-groups"}
              >
                Группы статусов
              </NavLink>
            </li>
            <li className="List__link">
              <NavLink
                className={"Link__text"}
                to={"/settings/funnel-order-states"}
              >
                Логика статусов
              </NavLink>
            </li>

          <li className="List__link">
            <NavLink
              className={"Link__text"}
              to={"/settings/buyer-order-neural"}
            >
              Оценка сложности
            </NavLink>
          </li>
        </ul>


        <ul className="Menu__list Menu__list_box">
          <li className="List__header">
            <div className="Header__text">Учет времени</div>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink className={"Link__text"} to={"/settings/time-work-categories"}>
              Категории работ
            </NavLink>
          </li>
        </ul>
      </div>
    </ScrollBlock>
  );
}
