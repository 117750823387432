import React, { useEffect, useRef, useState } from "react";
import { ROOT_URL } from "../../../../config/url";
import {
  useList,
  useProfile,
  useAction,
  useUnroll, useObject,
} from "@vokymlak/kabinet-ui";
import {useViewer} from "../../../../components/Viewer";
import {useParams} from "react-router-dom";

function useKanbanSpace(props) {
  const { isCount, isArchive, isTopicNull, authorId, executorId } = props;
  const { kanbanSpaceUuid } = useParams();
  const { profile } = useProfile();
  const [status, setStatus] = useState("null");
  const [searchQuery, setSearchQuery] = useState("null");

  const [selectAuthor, setSelectAuthor] = useState(0);
  const [selectEmployee, setSelectEmployee] = useState(0);
  const changeSelectEmployee = (accountId) => {
    setSelectEmployee(Number(accountId));
  };
  const changeSelectAuthor = (accountId) => {
    setSelectAuthor(Number(accountId));
  };

  const { viewer } = useViewer();

  const changeViewer = (image, mimeType) => {
    viewer(image, mimeType);
  };

  const {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject, changeUpdateObject],
    params: [_apiUrlParamsObject, setApiUrlParamsObject],
    setApiUrlParams,
    setApiUrl
  } = useObject({
    apiUrl: "/api/kanban-space/"+kanbanSpaceUuid+"/page",
    rootUrl: ROOT_URL,
  });

  useEffect(() => {
    setApiUrl("/api/kanban-space/"+kanbanSpaceUuid+"/page");
  }, [kanbanSpaceUuid]);

  const {
    list: [employees],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
  });

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  useAction("kanban", () => changeUpdateObject());

  const inputSearchText = useRef(null);


  useEffect(() => {
    let params = null;
    if (!!selectEmployee && selectEmployee !== 0) {
      params = {
        ...params,
        "executor": selectEmployee,
      };
    }
    if (!!selectAuthor && selectAuthor !== 0) {
      params = {
        ...params,
        "author": selectAuthor,
      };
    }
    params = {
      ...params,
      "query": searchQuery,
    };
    setApiUrlParams({ ...params });
  }, [selectEmployee, selectAuthor, searchQuery]);

  const onChangeSearchText = () => {
    setSearchQuery(inputSearchText.current.value);
  };

  return {
    profile,

    setObject,
    object,
    isUpdateObject,
    setIsUpdateObject,


    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    changeViewer,

    isCount,
    isArchive,
    isTopicNull,
    kanbanSpaceUuid,
    authorId,
    executorId,

    employees,

    setStatus,
    status,

    selectAuthor,
    selectEmployee,
    changeSelectEmployee,

    inputSearchText,
    onChangeSearchText,
    searchQuery,
  };
}

export default useKanbanSpace;
