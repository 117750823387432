import React, {useRef, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  Modal,
  Select,
  useList,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";
import ModalSpecificationItemSelect
  from "../../ScreenNomenclatures/NomenclaturePage/components/NomenclatureSpecifications/ModalSpecificationItemSelect";
import ModalSiteOptOrderItemSelect from "./ModalSiteOptOrderItemSelect";

export const ModalAddSiteOptOrderItem = (props) => {
  const selectNomenclatureUuid = useRef(null);

  const [selectItem, setSelectItem] = useState(null)
  const [isModalSelectItem, setIsModalSelectItem] = useState(false)

  const { notification } = useNotification();

  const {
    list: [list],
  } = useList({
    apiUrl: "api/nomenclatures/list",
    rootUrl: ROOT_URL,
  });

  const {
    object: [object],
  } = useObject({
    apiUrl: "api/site-opt-order/" + props.object.uuid,
    rootUrl: ROOT_URL,
  });

  const changeAdd = (event) => {
    event.preventDefault();

    // let nomenclatureUuid = selectNomenclatureUuid.current.value;
    //
    // nomenclatureUuid = nomenclatureUuid.length > 0
    //   ? nomenclatureUuid === "null"
    //     ? null
    //     : nomenclatureUuid
    //   : null
    //
    //   if (!nomenclatureUuid || nomenclatureUuid.length === 0) {
    //   notification("Выберите номенклатуру из списка", "red");
    //   return;
    // }

    if (!selectItem) {
      notification("Укажите номенклатуру", "red");
      return;
    }

    const jsonData = JSON.stringify({
      nomenclature: {
        uuid: selectItem.uuid,
      },
    });

    axios
      .post("/api/site-opt-order/" + props.object.uuid + "/item/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Позиция добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const selectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
        const option = {
          value: item.uuid,
          title: item.name,
        };

        options.push(option);
    });

    return options;
  };

  return (
    !!list &&
    !!object && (
      <>
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div
            className="Form__title"
            style={{ fontSize: 19, fontWeight: 700 }}
          >
            №{object.id}{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
            <div className="Text__signature">Добавить позицию</div>
          </div>


          {!selectItem && (
            <div className="Form__field Form__field_last"
                 style={{
                   cursor: "pointer"
                 }}
                 onClick={() => setIsModalSelectItem(true)}>
              <div className="Field__label">
                Номенклатура
              </div>
              <div className="Field__text">
                -
              </div>
            </div>
          )}

          {!!selectItem && (
            <div className="Form__field Form__field_last"
                 style={{
                   cursor: "pointer"
                 }}
                 onClick={() => setIsModalSelectItem(true)}>
              <div className="Field__label">
                Номенклатура
              </div>
              <div className="Field__text">
                {selectItem.id + ". " + selectItem.name   + (!!selectItem.article ? " - " + selectItem.article : "")}
              </div>
            </div>
          )}

          {/*<div className="Form__field">*/}
          {/*  <Select*/}
          {/*    isFixed={true}*/}
          {/*    reference={selectNomenclatureUuid}*/}
          {/*    options={*/}
          {/*      (!!list && selectOptions(list)) ||*/}
          {/*      []*/}
          {/*    }*/}
          {/*    label="Номенклатура"*/}
          {/*  />*/}
          {/*</div>*/}



          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>

          {/*<div className="Form__actions Form__actions_center">*/}
          {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
          {/*    Отмена*/}
          {/*  </div>*/}
          {/*</div>*/}
        </form>
      </Modal>

  {isModalSelectItem && (
    <ModalSiteOptOrderItemSelect
      item={selectItem}
      successfulRequest={item =>
        setSelectItem(item)
      }
      handleClose={() => setIsModalSelectItem(false)}
    />
  )}
</>
    )
  );
};
