import React, { useRef, useState } from "react";
import {
  useNotification,
  getErrorMessageByKey,
  jsonFormatText,
  isValidateNullText,
  Modal,
  Input,
  Select, newUuid,
} from "@vokymlak/kabinet-ui";

function ModalAddEditorTable(props) {
  const [isPublic, setIsPublic] = useState(true);
  const inputColumn = useRef(null);
  const inputLine  = useRef(null);

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const column = Number(inputColumn.current.value);
    const line = Number(inputLine.current.value);

    if (column === 0 || line === 0) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const textTable =
      ((isPublic && ('<thead>' + '<tr>'+'<th> </th>'.repeat(column)+'</tr>' + '</thead>')) || '')  +
      '<tbody>' + ('<tr>'+'<td> </td>'.repeat(column)+'</tr>').repeat(line) + '</tbody>';



    let selection = window.getSelection();
    // Устанавливаем выделение заново
    selection.removeAllRanges();
    selection.addRange(props.range);

    props.successfulRequest(`<p><br></p><figure id="${newUuid()}"><table>${textTable}</table></figure><p><br></p>`);
    props.handleClose();
  };

  const handleClose = () => {
    let selection = window.getSelection();
    // Устанавливаем выделение заново
    selection.removeAllRanges();
    selection.addRange(props.range);

    props.handleClose();
  };

  return (
    <Modal handleClose={() => handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">Таблица</div>

        <div className="Form__field">
          <Input reference={inputColumn} type="number" label="Количество столбцов *" />
        </div>

        <div className="Form__field">
          <Input reference={inputLine} type="number" label="Количество строк *" />
        </div>

        <div className="Form__field Form__field_last">
          <div
            className="Button__swith"
            style={{
              padding: "8px",
              width: "100%",
              border: isPublic
                ? "2px solid #ffe082"
                : "2px dashed #20202015",
              backgroundColor: isPublic ? "#ffe082" : "#ffffff",
              borderRadius: 6,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setIsPublic(!isPublic)}
          >
            <div
              className="Swith__text"
              style={{
                fontSize: 17,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              Заголовок таблицы
            </div>
            <div
              className={
                "Swith Swith_gray" +
                ((isPublic && " Swith_active Swith_active_gray") || "")
              }
            ></div>
          </div>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => handleClose()}>
            Отмена
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ModalAddEditorTable;
