import React, { Fragment, useEffect, useState } from "react";
import ModalEditKanbanSpace from "./ModalEditKanbanSpace";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ModalAddKanbanSpace from "./ModalAddKanbanSpace";
import {
  ButtonIcon,
  Loading,
} from "@vokymlak/kabinet-ui";
import { ScrollBlock } from "../../../../components/ScrollBlock/ScrollBlock";
import ModalAddKanbanState from "./ModalAddKanbanState";
import ModalEditKanbanState from "./ModalEditKanbanState";

export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

export const KanbanSpacesPage = (props) => {
  const {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    onDragEnd,
  } = props;

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalObjectEdit, setModalObjectEdit] = useState(null);
  const [modalItemAdd, setModalItemAdd] = useState(null);
  const [modalItemEdit, setModalItemEdit] = useState(null);

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="TicketsPage" style={{
        backgroundColor: "#f1f1f1"
      }}>
        <div className="Page__title">
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
              }}
            >
              Настройки досок
              <span
                style={{
                  fontSize: 13,
                  opacity: 0.5,
                  fontWeight: 400
                }}
              >
                          Добавьте доску и настройте отображение
                        </span>
            </div>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{ marginLeft: 8 }}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalObjectAdd(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              <DragDropContext onDragEnd={onDragEnd}>
                <StrictModeDroppable droppableId={"droppable"}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {!!list &&
                        list.map((item, index) => (
                          <Draggable
                            draggableId={String(item.uuid)}
                            index={index}
                            key={"draggable_" + item.uuid}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <Fragment key={"item_" + item.uuid}>
                                  <li
                                    className={
                                      isUnroll(item.uuid)
                                        ? "List__item active"
                                        : "List__item"
                                    }
                                    onClick={() => changeUnroll(item.uuid)}
                                  >
                                    <div className="Item__title">
                                      <div
                                        className="Element__title"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {item.title}
                                      </div>
                                    </div>
                                    <div className="Item__settings">
                                      <div className="Element__datetime"></div>
                                    </div>
                                  </li>

                                  {isUnroll(item.uuid) && (
                                    <li className="List__content">
                                      <div className="Content__header">
                                        <div className="Header__title">
                                            <div
                                              className="Button__text"
                                              style={{
                                                marginRight: 24,
                                              }}
                                              onClick={() =>
                                                setModalItemAdd(item)
                                              }
                                            >
                                              Добавить статус
                                            </div>
                                        </div>
                                        <div className="Header__settings">
                                          <ButtonIcon
                                            tooltipText={"Редактировать"}
                                            iconName={"custom-edit"}
                                            iconColor={"#202020"}
                                            size={36}
                                            style={{ marginLeft: 12 }}
                                            onClick={() => setModalObjectEdit(item)}
                                          />
                                        </div>
                                      </div>


                                      {!!item.kanban_states && (
                                        <div>
                                          <ul className="List"  style={{
                                            paddingTop: 8,
                                            paddingLeft: 8,
                                            paddingRight: 8,
                                            border: "2px solid #dbdbdb",
                                            borderRadius: 11
                                          }}>
                                            <DragDropContext onDragEnd={onDragEnd}>
                                              <StrictModeDroppable droppableId={item.uuid}>
                                                {(provided) => (
                                                  <div ref={provided.innerRef} {...provided.droppableProps}>
                                                    {!!item.kanban_states &&
                                                      item.kanban_states.map((state, stateIndex) => (
                                                        <Draggable
                                                          draggableId={String(state.uuid)}
                                                          index={stateIndex}
                                                          key={"draggable_" + state.uuid}
                                                        >
                                                          {(provided) => (
                                                            <div
                                                              ref={provided.innerRef}
                                                              {...provided.dragHandleProps}
                                                              {...provided.draggableProps}
                                                            >
                                                              <Fragment key={"state_" + state.uuid}>
                                                                <li
                                                                  className={
                                                                    isUnroll(state.uuid)
                                                                      ? "List__item active"
                                                                      : "List__item"
                                                                  }
                                                                  onClick={() => changeUnroll(state.uuid)}
                                                                >
                                                                  <div className="Item__title">
                                                                    <div
                                                                      className="Element__title"
                                                                      style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                      }}
                                                                    >
                                                                      {state.title}
                                                                    </div>
                                                                  </div>
                                                                  <div className="Item__settings">
                                                                    <div className="Element__datetime"></div>
                                                                  </div>
                                                                </li>

                                                                {isUnroll(state.uuid) && (
                                                                  <li className="List__content">
                                                                    <div className="Content__header">
                                                                      <div className="Header__title">
                                                                      </div>
                                                                      <div className="Header__settings">
                                                                        <ButtonIcon
                                                                          tooltipText={"Редактировать"}
                                                                          iconName={"custom-edit"}
                                                                          iconColor={"#202020"}
                                                                          size={36}
                                                                          style={{ marginLeft: 12 }}
                                                                          onClick={() => setModalItemEdit(state)}
                                                                        />
                                                                      </div>
                                                                    </div>
                                                                  </li>
                                                                )}
                                                              </Fragment>
                                                            </div>
                                                          )}
                                                        </Draggable>
                                                      ))}
                                                    {provided.placeholder}
                                                  </div>
                                                )}
                                              </StrictModeDroppable>
                                            </DragDropContext>
                                          </ul>
                                        </div>
                                      )}
                                    </li>
                                  )}
                                </Fragment>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              </DragDropContext>
            </ul>
          </div>
        )}

        {!!modalObjectEdit && (
          <ModalEditKanbanSpace
            object={modalObjectEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalObjectEdit(null)}
          />
        )}
        {!!isModalObjectAdd && (
          <ModalAddKanbanSpace
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}

        {!!modalItemEdit && (
          <ModalEditKanbanState
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}
        {!!modalItemAdd && (
          <ModalAddKanbanState
            object={modalItemAdd}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemAdd(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
};
