import React, {useState} from "react";
import moment from "moment";
import {ButtonIcon, Marker, useUnroll} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import styles from "../PlanProviding/Orders.module.scss";
import styleContent from "../../../styles/modules/Block.module.scss";
import ModalPlanWarehouseUpload from "./ModalPlanWarehouseUpload";
import ModalPlanWarehouseRatio from "./ModalPlanWarehouseRatio";
import {BuyerOrderObject} from "../../ScreenOrders/BuyerOrderObject";
import NomenclatureSpecifications
  from "../../ScreenNomenclatures/NomenclatureItem/NomenclatureSpecifications/NomenclatureSpecifications";

function PlanWarehouse() {
  const [selectMonthYear, setSelectMonthYear] = useState(null);
  const [selectRatio, setSelectRatio] = useState(null);
  const [isModalMonthYear, setIsModalSelectMonthYear] = useState(false);
  const [isModalSelectRatio, setIsModalSelectRatio] = useState(false);
  const [object, setObject] = useState(null);


  const monthText = (month) => {
    const months = [
      "январь",
      "февраль",
      "март",
      "апрель",
      "май",
      "июнь",
      "июль",
      "август",
      "сентябрь",
      "октябрь",
      "ноябрь",
      "декабрь",
    ];

    return (
      months[Number(month) - 1].charAt(0).toUpperCase() +
      months[Number(month) - 1].slice(1)
    );
  };

  const {isUnroll, changeUnroll} = useUnroll();

  const [tab, setTab] = useState('items');

  const getRatio = (amount, ratio) => {
    if (!!amount && !!ratio) {
      return Math.round((amount / ratio) * 100) / 100;
    }

    return null;
  }

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            План на сырьё

            {/*{!!object && (*/}
            {/*  <ButtonIconExport*/}
            {/*    tooltipText={"Выгрузить"}*/}
            {/*    iconName={"custom-download"}*/}
            {/*    iconColor={"#202020"}*/}
            {/*    size={24}*/}
            {/*    style={{ marginLeft: 12 }}*/}
            {/*    apiUrl={'/api/plan/warehouse/month/'  + selectMonth + '/year/' + selectYear + '/xlsx'}*/}
            {/*    rootUrl={ROOT_URL}*/}
            {/*    mimeType={*/}
            {/*      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"*/}
            {/*    }*/}
            {/*    fileName={*/}
            {/*      ("план_на_сырьё"+"_на_"+moment().format("DD_MM_YYYY")+".xlsx").toLowerCase()}*/}
            {/*  />*/}
            {/*)}*/}
          </div>

          <div className="Title__settings">
            {!!selectMonthYear && (
              <div
                className="Title__text"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 21,
                  fontWeight: 700,
                  marginLeft: 12,
                }}
              >
                {monthText(selectMonthYear.split("-")[1])}
                <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                  {selectMonthYear.split("-")[0]}
                </span>
                {/*<ButtonIcon*/}
                {/*  tooltipText={"Назад"}*/}
                {/*  iconName={"custom-circle-arrow-left"}*/}
                {/*  iconColor={"#202020"}*/}
                {/*  size={24}*/}
                {/*  style={{ marginLeft: 12 }}*/}
                {/*  onClick={() => changeSelectMonthMinus()}*/}
                {/*/>*/}
                {/*<ButtonIcon*/}
                {/*  tooltipText={"В&nbsp;перёд"}*/}
                {/*  iconName={"custom-circle-arrow-right"}*/}
                {/*  iconColor={"#202020"}*/}
                {/*  size={24}*/}
                {/*  style={{ marginLeft: 6 }}*/}
                {/*  onClick={() => changeSelectMonthPlus()}*/}
                {/*/>*/}
              </div>
            )}
            {/*<ButtonIcon*/}
            {/*  tooltipText={"Обновить"}*/}
            {/*  iconName={"custom-change-circle"}*/}
            {/*  iconColor={"#20202075"}*/}
            {/*  size={28}*/}
            {/*  style={{ marginLeft: 12 }}*/}
            {/*  onClick={() => (*/}
            {/*    setObject(null),*/}
            {/*      setIsUpdateObject(!isUpdateObject)*/}
            {/*  )}*/}
            {/*/>*/}
            <ButtonIcon
              tooltipText={"Редактировать"}
              iconName={"custom-edit"}
              iconColor={"#202020"}
              size={36}
              style={{marginLeft: 12}}
              onClick={() => setIsModalSelectMonthYear(true)}
            />
          </div>
        </div>


        {!!object && (
          <div className="Page__title" style={{height: "auto"}}>
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className={styles._block}>
                <div className={styles._block__content} style={{marginBottom: 4}}>
                  <div
                    className={styles._card + " " + styles._card_hover + " " + (tab === 'orders' ? styles._card_active : "")}
                    onClick={() => setTab('orders')}>
                    <div className={styles._card__number}>{!!object.orders ? object.orders.length : 0}</div>
                    <div className={styles._card__title}>Заказы</div>
                  </div>
                  <div
                    className={styles._card + " " + styles._card_hover + " " + (tab === 'orderItems' ? styles._card_active : "")}
                    onClick={() => setTab('orderItems')}>
                    <div className={styles._card__number}>{!!object.order_items ? object.order_items.length : 0}</div>
                    <div className={styles._card__title}>Позиции</div>
                  </div>
                  <div
                    className={styles._card + " " + styles._card_hover + " " + (tab === 'items' ? styles._card_active : "")}
                    onClick={() => setTab('items')}>
                    <div
                      className={styles._card__number}>{!!object.nomenclatures ? object.nomenclatures.length : 0}</div>
                    <div className={styles._card__title}>Номенклатура</div>
                  </div>

                  {/* Разделитель */}

                  <div style={{
                    height: 102,
                    width: 2,
                    backgroundColor: "#20202010",
                    borderRadius: 4,
                    marginRight: 8,
                    marginBottom: 8,
                    textAlign: "right"
                  }}>
                  </div>

                  <div
                    className={styles._card}>
                    <div
                      className={styles._card__number}>{(!!object.amount && new Intl.NumberFormat("ru-RU").format(Math.round(Number(
                      (object.amount / 100).toFixed(2))))) || 0}</div>
                    <div className={styles._card__title}>Сумма</div>
                  </div>
                  <div
                    className={styles._card + " " + styles._card_hover}
                    onClick={() => setIsModalSelectRatio(true)}>
                    <div
                      className={styles._card__number} style={{
                      display: "flex"
                    }}>
                      {(!!selectRatio && new Intl.NumberFormat("ru-RU").format(Math.round(Number(
                        (selectRatio / 100).toFixed(2))))) || "-"}
                      <ButtonIcon
                        // tooltipText={"Редактировать"}
                        iconName={"custom-edit"}
                        iconColor={"#202020"}
                        size={32}
                        style={{marginLeft: 12}}
                        onClick={() => null}
                      />
                    </div>
                    <div className={styles._card__title}>Соотношение</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <hr className={"Page__hr"}/>


        {!object && (
          <div className={"Page__loader"}>
            {/*<Loading />*/}
            Нет данных для отображения
          </div>
        )}

        {!!object && tab === 'items' && (
          <div className="Page__title" style={{height: "auto"}}>
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 21,
                fontWeight: 700,
              }}
            >
              Номенклатура
              {!!object.nomenclatures && (
                <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({object.nomenclatures.length})
              </span>
              )}
            </div>
          </div>
        )}

        {!!object && tab === 'orderItems' && (
          <div className="Page__title" style={{height: "auto"}}>
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 21,
                fontWeight: 700,
              }}
            >
              Позиции
              {!!object.order_items && (
                <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({object.order_items.length})
              </span>
              )}
            </div>
          </div>
        )}

        {tab === 'orders' && !!object && (
          <div className="Page__title" style={{height: "auto"}}>
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 21,
                fontWeight: 700,
              }}
            >
              Заказы
              {!!object.orders && (
                <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({object.orders.length})
              </span>
              )}
            </div>
          </div>
        )}

        {!!object && (
          <div className="Page__content">

            {tab === 'items' && !!object.nomenclatures && (
              <table className="Table" style={{marginBottom: 24}}>
                <thead className="Thead">
                <tr className="Table__tr Table__tr_padding Table__tr_border">
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor: "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">№</div>
                  </th>
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor: "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Номенклатура</div>
                  </th>
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor: "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Артикль</div>
                  </th>
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor: "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Номер</div>
                  </th>
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor: "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Количество</div>
                  </th>
                  <th
                    className="Table__th Table__th_border"
                    style={{
                      backgroundColor: "#dfdfdf",
                    }}
                  >
                    <div className="Th__title">Ед. измерения</div>
                  </th>
                </tr>
                </thead>
                <tbody>
                {object.nomenclatures.map((resource, index) => (
                  <tr
                    className="Table__tr Table__tr_hover Table__tr_padding Table__tr_border"
                    key={"item_" + index}
                  >
                    <td className="Table__td Table__td_border">
                      <div className="Td__text Td__text_center">
                        {resource.nomenclature.id}
                      </div>
                    </td>
                    <td className="Table__td Table__td_border">
                      <div className="Td__text">
                        <div style={{
                          fontSize: 17,
                          fontWeight: 400,
                        }}>
                          {resource.nomenclature.name}
                          {resource.nomenclature.specifications &&
                            resource.nomenclature.specifications.map((specification) => (
                              <div
                                key={"marker_" + specification.uuid}
                                className="Marker Marker_left Marker_inline Marker_small"
                              />
                            ))}
                        </div>
                      </div>
                    </td>
                    <td className="Table__td Table__td_border">
                      <div className="Td__text Td__text_center">
                        {!!resource.nomenclature.article && resource.nomenclature.article || "-"}
                      </div>
                    </td>
                    <td className="Table__td Table__td_border">
                      <div className="Td__text Td__text_center">
                        {resource.nomenclature.code}
                      </div>
                    </td>
                    <td className="Table__td Table__td_border">
                      <div className="Td__text Td__text_right">
                        <div style={{
                          fontSize: 17,
                          fontWeight: 700,
                        }}>
                          {(!!resource.nomenclature.warehouse_quantity && Number((!!getRatio(object.amount, selectRatio) && (resource.nomenclature.warehouse_quantity / getRatio(object.amount, selectRatio))) || resource.nomenclature.warehouse_quantity).toFixed(3)) || "-"}
                        </div>
                      </div>
                    </td>
                    <td className="Table__td Table__td_border">
                      <div className="Td__text ">
                        <div style={{
                          fontSize: 17,
                          fontWeight: 700,
                        }}>
                          {!!resource.nomenclature.measurement_type && (" " + resource.nomenclature.measurement_type.designation)}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            )}

            {!!object && (
              <div className="Page__content">


                {tab === 'orderItems' && !!object.order_items && (

                  <ul className="List">
                    {object.order_items.map((item, index) => (
                      <React.Fragment key={"resource_" + item.uuid}>
                        <li
                          className={
                            isUnroll(item.uuid) ? "List__item active" : "List__item"
                          }
                          onClick={() => changeUnroll(item.uuid)}
                        >
                          <div className="Item__title">
                            <div className="Element__title">
                              {index + 1}.&nbsp;
                              {item.nomenclature.name}
                              {item.nomenclature_specification_count > 0 && (
                                <Marker color={"#9e9e9e"} size={8} style={{marginLeft: 12}}/>
                              )}
                            </div>
                          </div>
                          <div className="Item__settings">
                            <div
                              style={{
                                textAlign: "right",
                                fontSize: 24,
                                fontWeight: 700,
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              {(!!getRatio(object.amount, selectRatio) && (Math.round((item.count_quantity / getRatio(object.amount, selectRatio)) * 100) / 100)) || item.count_quantity}
                            </div>
                          </div>
                        </li>

                        {isUnroll(item.uuid) && (
                          <li className="List__content">
                            <div className={styleContent._block} style={{
                              marginBottom: 12
                            }}>
                              <div className={styleContent._block__title}>Заказы</div>
                              <div className={styleContent._block__content}>
                                {!!item.orders && item.orders.map(order => (
                                  <span key={item.uuid + "_" + order.uuid}
                                        style={{
                                          fontSize: 17,
                                          fontWeight: 700,
                                          marginRight: 12
                                        }}>
                              {order.number}
                                    &nbsp;
                                    {!!order.date &&
                                      "от " +
                                      moment(order.date.substring(0, 19)).format(
                                        "DD.MM.YYYY"
                                      )}
                            </span>
                                ))}
                              </div>
                            </div>

                            <div className={styleContent._block}>
                              <div className={styleContent._block__title}>Спецификация</div>
                              <div className={styleContent._block__content}>
                                {item.nomenclature_specification_count > 0 && (
                                  <NomenclatureSpecifications
                                    nomenclature={item.nomenclature}
                                    quantity={(!!getRatio(object.amount, selectRatio) && (Math.round((item.count_quantity / getRatio(object.amount, selectRatio)) * 100) / 100)) || item.count_quantity}
                                    count={(!!getRatio(object.amount, selectRatio) && (Math.round((item.count_quantity / getRatio(object.amount, selectRatio)) * 100) / 100)) || item.count_quantity}
                                    isFirst={true}
                                  />
                                )}
                              </div>
                            </div>
                          </li>
                        )}
                      </React.Fragment>
                    ))}
                  </ul>
                )}

                {tab === 'orders' && !!object.orders && (
                  <ul className="List">
                    {object.orders.map((item) => (
                      <BuyerOrderObject
                        key={"item_" + item.uuid}
                        object={item}
                        isUnroll={isUnroll}
                        changeUnroll={changeUnroll}
                        searchQuery={''}
                        // isUpdateList={isUpdateObject}
                        // setIsUpdateList={setIsUpdateObject}
                      />
                    ))}
                  </ul>
                )}

              </div>
            )}

          </div>
        )}

        {isModalMonthYear && (
          <ModalPlanWarehouseUpload
            monthYear={selectMonthYear}
            setSelectMonthYear={setSelectMonthYear}
            successfulRequest={(o) => setObject(o)}
            handleClose={() => setIsModalSelectMonthYear(false)}
          />
        )}

        {isModalSelectRatio && (
          <ModalPlanWarehouseRatio
            ratio={selectRatio}
            setSelectRatio={setSelectRatio}
            handleClose={() => setIsModalSelectRatio(false)}
          />
        )}

      </div>
    </ScrollBlock>
  );
}

export default PlanWarehouse;
