import React, {Fragment, useEffect, useState} from "react";
import moment from "moment";
import {getAccountFio, useList} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";

function BuyerOrderObjectHistoryView(props) {
  const { object } = props;

  const {
    list: [list],
  } = useList({
    apiUrl:"/api/order/" +object.uuid + "/items",
    rootUrl:ROOT_URL
  });

  const [history, setHistory] = useState([]);

  useEffect(() => {
    const newHistory = []
    if (!!object) {
      if (!!object.history) {
        object.history.forEach(h => {
          const his = {
            date: moment(h.created_at.substring(0, 19)),
            action: 'state',
            info: h.buyer_order_state.name,
            account: h.account,
          }
          newHistory.push(his)
        })
      }

      if (!!list) {
        list.forEach(i => {
          if (!!i.collecting) {
            i.collecting.forEach(c => {
              if (c.montage_quantity > 0) {
                const his = {
                  date: moment(c.created_at.substring(0, 19)),
                  action: 'montage',
                  info: i.nomenclature.name + ": ",
                  count: c.montage_quantity,
                  account: c.account,
                }
                newHistory.push(his)
              }
              if (c.warehouse_quantity > 0) {
                const his = {
                  date: moment(c.created_at.substring(0, 19)),
                  action: 'warehouse',
                  info: i.nomenclature.name + ": ",
                  count: c.warehouse_quantity,
                  account: c.account,
                }
                newHistory.push(his)
              }
            })
          }

          if (!!i.inspects) {
            i.inspects.forEach(c => {
                const his = {
                  date: moment(c.created_at.substring(0, 19)),
                  action: 'verified',
                  info: i.nomenclature.name,
                  account: c.account,
                }
                newHistory.push(his)
            })
          }

          if (!!i.preparations) {
            i.preparations.forEach(c => {
              const his = {
                date: moment(c.created_at.substring(0, 19)),
                action: 'stickers',
                info: i.nomenclature.name,
                account: c.account,
              }
              newHistory.push(his)
            })
          }

          if (!!i.glued) {
            i.glued.forEach(c => {
              const his = {
                date: moment(c.created_at.substring(0, 19)),
                action: 'glued',
                info: i.nomenclature.name,
                account: c.account,
              }
              newHistory.push(his)
            })
          }
        })
      }

      setHistory(newHistory.sort((a, b) => b.date - a.date))
    } else {
      setHistory([])
    }
  }, [object, list])

  useEffect(() => {

    return () => {
      setHistory(null)
    }
  }, [])

  return (
    !!list &&
      <Fragment>
        {history.length > 0 && (
          <table
            className="Table"
            style={{
              marginBottom: 12,
            }}
          >
            <thead className="Thead">
            <tr className="Table__tr Table__tr_padding Table__tr_border">
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Дата</div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Действие</div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Изменение</div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Ответственный</div>
              </th>
            </tr>
            </thead>
            <tbody>
            {history.map((resource, index) => (
              <tr
                className="Table__tr Table__tr_hover Table__tr_padding Table__tr_border"
                key={"item_" + index}
              >
                <td className="Table__td Table__td_border">
                  <div className="Td__text ">
                    {moment(resource.date).format(
                    "DD.MM.YYYY HH:mm:ss"
                    )}
                  </div>
                </td>
                <td className="Table__td Table__td_border">
                  <div className="Td__text">
                    <div
                      style={{
                        fontSize: 17,
                        fontWeight: 400,
                      }}
                    >
                      {resource.action === 'state' && "Статус"}
                      {resource.action === 'montage' && "Собрано"}
                      {resource.action === 'warehouse' && "Со склада"}
                      {resource.action === 'verified' && (
                        <div
                          style={{
                            color: "white",
                            backgroundColor: "#0277bd",
                            fontSize: 13,
                            fontWeight: 700,
                            padding: "5px 8px 4px",
                            borderRadius: 24,
                            display: "inline-block"
                          }}
                        >
                          Комплект
                        </div>
                      )}
                      {resource.action === 'stickers' && (
                        <div
                          style={{
                            color: "white",
                            backgroundColor: "#7b1fa2",
                            fontSize: 13,
                            fontWeight: 700,
                            padding: "5px 8px 4px",
                            borderRadius: 24,
                            display: "inline-block"
                          }}
                        >
                          Наклейки
                        </div>
                      )}
                      {resource.action === 'glued' && (
                        <div
                          style={{
                            color: "white",
                            backgroundColor: "#4b1fa2",
                            fontSize: 13,
                            fontWeight: 700,
                            padding: "5px 8px 4px",
                            borderRadius: 24,
                            display: "inline-block"
                          }}
                        >
                          Наклеены
                        </div>
                      )}
                    </div>
                  </div>
                </td>
                <td className="Table__td Table__td_border">
                  <div className="Td__text">
                    <div
                      style={{
                        fontSize: 17,
                        fontWeight: 400,
                      }}
                    >
                      {resource.info}
                      {resource.count && (
                        <div style={{
                          display: "inline-block",

                        }}>
                        <div
                          className={
                            "Collected__count Collected__count_green"
                          }
                          style={{
                            borderRadius: 24,
                            display: "flex",
                            marginLeft: 0
                          }}
                        >
                          {resource.count}
                        </div>
                        </div>
                      )}
                    </div>
                  </div>
                </td>
                <td className="Table__td Table__td_border">
                  <div className="Td__text ">
                    <div
                      style={{
                        fontSize: 17,
                        fontWeight: 400,
                      }}
                    >
                      {(!!resource.account && getAccountFio(resource.account)) || "-"}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        )}

        {history.length === 0 && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            alignItems: "center",
            justifyContent: "center",
            padding: "32px 0",
            fontSize: 48,
            opacity: 0.1,
          }}
        >
          Нет истории
        </div>
        )}
      </Fragment>
  );
}

export default BuyerOrderObjectHistoryView;
