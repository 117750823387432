import React, {useRef, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../config/url";
import {useNotification, useList, Modal} from "@vokymlak/kabinet-ui";
import {getErrorMessageByKey} from "@vokymlak/kabinet-ui";

function ModalSelectTimeWorkCategory(props) {
  const {
    list: [list],
  } = useList({apiUrl:"/api/time-work-categories", rootUrl:ROOT_URL});

  const { notification } = useNotification();

  const changeSelect = (item) => {
    axios
      .get('/api/my/time-tracking/time-work-category/'+ item.uuid +'/start', {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('tokenSession'),
        },
      })
      .then(() => {
        props.successfulRequest();
        props.handleClose();

      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
        props.successfulRequest();
      })
  };

  return (
    !!list && (
      <Modal handleClose={() => props.handleClose()}>

          <form className="Form">
            <div className="Form__title">
              Выбор
              <div className="Text__signature">Временной учет</div>
            </div>

            {list.map(item => (
              <div className="Form__field" key={'item_'+item.uuid}>
                <div style={{
                  fontSize: 21,
                  textTransform: "uppercase",
                  backgroundColor: !!item.color ? item.color+"25" : null,
                  padding: 12,
                  borderRadius: 7,
                  fontWeight: 700,
                  cursor: "pointer"
                }} onClick={() => changeSelect(item)}>
                  <div >{item.name}</div>
                </div>
              </div>
            ))}

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => props.handleClose()}>
                Отмена
              </div>
            </div>
          </form>
      </Modal>
    )
  );
}

export default ModalSelectTimeWorkCategory;
