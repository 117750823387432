import React, {useEffect, useRef, useState} from "react";
import { ROOT_URL } from "../../../config/url";
import {useList, useObject, useUnroll} from "@vokymlak/kabinet-ui";
import {useAction} from "@vokymlak/kabinet-ui";
import { useParams } from "react-router-dom";
import {useProfile} from "@vokymlak/kabinet-ui";
import {useCtrlKey} from "../../../hooks/useCtrlKey";

function useStateOrders() {
  let { orderStateUuid } = useParams();

  const {
    object: [object],
  } = useObject({apiUrl:"/api/order-state/" +orderStateUuid, rootUrl:ROOT_URL});

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList(
    {
      apiUrl:"/api/orders/state/"+orderStateUuid,
      rootUrl:ROOT_URL,
      isPagination: true,
      paginationLimit: 100,
      isSearch: true
    }
  );

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  useAction("isUpdate", () => setIsUpdateList(!isUpdateList));

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const {profile} = useProfile()


  const [isSelect, setIsSelect] = useState(false);
  useCtrlKey(() => {
    setIsSelect(true)
  }, () => {
    setIsSelect(false)
  });
  const [selectOrders, setSelectOrders] = useState([]);
  const isSelectOrder = (selectList, uuid) => {
    let isTrue = false
    selectList.forEach(u => {
      if (u === uuid) {
        isTrue = true;
      }
    });
    return isTrue
  }
  const changeSelectOrder = (uuid) => {
    let isNew = true;
    let index = 0;

    selectOrders.forEach((u, i) => {
      if (u === uuid) {
        isNew = false;
        index = i;
      }
    });

    if (isNew) {
      setSelectOrders([...selectOrders, uuid]);
    } else {
      selectOrders.splice(index, 1);
      setSelectOrders([...selectOrders]);
    }
  };

  const [isModalOrdersStateEdit, setIsModalOrdersStateEdit] = useState(false);

  return {
    object,

    list,
    count,
    setList,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,
    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,
    inputSearchText,
    onChangeSearchText,
    searchQuery,
    setIsUpdateList,
    isUpdateList,

    profile,

    isSelect,
    selectOrders, setSelectOrders,
    isSelectOrder, changeSelectOrder,

    isModalOrdersStateEdit,
    setIsModalOrdersStateEdit,
  };
}

export default useStateOrders;
