import React, {useEffect, useState} from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import {SiteBlog} from "./SiteBlogView";
import {SiteBlogTopics} from "./SiteBlogTopics";

export const SiteBlogRouting = () => {
  return (
    <>
      <Routes>
        <Route
          path="/topics"
          element={<SiteBlogTopics />}
        />

        <Route path="/articles" element={<SiteBlog />} />

        <Route exact path="/" element={<Navigate to="./articles" replace />} />
      </Routes>
    </>
  );
};
