import React, {Fragment, useState} from "react";
import NomenclatureSpecifications from "./NomenclatureSpecifications/NomenclatureSpecifications";
import {
  ButtonIcon,
  getErrorMessageByKey,
  Icon,
  TextSearchMarker,
  Tooltip,
  useNotification,
} from "@vokymlak/kabinet-ui";
import {NavLink} from "react-router-dom";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import NomenclaturePhotosList from "./NomenclaturePhotosList";
import NomenclatureProductList from "./NomenclatureProductList";
import styleContent from "../../../styles/modules/Block.module.scss";
import ModalNomenclaturePhotoAdd from "./ModalNomenclaturePhotoAdd";
import ModalTransactionAdd from "../NomenclaturePage/components/NomenclatureTransactions/ModalTransactionAdd";
import NomenclatureStickersList from "./NomenclatureStickersList";
import ModalNomenclatureStickerAdd from "./ModalNomenclatureStickerAdd";
import ModalSpecificationsImport from "./ModalSpecificationsImport";
import ModalNomenclatureInCategory from "./ModalNomenclatureInCategory";
import ModalNomenclatureEdit from "./ModalNomenclatureEdit";

export const Nomenclature = (props) => {
  const {
    item,

    isEdit,

    isUnroll,
    changeUnroll,

    setIsUpdateList,
    isUpdateList,

    searchQuery,

    isSelectComposition,
    changeSelectComposition,
    selectComposition,

    isSelect,
    selectNomenclatures, setSelectNomenclatures,
    isSelectNomenclature, changeSelectNomenclature,

    isTransaction,
  } = props;

  const {notification} = useNotification();

  const [modalObjectAdd, setModalObjectAdd] = useState(null);
  const [modalEdit, setModalEdit] = useState(null);
  const [modalNomenclaturePhotoAdd, setModalNomenclaturePhotoAdd] = useState(null);
  const [modalNomenclatureStickerAdd, setModalNomenclatureStickerAdd] = useState(null);
  const [modalNomenclatureTransactionAdd, setModalNomenclatureTransactionAdd] = useState(null);
  const [modalNomenclatureInCategory, setModalNomenclatureInCategory] = useState(null);

  const isPublishedNomenclature = (object) => {
    axios
      .get("/api/nomenclature/" + object.uuid + "/edit/publish", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const isPublishedOptNomenclature = (object) => {
    axios
      .get("/api/nomenclature/" + object.uuid + "/edit/publish-opt", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  function getAllAmountComposition(compositions) {
    let amount = 0
    if (!!compositions) {
      compositions.forEach(composition => {
        if (!!composition && !!composition.nomenclature && !!composition.nomenclature.price) {
          amount += composition.value / (composition.nomenclature.value / composition.nomenclature.price);
        }
      })
    }
    return amount;
  }

  function calculatePercentageDifference(a, b) {
    // Вычисляем разницу между числами
    let difference = a - b;
    // Вычисляем процент разницы от первого числа
    let percentage = (difference / a) * 100;
    // Округляем до двух знаков после запятой
    // Возвращаем результат
    return percentage.toFixed(2);
  }

  function getAmountComposition(composition) {
    if (!!composition && !!composition.nomenclature && !!composition.nomenclature.price) {
      const amount = composition.value / (composition.nomenclature.value / composition.nomenclature.price)
      return (amount / 100).toFixed(3);
    }
    return 0;
  }

  function getBasicAmount(item) {
    let amount = 0;
    let value = 0
    let basicAmount = 0
    let basicValue = 0

    if (!item) {
      return null;
    }

    if (!!item.basic_specification && !!item.basic_specification.compositions) {
      basicAmount = getAllAmountComposition(item.basic_specification.compositions)
    }

    if (!!item.basic_specification && !!item.basic_specification.value) {
      basicValue = item.basic_specification.value
    }

    if (!!item.value) {
      value = item.value
    }

    amount = basicAmount / basicValue * value

    return amount;
  }

  return (!!item &&
    <Fragment>

      <li
        className={
          isUnroll(item.uuid) ? "List__item active" : "List__item"
        }
        style={{
          marginBottom: 12,
          position: "relative",
          backgroundColor: !!isSelectNomenclature && isSelectNomenclature(selectNomenclatures, item.uuid) && "#0061ff25"
        }}
        onClick={!!isSelect && isSelect ? () => changeSelectNomenclature(item.uuid) : () => changeUnroll(item.uuid)}
      >
        <div
          className="Item__title"
          style={{
            width: "100%",
          }}
        >
          <div
            className="Element__title"
            style={{
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontSize: 19,
                fontWeight: 700,
                width: "100%",
              }}
            >
              {item.id}.&nbsp;
              {(!!item.name && (
                  <TextSearchMarker
                    text={item.name}
                    search={searchQuery}
                  />
                )) ||
                "-"}
              {!!item.basic_specification && (
                <div
                  className="Marker Marker_left Marker_inline Marker_small"
                />
              )}

              {!!item.photos && (
                <Tooltip text={'Фото'} style={{height: 14, marginLeft: 8}}>
                  <Icon
                    name={"custom-attach-file"}
                    color={"#202020"}
                    size={14}
                  />
                </Tooltip>
              )}
            </div>

            {!!item.description && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  marginTop: 2,
                }}
              >
                <div
                  style={{
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                >
                  <div
                    style={{
                      fontSize: 13,
                      fontWeight: 700,
                    }}
                  >
                    Описание:&nbsp;
                  </div>
                  <TextSearchMarker
                    text={item.description}
                    search={searchQuery}
                  />
                </div>
              </div>
            )}

            {!!item.structure && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  marginTop: 2,
                }}
              >
                <div
                  style={{
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                >
                  <div
                    style={{
                      fontSize: 13,
                      fontWeight: 700,
                    }}
                  >
                    Состав:&nbsp;
                  </div>
                  <TextSearchMarker
                    text={item.structure}
                    search={searchQuery}
                  />
                </div>
              </div>
            )}

            {!!item.recommend && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  marginTop: 2,
                }}
              >
                <div
                  style={{
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                >
                  <div
                    style={{
                      fontSize: 13,
                      fontWeight: 700,
                    }}
                  >
                    Рекомендации:&nbsp;
                  </div>
                  <TextSearchMarker
                    text={item.recommend}
                    search={searchQuery}
                  />
                </div>
              </div>
            )}


            {(!!item.value || !!item.price || !!item.price_opt
              || !!item.price_sale || !!item.price_sale_opt || (!!item.is_pcs && item.is_pcs)) && (
              <div
                style={{
                  width: "100%",
                  opacity: 0.75,
                  marginTop: 4,
                  fontWeight: 700,
                  fontSize: 19,
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {!!item.is_pcs && item.is_pcs && (
                  <div
                    style={{
                      fontSize: 11,
                      fontWeight: 700,
                      backgroundColor: "#0061ff",
                      display: "inline-block",
                      color: "white",
                      padding: "2px 4px 2px",
                      borderRadius: 3,
                      margin: "0 4px 0 0",
                    }}
                  >
                    Штучно
                  </div>
                )}
                <span>
                  {!!item.value && !!item.measurement_unit_type ? (item.value / item.measurement_unit_type.ratio) : !!item.value && item.value}
                  {!!item.measurement_unit_type && <span style={{
                    marginLeft: 4,
                    fontWeight: 400,
                    fontSize: 13
                  }}>{item.measurement_unit_type.designation + '.'}</span>}
              </span>

                {!!item.price && <span style={{marginLeft: 16}}>{new Intl.NumberFormat("ru-RU").format(
                  (item.price / 100).toFixed(2))}<span
                  style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб.</span></span>}

                {!!item.price_sale && <span style={{marginLeft: 16}}>{new Intl.NumberFormat("ru-RU").format(
                  (item.price_sale / 100).toFixed(2))}<span
                  style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб. (акция)</span></span>}

                {!!item.price && !!item.price_opt && <span style={{marginLeft: 16}}>|</span>}

                {!!item.price_opt && <span style={{marginLeft: 16}}>{new Intl.NumberFormat("ru-RU").format(
                  (item.price_opt / 100).toFixed(2))}<span
                  style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб. (опт)</span></span>}

                {!!item.price_sale_opt && <span style={{marginLeft: 16}}>{new Intl.NumberFormat("ru-RU").format(
                  (item.price_sale_opt / 100).toFixed(2))}<span
                  style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб. (опт, акция)</span></span>}

                {!!getBasicAmount(item) && <span style={{marginLeft: 16}}>|</span>}

                {!!getBasicAmount(item) && <span style={{marginLeft: 16}}>{new Intl.NumberFormat("ru-RU").format(
                  (getBasicAmount(item) / 100).toFixed(2))}<span
                  style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб. (себес)</span></span>}
              </div>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "absolute",
                top: -8,
                left: 0,
                justifyContent: " flex-end",
                width: "100%",
                padding: "0 8px",
              }}
            >

              {!!item.seo_url && (
                <div
                  style={{
                    fontSize: 11,
                    fontWeight: 700,
                    backgroundColor: "#dbdbdb",
                    display: "inline-block",
                    color: "#202020",
                    padding: "2px 4px 2px",
                    borderRadius: 3,
                    margin: "0 2px",
                  }}
                >
                  {(!!item.seo_url && item.seo_url) || "-"}
                </div>
              )}

              {!!item.nomenclatures && (
                <div
                  style={{
                    fontSize: 11,
                    fontWeight: 700,
                    backgroundColor: "#0061ff",
                    display: "inline-block",
                    color: "#ffffff",
                    padding: "2px 4px 2px",
                    borderRadius: 3,
                    margin: "0 2px",
                  }}
                >
                  Комплект
                </div>
              )}

              {!!item.product_id && (
                <div
                  style={{
                    fontSize: 11,
                    fontWeight: 700,
                    backgroundColor: "#ffffff",
                    display: "inline-block",
                    color: "#0061ff",
                    padding: "2px 4px 2px",
                    borderRadius: 3,
                    margin: "0 2px",
                  }}
                >
                  В комплекте
                </div>
              )}

              {!!item.is_published && (
                <div
                  style={{
                    fontSize: 11,
                    fontWeight: 700,
                    backgroundColor: "#4caf50",
                    display: "inline-block",
                    color: "white",
                    padding: "2px 4px 2px",
                    borderRadius: 3,
                    margin: "0 2px",
                  }}
                >
                  На розницу
                </div>
              )}

              {!!item.is_published_opt && (
                <div
                  style={{
                    fontSize: 11,
                    fontWeight: 700,
                    backgroundColor: "#cf9400",
                    display: "inline-block",
                    color: "white",
                    padding: "2px 4px 2px",
                    borderRadius: 3,
                    margin: "0 2px",
                  }}
                >
                  На опт
                </div>
              )}


              {!!item.is_new && (
                <div
                  style={{
                    fontSize: 11,
                    fontWeight: 700,
                    backgroundColor: "#ffe082",
                    display: "inline-block",
                    color: "#202020",
                    padding: "2px 4px 2px",
                    borderRadius: 3,
                    margin: "0 2px",
                  }}
                >
                  Новинка
                </div>
              )}
              {!!item.is_buyer_choice && (
                <div
                  style={{
                    fontSize: 11,
                    fontWeight: 700,
                    backgroundColor: "#f58ea6",
                    display: "inline-block",
                    color: "#202020",
                    padding: "2px 4px 2px",
                    borderRadius: 3,
                    margin: "0 2px",
                  }}
                >
                  Хит
                </div>
              )}


              {!!item.nomenclature_category && (
                <div
                  style={{
                    fontSize: 11,
                    fontWeight: 700,
                    backgroundColor: "#202020",
                    display: "inline-block",
                    color: "white",
                    padding: "2px 4px 2px",
                    borderRadius: 3,
                    margin: "0 2px",
                  }}
                >
                  {(!!item.nomenclature_category.title && item.nomenclature_category.title) || "-"}
                </div>
              )}

              {!!item.is_deleted && (
                <div
                  style={{
                    fontSize: 11,
                    fontWeight: 700,
                    backgroundColor: "#f44336",
                    display: "inline-block",
                    color: "white",
                    padding: "2px 4px 2px",
                    borderRadius: 3,
                    margin: "0 2px",
                  }}
                >
                  Удалено в интеграции
                </div>
              )}
            </div>

          </div>
        </div>
        <div className="Item__settings">
          <div className="Element__size">
            <div className="Size__title">
              {(!!item.code || !!item.article) && (
                <div
                  style={{
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                >
                  {!!item.code && <TextSearchMarker text={item.code} search={searchQuery}/>}
                  {!!item.code && !!item.article && " | "}
                  {!!item.article && <TextSearchMarker text={item.article} search={searchQuery}/>}
                </div>
              )}
            </div>

            <>
              <div style={{
                height: 32,
                width: 2,
                backgroundColor: "#20202010",
                borderRadius: 4,
                marginLeft: 12,
                textAlign: "right"
              }}/>

              <div style={{
                padding: "4px 8px",
                backgroundColor: ((!!item.quantity && (item.quantity > 0 ? "#2e7d3233" : "#bf360c33")) || "#20202010"),
                borderRadius: 4,
                marginLeft: 12,
                textAlign: "right"
              }}>
                <div style={{
                  fontSize: 21,
                  fontWeight: 700,
                }}>
                  {!item.is_pcs && (
                    <>
                      {(!!item.quantity && !!item.measurement_unit_type ? (item.quantity / item.measurement_unit_type.ratio).toFixed(3) : !!item.quantity && item.quantity.toFixed(3)) || "0"}
                      {!!item.measurement_unit_type && <span style={{
                        marginLeft: 4,
                        fontWeight: 400,
                        fontSize: 13
                      }}>{item.measurement_unit_type.designation + '.'}</span>}
                    </>
                  )}

                  {item.is_pcs && (
                    <>
                      {(!!item.quantity && (item.quantity)) || "0"}
                    </>
                  )}
                </div>
                <div style={{
                  fontSize: 12,
                  fontWeight: 600,
                }}>
                  кол.
                </div>
              </div>
            </>
          </div>
        </div>
      </li>

      {isUnroll(item.uuid) && (
        <li className="List__content" style={{
          backgroundColor: "#00000005",
          border: "1px solid #00000005",
          borderRadius: 7,
          marginBottom: 6,
          paddingTop: 6,
          paddingRight: 14,
          paddingLeft: 14,
        }}>
          <div className="Content__header">
            <div className="Header__title">
              <NavLink to={"/nomenclature/" + item.uuid} target={'_blank'}
                       style={{
                         marginRight: 24,
                       }}>
                <div className="Button__text">Подробнее</div>
              </NavLink>

              {isEdit && (
                <div
                  className="Button__text Button__text_right"
                  onClick={() => setModalNomenclatureStickerAdd(item)}
                >
                  Прикрепить этикетку
                </div>
              )}

              {isEdit && (
                <div
                  className="Button__text Button__text_right"
                  onClick={() => setModalNomenclaturePhotoAdd(item)}
                >
                  Прикрепить фото
                </div>
              )}

              {isEdit && (
                <div
                  className="Button__text"
                  style={{
                    marginRight: 24,
                  }}
                  onClick={() => setModalNomenclatureInCategory(item)}
                >
                  В категорию
                </div>
              )}

              {(isEdit || isTransaction) && (
                <div
                  className="Button__text"
                  style={{
                    marginRight: 24,
                  }}
                  onClick={() => setModalNomenclatureTransactionAdd(item)}
                >
                  Учёт
                </div>
              )}

            </div>
            <div className="Header__settings">
              {isEdit && (
                <div className="Button__swith">
                  <div className="Swith__text">На опт</div>
                  <div
                    className={
                      "Swith" +
                      ((!!item.is_published_opt && " Swith_active ") ||
                        "")
                    }
                    onClick={() => isPublishedOptNomenclature(item)}
                  ></div>
                </div>
              )}
              {isEdit && (
                <div className="Button__swith">
                  <div className="Swith__text">На розницу</div>
                  <div
                    className={
                      "Swith" +
                      ((!!item.is_published && " Swith_active ") ||
                        "")
                    }
                    onClick={() => isPublishedNomenclature(item)}
                  ></div>
                </div>
              )}
              {isEdit && !!item.code && (
                <ButtonIcon
                  tooltipText={"Импорт"}
                  iconName={"custom-cloud-upload"}
                  iconColor={"#202020"}
                  size={26}
                  style={{marginLeft: 12}}
                  onClick={() => setModalObjectAdd(item)}
                />
              )}
              {isEdit && (
                <ButtonIcon
                  tooltipText={"Редактировать"}
                  iconName={"custom-edit"}
                  iconColor={"#202020"}
                  size={36}
                  style={{
                    marginLeft: 16,
                    zIndex: 9,
                  }}
                  onClick={() => setModalEdit(item)}
                />
              )}
            </div>
          </div>

          <NomenclatureStickersList {...props} object={item}/>

          <NomenclaturePhotosList {...props} object={item}/>

          <NomenclatureProductList {...props} object={item}/>

          {item.basic_specification && (

            <div className={styleContent._block}>
              <div className={styleContent._block__title}>Cпецификация</div>
              <div className={styleContent._block__content}>
                <Fragment>
                  <table className="Table">
                    <thead>
                    <tr
                      className="Table__tr"
                      style={{
                        backgroundColor: "#c5c5c5",
                        border: "2px solid #202020",
                      }}
                    >
                      <th className="Table__td">
                        <div className="Td__title">
                          {item.basic_specification.name}
                          {!!item.basic_specification.code && (
                            " | " + item.basic_specification.code
                          )}
                        </div>

                        <div className="Td__title">
                          <div
                            style={{
                              width: "100%",
                              opacity: 0.75,
                              marginTop: 4,
                              fontWeight: 700,
                              fontSize: 19,
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                        <span>
                  {!!item.basic_specification.value && !!item.basic_specification.measurement_unit_type
                    ? (item.basic_specification.value / item.basic_specification.measurement_unit_type.ratio)
                    : !!item.basic_specification.value && item.basic_specification.value}
                          {!!item.basic_specification.measurement_unit_type && <span style={{
                            marginLeft: 4,
                            fontWeight: 400,
                            fontSize: 13
                          }}>{item.basic_specification.measurement_unit_type.designation + '.'}</span>}
              </span>

                            {!!item.basic_specification.compositions &&
                              <span style={{marginLeft: 16}}>{new Intl.NumberFormat("ru-RU").format(
                                (getAllAmountComposition(item.basic_specification.compositions) / 100).toFixed(2))}<span
                                style={{marginLeft: 4, fontWeight: 400, fontSize: 13}}>руб. (себес)</span></span>}
                          </div>
                        </div>

                      </th>
                      <th
                        colSpan={3}
                        className="Table__td"
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <div
                          style={{
                            color: "white",
                            backgroundColor: "#202020",
                            fontSize: 13,
                            fontWeight: 700,
                            padding: "5px 8px 4px",
                            borderRadius: 24,
                            display: "inline-block",
                            marginLeft: 8,
                          }}
                        >
                          Основная
                        </div>
                        {item.basic_specification.is_deleted && (
                          <div
                            style={{
                              color: "white",
                              backgroundColor: "#f44336",
                              fontSize: 13,
                              fontWeight: 700,
                              padding: "5px 8px 4px",
                              borderRadius: 24,
                              display: "inline-block",
                              marginLeft: 8,
                            }}
                          >
                            Удалена
                          </div>
                        )}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {!!item.basic_specification.compositions &&
                      item.basic_specification.compositions.map((composition, compositionIndex) => (
                        <Fragment
                          key={item.basic_specification.uuid + ":" + composition.uuid}
                        >
                          <tr
                            className={
                              isSelectComposition(composition)
                                ? "Table__tr Table__tr_hover Table__tr_focus"
                                : "Table__tr Table__tr_hover"
                            }
                            style={{
                              borderStyle: "2px solid",
                              borderColor: "#bdbdbd",
                              borderBottomColor: isSelectComposition(composition) ? "#202020" : "#bdbdbd",
                            }}
                          >
                            <td
                              className="Table__td"
                              style={{
                                width: "100%",
                              }}
                            >
                              <div className="Td__title Td__title_flex">
                                {compositionIndex + 1} #{" "}
                                {composition.nomenclature.name}
                                {composition.nomenclature
                                  .specification_count > 0 && (
                                  <div
                                    className="Button__circle"
                                    onClick={() =>
                                      changeSelectComposition(
                                        composition
                                      )
                                    }
                                    onContextMenu={() =>
                                      changeSelectComposition(
                                        composition
                                      )
                                    }
                                    style={{
                                      padding:
                                        !!selectComposition &&
                                        composition.id ===
                                        selectComposition.id
                                          ? "3px 4px 5px 4px"
                                          : "5px 4px 3px 4px",
                                    }}
                                  >
                                    {!!selectComposition &&
                                      composition.id ===
                                      selectComposition.id && (
                                        <Icon
                                          name={"custom-expand-less"}
                                          color={"#202020"}
                                          size={24}
                                        />
                                      )}
                                    {(!selectComposition ||
                                      (!!selectComposition &&
                                        composition.id !==
                                        selectComposition.id)) && (
                                      <Icon
                                        name={"custom-expand-more"}
                                        color={"#202020"}
                                        size={24}
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            </td>
                            <td
                              className="Table__td"
                              style={{
                                textAlign: "right",
                                fontSize: 20,
                                fontWeight: 700,
                                opacity: .33
                              }}
                            >
                              {(!!composition.quantity && getAmountComposition(composition)) || 0}
                              <span style={{
                                textAlign: "center",
                                fontSize: 14,
                                fontWeight: 600,
                                marginLeft: 6
                              }}>
                          руб
                        </span>
                            </td>
                            <td
                              className="Table__td"
                              style={{
                                textAlign: "right",
                                fontSize: 20,
                                fontWeight: 700,
                              }}
                            >
                              {composition.quantity}
                            </td>
                            <td
                              className="Table__td Table__td_all-border"
                              style={{
                                textAlign: "center",
                                fontSize: 14,
                                fontWeight: 600,
                              }}
                            >
                              {/*{!!composition.measurement_type && composition.measurement_type.designation}*/}
                              {!!composition.measurement_unit_type ? composition.measurement_unit_type.designation :
                                (!!composition.measurement_type
                                  && composition.measurement_type
                                    .designation)}
                            </td>
                          </tr>
                          {!!selectComposition &&
                            composition.id === selectComposition.id && (
                              <>
                                {composition.nomenclature.specification_count >
                                  0 && (
                                    <tr
                                      className={
                                        isSelectComposition(composition)
                                          ? "Table__tr Table__tr_focus_light"
                                          : "Table__tr"
                                      }
                                      style={{
                                        borderStyle: "2px solid",
                                        borderColor: isSelectComposition(composition) ? "#202020" : "#bdbdbd",
                                      }}
                                    >
                                      <td className="Table__td" colSpan={5} style={{
                                        padding: "0px"
                                      }}>
                                        <NomenclatureSpecifications
                                          nomenclature={composition.nomenclature}
                                          quantity={composition.quantity}
                                          count={composition.quantity}
                                          isFirst={true}
                                        />
                                      </td>
                                    </tr>
                                  )}
                              </>
                            )}
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                </Fragment>
              </div>
            </div>
          )}
        </li>
      )}


      {!!modalNomenclaturePhotoAdd && (
        <ModalNomenclaturePhotoAdd
          object={modalNomenclaturePhotoAdd}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalNomenclaturePhotoAdd(null)}
        />
      )}

      {!!modalNomenclatureStickerAdd && (
        <ModalNomenclatureStickerAdd
          object={modalNomenclatureStickerAdd}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalNomenclatureStickerAdd(null)}
        />
      )}

      {!!modalNomenclatureTransactionAdd && (
        <ModalTransactionAdd
          object={modalNomenclatureTransactionAdd}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalNomenclatureTransactionAdd(null)}
        />
      )}

      {!!modalObjectAdd && (
        <ModalSpecificationsImport
          object={modalObjectAdd}
          successfulRequest={() =>
            setIsUpdateList(!isUpdateList)
          }
          handleClose={() => setModalObjectAdd(null)}
        />
      )}

      {!!modalNomenclatureInCategory && (
        <ModalNomenclatureInCategory
          object={modalNomenclatureInCategory}
          successfulRequest={() =>
            setIsUpdateList(!isUpdateList)
          }
          handleClose={() => setModalNomenclatureInCategory(null)}
        />
      )}

      {!!modalEdit && (
        <ModalNomenclatureEdit
          object={modalEdit}
          successfulRequest={() =>
            setIsUpdateList(!isUpdateList)
          }
          handleClose={() => setModalEdit(null)}
        />
      )}
    </Fragment>
  );
}
