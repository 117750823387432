import axios from "axios";
import { TIME_TRACKING_ERROR, TIME_TRACKING_LOGOUT, TIME_TRACKING_SUCCESS, TIME_TRACKING_REQUEST } from "../actionTypes";
import { ROOT_URL } from "../../config/url";

export async function timeTrackingInit(dispatch, callback) {
    dispatch({ type: TIME_TRACKING_REQUEST });

    try {
        const response = await axios.get(
            '/api/my/time-tracking',
            {
                withCredentials: false,
                baseURL: ROOT_URL,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('tokenSession')
                }
            });

        const timeTracking = response.data;

        dispatch({ type: TIME_TRACKING_SUCCESS, timeTrackingData: timeTracking });

        if (callback) callback();
    }

    catch (error) {
        dispatch({ type: TIME_TRACKING_ERROR, timeTrackingError: 'Что то пошло не так, попробуйте обновить страницу' });
    }
}

export function timeTrackingLogout(dispatch) {
    dispatch({ type: TIME_TRACKING_LOGOUT });
}
