import React, {useState} from "react";
import {Modal} from "@vokymlak/kabinet-ui";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {getErrorMessageByKey} from "@vokymlak/kabinet-ui";
import {useNotification} from "@vokymlak/kabinet-ui";
import {Loading} from "@vokymlak/kabinet-ui";
import moment from "moment";

function ModalOrdersImport(props) {

  const [isRequestDay, setIsRequestDay] = useState(false);
  const [isRequestMonth, setIsRequestMonth] = useState(false);
  const { notification } = useNotification();

  const changeImportMonth = () => {
    setIsRequestMonth(true)
    axios
      .get("/api/orders/import/month?"  +
        "date=" +
        props.month, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsRequestMonth(false)
        props.successfulRequest();
        if (!isRequestDay) {
          props.handleClose();
        }
      })
      .catch((error) => {
        setIsRequestMonth(false)
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeImportDay = () => {
    setIsRequestDay(true)

    axios
      .get("/api/orders/import/day?"  +
        "date=" +
        props.day, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsRequestDay(false)
        props.successfulRequest();
        if (!isRequestMonth) {
          props.handleClose();
        }
      })
      .catch((error) => {
        setIsRequestDay(false)
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const monthText = (month) => {
    const months = [
      "январь",
      "февраль",
      "март",
      "апрель",
      "май",
      "июнь",
      "июль",
      "август",
      "сентябрь",
      "октябрь",
      "ноябрь",
      "декабрь",
    ];

    return (
      months[Number(month) - 1].charAt(0).toUpperCase() +
      months[Number(month) - 1].slice(1)
    );
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <div className="Form">
        <div className="Form__title">Импорт</div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue"
          onClick={!isRequestMonth ? () => changeImportMonth() : () => null}>

            {!isRequestMonth
              ? "Продолжить за " + monthText(moment(props.month, "YYYY-MM-DD").format("MM"))
              : <Loading size="medium" color="blue" />}
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue"
                  onClick={!isRequestDay ? () => changeImportDay() : () => null}>
            {!isRequestDay
              ? "Продолжить за " + moment(props.day, "YYYY-MM-DD").format("DD.MM.YYYY")
              : <Loading size="medium" color="blue" />}
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Отмена
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalOrdersImport;
