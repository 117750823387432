import React, {useEffect, useState} from "react";
import moment from "moment";
import {ROOT_URL} from "../../../../../config/url";
import {useList} from "@vokymlak/kabinet-ui";

function useKpiPackingSummaryTable({date}) {
  const [calendarDays, setCalendarDays] = useState(
    new Date(
      Number(moment(date).format("YYYY")),
      Number(moment(date).format("MM")),
      0
    ).getDate()
  );
  const [days, setDays] = useState(null);

  useEffect(() => {
    setCalendarDays(
      new Date(
        Number(moment(date).format("YYYY")),
        Number(moment(date).format("MM")),
        0
      ).getDate()
    );
  }, [date]);

  useEffect(() => {
    const days = [];
    for (let i = 0; i < calendarDays; i++) {
      let day = i + 1;

      const d = {
        day,
      };

      days.push(d);
    }

    setDays([...days]);
  }, [calendarDays]);

  const weekDayText = (weekDay) => {
    const weekDays = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

    return weekDays[Number(weekDay)];
  };

  useEffect(() => {
    return () => {
      setDays(0);
    };
  }, []);


  const {
    list: [list, _count, setList],
    setApiUrlParams
  } = useList({
    apiUrl: "/api/employee/kpi/packing/account",
    rootUrl: ROOT_URL,
    urlParams: {
      date: date,
    }
  });


  useEffect(() => {
    setList(null)
    const params = {
      date: date,
    };
    setApiUrlParams(params);
  }, [date]);

  const getAccountAllDayCount = (list) => {
    let dayCount = {
      quantityMontage: 0,
      quantityWarehouse: 0,
      amount: 0,
      countEntries: 0,
      buyerOrders: 0,

      countProbes: 0,
      count50s: 0,
      count100s: 0,
      count200s: 0,
      count250s: 0,
      count500s: 0,
      count1000s: 0,
      count5000s: 0,
      count1s: 0,
      time: 0,
    };

    if (!!list) {
      list.map((point) => {
        dayCount.quantityMontage += point.montage_quantity;
        dayCount.quantityWarehouse += point.warehouse_quantity;
        dayCount.amount += point.amount;
        dayCount.countEntries += point.count_entries;
        dayCount.buyerOrders += point.count_buyer_orders;
        dayCount.countProbes += point.count_probes;
        dayCount.count50s += point.count_50s;
        dayCount.count100s += point.count_100s;
        dayCount.count200s += point.count_200s;
        dayCount.count250s += point.count_250s;
        dayCount.count500s += point.count_500s;
        dayCount.count1000s += point.count_1000s;
        dayCount.count5000s += point.count_5000s;
        dayCount.count1s += point.count_1s;
        dayCount.time += point.time;
      });
    }

    return dayCount;
  };

  const getDayCount = (list, year, month, day) => {
    let dayCount = {
      quantityMontage: 0,
      quantityWarehouse: 0,
      amount: 0,
      countEntries: 0,
      buyerOrders: 0,
      buyerOrderNumbers: null,

      countProbes: 0,
      count50s: 0,
      count100s: 0,
      count200s: 0,
      count250s: 0,
      count500s: 0,
      count1000s: 0,
      count5000s: 0,
      count1s: 0,
      time: 0,
    };

    if (!!list) {
      list.map((point) => {
        if (
          Number(moment(point.date.substring(0, 19)).format("YYYY")) ===
          Number(year) &&
          Number(moment(point.date.substring(0, 19)).format("MM")) ===
          Number(month) &&
          Number(moment(point.date.substring(0, 19)).format("DD")) ===
          Number(day)
        ) {
          dayCount.quantityMontage = point.montage_quantity;
          dayCount.quantityWarehouse = point.warehouse_quantity;
          dayCount.amount = point.amount;
          dayCount.countEntries = point.count_entries;
          dayCount.buyerOrders = point.count_buyer_orders;
          dayCount.buyerOrderNumbers = point.buyer_order_numbers;
          dayCount.countProbes = point.count_probes;
          dayCount.count50s = point.count_50s;
          dayCount.count100s = point.count_100s;
          dayCount.count200s = point.count_200s;
          dayCount.count250s = point.count_250s;
          dayCount.count500s = point.count_500s;
          dayCount.count1000s = point.count_1000s;
          dayCount.count5000s = point.count_5000s;
          dayCount.count1s = point.count_1s;
          dayCount.time = point.time;
        }
      });
    }

    return dayCount;
  };


  return {
    date,
    days,
    list,
    weekDayText,
    getDayCount,
    getAccountAllDayCount,
  };
}

export default useKpiPackingSummaryTable;
