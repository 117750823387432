import React, {useRef, useState} from "react";
import {
  ButtonIcon,
  getAccountFio, getErrorMessageByKey, isValidateNullText, jsonFormatText,
  Modal,
  Textarea,
  useNotification,
  useObject,
  useProfile,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";
import styleContent from "../../../../styles/modules/Block.module.scss";
import {ROOT_URL} from "../../../../config/url";
import {Avatar} from "../../../../components/Avatar/Avatar";
import ModalKanbanTaskEdit from "./ModalKanbanTaskEdit";
import ModalKanbanTaskMessageEdit from "./ModalKanbanTaskMessageEdit";
import ModalKanbanTaskMessageAdd from "./ModalKanbanTaskMessageAdd";
import axios from "axios";

function ModalKanbanTaskShow(props) {
  const {profile} = useProfile();
  const [isRequest, setIsRequest] = useState(false);

  const {
    object: [object],
    update: [isUpdateObject, setIsUpdateObject],
  } = useObject({
    apiUrl: "/api/kanban-task/" + props.item.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const {notification} = useNotification();

  const inputText = useRef(null);

  const [modalItemMessageAdd, setModalItemMessageAdd] = useState(null);
  const [modalItemMessageEdit, setModalItemMessageEdit] = useState(null);

  const [modalItemEdit, setModalItemEdit] = useState(null);

  function isMD5Hash(str) {
    // Проверяем, соответствует ли строка формату MD5 хэша.
    const md5Regex = /^[a-f0-9]{32}$/i;
    return md5Regex.test(str);
  }

  const [link, setLink] = useState('messages');

  const changeMessage = (object) => {
    if (isRequest) {
      return;
    }

    const text = jsonFormatText(inputText.current.value);

    if (isValidateNullText(text)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      comment: text,
    });

    setIsRequest(true);

    axios
      .post("/api/kanban-task/" + object.uuid + "/comment/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateObject(!isUpdateObject);
        inputText.current.value = null;
        notification("Сообщение добавлено", "green");
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      })
      .finally(() => {
        setIsRequest(false);
      });
  };

  return (
    !!object &&
    <>
      <Modal handleClose={() => props.handleClose()} contentStyle={{
        width: 520
      }}>
        <div className="Form" style={{
          padding: 6
        }}>
          <div className="Form__title" style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            alignItems: "center",
            fontWeight: 600
          }}>
            <div>
              {object.title}
              <div className="Text__signature">{(!!object.kanban_state && object.kanban_state.title) || "-"}</div>
            </div>
          </div>


          <div className="Form__field">
            <div className={"Field__label"}>Описание</div>
            <div className={"Field__text"}>
              {(!!object.description && object.description) || "-"}
            </div>
          </div>

          <div className="Form__field">
            {!!object.executor && (
              <div
                style={{
                  width: "100%",
                  marginTop: 4,
                }}
              >
                <div style={{
                  padding: "8px",
                  backgroundColor: "#20202010",
                  borderRadius: 7,
                  textAlign: "left"
                }}>

                  <div style={{
                    display: "flex",
                    alignItems: "center"
                  }}>
                    <div style={{
                      marginRight: 8,
                      height: 36
                    }}>
                      {!!object.executor.avatar && isMD5Hash(object.executor.avatar) && (
                        <img height={36} width={36} style={{
                          borderRadius: 36,
                        }} src={ROOT_URL + "/api/account/avatar/" + object.executor.avatar}/>
                      )}
                      {!!object.executor.avatar && !isMD5Hash(object.executor.avatar) && (
                        <img height={36} width={36} style={{
                          borderRadius: 36,
                        }} src={"data:image/jpeg;base64, " + object.executor.avatar}/>
                      )}

                      {!object.executor.avatar && (
                        <Avatar size={36} value={getAccountFio(object.executor)}/>
                      )}
                    </div>
                    <div>
                      <div style={{
                        fontSize: 12,
                        fontWeight: 400,
                      }}>
                        Исполнитель
                      </div>
                      <div style={{
                        fontSize: 15,
                        fontWeight: 700,
                      }}>
                        {getAccountFio(object.executor)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>


          <hr className={"Page__hr"} style={{marginTop: 24}}/>

          {link === 'messages' && (
            <>
              {!!object.comments && (
                <div className="Form__field">
                  <div className={"Field__text"}>
                    <div
                      className={
                        styleContent._block + " " + styleContent._block_bottom
                      }
                    >
                      <div className={styleContent._block__content}>
                        <div className={styleContent._block}>
                          <div className={styleContent._block__content} style={{
                            flexDirection: "column",
                            flexWrap: "nowrap",
                          }}>
                            {object.comments.map((comment) => (
                              <div key={"comment_" + comment.uuid}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: !!comment.author && profile.uuid === comment.author.uuid && "flex-end"
                                  }}>
                                  <div
                                    style={{
                                      display: "inline-block",
                                      width: 320,
                                      backgroundColor: "#ffffff",
                                      marginBottom: 4,
                                      borderRadius: 5,

                                      border: "1px solid #dbdbdb75",
                                      margin: "0 0 8px 0",
                                    }}>

                                    <div style={{
                                      fontSize: 17,
                                      fontWeight: 400,
                                      padding: "8px 10px",
                                      borderRadius: "5px 5px 0 0",
                                      wordWrap: "break-word",
                                      whiteSpace: "pre-line"
                                    }}>
                                      {comment.comment}
                                    </div>
                                    {!!comment.author && (
                                      <div style={{
                                        padding: "4px 10px 4px 8px",
                                        backgroundColor: !!comment.author && profile.uuid === comment.author.uuid ? "white" : "#20202025",
                                        borderRadius: "0 0 5px 5px"
                                      }}>
                                        <div style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          width: "100%",
                                        }}>
                                          <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}>
                                            <div style={{
                                              marginRight: 8
                                            }}>
                                              {!!comment.author.avatar && isMD5Hash(comment.author.avatar) && (
                                                <img height={36} width={36} style={{
                                                  borderRadius: 36,
                                                }} src={ROOT_URL + "/api/account/avatar/" + comment.author.avatar}/>
                                              )}
                                              {!!comment.author.avatar && !isMD5Hash(comment.author.avatar) && (
                                                <img height={36} width={36} style={{
                                                  borderRadius: 36,
                                                }} src={"data:image/jpeg;base64, " + comment.author.avatar}/>
                                              )}

                                              {!comment.author.avatar && (
                                                <Avatar size={36} value={getAccountFio(comment.author)}/>
                                              )}
                                            </div>
                                            <div>
                                              <div style={{
                                                fontSize: 15,
                                                fontWeight: 600
                                              }}>{getAccountFio(comment.author)}</div>
                                              <div style={{
                                                marginTop: 2,
                                                fontSize: 13,
                                                fontWeight: 400
                                              }}>{moment.unix(comment.index).format(
                                                "HH:mm DD.MM.YYYY"
                                              )}</div>
                                            </div>
                                          </div>

                                          <div>
                                            {!!comment.author && profile.uuid === comment.author.uuid && (
                                              <ButtonIcon
                                                // tooltipText={"Редактировать"}
                                                iconName={"custom-edit"}
                                                iconColor={"#202020"}
                                                size={36}
                                                style={{marginLeft: 12}}
                                                onClick={() => setModalItemMessageEdit(comment)}
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}


              {/*{!object.comments && (*/}
              {/*  <div className="Form__field">*/}
              {/*    <div className={"Field__label"}>Нет комментариев</div>*/}
              {/*  </div>*/}
              {/*)}*/}

              <div className="Form__field Form__field_last">
                <Textarea
                  reference={inputText}
                  label="Сообщение *"
                />
              </div>

              {!object.employee_archived_at && (
                <div className="Form__actions Form__actions_center">
                  <button className="Action__button Action__button_all Action__button_blue"
                          onClick={() => changeMessage(object)}>
                    Оставить комментарий
                  </button>
                </div>
              )}
            </>
          )}

          <div className="Form__actions">
            <div
              className="Button__text"
              onClick={() => setModalItemEdit(object)}
              style={{
                color: "#202020"
              }}
            >
              Редактировать
            </div>


            <div className="Action__link" onClick={() => props.handleClose()}>
              Закрыть
            </div>
          </div>
        </div>
      </Modal>

      {!!modalItemMessageAdd && (
        <ModalKanbanTaskMessageAdd
          object={modalItemMessageAdd}
          successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
          handleClose={() => setModalItemMessageAdd(null)}
        />
      )}

      {!!modalItemMessageEdit && (
        <ModalKanbanTaskMessageEdit
          object={modalItemMessageEdit}
          successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
          handleClose={() => setModalItemMessageEdit(null)}
        />
      )}

      {!!modalItemEdit && (
        <ModalKanbanTaskEdit
          object={props.object}
          item={modalItemEdit}
          successfulRequest={() => (props.successfulRequest(), props.handleClose())}
          handleClose={() => setModalItemEdit(null)}
        />
      )}

    </>
  );
}

export default ModalKanbanTaskShow;
