import React, {useState} from "react";
import {ButtonIcon, getAccountFio, Icon, Loading, Tooltip} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import styleContent from "../../../styles/modules/Block.module.scss";
import moment from "moment/moment";
import styleCard from "../../../styles/modules/Card.module.scss";
import {formatAmount} from "../../../functions/formatAmount";

function DashboardRegistrationPage(props) {
  const {
    object,
    setObject,

    setIsUpdateObject,
    isUpdateObject,
  } = props;

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
    <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 21,
            fontWeight: 700,
          }}
        >
          Этап оформления

          {!!object && (
            <div style={{
              marginLeft: 24,
              fontSize: 32,
              marginBottom: 0,
              opacity: .75
            }}>{moment(object.data).format("DD.MM.YYYY")}</div>
          )}
        </div>

        <div className="Title__settings">
          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 12 }}
            onClick={() => (
              setObject(null),
                setIsUpdateObject(!isUpdateObject)
            )}
          />
        </div>
      </div>

      {!!object && (
        <div className="Page__content">
          <div
            className={styleContent._block + " " + styleContent._block_bottom}
          >
            <div className={styleContent._block__content}>
              <div className={styleCard._block}>
                <div
                  className={styleCard._block__number}
                >
                  {((!!object.count && object.count) || 0)}
                </div>
                <div
                  className={styleCard._block__title}
                >
                  Количество
                </div>
              </div>

              <div className={styleCard._block}>
                <div
                  className={styleCard._block__number}
                >
                  {(!!object.amount && new Intl.NumberFormat("ru").format((Number(object.amount) / 100).toFixed(2))) || 0}
                </div>
                <div
                  className={styleCard._block__title}
                >
                  Сумма&nbsp;(руб)
                </div>
              </div>

            </div>
          </div>
        </div>
      )}


      {!object && (
        <div className={"Page__loader"}>
          <Loading/>
        </div>
      )}


      {!!object && (
        <div className="Page__content">
          {!!object.orders && (
            <table className="Table" style={{marginBottom: 24}}>
              <thead className="Thead">
              <tr className="Table__tr Table__tr_padding Table__tr_border">
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title"></div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Дата</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Номер</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Состояние</div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div className="Th__title">Сумма</div>
                </th>
              </tr>
              </thead>
              <tbody>
              {object.orders.map((item, index) => (
                <tr
                  className="Table__tr Table__tr_hover Table__tr_padding Table__tr_border"
                  key={"item_" + index}
                >
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      {index + 1}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      {(!!item.date &&
                        moment(item.date.substring(0, 19)).format(
                          "DD.MM.YYYY HH:mm"
                        )) || "-"}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      <div style={{
                        fontSize: 15,
                      }}>
                        {(!!item.number && item.number) || "-"}
                      </div>
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      {(!!item.buyer_order_state && item.buyer_order_state.name) || "-"}
                    </div>
                  </td>
                  <td className="Table__td Table__td_border">
                    <div className="Td__text Td__text_center">
                      {(!!item.amount && formatAmount(item.amount)) || "-"}
                    </div>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          )}
        </div>
      )}

    </div>
    </ScrollBlock>
  );
}

export default DashboardRegistrationPage;
