import React from "react";
import { ROOT_URL } from "../../../config/url";
import {useAction, useList, useObject} from "@vokymlak/kabinet-ui";
import {useProfile} from "@vokymlak/kabinet-ui";

function useViewOrders() {
  const {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject],
  } = useObject({apiUrl:"/api/orders/view", rootUrl:ROOT_URL});

  const {
    list: [listOrderStateGroups],
  } = useList({apiUrl:"/api/order-state-groups", rootUrl:ROOT_URL});

  useAction("isUpdate", () => setIsUpdateObject(!isUpdateObject));

  const {profile} = useProfile()

  const isViewOrderStateGroup = (group, state) => {
    let isView = false;

    if (!!group && !!group.order_states) {
      group.order_states.map((orderState) => {
        if (orderState.order_state.uuid === state.uuid) {
          isView = true;
        }
      });
    }

    return isView;
  };

  const isViewOrderStateNotGroups = (groups, state) => {
    let isView = true;

    if (!!groups) {
      groups.map((group) => {
        if (!!group.order_states) {
          group.order_states.map((orderState) => {
            if (orderState.order_state.uuid === state.uuid) {
              isView = false;
            }
          });
        }
      });
    }

    return isView;
  };

  return {
    object,
    setObject,
    setIsUpdateObject,
    isUpdateObject,

    profile,

    listOrderStateGroups,
    isViewOrderStateGroup,
    isViewOrderStateNotGroups,
  };
}

export default useViewOrders;
