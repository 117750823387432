import React, {Fragment, useState} from "react";
import {ButtonIcon, getAccountFio, Loading, Pagination, Search, Select, TextSearchMarker,} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../../components/ScrollBlock/ScrollBlock";
import moment from "moment/moment";
import ModalSiteBlogEdit from "./ModalSiteBlogEdit";
import ModalSiteBlogArticle from "./ModalSiteBlogArticle";
import ModalSiteBlogAdd from "./ModalSiteBlogAdd";
import ModalSiteBlogImageAdd from "./ModalSiteBlogImageAdd";
import SiteBlogImagesList from "./SiteBlogImagesList";
import {useNavigate} from "react-router-dom";

function SiteBlogPage(props) {
  const {
    list,
    count,
    setList,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,
  } = props;

  const navigate = useNavigate();

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(null);
  const [modalSiteNewArticle, setModalSiteNewArticle] = useState(null);
  const [modalSiteNewImageAdd, setModalSiteNewImageAdd] = useState(null);

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Блог статей
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
            )}
            <ButtonIcon
              style={{
                marginLeft: 16
              }}
              iconName={"custom-settings"}
              iconColor={"#20202075"}
              size={24}
              onClick={() => navigate("/site-blog/topics")}
            />

            <span style={{width: 220, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{marginLeft: 8}}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 500,
                      title: 500,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{marginLeft: 12}}
              onClick={() => setIsModalObjectAdd(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid) ? "List__item active" : "List__item"
                    }
                    onClick={() => changeUnroll(item.uuid)}
                    style={{
                      padding: "10px 12px",
                      position: "relative",
                      marginBottom: 12
                    }}
                  >
                    <div
                      className="Item__title"
                      style={{
                        width: "100%",
                      }}
                    >
                      <div
                        className="Element__title"
                        style={{
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 19,
                            fontWeight: 700,
                            width: "100%",
                          }}
                        >
                          <span
                            style={{
                              opacity: .5
                            }}>
                          #<TextSearchMarker
                            text={item.id}
                            search={searchQuery}
                          />
                          </span>
                          {!!item.title && (
                            <span
                              style={{
                                marginLeft: 8
                              }}>
                            <TextSearchMarker
                              text={item.title}
                              search={searchQuery}
                            />
                          </span>
                          )}
                        </div>

                        <div
                          style={{
                            fontSize: 15,
                            fontWeight: 500,
                            width: "100%",
                            marginTop: 4
                          }}
                        >
                          {!!item.description && (
                            <div
                              style={{
                                borderRadius: 5,
                                marginBottom: 4
                              }}
                            >
                              <div style={{
                                fontSize: 13
                              }}>Описание
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  marginTop: 2,
                                }}
                              >
                                <TextSearchMarker text={item.description}
                                                  search={searchQuery}/>
                              </div>
                            </div>
                          )}

                          {!!item.author && (
                            <div
                              style={{
                                borderRadius: 5,
                                marginTop: 8,
                                marginBottom: 4
                              }}
                            >
                              <div style={{
                                fontSize: 13
                              }}>Автор
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  marginTop: 2,
                                }}
                              >
                                <span>
                                <TextSearchMarker text={getAccountFio(item.author)}
                                                  search={searchQuery}/>
                                  </span>
                              </div>
                            </div>
                          )}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            top: -8,
                            left: 0,
                            justifyContent: " flex-end",
                            width: "100%",
                            padding: "0 8px",
                          }}
                        >
                          {!!item.seo_url && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#dbdbdb",
                                display: "inline-block",
                                color: "#202020",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              {(!!item.seo_url && item.seo_url) || "-"}
                            </div>
                          )}

                          {item.is_published && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#0061ff",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Публикация
                              <span>
                                {!!item.published_date && ' с ' + moment(item.published_date.substring(0, 10)).format("DD.MM.YYYY")}
                              </span>
                            </div>
                          )}

                          {!!item.site_blog_topic && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#202020",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              {(!!item.site_blog_topic.title && item.site_blog_topic.title) || "-"}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime">
                      </div>
                    </div>
                  </li>

                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      <div className="Content__header">
                        <div className="Header__title">
                          {!item.images && (
                          <div
                            className="Button__text Button__text_right"
                            onClick={() => setModalSiteNewImageAdd(item)}
                          >
                            Прикрепить картинку
                          </div>
                          )}
                          <div
                            className="Button__text Button__text_right"
                            onClick={() => setModalSiteNewArticle(item)}
                          >
                            Статья
                          </div>
                        </div>
                        <div className="Header__settings">
                          <ButtonIcon
                            tooltipText={"Редактировать"}
                            iconName={"custom-edit"}
                            iconColor={"#202020"}
                            size={36}
                            style={{marginLeft: 12}}
                            onClick={() => setModalItemEdit(item)}
                          />
                        </div>
                      </div>

                      <SiteBlogImagesList {...props} object={item} />
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!isModalObjectAdd && (
          <ModalSiteBlogAdd
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}

        {!!modalItemEdit && (
          <ModalSiteBlogEdit
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}

        {modalSiteNewArticle && (
          <ModalSiteBlogArticle
            object={modalSiteNewArticle}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalSiteNewArticle(null)}
          />
        )}

        {!!modalSiteNewImageAdd && (
          <ModalSiteBlogImageAdd
            object={modalSiteNewImageAdd}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalSiteNewImageAdd(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default SiteBlogPage;
