import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey,
  Textarea,
  useNotification,
  Modal,
  jsonFormatText,
  isValidateNullText, Input, Select, useList,
} from "@vokymlak/kabinet-ui";
import moment from "moment";

function convertToSEOAddress(productName) {
  // Преобразование к нижнему регистру
  productName = productName.toLowerCase();

  // Замена пробелов на дефисы
  productName = productName.replace(/\s+/g, '-');

  // Замена русских символов на английские эквиваленты
  const cyrillicToLatinMap = {
    "а": 'a', "б": 'b', "в": 'v', "г": 'g', "д": 'd', "е": 'e', "ё": 'yo', "ж": 'zh',
    "з": 'z', "и": 'i', "й": 'y', "к": 'k', "л": 'l', "м": 'm', "н": 'n', "о": 'o',
    "п": 'p', "р": 'r', "с": 's', "т": 't', "у": 'u', "ф": 'f', "х": 'h', "ц": 'c',
    "ч": 'ch', "ш": 'sh', "щ": 'sch', "ъ": '%', "ы": 'y', "ь": '%', "э": 'e', "ю": 'yu',
    "я": 'ya'
  };
  productName = productName.replace(/[а-яё]/g, function (char) {
    return cyrillicToLatinMap[char] || char;
  });

  // Удаление специальных символов и знаков препинания, сохраняя русские символы
  productName = productName.replace(/[^\dA-Za-zА-Яа-я-]/g, '');

  // Удаление двойных и последних дефисов
  productName = productName.replace(/[-]{2,}/g, '-').replace(/^-+|-+$/g, '');

  // Возвращаем результат
  return productName;
}

function ModalSiteBlogAdd(props) {
  const [isPublished, setIsPublished] = useState(false);

  const {
    list: [topics],
  } = useList({ apiUrl: "/api/site-blog-topics", rootUrl: ROOT_URL });

  const inputTitle = useRef(null);
  const inputSeoUrl = useRef(null);
  const inputPublishedDate = useRef(null);
  const textareaDescription = useRef(null);

  const inputTopicUuid = useRef(null);

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const title = jsonFormatText(inputTitle.current.value);
    const seoUrl = jsonFormatText(inputSeoUrl.current.value);
    const publishedDate = jsonFormatText(inputPublishedDate.current.value);
    const description = jsonFormatText(textareaDescription.current.value);
    const topicUuid = inputTopicUuid.current.value;

    if (isValidateNullText(title)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      title: title,
      description: description,
      published_date: publishedDate,
      is_published: isPublished,
      seo_url: seoUrl,
      site_blog_topic:
        topicUuid.length > 0
          ? topicUuid === "null"
            ? null
            : {
              uuid: topicUuid,
            }
          : null,
    });

    axios
      .post("/api/site-blog/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Новость добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const generateSeoUrl = () => {
    const title = jsonFormatText(inputTitle.current.value);

    if (!isValidateNullText(title)) {
      const seoUrl = convertToSEOAddress(title)
      console.log(seoUrl)
      inputSeoUrl.current.value = seoUrl
    }
  }

  const getSelectTopicOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((type) => {
      const option = {
        value: type.uuid,
        title: type.title,
      };

      options.push(option);
    });

    return options;
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Новая
          <div className="Text__signature">Статья в блог</div>
        </div>

        <div className="Form__field">
          <Input reference={inputTitle} label="Заголовок *"/>
        </div>

        <div className="Form__field">
          <Input reference={inputSeoUrl} label="SEO адрес"
                 isFixed={true}
          />
          <div className="Action__link Action__link_blue" style={{
            marginTop: 4,
            fontSize: 12,
            padding: "0 4px"
          }} onClick={() => generateSeoUrl()}>
            Сгенерировать по названию
          </div>
        </div>

        <div className="Form__field">
          <Textarea reference={textareaDescription} label="Описание"/>
        </div>

        <div className="Form__field Form__field_last">
          <Input reference={inputPublishedDate} type={'date'} isFixed={true} label="Дата пуликации"
                 value={moment().format("YYYY-MM-DD")}/>
        </div>

        <div className="Form__field">
          <div
            className="Button__swith"
            style={{
              padding: "8px",
              width: "100%",
              border: isPublished ? "2px solid #ffe082" : "2px dashed #20202015",
              backgroundColor: isPublished ? "#ffe082" : "#ffffff",
              borderRadius: 6,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setIsPublished(!isPublished)}
          >
            <div
              className="Swith__text"
              style={{
                fontSize: 17,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              На публикацию
            </div>
            <div
              className={
                "Swith Swith_gray" +
                ((isPublished && " Swith_active Swith_active_gray") || "")
              }
            ></div>
          </div>
        </div>

        <div className="Form__field Form__field_last">
          <Select
            isFixed={true}
            reference={inputTopicUuid}
            options={topics && getSelectTopicOptions(topics)}
            label="Тема"
          />
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalSiteBlogAdd;
