import React, { useState, useEffect, useRef } from "react";
import { ROOT_URL } from "../../../config/url";
import {useList, useUnroll, useAction, useProfile} from "@vokymlak/kabinet-ui";
import moment from "moment";

export const useBuyerOrders = () => {
  const {profile} = useProfile()

  const [selectYear, setSelectYear] = useState(moment().format('YYYY'));

  const {
    list: [listYears]
  } = useList({
    apiUrl: "/api/buyer-orders/years",
    rootUrl: ROOT_URL,
  });

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, changeUpdate],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
    setApiUrlParams,
  } = useList({
    apiUrl: "/api/buyer-orders",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 25,
    isSearch: true,
    urlParams: {
      year: selectYear
    }
  });

  const changeSelectYear = (year) => {
    setSelectYear(year);
  };

  useEffect(() => {
    const params = {
      year: selectYear
    };
    setApiUrlParams(params);
  }, [selectYear]);

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const inputSearchText = useRef(null);
  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const { setAction } = useAction("buyer-orders", () => changeUpdate());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "buyer-orders"]);
    } else {
      setAction("buyer-orders");
    }
  }, [list]);


  return {
    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    profile,

    listYears,
    changeSelectYear,
  };
}
