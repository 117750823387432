import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import { ROLE_ADMINISTRATOR } from "../../../config/constants";
import {
  getAccountFio,
  getErrorMessageByKey,
  Modal,
  Select,
  useList,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";

export const ModalAddAccountOpportunityType = (props) => {
  const inputOpportunityTypeTag = useRef(null);

  const { notification } = useNotification();

  const {
    list: [opportunityTypes],
  } = useList({
    apiUrl: "/api/opportunity-types",
    rootUrl: ROOT_URL,
  });

  const {
    object: [account],
    setApiUrl,
  } = useObject({
    apiUrl: "/api/employee/" + props.account.uuid,
    rootUrl: ROOT_URL,
  });

  useEffect(() => {
    if (props.account) {
      setApiUrl("/api/employee/" + props.account.uuid);
    }
  }, [props.account]);

  const changeAdd = (event) => {
    event.preventDefault();

    const opportunityTypeTag = inputOpportunityTypeTag.current.value;

    if (!opportunityTypeTag || opportunityTypeTag.length === 0) {
      return;
    }

    const jsonData = JSON.stringify({
      opportunity_type: {
        tag: opportunityTypeTag,
      },
    });

    axios
      .post("/api/employee/" + props.account.uuid + "/opportunity-type/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Доступ добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const OpportunityTypesAsSelectOptions = (opportunities, account) => {
    const options = [];

    opportunities.map((opportunity) => {
      if (opportunity.tag !== ROLE_ADMINISTRATOR) {
        const option = {
          value: opportunity.tag,
          title: opportunity.name,
        };

        let opportunitySuccess = false;

        account.account_opportunities &&
          account.account_opportunities.map((ar) => {
            if (ar.opportunity_type.tag === opportunity.tag) {
              opportunitySuccess = true;
            }
          });

        if (!opportunitySuccess) {
          options.push(option);
        }
      }
    });

    return options;
  };

  return (
    !!opportunityTypes &&
    !!account && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div
            className="Form__title"
            style={{ fontSize: 19, fontWeight: 700 }}
          >
            {getAccountFio(account)}
            <div className="Text__signature">Аккаунт</div>
          </div>

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={inputOpportunityTypeTag}
              options={
                (!!opportunityTypes && OpportunityTypesAsSelectOptions(opportunityTypes, account)) ||
                []
              }
              label="Доступ"
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
};
