import React, {useRef, useState} from "react";
import {isValidateNullText, jsonFormatText, Modal} from "@vokymlak/kabinet-ui";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {Input} from "@vokymlak/kabinet-ui";
import {getErrorMessageByKey} from "@vokymlak/kabinet-ui";
import {useNotification} from "@vokymlak/kabinet-ui";

function ModalAddTimeWorkCategory(props) {
  const [isUnblockWork, setIsUnblockWork] = useState(false);
  const [isConsidered, setIsConsidered] = useState(false);

  const inputName = useRef(null);
  const inputColor = useRef(null);
  const inputMinOperatingTime = useRef(null);

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const name = jsonFormatText(inputName.current.value);
    const color = inputColor.current.value;
    const minOperatingTime = Number(
      jsonFormatText(inputMinOperatingTime.current.value).length > 0
        ? jsonFormatText(inputMinOperatingTime.current.value)
        : '0'
    );

    if (
      isValidateNullText(name)
    ) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      name: name,
      color: color.length > 0 ? color !== "#000000" ? color : null : null,
      is_unblock_work: isUnblockWork,
      min_operating_time: minOperatingTime,
      is_considered: isConsidered,
    });

    axios
      .post("/api/time-work-category/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Категория работ добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Новая
          <div className="Text__signature">Категория работ</div>
        </div>

        <div className="Form__field">
          <Input
            reference={inputName}
            type="text"
            label="Наименование *"
          />
        </div>

        <div className="Form__field">
          <Input
            reference={inputColor}
            type="color"
            label="Цвет"
            isFixed={true}
          />
        </div>

        <div className="Form__field">
          <Input
            reference={inputMinOperatingTime}
            type="number"
            isFixed={true}
            label="Минимальное время работы (секунды)"
            value={0}
          />
        </div>

        <div className="Form__field">
          <div
            className="Button__swith"
            style={{
              padding: "8px",
              width: "100%",
              border: isUnblockWork
                ? "2px solid #ffe082"
                : "2px dashed #20202015",
              backgroundColor: isUnblockWork ? "#ffe082" : "#ffffff",
              borderRadius: 6,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setIsUnblockWork(!isUnblockWork)}
          >
            <div
              className="Swith__text"
              style={{
                fontSize: 17,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              Разблокировать работу
            </div>
            <div
              className={
                "Swith Swith_gray" +
                ((isUnblockWork && " Swith_active Swith_active_gray") || "")
              }
            ></div>
          </div>
        </div>

        <div className="Form__field Form__field_last">
          <div
            className="Button__swith"
            style={{
              padding: "8px",
              width: "100%",
              border: isConsidered
                ? "2px solid #ffe082"
                : "2px dashed #20202015",
              backgroundColor: isConsidered ? "#ffe082" : "#ffffff",
              borderRadius: 6,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setIsConsidered(!isConsidered)}
          >
            <div
              className="Swith__text"
              style={{
                fontSize: 17,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              Учитывать время
            </div>
            <div
              className={
                "Swith Swith_gray" +
                ((isConsidered && " Swith_active Swith_active_gray") || "")
              }
            ></div>
          </div>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalAddTimeWorkCategory;
