import React, {useEffect, useState} from "react";
import moment from "moment";
import {ROOT_URL} from "../../../../../config/url";
import {useList} from "@vokymlak/kabinet-ui";

function useKpiPackingSummaryTable({date, selectEmployee, dateFromTo}) {
  const [days, setDays] = useState(null);

  useEffect(() => {
    if (!!dateFromTo) {
      const days = [];
      const dateOne = moment(dateFromTo.dateFrom);
      const dateTwo = moment(dateFromTo.dateTo);

      const currentDate = dateOne.clone();

      while (currentDate.isBefore(dateTwo)) {
        const d = {
          day: currentDate.format('YYYY-MM-DD'),
        };
        days.push(d);

        currentDate.add(1, 'day');
      }

      const d = {
        day: dateTwo.format('YYYY-MM-DD'),
      };
      days.push(d);

      setDays([...days]);
    } else {
      const days = [];
      const calendarDays = new Date(
        Number(moment(date).format("YYYY")),
        Number(moment(date).format("MM")),
        0
      ).getDate()

      for (let i = 0; i < calendarDays; i++) {
        let day = i + 1;

        const d = {
          day: moment(date).format("YYYY-MM-") +
            day,
        };
        days.push(d);
      }

      setDays([...days]);
    }
  }, [date, dateFromTo]);

  const weekDayText = (weekDay) => {
    const weekDays = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

    return weekDays[Number(weekDay)];
  };


  useEffect(() => {
    return () => {
      setDays(0);
    };
  }, []);

  const {
    list: [list, _count, setList],
    setApiUrlParams,
  } = useList({
      apiUrl: "/api/kpi/packing/account",
      rootUrl: ROOT_URL,
      urlParams: {
        account: !!selectEmployee && selectEmployee > 0 ? selectEmployee : 0,
        date: date,
        date_from: (!!dateFromTo && dateFromTo.dateFrom) || "",
        date_to: (!!dateFromTo && dateFromTo.dateTo) || "",
      }
    }
  );

  useEffect(() => {
    setList(null)
    const params = {
      account: !!selectEmployee && selectEmployee > 0 ? selectEmployee : 0,
      date: date,
      date_from: (!!dateFromTo && dateFromTo.dateFrom) || "",
      date_to: (!!dateFromTo && dateFromTo.dateTo) || "",
    };
    setApiUrlParams(params);
  }, [selectEmployee, date]);

  const getAllAccountAllDayCount = (list) => {
    let dayCount = {
      quantityMontage: 0,
      quantityWarehouse: 0,
      amount: 0,
      countEntries: 0,
      buyerOrders: 0,

      countProbes: 0,
      count50s: 0,
      count100s: 0,
      count200s: 0,
      count250s: 0,
      count500s: 0,
      count1000s: 0,
      count5000s: 0,
      count1s: 0,
      time: 0,
    };

    if (!!list) {
      list.map((item) => {
        if (!!item.days) {
          item.days.map((point) => {
            dayCount.quantityMontage += point.montage_quantity;
            dayCount.quantityWarehouse += point.warehouse_quantity;
            dayCount.amount += point.amount;
            dayCount.countEntries += point.count_entries;
            dayCount.buyerOrders += point.count_buyer_orders;
            dayCount.countProbes += point.count_probes;
            dayCount.count50s += point.count_50s;
            dayCount.count100s += point.count_100s;
            dayCount.count200s += point.count_200s;
            dayCount.count250s += point.count_250s;
            dayCount.count500s += point.count_500s;
            dayCount.count1000s += point.count_1000s;
            dayCount.count5000s += point.count_5000s;
            dayCount.count1s += point.count_1s;
            dayCount.time += point.time;
          });
        }
      });
    }

    return dayCount;
  };

  const getAccountAllDayCount = (list) => {
    let dayCount = {
      quantityMontage: 0,
      quantityWarehouse: 0,
      amount: 0,
      countEntries: 0,
      buyerOrders: 0,

      countProbes: 0,
      count50s: 0,
      count100s: 0,
      count200s: 0,
      count250s: 0,
      count500s: 0,
      count1000s: 0,
      count5000s: 0,
      count1s: 0,
      time: 0,
    };

    if (!!list) {
      list.map((point) => {
        dayCount.quantityMontage += point.montage_quantity;
        dayCount.quantityWarehouse += point.warehouse_quantity;
        dayCount.amount += point.amount;
        dayCount.countEntries += point.count_entries;
        dayCount.buyerOrders += point.count_buyer_orders;
        dayCount.countProbes += point.count_probes;
        dayCount.count50s += point.count_50s;
        dayCount.count100s += point.count_100s;
        dayCount.count200s += point.count_200s;
        dayCount.count250s += point.count_250s;
        dayCount.count500s += point.count_500s;
        dayCount.count1000s += point.count_1000s;
        dayCount.count5000s += point.count_5000s;
        dayCount.count1s += point.count_1s;
        dayCount.time += point.time;
      });
    }

    return dayCount;
  };

  const getAllAccountDayCount = (list, year, month, day) => {
    let dayCount = {
      quantityMontage: 0,
      quantityWarehouse: 0,
      amount: 0,
      countEntries: 0,
      buyerOrders: 0,

      countProbes: 0,
      count50s: 0,
      count100s: 0,
      count200s: 0,
      count250s: 0,
      count500s: 0,
      count1000s: 0,
      count5000s: 0,
      count1s: 0,
      time: 0,
    };

    if (!!list) {
      list.map((item) => {
        if (!!item.days) {
          item.days.map((point) => {
            if (
              Number(moment(point.date.substring(0, 19)).format("YYYY")) ===
              Number(year) &&
              Number(moment(point.date.substring(0, 19)).format("MM")) ===
              Number(month) &&
              Number(moment(point.date.substring(0, 19)).format("DD")) ===
              Number(day)
            ) {
              dayCount.quantityMontage += point.montage_quantity;
              dayCount.quantityWarehouse += point.warehouse_quantity;
              dayCount.amount += point.amount;
              dayCount.countEntries += point.count_entries;
              dayCount.buyerOrders += point.count_buyer_orders;
              dayCount.countProbes += point.count_probes;
              dayCount.count50s += point.count_50s;
              dayCount.count100s += point.count_100s;
              dayCount.count200s += point.count_200s;
              dayCount.count250s += point.count_250s;
              dayCount.count500s += point.count_500s;
              dayCount.count1000s += point.count_1000s;
              dayCount.count5000s += point.count_5000s;
              dayCount.count1s += point.count_1s;
              dayCount.time += point.time;
            }
          });
        }
      });
    }

    return dayCount;
  };

  const getDayCount = (list, year, month, day) => {
    let dayCount = {
      quantityMontage: 0,
      quantityWarehouse: 0,
      amount: 0,
      countEntries: 0,
      buyerOrders: 0,
      buyerOrderNumbers: null,

      countProbes: 0,
      count50s: 0,
      count100s: 0,
      count200s: 0,
      count250s: 0,
      count500s: 0,
      count1000s: 0,
      count5000s: 0,
      count1s: 0,
      time: 0,
    };

    if (!!list) {
      list.map((point) => {
        if (
          Number(moment(point.date.substring(0, 19)).format("YYYY")) ===
          Number(year) &&
          Number(moment(point.date.substring(0, 19)).format("MM")) ===
          Number(month) &&
          Number(moment(point.date.substring(0, 19)).format("DD")) ===
          Number(day)
        ) {
          dayCount.quantityMontage = point.montage_quantity;
          dayCount.quantityWarehouse = point.warehouse_quantity;
          dayCount.amount = point.amount;
          dayCount.countEntries = point.count_entries;
          dayCount.buyerOrders = point.count_buyer_orders;
          dayCount.buyerOrderNumbers = point.buyer_order_numbers;
          dayCount.countProbes = point.count_probes;
          dayCount.count50s = point.count_50s;
          dayCount.count100s = point.count_100s;
          dayCount.count200s = point.count_200s;
          dayCount.count250s = point.count_250s;
          dayCount.count500s = point.count_500s;
          dayCount.count1000s = point.count_1000s;
          dayCount.count5000s = point.count_5000s;
          dayCount.count1s = point.count_1s;
          dayCount.time = point.time;
        }
      });
    }

    return dayCount;
  };


  return {
    selectEmployee,
    date,
    days,
    list,
    weekDayText,
    getDayCount,
    getAllAccountDayCount,
    getAllAccountAllDayCount,
    getAccountAllDayCount,
  };
}

export default useKpiPackingSummaryTable;
