import React, {useEffect, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../config/url";
import {useNotification, Modal} from "@vokymlak/kabinet-ui";
import {getAccountFio, getErrorMessageByKey} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";
import TimerWorkCategory from "./TimerWorkCategory";

function ModalSelectTimeWorkCategory(props) {
  // const [dataTime, setDataTime] = useState(new Date());
  // const [timer, setTimer] = useState(null);
  // const [seconds, setSeconds] = useState(0);

  const { notification } = useNotification();

  // useEffect(() => {
  //   if (!!props.object) {
  //     setDataTime((!!props.object.start_time && props.object.start_time.substring(0, 19)) || null);
  //   } else {
  //     setDataTime(null);
  //   }
  // }, [props.object]);
  //
  //
  // useEffect(() => {
  //   return () => {
  //     if (!!timer) {
  //       clearInterval(timer);
  //     }
  //     setTimer(null);
  //     setSeconds(0);
  //   };
  // }, []);
  //
  // useEffect(() => {
  //   if (!!dataTime) {
  //     const tick = (num) => setSeconds(seconds + num);
  //
  //     setTimer(setInterval(() => tick(1), 1000));
  //   } else {
  //     if (!!timer) {
  //       clearInterval(timer);
  //       setTimer(null);
  //     }
  //     setSeconds(0);
  //   }
  //
  //   return () => {
  //     if (!!timer) {
  //       clearInterval(timer);
  //     }
  //   };
  // }, [seconds, dataTime]);
  //
  // const getTimeString = (dataTime) => {
  //   const dateA = moment(new Date());
  //   const dateB = moment(dataTime);
  //
  //   const diff = dateA.diff(dateB, "seconds");
  //
  //   return timeFormat(diff * 1000);
  // };
  //
  // const timeFormat = (function () {
  //   function num(val) {
  //     val = Math.floor(val);
  //     return val < 10 ? "0" + val : val;
  //   }
  //
  //   return function (ms /**number*/) {
  //     let sec = ms / 1000,
  //       hours = (sec / 3600) % 24,
  //       minutes = (sec / 60) % 60,
  //       seconds = sec % 60;
  //     if (sec / 60 > 60) {
  //       return num(hours) + ":" + num(minutes) + ":" + num(seconds);
  //     } else {
  //       return num(minutes) + ":" + num(seconds);
  //     }
  //   };
  // })();

  const changeEnd = () => {
    axios
      .get('/api/my/time-tracking/time-work-category/end', {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('tokenSession'),
        },
      })
      .then(() => {
        props.successfulRequest();
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(!!error.response && !!error.response.data && error.response.data.error), "red");
        props.successfulRequest();
      })
  };

  return (
    !!props.object && (
      <Modal
        dialogStyle={{
          height: "100%",
          width: "100%",
          margin: 0,
          backgroundColor: "white"
        }}
        contentStyle={{
          borderRadius: 0,
          height: "100%",
          width: "100%",
          backgroundColor: props.object.time_work_category.color+'75'
      }}
      handleClose={() => null}>


          <div style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            alignItems: "center",
            placeContent: "center",
            height: "100%",
          }}>


            <div style={{
              fontSize: 48,
              fontWeight: 700,
              color: "#202020",
              marginBottom: 24
            }}>
              {props.object.time_work_category.name}
            </div>

            <div style={{
              fontSize: 32,
              fontWeight: 400,
              color: "#202020",
              marginBottom: 72
            }}>
              {getAccountFio(props.profile)}
            </div>

            <div style={{
              fontSize: 72,
              fontWeight: 700,
              color: "#202020",
              marginBottom: 72
            }}>
              {/*{(!!dataTime && getTimeString(dataTime)) || "00:00"}*/}
              <TimerWorkCategory object={props.object} />
            </div>

            <div style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <button style={{
              backgroundColor: "#ffffff75",
              border: "none",
              padding: "12px 24px",
              borderRadius: 32,
              fontSize: 24,
              fontWeight: 400,
              color: "#202020",
              cursor: "pointer",
              margin: "0 12px"
            }}
                    onClick={() => changeEnd()}
            >
              Завершить
            </button>

            {props.object.time_work_category.is_unblock_work && (
            <button style={{
              backgroundColor: "#ffffff75",
              border: "none",
              padding: "12px 24px",
              borderRadius: 32,
              fontSize: 24,
              fontWeight: 400,
              color: "#202020",
              cursor: "pointer",
              margin: "0 12px"
            }}
                    onClick={() => props.handleClose()}
            >
               Свернуть
            </button>
            )}
            </div>
          </div>
      </Modal>
    )
  );
}

export default ModalSelectTimeWorkCategory;
