import React from "react";
import {
  Loading,
  ButtonIcon,
} from "@vokymlak/kabinet-ui";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import styleContent from "../../../styles/modules/Block.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";
import moment from "moment";

function LeadChartsPage(props) {
  const {
    object,
    setObject,

    setIsUpdateObject,
    isUpdateObject,

    objectMonth,
    setObjectMonth,

    setIsUpdateObjectMonth,
    isUpdateObjectMonth,
  } = props;

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div className="Page__title">
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
              }}
            >
              Сводная по лидам
            </div>
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => (setObject(null), setIsUpdateObject(!isUpdateObject), setObjectMonth(null), setIsUpdateObjectMonth(!isUpdateObjectMonth))}
            />
          </div>
        </div>

        {!object && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}

        {!!object && (
          <div className="Page__content">
            <div
              className={styleContent._block + " " + styleContent._block_bottom}
            >
              <div className={styleContent._block__title}>
                {(!!object.statistic_date && moment(object.statistic_date).format("DD.MM.YYYY")) || "-"}
              </div>
              <div className={styleContent._block__content}>
                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >
                    {((!!object.seo_site_form_count && object.seo_site_form_count) || 0) +
                      ((!!object.ad_site_form_count && object.ad_site_form_count) || 0) +
                      ((!!object.seo_jivo_count && object.seo_jivo_count) || 0)}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    Всего
                  </div>
                </div>

                <div style={{
                  height: 88,
                  width: 2,
                  backgroundColor: "#20202010",
                  borderRadius: 4,
                  marginRight: 8,
                  marginBottom: 8,
                  textAlign: "right"
                }}>
                </div>

                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >
                    {(!!object.ad_spending && new Intl.NumberFormat("ru").format((Number(object.ad_spending) / 100).toFixed(2))) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    Затраты&nbsp;на&nbsp;рек&nbsp;(руб)
                  </div>
                </div>

                <div style={{
                  height: 88,
                  width: 2,
                  backgroundColor: "#20202010",
                  borderRadius: 4,
                  marginRight: 8,
                  marginBottom: 8,
                  textAlign: "right"
                }}>
                </div>

                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >
                    {(!!object.ad_spending &&
                      new Intl.NumberFormat("ru").
                      format((Number(object.ad_spending / ((!!object.ad_site_form_count && object.ad_site_form_count) || 0)) / 100)
                        .toFixed(2))) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    Стоимость&nbsp;лида&nbsp;с&nbsp;рек&nbsp;(руб)
                  </div>
                </div>

                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >
                    {(!!object.ad_spending &&
                      new Intl.NumberFormat("ru").
                      format((Number(object.ad_spending / (
                        ((!!object.seo_site_form_count && object.seo_site_form_count) || 0) +
                        ((!!object.ad_site_form_count && object.ad_site_form_count) || 0) +
                        ((!!object.seo_jivo_count && object.seo_jivo_count) || 0)
                      ) / 100))
                        .toFixed(2))) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    Стоимость&nbsp;лида&nbsp;общая&nbsp;(руб)
                  </div>
                </div>

                <div style={{
                  height: 88,
                  width: 2,
                  backgroundColor: "#20202010",
                  borderRadius: 4,
                  marginRight: 8,
                  marginBottom: 8,
                  textAlign: "right"
                }}>
                </div>


                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >
                    {(!!object.seo_site_form_count && object.seo_site_form_count) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    С Формы (seo)
                  </div>
                </div>

                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >
                    {(!!object.ad_site_form_count && object.ad_site_form_count) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    С&nbsp;Формы&nbsp;(рек)
                  </div>
                </div>

                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >{(!!object.seo_jivo_count && object.seo_jivo_count) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    С&nbsp;Jivo
                  </div>
                </div>

                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >{(!!object.avito_count && object.avito_count) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    С&nbsp;Avito
                  </div>
                </div>

                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >{(!!object.vk_count && object.vk_count) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    С&nbsp;vk
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!!objectMonth && (
          <div className="Page__content">
            <div
              className={styleContent._block + " " + styleContent._block_bottom}
            >
              <div className={styleContent._block__title}>
                за месяц
              </div>
              <div className={styleContent._block__content}>
                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >
                    {((!!objectMonth.seo_site_form_count && objectMonth.seo_site_form_count) || 0) +
                      ((!!objectMonth.ad_site_form_count && objectMonth.ad_site_form_count) || 0) +
                      ((!!objectMonth.seo_jivo_count && objectMonth.seo_jivo_count) || 0)}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    Всего
                  </div>
                </div>

                <div style={{
                  height: 88,
                  width: 2,
                  backgroundColor: "#20202010",
                  borderRadius: 4,
                  marginRight: 8,
                  marginBottom: 8,
                  textAlign: "right"
                }}>
                </div>

                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >
                    {(!!objectMonth.ad_spending && new Intl.NumberFormat("ru").format((Number(objectMonth.ad_spending) / 100).toFixed(2))) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    Затраты&nbsp;на&nbsp;рек&nbsp;(руб)
                  </div>
                </div>

                <div style={{
                  height: 88,
                  width: 2,
                  backgroundColor: "#20202010",
                  borderRadius: 4,
                  marginRight: 8,
                  marginBottom: 8,
                  textAlign: "right"
                }}>
                </div>

                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >
                    {(!!objectMonth.ad_spending &&
                      new Intl.NumberFormat("ru").
                      format((Number(objectMonth.ad_spending / ((!!objectMonth.ad_site_form_count && objectMonth.ad_site_form_count) || 0)) / 100)
                        .toFixed(2))) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    Стоимость&nbsp;лида&nbsp;с&nbsp;рек&nbsp;(руб)
                  </div>
                </div>

                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >
                    {(!!objectMonth.ad_spending &&
                      new Intl.NumberFormat("ru").
                      format((Number(objectMonth.ad_spending / (
                        ((!!objectMonth.seo_site_form_count && objectMonth.seo_site_form_count) || 0) +
                        ((!!objectMonth.ad_site_form_count && objectMonth.ad_site_form_count) || 0) +
                        ((!!objectMonth.seo_jivo_count && objectMonth.seo_jivo_count) || 0)
                      ) / 100))
                        .toFixed(2))) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    Стоимость&nbsp;лида&nbsp;общая&nbsp;(руб)
                  </div>
                </div>

                <div style={{
                  height: 88,
                  width: 2,
                  backgroundColor: "#20202010",
                  borderRadius: 4,
                  marginRight: 8,
                  marginBottom: 8,
                  textAlign: "right"
                }}>
                </div>


                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >
                    {(!!objectMonth.seo_site_form_count && objectMonth.seo_site_form_count) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    С Формы (seo)
                  </div>
                </div>

                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >
                    {(!!objectMonth.ad_site_form_count && objectMonth.ad_site_form_count) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    С&nbsp;Формы&nbsp;(рек)
                  </div>
                </div>

                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >{(!!objectMonth.seo_jivo_count && objectMonth.seo_jivo_count) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    С&nbsp;Jivo
                  </div>
                </div>

                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >{(!!objectMonth.avito_count && objectMonth.avito_count) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    С&nbsp;Avito
                  </div>
                </div>

                <div className={styleCard._block}>
                  <div
                    className={styleCard._block__number}
                  >{(!!objectMonth.vk_count && objectMonth.vk_count) || 0}
                  </div>
                  <div
                    className={styleCard._block__title}
                  >
                    С&nbsp;vk
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </ScrollBlock>
  );
}

export default LeadChartsPage;
