import React from "react";
import KpiPackingAnalytics from "./components/KpiPackingAnalytics/KpiPackingAnalytics";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {ButtonIcon} from "@vokymlak/kabinet-ui";


export const OrderMarksPage = (props) => {
  const {
    monthText,
    selectMonth,
    selectYear,


    changeSelectMonthMinus,
    changeSelectMonthPlus,
  } = props;

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
    <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 21,
            fontWeight: 700,
          }}
        >
          Отметки по заказам
        </div>


        <div className="Title__settings">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
              marginLeft: 12,
            }}
          >
            {monthText(selectMonth)}
            <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                {selectYear}
              </span>
            <ButtonIcon
              tooltipText={"Назад"}
              iconName={"custom-circle-arrow-left"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 12 }}
              onClick={() => changeSelectMonthMinus()}
            />
            <ButtonIcon
              tooltipText={"В&nbsp;перёд"}
              iconName={"custom-circle-arrow-right"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 6 }}
              onClick={() => changeSelectMonthPlus()}
            />
            {/*<ButtonIcon*/}
            {/*  tooltipText={"Обновить"}*/}
            {/*  iconName={"custom-change-circle"}*/}
            {/*  iconColor={"#20202075"}*/}
            {/*  size={28}*/}
            {/*  style={{ marginLeft: 12 }}*/}
            {/*  onClick={() => null}*/}
            {/*/>*/}
          </div>
        </div>
      </div>

      <KpiPackingAnalytics
        date={selectYear + "-" + selectMonth + "-01"}
      />
    </div>
    </ScrollBlock>
  );
}
