import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
  isValidateNullText,
  Input,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";

function ModalLeadStatisticEdit(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/lead-statistic/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputStatisticDate = useRef(null);

  const inputSeoSiteFormCount = useRef(null);
  const inputSeoJivoCount = useRef(null);
  const inputAdSiteFormCount = useRef(null);
  const inputAdSpending = useRef(null);
  const inputAvitoCount = useRef(null);
  const inputVkCount = useRef(null);

  const [isDelete, setIsDelete] = useState(false);

  const { notification } = useNotification();


  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0 && numberString !== "null"
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeEdit = (event) => {
    event.preventDefault();

    const statisticDate = inputStatisticDate.current.value;
    const seoSiteFormCount = inputSeoSiteFormCount.current.value;
    const seoJivoCount = inputSeoJivoCount.current.value;
    const adSiteFormCount = inputAdSiteFormCount.current.value;
    const avitoCount = inputAvitoCount.current.value;
    const vkCount = inputVkCount.current.value;

    const adSpending = inputAdSpending.current.value;

    if (isValidateNullText(statisticDate)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      statistic_date: !!statisticDate && statisticDate.length > 0 ? statisticDate : null,
      seo_site_form_count: Number(seoSiteFormCount),
      seo_jivo_count: Number(seoJivoCount),
      ad_site_form_count: Number(adSiteFormCount),
      ad_spending: numberConvert(adSpending),
      avito_count: Number(avitoCount),
      vk_count: Number(vkCount),
    });

    axios
      .put("/api/lead-statistic/" + props.object.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/lead-statistic/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные по лидам удалены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              {(!!object.statistic_date && (moment(object.statistic_date).format(
                  "DD.MM.YYYY"))) ||
                "-"}
              <div className="Text__signature">Данные по лидам</div>
            </div>

            <div className="Form__field">
              <Input
                reference={inputStatisticDate}
                type="date"
                isFixed={true}
                label="Дата"
                value={
                  !!object.statistic_date
                    ? moment(object.statistic_date).format("YYYY-MM-DD")
                    : null
                }
              />
            </div>

            <div className="Form__field">
              <Input type={'number'} reference={inputSeoSiteFormCount} label="Лиды с формы (seo)"
                     value={!!object.seo_site_form_count && object.seo_site_form_count}/>
            </div>

            <div className="Form__field">
              <Input type={'number'} reference={inputAdSiteFormCount} label="Лиды с формы (реклама)"
                     value={!!object.ad_site_form_count && object.ad_site_form_count}/>
            </div>

            <div className="Form__field">
              <Input type={'number'} reference={inputSeoJivoCount} label="Лиды с Jivo"
                     value={!!object.seo_jivo_count && object.seo_jivo_count}/>
            </div>

            <div className="Form__field Form__field_last">
              <Input type={'number'} reference={inputAdSpending} label="Затраты на рекламу"
                     value={!!object.ad_spending && (object.ad_spending / 100)}/>
            </div>

            <div className="Form__field Form__field_last">
              <Input type={'number'} reference={inputAvitoCount} label="Лиды с Avito"
                     value={!!object.avito_count && (object.avito_count / 100)}/>
            </div>

            <div className="Form__field Form__field_last">
              <Input type={'number'} reference={inputVkCount} label="Лиды с vk"
                     value={!!object.vk_count && (object.vk_count / 100)}/>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
            </div>
          </form>
        )}
        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              {(!!object.statistic_date && (moment(object.statistic_date).format(
                  "DD.MM.YYYY"))) ||
                "-"}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Продолжить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalLeadStatisticEdit;
