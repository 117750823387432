import React, { useRef, useState } from "react";
import axios from "axios";
import {
  getErrorMessageByKey,
  useNotification,
  Input,
  Modal, Loading,
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import moment from "moment/moment";

function ModalOrderHistoryExport(props) {
  const [isRequest, setIsRequest] = useState(false);
  const [dateFromTo, setDateFromTo] = useState(null);
  const inputDateFrom = useRef(null);
  const inputDateTo = useRef(null);

  const { notification } = useNotification();

  const changeImport = () => {
    if (!dateFromTo) {
      return null;
    }

    setIsRequest(true);

    axios
      .get(
        "/api/orders/history/export/xlsx?date_from="+dateFromTo.dateFrom+"&date_to="+dateFromTo.dateTo,
        {
          responseType: 'arraybuffer',
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            'Content-Type': 'application/json',
            Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          ("история_заказов_"+
            dateFromTo.dateFrom.replaceAll("-","_")+
          "_по_"+dateFromTo.dateTo.replaceAll("-","_")+".xlsx").toLowerCase()
        ); //or any other extension
        document.body.appendChild(link);
        link.click();

        notification("Выгрузка завершена", "green");
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      })
      .finally(() => {
        setIsRequest(false);
      });
  };

  const changeDateFromTo = () => {
    const dateFromString = inputDateFrom.current.value;
    const dateToString = inputDateTo.current.value;

    if (!!dateFromString && dateFromString.length > 0 &&
      !!dateToString && dateToString.length > 0) {
      const dateFrom = moment(dateFromString);
      const dateTo = moment(dateToString);

      if (dateFrom.isBefore(dateTo)) {
        setDateFromTo({
          dateFrom: dateFrom.format("YYYY-MM-DD"),
          dateTo: dateTo.format("YYYY-MM-DD"),
        })
      } else {
        setDateFromTo(null);
      }
    } else {
      setDateFromTo(null);
    }
  }

  return (
    <Modal handleClose={() => props.handleClose()}>
      <div className="Form">
        <div className="Form__title">
          Выгрузка истории заказов
        </div>

        <div className="Form__field">
          <Input
            isFixed={true}
            reference={inputDateFrom}
            type={'date'}
            label="Дата от"
            onChange={() => changeDateFromTo()}
          />
        </div>

        <div className="Form__field Form__field_last">
          <Input
            isFixed={true}
            reference={inputDateTo}
            type={'date'}
            label="Дата до"
            onChange={() => changeDateFromTo()}
          />
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue"
                  onClick={!isRequest ? () => changeImport() : () => null}>
            {!isRequest
              ? "Выгрузить"
              : <Loading size="medium" color="blue"/>}
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Закрыть
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalOrderHistoryExport;
