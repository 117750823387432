import React, { useState, useEffect, useRef } from "react";
import {Modal} from "@vokymlak/kabinet-ui";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {getErrorMessageByKey} from "@vokymlak/kabinet-ui";
import {useNotification} from "@vokymlak/kabinet-ui";
import {Icon} from "@vokymlak/kabinet-ui";

function ModalBuyerOrderExpertAssessmentEdit(props) {
  const [buyerOrder, setBuyerOrder] = useState(props.buyerOrder);

  const [expertAssessment, setExpertAssessment] = useState(0);

  useEffect(() => {
    if (!!buyerOrder) {
      setExpertAssessment(
        !!buyerOrder.expert_assessment
          ? buyerOrder.expert_assessment
          : 0
      )
    } else {
      setExpertAssessment(0)
    }
  }, [buyerOrder])


  const { notification } = useNotification();

  useEffect(() => {
    if (props.buyerOrder) {
      setBuyerOrder(props.buyerOrder);
    }
  }, [props.buyerOrder]);

  useEffect(() => {
    return () => {
      setBuyerOrder(null);
    };
  }, []);

  const addAccountRole = (event) => {
    event.preventDefault();

    const jsonData = JSON.stringify({
      expert_assessment: !!expertAssessment ? expertAssessment > 0 ? expertAssessment : null : null,
    });

    axios
      .post("/api/buyer-order/" + buyerOrder.id + "/expert-assessment", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    buyerOrder && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => addAccountRole(event)}>
          <div className="Form__title">
            Оценка сложности
            <br />
            <div className="Text__signature">
              {buyerOrder.number} от {buyerOrder.date}
            </div>
          </div>

          <div className="Form__field Form__field_last" style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "space-between",
          }}>
            <div className="Button__icon Button__icon_32" style={{
              opacity: expertAssessment === 0 ? 1 : .33
            }} onClick={() => setExpertAssessment(0)}>
              <Icon name={'custom-looks-0'} color={'#202020'} size={32} />
            </div>
            <div className="Button__icon Button__icon_32" style={{
              opacity: expertAssessment === 1 ? 1 : .33
            }} onClick={() => setExpertAssessment(1)}>
              <Icon name={'custom-looks-1'} color={'#c0ca33'} size={32} />
            </div>
            <div className="Button__icon Button__icon_32" style={{
              opacity: expertAssessment === 2 ? 1 : .33
            }} onClick={() => setExpertAssessment(2)}>
              <Icon name={'custom-looks-2'} color={'#fdd835'} size={32} />
            </div>
            <div className="Button__icon Button__icon_32" style={{
              opacity: expertAssessment === 3 ? 1 : .33
            }} onClick={() => setExpertAssessment(3)}>
              <Icon name={'custom-looks-3'} color={'#ffb300'} size={32} />
            </div>
            <div className="Button__icon Button__icon_32" style={{
              opacity: expertAssessment === 4 ? 1 : .33
            }} onClick={() => setExpertAssessment(4)}>
              <Icon name={'custom-looks-4'} color={'#fb8c00'} size={32} />
            </div>
            <div className="Button__icon Button__icon_32" style={{
              opacity: expertAssessment === 5 ? 1 : .33
            }} onClick={() => setExpertAssessment(5)}>
              <Icon name={'custom-looks-5'} color={'#f4511e'} size={32} />
            </div>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Применить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalBuyerOrderExpertAssessmentEdit;
