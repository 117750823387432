import React from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {getErrorMessageByKey, Modal, useNotification, useObject,} from "@vokymlak/kabinet-ui";
import moment from "moment";

function ModalSiteOrderToSentMail(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/site-opt-order/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const {notification} = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    axios
      .get("/api/site-opt-order/" + props.object.uuid + "/sent-mail", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeEdit(event)}>
          <div
            className="Form__title"
            style={{fontSize: 19, fontWeight: 700}}
          >
            №{object.id}{!!object.date && ' от ' + moment(object.date.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
            <div className="Text__signature">Отправить на почту</div>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Отправить
            </button>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalSiteOrderToSentMail;
