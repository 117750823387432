import { useEffect, useCallback } from 'react';

const useMinuteInterval = (callback) => {
  const callbackMemoized = useCallback(callback, [callback]);

  useEffect(() => {
    const intervalId = setInterval(callbackMemoized, 60000); // 60000 миллисекунд = 1 минута

    return () => clearInterval(intervalId);
  }, [callbackMemoized]);
};

export default useMinuteInterval;
