import React from "react";
import {ButtonIcon, Icon} from "@vokymlak/kabinet-ui";
import ModalFunnelOrderStateAdd from "./ModalFunnelOrderStateAdd";
import {Loading} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";

function FunnelOrderStatesPage(props) {
  const {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalObjectItemAdd,
    setModalObjectItemAdd,

    deleteItem,
  } = props;

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
    <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 21,
            fontWeight: 700,
          }}
        >
          Логика статусов
        </div>

        <div className="Title__settings">
          {!!list && (
            <ButtonIcon
              tooltipText={
                list.length === unrolls.length ? "Свернуть" : "Развернуть"
              }
              iconName={
                list.length === unrolls.length
                  ? "custom-unfold-less"
                  : "custom-unfold-more"
              }
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => changeAllUnroll(list, "uuid")}
            />
          )}

          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 8 }}
            onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
          />
        </div>
      </div>


      {!list && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}


      {!!list && (
        <div className="Page__content">
          <ul className="List">
          {list.map((item) => (
              <React.Fragment key={"item_" + item.tag}>
                <li
                  className={
                    isUnroll(item.tag) ? "List__item active" : "List__item"
                  }
                  onClick={() => changeUnroll(item.tag)}
                >
                  <div className="Item__title">
                    <div className="Element__title">{item.name}</div>
                  </div>
                  <div className="Item__settings">
                    <div className="Element__datetime"></div>
                  </div>
                </li>

                {isUnroll(item.tag) && (
                  <li className="List__content">
                    <div className="Content__header">
                      <div className="Header__title">
                        <div
                          className="Button__text"
                          onClick={() => setModalObjectItemAdd(item)}
                        >
                          Добавить статус
                        </div>
                      </div>
                      <div className="Header__settings"></div>
                    </div>

                    <div>
                      <table className="Table">
                        <tbody>
                                {!!item.order_states &&
                                  item.order_states.map(
                                    (orderState, index) => (
                                            <tr className="Table__tr" key={index}>
                                            <td className="Table__td">
                                              <div className="Td__title">
                                                {index + 1}
                                                {". "}
                                                {
                                                  orderState
                                                    .order_state.name
                                                }
                                              </div>
                                            </td>
                                            <td className="Table__td Table__td_child">
                                              <ButtonIcon
                                                tooltipText={"Удалить"}
                                                iconName={"custom-delete"}
                                                iconColor={"#0064ff"}
                                                size={32}
                                                onClick={() =>
                                                  deleteItem(
                                                    orderState.uuid
                                                  )
                                                }
                                              />
                                            </td>
                                          </tr>
                                    ))}
                              </tbody>
                      </table>
                    </div>


                  </li>
                )}
              </React.Fragment>
            ))}
        </ul>
      </div>
      )}

      {!!modalObjectItemAdd && (
        <ModalFunnelOrderStateAdd
          object={modalObjectItemAdd}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalObjectItemAdd(null)}
        />
      )}

    </div>
    </ScrollBlock>
  );
}

export default FunnelOrderStatesPage;
