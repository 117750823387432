import React, {useEffect} from "react";
import { ROOT_URL } from "../../../config/url";
import {
  useList,
  useAction,
  useUnroll,
} from "@vokymlak/kabinet-ui";

function useLeadStatistics() {
  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, changeUpdate],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
  } = useList({
    apiUrl: "/api/lead-statistics",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 100,
  });

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const { setAction } = useAction("lead-statistics", () => changeUpdate());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "lead-statistics"]);
    } else {
      setAction("lead-statistics");
    }
  }, [list]);

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  return {
    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,
  };
}

export default useLeadStatistics;
