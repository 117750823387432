import React, { Fragment, useEffect, useState } from "react";
import {ButtonIcon, Icon} from "@vokymlak/kabinet-ui";
import {Tooltip} from "@vokymlak/kabinet-ui";
import ModalOrderStateGroupAdd from "./ModalOrderStateGroupAdd";
import ModalOrderStateGroupEdit from "./ModalOrderStateGroupEdit";
import ModalGroupOrderStateAdd from "./ModalGroupOrderStateAdd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {Loading} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";

export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

function OrderStateGroupsPage(props) {
  const {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,
    modalObjectItemAdd,
    setModalObjectItemAdd,

    updateList,

    deleteControlGroupPoint,

    onDragEndDrop,
  } = props;

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
    <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 21,
            fontWeight: 700,
          }}
        >
          Группы статусов
          {!!list && (
            <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({list.length})
              </span>
          )}
        </div>

        <div className="Title__settings">
          {!!list && (
            <ButtonIcon
              tooltipText={
                list.length === unrolls.length ? "Свернуть" : "Развернуть"
              }
              iconName={
                list.length === unrolls.length
                  ? "custom-unfold-less"
                  : "custom-unfold-more"
              }
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => changeAllUnroll(list, "uuid")}
            />
          )}

          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 8 }}
            onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
          />

          <ButtonIcon
            tooltipText={"Добавить"}
            iconName={"custom-add"}
            iconColor={"#0064ff"}
            size={36}
            style={{ marginLeft: 12 }}
            onClick={() => setIsModalObjectAdd(true)}
          />
        </div>
      </div>


      {!list && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}


      {!!list && (
        <div className="Page__content">
          <ul className="List">
          <DragDropContext onDragEnd={onDragEndDrop}>
            <StrictModeDroppable droppableId={"droppable"}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {!!list &&
                    list.map((item, index) => (
                      <Draggable
                        draggableId={String(item.uuid)}
                        index={index}
                        key={"draggable_" + item.uuid}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <Fragment key={"item_" + item.uuid}>
                <li
                  className={
                    isUnroll(item.uuid) ? "List__item active" : "List__item"
                  }
                  onClick={() => changeUnroll(item.uuid)}
                >
                  <div className="Item__title">
                    <div className="Element__title">{item.name}</div>
                  </div>
                  <div className="Item__settings">
                    <div className="Element__datetime"></div>
                  </div>
                </li>

                {isUnroll(item.uuid) && (
                  <li className="List__content">
                    <div className="Content__header">
                      <div className="Header__title">
                        <div
                          className="Button__text"
                          onClick={() => setModalObjectItemAdd(item)}
                        >
                          Добавить
                        </div>
                      </div>
                      <div className="Header__settings">
                        <ButtonIcon
                          tooltipText={"Редактировать"}
                          iconName={"custom-edit"}
                          iconColor={"#202020"}
                          size={36}
                          style={{ marginLeft: 12 }}
                          onClick={() => setModalItemEdit(item)}
                        />
                      </div>
                    </div>

                    <div>
                      <table className="Table">
                        <tbody>
                          {!!item.order_states &&
                            item.order_states.map((state, index) => (
                              <tr className="Table__tr" key={index}>
                                <td className="Table__td">
                                  <div className="Td__title"
                                       style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}>
                                    <div
                                      style={{
                                        display: "inline-block",
                                        height: 10,
                                        width: 10,
                                        borderRadius: 10,
                                        backgroundColor: !!state.order_state.color ? state.order_state.color : "#9e9e9e",
                                        marginRight: 12
                                      }} />
                                    {index + 1}
                                    {". "}
                                    {state.order_state.name}
                                  </div>
                                </td>
                                <td className="Table__td Table__td_child">
                                  <ButtonIcon
                                    tooltipText={"Удалить"}
                                    iconName={"custom-delete"}
                                    iconColor={"#0064ff"}
                                    size={32}
                                    onClick={() =>
                                      deleteControlGroupPoint(state.uuid)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>


                  </li>
                )}
                            </Fragment>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </ul>
      </div>
      )}

      {!!modalItemEdit && (
        <ModalOrderStateGroupEdit
          object={modalItemEdit}
          successfulRequestEdit={(item) => updateList(item)}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalItemEdit(null)}
        />
      )}
      {!!isModalObjectAdd && (
        <ModalOrderStateGroupAdd
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setIsModalObjectAdd(false)}
        />
      )}
      {!!modalObjectItemAdd && (
        <ModalGroupOrderStateAdd
          object={modalObjectItemAdd}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalObjectItemAdd(null)}
        />
      )}
    </div>
    </ScrollBlock>
  );
}

export default OrderStateGroupsPage;
