import React, { Fragment, useEffect, useState } from "react";
import {ButtonIcon, Icon} from "@vokymlak/kabinet-ui";
import {Tooltip} from "@vokymlak/kabinet-ui";
import ModalOrderStatesImport from "./ModalOrderStatesImport";
import ModalOrderStateEdit from "./ModalOrderStateEdit";
import styleContent from "../../../styles/Content.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {Loading} from "@vokymlak/kabinet-ui";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";

export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

function ImportOrderStatesPage(props) {
  const {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,

    updateList,

    onDragEnd,
  } = props;

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
    <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 21,
            fontWeight: 700,
          }}
        >
          Статусы заказа
          {!!list && (
            <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({list.length})
              </span>
          )}
        </div>

        <div className="Title__settings">
          {!!list && (
            <ButtonIcon
              tooltipText={
                list.length === unrolls.length ? "Свернуть" : "Развернуть"
              }
              iconName={
                list.length === unrolls.length
                  ? "custom-unfold-less"
                  : "custom-unfold-more"
              }
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => changeAllUnroll(list, "uuid")}
            />
          )}

          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 8 }}
            onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
          />

          <ButtonIcon
            tooltipText={"Импорт"}
            iconName={"custom-cloud-upload"}
            iconColor={"#202020"}
            size={26}
            style={{ marginLeft: 12 }}
            onClick={() => setIsModalObjectAdd(true)}
          />
        </div>
      </div>


      {!list && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}


      {!!list && (
        <div className="Page__content">
          <ul className="List">
          <DragDropContext onDragEnd={onDragEnd}>
            <StrictModeDroppable droppableId={"droppable"}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {!!list &&
                    list.map((item, index) => (
                      <Draggable
                        draggableId={String(item.uuid)}
                        index={index}
                        key={"draggable_" + item.uuid}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <Fragment key={"item_" + item.uuid}>
                              <li
                                className={
                                  isUnroll(item.uuid)
                                    ? "List__item active"
                                    : "List__item"
                                }
                                onClick={() => changeUnroll(item.uuid)}
                              >
                                <div className="Item__title">
                                  <div className="Element__title" style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}>
                                    <div
                                      style={{
                                        display: "inline-block",
                                        height: 10,
                                        width: 10,
                                        borderRadius: 10,
                                        backgroundColor: !!item.color ? item.color : "#9e9e9e",
                                        marginRight: 8
                                      }} />
                                    {index + 1}
                                    {". "}
                                    {item.name}
                                  </div>
                                </div>
                                <div className="Item__settings">
                                  <div className="Element__datetime">
                                    {item.fs_is_deleted && (
                                      <div
                                      style={{
                                      display: "inline-block",
                                      backgroundColor: "#f44336",
                                      padding: "0 4px 2px",
                                      fontSize: 15,
                                      borderRadius: 4,
                                      color: "white",
                                      fontWeight: 700,
                                    }}
                                      >
                                      Удалено
                                      </div>
                                      )}
                                  </div>
                                </div>
                              </li>

                              {isUnroll(item.uuid) && (
                                <li className="List__content">
                                  <div className="Content__header">
                                    <div className="Header__title"></div>
                                    <div className="Header__settings">
                                      <ButtonIcon
                                        tooltipText={"Редактировать"}
                                        iconName={"custom-edit"}
                                        iconColor={"#202020"}
                                        size={36}
                                        style={{ marginLeft: 12 }}
                                        onClick={() => setModalItemEdit(item)}
                                      />
                                    </div>
                                  </div>

                                  <div style={{marginBottom: 12}}>
                                    <table className="Table">
                                      <tbody>
                                      {!!item.name && (
                                        <tr className="Table__tr">
                                          <td className="Table__td">
                                            <div className="Td__title">
                                              Наименование
                                            </div>
                                          </td>
                                          <td className="Table__td">
                                            <div className="Td__title">
                                              {item.name}{!!item.fs_name && (" (" + item.fs_name + ")")}
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                      {!!item.fs_hash && (
                                        <tr className="Table__tr">
                                          <td className="Table__td">
                                            <div className="Td__title">
                                              Ключ (импорт)
                                            </div>
                                          </td>
                                          <td className="Table__td">
                                            <div className="Td__title">
                                              {item.fs_hash}
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                      {item.fs_is_deleted && (
                                        <tr className="Table__tr">
                                          <td className="Table__td">
                                            <div className="Td__title">
                                              Удалено (импорт)
                                            </div>
                                          </td>
                                          <td className="Table__td">
                                            <div className="Td__title">
                                              Да
                                            </div>
                                          </td>
                                        </tr>
                                      )}
                                      </tbody>
                                    </table>
                                  </div>

                                  <div className={styleContent._block}>
                                    <div
                                      className={styleContent._block__content}
                                    >
                                      <div className={styleCard._block}>
                                        <div
                                          className={styleCard._block__number}
                                        >
                                          {!!item.group_order_states
                                            ? item.group_order_states
                                              .length
                                            : "0"}
                                        </div>
                                        <div
                                          className={styleCard._block__title}
                                        >
                                          Группы
                                        </div>
                                      </div>
                                      <div className={styleCard._block}>
                                        <div
                                          className={styleCard._block__number}
                                        >
                                          {!!item.count_orders
                                            ? item.count_orders
                                            : "0"}
                                        </div>
                                        <div
                                          className={styleCard._block__title}
                                        >
                                          Заказы
                                        </div>
                                      </div>
                                    </div>
                                  </div>


                                </li>
                              )}
                            </Fragment>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </ul>
      </div>
      )}

      {!!modalItemEdit && (
        <ModalOrderStateEdit
          object={modalItemEdit}
          successfulRequestEdit={(item) => updateList(item)}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalItemEdit(null)}
        />
      )}
      {!!isModalObjectAdd && (
        <ModalOrderStatesImport
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setIsModalObjectAdd(false)}
        />
      )}
    </div>
    </ScrollBlock>
  );
}

export default ImportOrderStatesPage;
