import React, { useRef } from "react";
import {Modal} from "@vokymlak/kabinet-ui";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {getErrorMessageByKey, useNotification, Input, useObject} from "@vokymlak/kabinet-ui";

function ModalOrderStateEdit(props) {
  const {
    object: [object],
  } = useObject({apiUrl:"/api/order-state/" +props.object.uuid, rootUrl:ROOT_URL});

  const inputName = useRef(null);
  const inputColor = useRef(null);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const name = inputName.current.value;
    const color = inputColor.current.value;

    if (!name || name.length === 0) {
      return;
    }

    const jsonData = JSON.stringify({
      name: name,
      color: color.length > 0 ? color !== "#000000" ? color : null : null,
    });

    axios
      .put(
        "/api/order-state/" + props.object.uuid + "/edit",
        jsonData,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then((response) => {
        props.successfulRequestEdit(response.data);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });

    props.handleClose();
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {object.name}
              <div className="Text__signature">Статус заказа</div>
            </div>

            <div className="Form__field">
              <Input
                reference={inputName}
                type="text"
                label="Наименование *"
                value={object.name}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputColor}
                type="color"
                label="Цвет"
                isFixed={true}
                value={object.color}
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => props.handleClose()}>
                Отмена
              </div>
            </div>
          </form>
      </Modal>
    )
  );
}

export default ModalOrderStateEdit;
