function rootUrl() {
    if (document.location.host === "localhost") {
        return "http://localhost";
    } else if (document.location.host === "localhost:7778") {
        return "http://localhost:7777";
    } else if (document.location.host === "localhost:7777") {
        return "http://localhost:7777";
    } else {
        const url = new URL(document.location.href);
        // console.log("Error, the http address connection is not defined.");
        return url.protocol + "//" + url.hostname;
    }
}

export const ROOT_URL = rootUrl();

function wsUrl() {
    if (document.location.host === "localhost") {
        return "ws://localhost";
    } else if (document.location.host === "localhost:7778") {
        return "ws://localhost:7777";
    } else if (document.location.host === "localhost:7777") {
        return "ws://localhost:7777";
    } else {
        const url = new URL(document.location.href);
        // console.log("Error, the ws address connection is not defined.");
        return url.protocol.replace("http", "ws") + "//" + url.hostname;
    }
}

export const WS_URL = wsUrl();