// Роль администратора.
export const ROLE_ADMINISTRATOR = 'ADMINISTRATOR'
// Роль руководитель.
export const ROLE_DIRECTOR = 'DIRECTOR'
// Роль сотрудника.
export const ROLE_EMPLOYEE = 'EMPLOYEE'
// Роль клиент.
export const ROLE_CLIENT = 'CLIENT'
// Роль фасовщик.
export const ROLE_PACKER = 'PACKER'
// Роль комплектовщик.
export const ROLE_PICKER = 'PICKER'
// Роль наклейщик.
export const ROLE_STICKER = 'STICKER'
// Роль менеджер.
export const ROLE_MANAGER = 'MANAGER'
// Роль аналитик.
export const ROLE_ANALYST = 'ANALYST'
// Роль курьер.
export const ROLE_COURIER = 'COURIER'
// Менеджер по производству.
export const ROLE_MANAGER_INDUSTRY = 'MANAGER_INDUSTRY'
// Менеджер по сайту.
export const ROLE_MANAGER_SITE = 'MANAGER_SITE'
// Менеджер по продажам.
export const ROLE_MANAGER_SALES = 'MANAGER_SALES'

// Код ошибки таймаута запроса.
export const ERROR_CODE_ECONNABORTED = 'ECONNABORTED'
// Код ошибки отмены запроса.
export const ERR_CANCELED = 'ERR_CANCELED'

// Код ошибки 400.
export const ERR_BAD_REQUEST = 'ERR_BAD_REQUEST'


// Продукт.
export const PRODUCT = 'PRODUCT'
// Материал.
export const MATERIAL = 'MATERIAL'
// Ресурс.
export const RESOURCE = 'RESOURCE'

// Количество.
export const AMOUNT = 'AMOUNT'
// Масса.
export const WEIGHT = 'WEIGHT'
// Объем.
export const VOLUME = 'VOLUME'
// Пропорция.
export const PROPORTION = 'PROPORTION'
// Процент.
export const PRECENT = 'PRECENT'

// Поступление.
export const INCOMING = 'INCOMING'
// Изготовление.
export const MAKING = 'MAKING'
// Расход.
export const EXPENSE = 'EXPENSE'
// Списание.
export const CANCELLATION = 'CANCELLATION'
// Ревизия.
export const REVISION = 'REVISION'

// Тикет идея.
export const TICKET_TYPE_IDEA = 'IDEA'
// Тикет ошибка.
export const TICKET_TYPE_ERROR = 'ERROR'

// Тикет запланировано.
export const TICKET_STATUS_PLANNED = 'PLANNED'
// Тикет завершено.
export const TICKET_STATUS_COMPLETED = 'COMPLETED'
// Тикет отклонено.
export const TICKET_STATUS_REJECTED = 'REJECTED'

// Сотрудники.
export const OPPORTUNITY_EMPLOYEES = 'EMPLOYEES'
// Тикеты.
export const OPPORTUNITY_TICKETS = 'TICKETS'
// Справочник.
export const OPPORTUNITY_HANDBOOK = 'HANDBOOK'
// Канбан.
export const OPPORTUNITY_KANBAN = 'KANBAN'
// Учет времени.
export const OPPORTUNITY_TIME_TRACKING = 'TIME_TRACKING'
// Табель.
export const OPPORTUNITY_TIME_SHEET = 'TIME_SHEET'
// Прайсы.
export const OPPORTUNITY_PRICES = 'PRICES'
// Сайт.
export const OPPORTUNITY_SITE = 'SITE'
// Лиды.
export const OPPORTUNITY_LEAD = 'LEAD'
// Показатели.
export const OPPORTUNITY_SUMMARY = 'SUMMARY'
// Экраны.
export const OPPORTUNITY_DASHBOARD = 'DASHBOARD'
// Номенклатура.
export const OPPORTUNITY_NOMENCLATURES = 'NOMENCLATURES'
// Вся номенклатура.
export const OPPORTUNITY_NOMENCLATURES_ALL = 'NOMENCLATURES_ALL'
// Планы.
export const OPPORTUNITY_PLAN = 'PLAN'
// План доставки.
export const OPPORTUNITY_PLAN_DISPATCH = 'PLAN_DISPATCH'
// Заказы.
export const OPPORTUNITY_ORDER = 'ORDER'
// Заказы по статусам.
export const OPPORTUNITY_ORDER_STATUS = 'ORDER_STATUS'
// Все заказы.
export const OPPORTUNITY_ORDER_ALL = 'ORDER_ALL'
// Персональные.
export const OPPORTUNITY_MY = 'MY'
// Склад продукции.
export const OPPORTUNITY_PRODUCT_WAREHOUSE = 'PRODUCT_WAREHOUSE'
