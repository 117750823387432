import React, {useState} from "react";
import {Modal, useList} from "@vokymlak/kabinet-ui";
import ModalSelectNomenclature from "./ModalSelectNomenclature";
import {ROOT_URL} from "../../../config/url";
import ModalTransactionAddComing
  from "../../ScreenNomenclatures/NomenclaturePage/components/NomenclatureTransactions/ModalTransactionAddComing";

export const ModalAddProductWarehouse = (props) => {
  const [selectItem, setSelectItem] = useState(null)
  const [isModalSelectItem, setIsModalSelectItem] = useState(false)

  const {
    list: [list],
  } = useList({
    apiUrl: "api/nomenclatures/list",
    rootUrl: ROOT_URL,
  });


  const [modalObjectComing, setModalObjectComing] = useState(null)

  return (
    !!list && (
      <>
        <Modal handleClose={() => props.handleClose()}>
          <div className="Form">
            <div
              className="Form__title"
              style={{fontSize: 19, fontWeight: 700}}
            >
              Новый товар
              <div className="Text__signature">Добавить на склад</div>
            </div>

            {!selectItem && (
              <div className="Form__field Form__field_last"
                   style={{
                     cursor: "pointer"
                   }}
                   onClick={() => setIsModalSelectItem(true)}>
                <div className="Field__label">
                  Номенклатура
                </div>
                <div className="Field__text">
                  -
                </div>
              </div>
            )}

            {!!selectItem && (
              <div className="Form__field Form__field_last"
                   style={{
                     cursor: "pointer"
                   }}
                   onClick={() => setIsModalSelectItem(true)}>
                <div className="Field__label">
                  Номенклатура
                </div>
                <div className="Field__text">
                  {selectItem.id + ". " + selectItem.name + (!!selectItem.article ? " - " + selectItem.article : "")}
                </div>
              </div>
            )}

            {!!selectItem && (
              <div className="Form__actions Form__actions_center">
                <button className="Action__button Action__button_all Action__button_blue"
                        onClick={() => setModalObjectComing(selectItem)}>
                  Приход
                </button>
              </div>
            )}
          </div>
        </Modal>

        {isModalSelectItem && (
          <ModalSelectNomenclature
            item={selectItem}
            successfulRequest={item =>
              setSelectItem(item)
            }
            handleClose={() => setIsModalSelectItem(false)}
          />
        )}

        {!!modalObjectComing && (
          <ModalTransactionAddComing
            nomenclature={modalObjectComing}
            successfulRequest={() => (props.successfulRequest(), props.handleClose())}
            handleClose={() => setModalObjectComing(null)}
          />
        )}
      </>
    )
  );
};
