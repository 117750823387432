import React, { useState } from "react";
import { ROOT_URL } from "../../../config/url";
import {useUnroll} from "@vokymlak/kabinet-ui";
import {useAction, useList, dndArray} from "@vokymlak/kabinet-ui";
import axios from "axios";

function useTimeWorkCategories() {
  const {
    list: [list, _count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
  } = useList({apiUrl:"/api/time-work-categories", rootUrl:ROOT_URL});

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(null);

  useAction("settings", () => updateList());

  const onDragEnd = (result) => {
    if (result.destination) {
      const oldIndex = result.source.index;
      const newIndex = result.destination.index;
      const objectId = result.draggableId;

      let object = null;

      if (!!list) {
        list.map((item) => {
          if (objectId === item.uuid) {
            object = { ...item };
          }
        });

        setList([...dndArray(list, oldIndex, newIndex)]);

        axios
          .get("/api/time-work-category/" + object.uuid + "/index/" + newIndex, {
            withCredentials: false,
            baseURL: ROOT_URL,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("tokenSession"),
            },
          })
          .then(() => {
            setIsUpdateList(!isUpdateList);
          })
          .catch(() => {
            setIsUpdateList(!isUpdateList);
          });
      }
    }
  };

  return {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,

    onDragEnd,
  };
}

export default useTimeWorkCategories;
